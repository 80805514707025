import {AiFillDatabase, AiFillCloud} from "@onemind-services-llc/react-icons-ng/ai";
import {BsLaptop} from "@onemind-services-llc/react-icons-ng/bs";
import {FaPaw} from "@onemind-services-llc/react-icons-ng/fa";
import {
  GiBearFace,
  GiBearHead,
  GiBull,
  GiDeer,
  GiDolphin,
  GiElephant,
  GiFox,
  GiLion,
  GiMammoth,
  GiPolarBear,
  GiRat,
  GiWolfHead,
} from "@onemind-services-llc/react-icons-ng/gi";
import {HiServerStack} from "@onemind-services-llc/react-icons-ng/hi";

const Icons = {
  "Fox-24": GiFox,
  "Bobcat-32": FaPaw,
  "Wolf-48": GiWolfHead,
  "Honey-Badger-56": GiRat,
  "Polar-Bear-88": GiPolarBear,
  "Orca-192": GiDolphin,
  "Elephant-80": GiElephant,
  "Mammoth-88": GiMammoth,
  "Lion-72": GiLion,
  "Bull-128": GiBull,
  "Dear-40": GiDeer,
  "Bear-40": GiBearFace,
  "Grizzly-80": GiBearHead,
  Base: AiFillDatabase,
  Pro: AiFillDatabase,
  Expert: AiFillDatabase,
  Small: BsLaptop,
  Medium: HiServerStack,
  Large: AiFillDatabase,
};

export const iconName = (servername) => {
  for (const key in Icons) {
    if (servername.includes(key)) {
      const Icon = Icons[key];
      return Icon;
    }
  }
  return AiFillCloud;
};
