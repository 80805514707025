import React from "react";
import {useMatch} from "@tanstack/react-location";

import TranslationsCard from "../../../../components/DeviceDetails/translationsCard";
const Translations = () => {
  const {
    params: {cloudInstanceId},
  } = useMatch();

  return <TranslationsCard params={{cloud_instance_id: cloudInstanceId}} />;
};

export default Translations;
