import {useMatch} from "@tanstack/react-location";
import {Box, Flex, Divider, Tag, useColorModeValue} from "@chakra-ui/react";
import {SafeView, Loader} from "@onemind-services-llc/ui-components";
import humanizeString from "string-humanize";
import {useGetSubscriptionCreditNoteBillingSubscriptionsSubscriptionIdCreditNotesIdGet} from "../../../../../orval-cml/default/default";
import {formatDate} from "../utils/formatDate";
import {getCreditNotesStatusColorScheme} from "../utils/getCreditNoteStatusColorScheme";
import {getCreditNoteStatusDatePrefix} from "../utils/getCreditNoteStatusDatePrefix";
import CreditNoteHeader from "./creditNoteHeader";
import CreditNoteItems from "./creditNoteItems";
import CreditNoteOverview from "./creditNoteOverview";
import BreadCrumb from "../../../../../components/breadCrumb";

const CreditNotesDetails = () => {
  const {
    params: {subscriptionId, creditNoteId},
  } = useMatch();

  const {data: {data: creditNoteDetails = {}} = {}, isLoading} = useGetSubscriptionCreditNoteBillingSubscriptionsSubscriptionIdCreditNotesIdGet(
    subscriptionId,
    creditNoteId,
    {
      query: {queryKey: ["creditNoteDetails", subscriptionId, creditNoteId]},
    }
  );

  const {status, date} = creditNoteDetails;

  const borderColor = useColorModeValue("gray.300", "gray.600");
  const colorScheme = getCreditNotesStatusColorScheme(status);
  const datePrefix = getCreditNoteStatusDatePrefix(status);
  const formattedDate = formatDate(date);
  const readableStatus = humanizeString(status);
  return (
    <SafeView>
      {isLoading ? <Loader /> : null}
      <BreadCrumb />
      <Flex my="20px" justifyContent={"center"}>
        <Box
          width={{base: "100%", lg: "800px", xl: "1000px"}}
          borderRadius="3px"
          borderWidth="1px"
          borderColor={borderColor}
          boxShadow={"0px 3px 6px #00000029"}
          padding="20px"
          overflowX={"auto"}
          position="relative"
        >
          {status && (
            <Tag colorScheme={colorScheme} borderTopRadius="none" position={"absolute"} top="0px" size="md">
              {`${readableStatus} ${datePrefix} ${formattedDate}`}
            </Tag>
          )}
          <CreditNoteHeader creditNoteDetails={creditNoteDetails} />
          <CreditNoteOverview creditNoteDetails={creditNoteDetails} />
          <Divider my="25px" borderColor={borderColor} />
          <CreditNoteItems creditNoteDetails={creditNoteDetails} />
        </Box>
      </Flex>
    </SafeView>
  );
};

export default CreditNotesDetails;
