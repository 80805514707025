import React from "react";
import {Link as RLLink, useMatch} from "@tanstack/react-location";
import {Box, Heading, Link, SimpleGrid, Stack, Table, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react";
import {Loader} from "@onemind-services-llc/ui-components";
import DeviceDetailsCardItem from "../../Baremetal/inc/DeviceDetailsCardItem";
import BookingDetailsCard from "../../../components/DeviceDetails/bookingDetails";
import {useGetBookingBookingsBookingsIdGet} from "../../../orval-cml/default/default.ts";

const BookingDetailsOverview = () => {
  const {
    params: {bookingId},
  } = useMatch();

  const {data: {data: bookingDetails = {}} = {}, isLoading} = useGetBookingBookingsBookingsIdGet(bookingId, {
    query: {
      queryKey: ["bookingDetails", bookingId],
    },
  });

  return (
    <Box margin="20px 0px">
      {isLoading ? <Loader /> : null}
      <SimpleGrid gap={"20px"} columns={{base: 1, lg: 2}}>
        <Stack gap={"15px"}>
          <BookingDetailsCard data={bookingDetails} fromTable={false} />
          <Box borderRadius="3px" borderWidth="1px" boxShadow={"0px 3px 6px #00000029"} padding="20px" position={"relative"} overflowX="auto">
            <DeviceDetailsCardItem devices={bookingDetails?.devices} />
          </Box>
        </Stack>
        <Stack gap={"15px"}>
          <Box borderRadius="3px" borderWidth="1px" boxShadow={"0px 3px 6px #00000029"} padding="20px" position={"relative"} overflowX="auto">
            <Heading fontSize={"24px"} fontWeight={700} marginBottom="10px">
              Booked Products
            </Heading>
            <Table>
              <Thead>
                <Tr>
                  <Th textTransform={"none"}>ID</Th>
                  <Th textTransform={"none"}>Name</Th>
                  <Th textTransform={"none"}>System Guide</Th>
                </Tr>
              </Thead>
              {bookingDetails?.booked_products?.length > 0 && (
                <Tbody>
                  {bookingDetails?.booked_products?.map((product, index) => (
                    <Tr key={index}>
                      <Td>{product?.id}</Td>
                      <Td>{product?.display}</Td>
                      <Td>
                        {product?.flipping_links?.map((link, index) => (
                          <Link href={link} target="_blank" color="link.100" marginRight={"10px"} key={`link-${index}`}>
                            Book {index + 1}
                          </Link>
                        ))}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              )}
            </Table>
            {bookingDetails?.booked_products?.length === 0 && (
              <Box padding="20px" textAlign="center">
                {"No Booked Products"}
              </Box>
            )}
          </Box>
          <Box borderRadius="3px" borderWidth="1px" boxShadow={"0px 3px 6px #00000029"} padding="20px" position={"relative"} overflowX="auto">
            <Heading fontSize={"24px"} fontWeight={700} marginBottom="10px">
              Cloud Instances
            </Heading>
            <Table>
              <Thead>
                <Tr>
                  <Th textTransform={"none"}>ID</Th>
                  <Th textTransform={"none"}>Name</Th>
                </Tr>
              </Thead>
              {bookingDetails?.virtual_machines?.length > 0 && (
                <Tbody>
                  {bookingDetails?.virtual_machines?.map((cloudInstance, index) => (
                    <Tr key={index}>
                      <Td>
                        <Link key={index} to={`/app/cloud-instances/${cloudInstance.id}`} as={RLLink} color="link.100">
                          {cloudInstance?.id}
                        </Link>
                      </Td>
                      <Td>{cloudInstance?.display}</Td>
                    </Tr>
                  ))}
                </Tbody>
              )}
            </Table>
            {bookingDetails?.virtual_machines?.length == 0 && (
              <Box padding="20px" textAlign="center">
                {"No Cloud Instances Found"}
              </Box>
            )}
          </Box>
        </Stack>
      </SimpleGrid>
    </Box>
  );
};

export default BookingDetailsOverview;
