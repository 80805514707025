import {useContext} from "react";
import {isEmpty} from "lodash";
import {TableContainer, Table} from "@onemind-services-llc/ui-components";
import {UserContext} from "../../../contexts/userContext";
import {useGetListCustomerCreditsBillingCustomersCreditsGet, useUpdateUserMetadataAuthUserMetadataPatch} from "../../../orval-cml/default/default";
import CreditsMobileTable from "./creditsMobileTable";
import {defaultColumns, columnsList} from "./creditsTableColumns";

const Credits = () => {
  const userProfile = useContext(UserContext);
  const {user_metadata = {}} = userProfile || {};
  const {table = {}} = user_metadata;
  const {customerCreditsColumns = []} = table;

  const {data: {data: {results: credits = []} = {}} = {}, isLoading} = useGetListCustomerCreditsBillingCustomersCreditsGet();

  const {mutate: updateUserData} = useUpdateUserMetadataAuthUserMetadataPatch();

  const saveTableConfig = (columnList) => {
    updateUserData({
      data: {
        user_metadata: {
          ...user_metadata,
          table: {...table, customerCreditsColumns: columnList},
        },
      },
    });
  };

  return (
    <TableContainer>
      <Table
        card={CreditsMobileTable}
        columns={defaultColumns}
        data={credits}
        isLoading={isLoading}
        tableConfigProps={{
          columnsList: isEmpty(customerCreditsColumns) ? columnsList : customerCreditsColumns,
          saveTableConfig,
        }}
      />
    </TableContainer>
  );
};

export default Credits;
