import {Box, Heading, Table, Tbody, Tr, Td, useColorModeValue} from "@chakra-ui/react";

const CreditsMobileTable = (props) => {
  const {data = {}} = props;
  const {amount, closing_balance: closingBalance, credit_type: creditType, currency_code: currencyCode, type} = data;
  const borderColor = useColorModeValue("gray.300", "gray.600");

  return (
    <Box borderRadius="3px" borderWidth="1px" borderColor={borderColor} boxShadow={"0px 3px 6px #00000029"} padding="20px" overflowX={"auto"}>
      <Table>
        <Tbody>
          <Tr>
            <Td>
              <Heading fontSize={"20px"} fontWeight={700}>
                Amount
              </Heading>
            </Td>
            {type === "decrement" ? <Td color={"red.500"}>{`- ${amount} ${currencyCode}`}</Td> : <Td>{`${amount} ${currencyCode}`}</Td>}
          </Tr>
          <Tr>
            <Td>
              <Heading fontSize={"20px"} fontWeight={700}>
                Closing Balance
              </Heading>
            </Td>
            <Td>{`${closingBalance} ${currencyCode}`}</Td>
          </Tr>
          <Tr>
            <Td>
              <Heading fontSize={"20px"} fontWeight={700}>
                Credit Type
              </Heading>
            </Td>
            <Td>{creditType}</Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );
};

export default CreditsMobileTable;
