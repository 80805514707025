import {listDevicesApiDcimDevicesGet, getDeviceApiDcimDevicesIdGet} from "../../orval-cml/default/default";
import {usePrefetchQuery} from "../usePrefetchQuery";

export const useBaremetalListLoader = () => {
  const {prefetchQuery} = usePrefetchQuery();

  const baremetalListLoader = () => {
    const searchKey = "";
    const sortString = null;
    const currentPage = 1;
    const pageSize = 25;
    return prefetchQuery({
      queryKey: ["baremetals", searchKey, sortString, currentPage, pageSize],
      queryFn: () =>
        listDevicesApiDcimDevicesGet({
          q: searchKey,
          ordering: sortString,
          limit: pageSize,
          offset: (currentPage - 1) * pageSize,
        }),
    });
  };
  return {baremetalListLoader};
};

export const useBaremetalDetailsLoader = () => {
  const {prefetchQuery} = usePrefetchQuery();

  const baremetalDetailsLoader = (deviceId) => {
    return prefetchQuery({
      queryKey: ["bmDetails", deviceId],
      queryFn: () => getDeviceApiDcimDevicesIdGet(deviceId),
    });
  };
  return {baremetalDetailsLoader};
};
