import React from "react";
import {useMediaQuery, Box} from "@chakra-ui/react";
import {Outlet} from "@tanstack/react-location";
import Sidebar from "../components/sidebar";
import {sidebarWidth, topBarHeight} from "../components/sidebar/sidebar.constants";
import TopBar from "../components/topBar";
import {UserContext} from "../contexts/userContext";
import {useUpdateUserProfileAuthMePatch, useGetUserProfileAuthMeGet} from "../orval-cml";

const AppLayout = () => {
  const [isMobile] = useMediaQuery("(max-width: 660px)");
  const {mutate} = useUpdateUserProfileAuthMePatch();

  const {data: userProfile = {}} = useGetUserProfileAuthMeGet({
    query: {
      onSuccess: (res) => {
        if (res) {
          mutate({data: {hutk: document.cookie.match(/hubspotutk=(\w*);/i)?.[1]}});
        }
      },
    },
  });

  return (
    <UserContext.Provider value={userProfile.data}>
      <Sidebar />
      {!isMobile && <TopBar />}
      <Box minH={"calc(100vh - 120px)"} pt={topBarHeight} pl={sidebarWidth}>
        <Outlet />
      </Box>
    </UserContext.Provider>
  );
};

export default AppLayout;
