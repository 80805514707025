import React, {Fragment, useContext} from "react";
import {Link as RLLink} from "@tanstack/react-location";
import {
  Box,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import {FaBars} from "@onemind-services-llc/react-icons-ng/fa";
import {BiLogOutCircle} from "@onemind-services-llc/react-icons-ng/bi";
import {ColorModeToggle} from "@onemind-services-llc/ui-components";
import {AppContext} from "../../contexts/appContext";
import {useLogout} from "../../hooks/useLogout";
import {useRoutes} from "../../routes/routes";
import NavigationMenu from "../navigationMenu";
import ProfileMenu from "../profileMenu";
import {sidebarWidth, topBarHeight} from "./sidebar.constants";
import "./sidebar.css";

const Sidebar = () => {
  const {sidebarRoutes} = useRoutes();
  const {logout} = useLogout();
  const {isOpen, onOpen, onClose} = useDisclosure();
  const backgroundColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.300", "gray.600");
  const menuIconColor = useColorModeValue("gray.600", "gray.200");
  const sidebarLinkClass = useColorModeValue("sidebar-link", "sidebar-link-dark");
  const sidebarActiveLinkClass = useColorModeValue("sidebar-link-active", "sidebar-link-active-dark");
  const [isMobile] = useMediaQuery("(max-width: 660px)");
  const appSettings = useContext(AppContext);

  const CreateLinks = () => {
    return (
      <Stack spacing={4}>
        {sidebarRoutes.map((prop, key) => {
          const {path, name, icon: SidebarIcon} = prop;
          return (
            <RLLink
              to={`/app/${path}`}
              onClick={onClose}
              key={key}
              data-cy={`cy_${name}`}
              className={sidebarLinkClass}
              getActiveProps={() => ({className: sidebarActiveLinkClass})}
              search={window.location.pathname === `/app/${path}`}
              preload={1}
            >
              <Stack
                alignItems={"center"}
                direction={{base: "row", sm: "column"}}
                justifyContent={{base: "start", sm: "center"}}
                px={2}
                py={{base: 2, sm: 6}}
                spacing={{base: 3, sm: 4}}
              >
                <Icon as={SidebarIcon} fontSize={{base: "16px", sm: "30px"}} lineHeight="1" className="sidebar-icon" />
                <Text fontSize="16px" className="sidebar-text" textAlign={"center"}>
                  {prop.name}
                </Text>
              </Stack>
            </RLLink>
          );
        })}
      </Stack>
    );
  };

  return (
    <Fragment>
      {!isMobile ? (
        <Box
          bg={backgroundColor}
          borderRightWidth="1px"
          borderRightColor={borderColor}
          bottom={0}
          left={0}
          position="fixed"
          top={0}
          width={sidebarWidth}
        >
          <Stack spacing={0}>
            <Center
              as={RLLink}
              to="/app/subscriptions"
              display="flex"
              justifyContent={"center"}
              py={3}
              borderBottomWidth="1px"
              borderBottomColor={borderColor}
            >
              <Image src={appSettings.logo} alt="logo" width="70%" />
            </Center>
            <CreateLinks />
          </Stack>
        </Box>
      ) : (
        <Fragment>
          <Flex
            borderBottomWidth="1px"
            borderColor={borderColor}
            bg={backgroundColor}
            position="fixed"
            top="0px"
            left="0px"
            right="0px"
            minH={topBarHeight}
            zIndex="banner"
            alignItems={"center"}
            justifyContent="space-between"
            px={4}
          >
            <RLLink to="/app/subscriptions">
              <Image src={appSettings.logo} alt="logo" width="70px" />
            </RLLink>
            <HStack alignItems={"center"} spacing={3}>
              <NavigationMenu />
              <ColorModeToggle />
              <ProfileMenu />
              <IconButton onClick={onOpen} icon={<Icon as={FaBars} color={menuIconColor} />} data-cy="sidebar-menu" />
            </HStack>
          </Flex>
          <Drawer isOpen={isOpen} onClose={onClose} placement="right">
            <DrawerOverlay />
            <DrawerContent p={0} bg={backgroundColor}>
              <DrawerCloseButton data-cy="sidebar-close" />
              <DrawerHeader>
                <RLLink to="/app/subscriptions" onClick={onClose}>
                  <Image src={appSettings.logo} alt="logo" width="100px" />
                </RLLink>
              </DrawerHeader>
              <DrawerBody>
                <Stack spacing={{base: 6, sm: 8}}>
                  <CreateLinks />
                  <HStack
                    alignItems={"center"}
                    data-cy={"sidebar-logout"}
                    onClick={() => {
                      logout();
                      onClose();
                    }}
                    px={2}
                    py={{base: 2, sm: 6}}
                    spacing={3}
                  >
                    <Icon as={BiLogOutCircle} fontSize="16px" lineHeight="1" />
                    <Text fontSize="16px" lineHeight="1">
                      Logout
                    </Text>
                  </HStack>
                </Stack>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </Fragment>
      )}
    </Fragment>
  );
};

export default Sidebar;
