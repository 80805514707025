import React from "react";
import {Box, Heading} from "@chakra-ui/react";
import {useMatch} from "@tanstack/react-location";
import {HeaderContainer, Loader} from "@onemind-services-llc/ui-components";
import {useGetBookingBookingsBookingsIdGet} from "../../../orval-cml/default/default";

const BookingDetailsHeader = () => {
  const {
    params: {bookingId},
  } = useMatch();

  const {data: {data: bookingDetails = {}} = {}, isLoading} = useGetBookingBookingsBookingsIdGet(bookingId, {
    query: {
      queryKey: ["bookingDetails", bookingId],
    },
  });

  return (
    <HeaderContainer>
      {isLoading ? <Loader /> : null}
      <Box>
        <Heading fontSize={"20px"} fontWeight={700}>
          {bookingDetails.display}
        </Heading>
      </Box>
    </HeaderContainer>
  );
};

export default BookingDetailsHeader;
