export const getInvoiceStatusColorScheme = (status: string) => {
  switch (status) {
    case "unpaid":
    case "overdue":
    case "pending":
      return "red";
    case "draft":
    case "void":
      return "orange";
    case "paid_partial":
    case "refunded":
    case "refunded_partial":
      return "yellow";
    default:
      return "green";
  }
};
