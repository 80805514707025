import {Heading, HStack, Stack} from "@chakra-ui/react";
import {formatDate} from "../utils/formatDate";

const CreditNoteOverview = (props) => {
  const {creditNoteDetails = {}} = props;
  const {amount_available: amountAvailable, amount_refunded: amountRefunded, date} = creditNoteDetails;
  const formattedDate = formatDate(date);
  return (
    <Stack spacing={4}>
      <HStack alignItems={"baseline"} spacing={3}>
        <Heading fontSize={"18px"} fontWeight={400} minWidth="150px">
          Date
        </Heading>
        <Heading fontWeight={700} fontSize={"20px"}>
          {formattedDate}
        </Heading>
      </HStack>
      <HStack alignItems={"baseline"} spacing={3}>
        <Heading fontSize={"18px"} fontWeight={400} minWidth="150px">
          Amount Available
        </Heading>
        <Heading fontWeight={700} fontSize={"20px"}>
          ${amountAvailable}
        </Heading>
      </HStack>
      <HStack alignItems={"baseline"} spacing={3}>
        <Heading fontSize={"18px"} fontWeight={400} minWidth="150px">
          Amount Refunded
        </Heading>
        <Heading fontWeight={700} fontSize={"20px"}>
          ${amountRefunded}
        </Heading>
      </HStack>
    </Stack>
  );
};

export default CreditNoteOverview;
