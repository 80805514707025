import {Box} from "@chakra-ui/react";
import Credits from "./Credits";
import Profile from "./UserProfile";
import PaymentMethods from "./PaymentMethods";

const ProfileTabs = [
  {
    label: () => {
      return <Box fontSize={"20px"}>Profile</Box>;
    },
    component: Profile,
  },
  {
    label: () => {
      return <Box fontSize={"20px"}>Payment Methods</Box>;
    },
    component: PaymentMethods,
  },
  {
    label: () => {
      return <Box fontSize={"20px"}>Credits</Box>;
    },
    component: Credits,
  },
];

export default ProfileTabs;
