import React from "react";
import {Box, Checkbox, CheckboxGroup, Heading, Image, SimpleGrid, Stack, Text, VStack} from "@chakra-ui/react";
import {useMatch} from "@tanstack/react-location";
import {useListJumpboxesBillingProductsPlansJumpboxesGet} from "../../../../orval-cml";

const JumpBox = (props) => {
  const {selectedFrequency, setSelectedJumpbox} = props;
  const {params: {productFamily} = {}} = useMatch();

  const {data: {data: {results: jumpboxList = []} = {}} = {}} = useListJumpboxesBillingProductsPlansJumpboxesGet(
    {
      product_family_id: productFamily,
      period_unit: selectedFrequency.unit,
      period: selectedFrequency.period,
    },
    {
      query: {
        queryKey: ["jumpbox", productFamily, selectedFrequency],
      },
    }
  );

  const handleChange = (values) => {
    const selectedJumpbox = jumpboxList.filter((item) => values.includes(item.id));
    setSelectedJumpbox(selectedJumpbox || []);
  };

  return (
    <Box>
      {jumpboxList.length > 0 && (
        <Stack spacing={4}>
          <Heading as="h2" fontSize={"20px"} fontWeight={700} data-cy="choose-jumpbox-heading">
            Choose your JumpBox (Optional)
          </Heading>
          <Box>
            <CheckboxGroup onChange={handleChange}>
              <SimpleGrid columns={{base: 1, sm: 2, md: 3, lg: 4}} spacing={5}>
                {jumpboxList.map((jumpbox) => {
                  return <JumpboxCard jumpbox={jumpbox} key={jumpbox.id} />;
                })}
              </SimpleGrid>
            </CheckboxGroup>
          </Box>
        </Stack>
      )}
    </Box>
  );
};

export default JumpBox;

const JumpboxCard = ({jumpbox}) => {
  const {display} = jumpbox.item_prices[0];
  const {memory, ssd, vcpu} = jumpbox.config;
  return (
    <Box borderRadius="4px" borderWidth="1px" p={4}>
      <Checkbox size="lg" value={jumpbox.id}>
        <Stack direction="row" spacing={4} alignItems="center" data-cy="jumpbox-card">
          <Image height="40px" width="40px" objectFit="contain" src={jumpbox.image_url} alt={display} />
          <VStack align="start" spacing={0}>
            <Text fontSize="lg" fontWeight="bold">
              {display}
            </Text>
            <Text fontSize="sm" color="gray.500">
              {vcpu}, {memory}, {ssd}
            </Text>
          </VStack>
        </Stack>
      </Checkbox>
    </Box>
  );
};
