import React, {useEffect} from "react";
import {useSearch} from "@tanstack/react-location";
import {Heading, Box} from "@chakra-ui/react";
import {SafeView, HeaderContainer} from "@onemind-services-llc/ui-components";
import {BackButton} from "../../../../components/backButton";

const BookProduct = () => {
  const {product = {}} = useSearch();
  const {display, product_id, category_id, account_host} = product;

  const onLoadHandler = () => {
    // eslint-disable-next-line no-undef
    new DROPLET.Widget({
      host: account_host,
      target: "CHECKFRONT_WIDGET_01",
      item_id: product_id,
      category_id,
      options: "tabs",
      provider: "droplet",
    }).render();
  };

  useEffect(() => {
    const div = document.getElementById("CHECKFRONT_WIDGET_01");
    const widgetContainer = document.getElementById("CHECKFRONT_WIDGET_CONTAINER");

    const script = document.createElement("script");
    script.src = "//cmlbooking.checkfront.com/lib/interface--0.js";
    script.async = true;
    script.onload = onLoadHandler;
    document.body.appendChild(script);

    const p = document.createElement("p");
    p.setAttribute("id", "CHECKFRONT_LOADER");
    p.setAttribute("style", "background: url('//cmlbooking.checkfront.com/images/loader.gif') left center no-repeat; padding: 5px 5px 5px 20px");
    div.appendChild(p);
    widgetContainer.appendChild(div);

    return () => {
      document.body.removeChild(script);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SafeView>
      <HeaderContainer>
        <Box display="flex" alignItems="center">
          <BackButton />
          <Heading fontWeight="semibold" pl="10px">
            {display}
          </Heading>
        </Box>
      </HeaderContainer>
      <div id="CHECKFRONT_WIDGET_CONTAINER">
        <div id="CHECKFRONT_WIDGET_01" />
      </div>
    </SafeView>
  );
};

export default BookProduct;
