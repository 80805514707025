import React, {useMemo} from "react";
import {Box, Heading, SimpleGrid, useMediaQuery} from "@chakra-ui/react";
import {HeaderContainer} from "@onemind-services-llc/ui-components";
import ProductCard from "./productCard";
import ProductLoadingCard from "./productLoadingCard";
import {useListBookingProductsBookingsProductsGet} from "../../../../orval-cml/default/default.ts";
const Products = ({selectedProductGroup}) => {
  const [isMobile] = useMediaQuery("(max-width: 660px)");
  const [isIpad] = useMediaQuery("(max-width: 820px)");

  const arrayWithEmptyValues = useMemo(() => {
    if (isMobile) {
      return Array(1).fill("");
    } else if (isIpad) {
      return Array(2).fill("");
    } else {
      return Array(4).fill("");
    }
  }, [isMobile, isIpad]);

  const group_id = selectedProductGroup.id;

  const {
    data: {data: {results: productsList = []} = {}} = {},
    isInitialLoading,
    isFetching,
  } = useListBookingProductsBookingsProductsGet(
    {group_id},
    {
      query: {enabled: group_id ? true : false},
      queryKey: ["products", group_id],
    }
  );

  return (
    <Box>
      <HeaderContainer>
        <Box display="flex" alignItems="center">
          <Heading fontWeight={700} fontSize="24px">
            2. Select Product
          </Heading>
        </Box>
      </HeaderContainer>
      {isInitialLoading || isFetching ? (
        <SimpleGrid columns={{base: 1, sm: 2, md: 4}} spacing="20px">
          {arrayWithEmptyValues.map((item, index) => {
            return <ProductLoadingCard key={index} />;
          })}
        </SimpleGrid>
      ) : (
        <SimpleGrid columns={{base: 1, sm: 2, md: 4}} spacing="20px">
          {productsList?.length > 0 &&
            productsList.map((product) => {
              return <ProductCard key={product.id} product={product} />;
            })}
        </SimpleGrid>
      )}
    </Box>
  );
};

export default Products;
