import {Heading, Image, VStack} from "@chakra-ui/react";

type Props = {content: string};

const NoData = (props: Props) => {
  const {content} = props;
  return (
    <VStack p={6} spacing={6}>
      <Image alt="no data" src="/no-data.png" width={"200px"} />
      <Heading fontSize={{base: "20px", sm: "30px"}} textAlign={"center"}>
        {content}
      </Heading>
    </VStack>
  );
};

export default NoData;
