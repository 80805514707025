import {
  listBookingsBookingsBookingsGet,
  getBookingBookingsBookingsIdGet,
  listBookingProductGroupsBookingsProductsGroupsGet,
  listBookingProductsBookingsProductsGet,
} from "../../orval-cml/default/default";
import {usePrefetchQuery} from "../usePrefetchQuery";

export const useLearningLabsListLoader = () => {
  const {prefetchQuery} = usePrefetchQuery();
  const learningLabsListLoader = () => {
    const searchKey = "";
    const sortString = null;
    const currentPage = 1;
    const pageSize = 25;
    return prefetchQuery({
      queryKey: ["learningLabs", searchKey, sortString, currentPage, pageSize],
      queryFn: () =>
        listBookingsBookingsBookingsGet({
          q: searchKey,
          ordering: sortString,
          limit: pageSize,
          offset: (currentPage - 1) * pageSize,
        }),
    });
  };
  return {learningLabsListLoader};
};

export const useLearningLabsDetailsLoader = () => {
  const {prefetchQuery} = usePrefetchQuery();
  const learningLabsDetailsLoader = (bookingId) => {
    return prefetchQuery({
      queryKey: ["bookingDetails", bookingId],
      queryFn: () => getBookingBookingsBookingsIdGet(bookingId),
    });
  };
  return {learningLabsDetailsLoader};
};

export const useProductGroupsListLoader = () => {
  const {prefetchQuery} = usePrefetchQuery();
  const productGroupsListLoader = () => {
    return prefetchQuery({
      queryKey: ["product-groups"],
      queryFn: () => listBookingProductGroupsBookingsProductsGroupsGet(),
    });
  };
  return {productGroupsListLoader};
};

export const useProductsListLoader = () => {
  const {prefetchQuery} = usePrefetchQuery();
  const productsListLoader = (group_id) => {
    return prefetchQuery({
      queryKey: ["products", group_id],
      queryFn: () => listBookingProductsBookingsProductsGet({group_id}),
    });
  };
  return {productsListLoader};
};
