import {Box, Heading, Table, Tbody, Td, Tr, useColorMode} from "@chakra-ui/react";
import {useNavigate} from "@tanstack/react-location";

const LegacySubscriptionCard = (props) => {
  const {data = {}, fromTable = true} = props;
  const {colorMode} = useColorMode();
  const navigate = useNavigate();

  const handleCardClick = () => {
    if (fromTable) {
      navigate({to: `/app/legacy/subscriptions/${data.id}`});
    }
  };

  return (
    <Box
      borderRadius="3px"
      borderWidth="1px"
      borderColor={colorMode === "light" ? "gray.300" : "gray.600"}
      boxShadow={"0px 3px 6px #00000029"}
      padding="20px"
      onClick={handleCardClick}
    >
      <Heading fontSize={"24px"} fontWeight={700} marginBottom="10px">
        Overview
      </Heading>
      <Table>
        <Tbody>
          <Tr>
            <Td>
              <Heading fontSize={"20px"} fontWeight={700}>
                Status
              </Heading>
            </Td>
            <Td>{data.status}</Td>
          </Tr>
          <Tr>
            <Td>
              <Heading fontSize={"20px"} fontWeight={700}>
                Total
              </Heading>
            </Td>
            <Td>
              {data.total} {data.currency} ({data.payment_method})
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Heading fontSize={"20px"} fontWeight={700}>
                Billing period
              </Heading>
            </Td>
            <Td>{`Every ${data.billing_interval} ${data.billing_period}`}</Td>
          </Tr>
          <Tr>
            <Td>
              <Heading fontSize={"20px"} fontWeight={700}>
                Started on
              </Heading>
            </Td>
            <Td>{data.start_date_gmt}</Td>
          </Tr>
          {data.end_date_gmt && (
            <Tr>
              <Td>
                <Heading fontSize={"20px"} fontWeight={700}>
                  Ends on
                </Heading>
              </Td>
              <Td>{data.end_date_gmt}</Td>
            </Tr>
          )}
          <Tr>
            <Td>
              <Heading fontSize={"20px"} fontWeight={700}>
                Next payment
              </Heading>
            </Td>
            <Td>{data.next_payment_date_gmt}</Td>
          </Tr>
          <Tr>
            <Td>
              <Heading fontSize={"20px"} fontWeight={700}>
                Last payment
              </Heading>
            </Td>
            <Td>{data.last_payment_date_gmt}</Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );
};

export default LegacySubscriptionCard;
