import React, {Fragment, useContext, useState} from "react";
import {isEmpty} from "lodash";
import {Table} from "@onemind-services-llc/ui-components";
import BaremetalCard from "../../components/DeviceDetails/baremetalOverview";
import {TableHeader} from "../../components/tableHeader";
import {NoDataComponent} from "../../components/noDataComponent";
import {UserContext} from "../../contexts/userContext";
import useSearchAndSort from "../../hooks/useSearchAndSort";
import {useListDevicesApiDcimDevicesGet, useUpdateUserMetadataAuthUserMetadataPatch} from "../../orval-cml/default/default";
import {columnsList, defaultColumns} from "./baremetalTableColumns";

const sortOptions = [
  {label: "Name", value: "name"},
  {label: "IP", value: "primary_ip"},
  {label: "OS", value: "platform"},
  {label: "Role", value: "role"},
  {label: "Status", value: "status"},
];

const linkOptions = {
  linkText: "Add Baremetal",
  linkTo: "/app/add-product/Baremetal",
};

const BareMetalTable = () => {
  const userProfile = useContext(UserContext);
  const {user_metadata = {}} = userProfile || {};
  const {table = {}} = user_metadata;
  const {baremetalColumns = []} = table;

  const {searchKey, setSearchKey, sortString, sortConfig, setSortConfig} = useSearchAndSort();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);

  const {data: {data: {results: deviceList = [], count: totalCount = 0} = {}} = {}, isLoading} = useListDevicesApiDcimDevicesGet(
    {
      q: searchKey,
      ordering: sortString,
      limit: pageSize,
      offset: (currentPage - 1) * pageSize,
    },
    {
      query: {
        queryKey: ["baremetals", searchKey, sortString, currentPage, pageSize],
      },
    }
  );

  const {mutate: updateUserData} = useUpdateUserMetadataAuthUserMetadataPatch();

  const saveTableConfig = (columnList) => {
    updateUserData({
      data: {
        user_metadata: {
          ...user_metadata,
          table: {...table, baremetalColumns: columnList},
        },
      },
    });
  };

  return (
    <Fragment>
      <Table
        actionButtons={[deviceList.length > 0 ? <TableHeader linkText={linkOptions.linkText} linkTo={linkOptions.linkTo} /> : null]}
        card={BaremetalCard}
        columns={defaultColumns}
        data={deviceList}
        isLoading={isLoading}
        noDataComponent={
          <NoDataComponent
            description="It looks like you haven't added any baremetal yet. Click the button below to add your first baremetal."
            linkText={linkOptions.linkText}
            linkTo={linkOptions.linkTo}
            title="Get started with your first Baremetal"
          />
        }
        paginationProps={{currentPage, setCurrentPage, pageSize, setPageSize, totalCount}}
        searchProps={{searchKey, setSearchKey}}
        sortProps={{sortConfig, setSortConfig, sortOptions}}
        tableConfigProps={{
          columnsList: isEmpty(baremetalColumns) ? columnsList : baremetalColumns,
          saveTableConfig,
        }}
      />
    </Fragment>
  );
};

export default BareMetalTable;
