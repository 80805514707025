// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import axios, {AxiosRequestConfig} from "axios";
import CookieService from "./cookies";

const requestProcess = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
});

requestProcess.interceptors.request.use((req) => {
  const access = CookieService().getAccessToken();
  if (access) {
    req.headers.Authorization = `Bearer ${access}`;
  }

  if (!import.meta.env.PROD) {
    req.headers["Cache-Control"] = "no-store";
  }

  return req;
});

export default requestProcess;

export const customInstance = <T>(config: AxiosRequestConfig): Promise<T> => {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source();
  return requestProcess({...config, cancelToken: source.token});
};
