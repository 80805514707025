import React from "react";
import {useMatch} from "@tanstack/react-location";
import CredentialsCard from "../../../../components/DeviceDetails/credentialsCard";

const Credentials = () => {
  const {
    params: {deviceId},
  } = useMatch();

  return <CredentialsCard params={{device_id: deviceId}} />;
};

export default Credentials;
