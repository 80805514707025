import {Button} from "@chakra-ui/react";
import {BsDatabaseAdd} from "@onemind-services-llc/react-icons-ng/bs";
import {Link} from "@tanstack/react-location";

type Props = {
  linkText: string;
  linkTo: string;
};

export const TableHeader = (props: Props) => {
  const {linkText, linkTo} = props;
  return (
    <Button as={Link} variant={"brand"} leftIcon={<BsDatabaseAdd />} to={linkTo}>
      {linkText}
    </Button>
  );
};
