import React from "react";
import {createDataColumn} from "@onemind-services-llc/ui-components";
import {Link as RLLink} from "@tanstack/react-location";
import {Link} from "@chakra-ui/react";

export const defaultColumns = [
  createDataColumn("id", {
    cell: (data) => (
      <Link to={`/app/baremetal/${data.getValue()}`} as={RLLink} color="link.100" preload={1}>
        {data.getValue()}
      </Link>
    ),
  }),
  createDataColumn("name"),
  createDataColumn("primary_ip", {
    header: () => "IP",
    accessorFn: (row) => row.primary_ip?.display,
  }),
  createDataColumn("platform", {
    header: () => "OS",
    accessorFn: (row) => row.platform?.display,
  }),
  createDataColumn("device_role", {
    header: () => "Role",
    accessorFn: (row) => row.device_role?.display,
  }),
  createDataColumn("device_type", {
    header: () => "Type",
    accessorFn: (row) => `${row.device_type?.manufacturer?.name} ${row.device_type?.display}`,
  }),
  createDataColumn("status", {
    accessorFn: (row) => row.status?.label,
  }),
];

export const columnsList = [
  {display: "ID", value: "id", visible: true},
  {display: "Name", value: "name", visible: true},
  {display: "IP", value: "primary_ip", visible: true},
  {display: "OS", value: "platform", visible: true},
  {display: "Role", value: "device_role", visible: true},
  {display: "Type", value: "device_type", visible: true},
  {display: "Status", value: "status", visible: true},
];
