import React, {useEffect} from "react";
import {useNavigate} from "@tanstack/react-location";
import {useClearCookies} from "../../hooks/useClearCookies";
const Logout = () => {
  const {clearCookies} = useClearCookies();
  const navigate = useNavigate();

  useEffect(() => {
    clearCookies();
    navigate({to: "/auth"});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <div></div>;
};

export default Logout;
