import {Heading, HStack, Stack, Table, Thead, Tbody, Tr, Th, Td, TableContainer, Text} from "@chakra-ui/react";
import {Fragment} from "react";

const CreditNoteItems = (props) => {
  const {creditNoteDetails = {}} = props;
  const {items = [], total, amount_available: amountAvailable, currency_code: currencyCode} = creditNoteDetails;
  return (
    <Fragment>
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>Items</Th>
              <Th textAlign={"end"}>Amount({currencyCode})</Th>
            </Tr>
          </Thead>
          <Tbody>
            {items.map((item = {}) => {
              const {description, quantity, unit_amount} = item;
              return (
                <Tr key={item.id}>
                  <Td>
                    <Text color="brand.100">{description}</Text>
                  </Td>
                  <Td textAlign={"end"}>
                    <Heading fontSize={"16px"}>${quantity * unit_amount}</Heading>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <Stack alignItems={"flex-end"} mt={4} pr={6} spacing={4}>
        <HStack spacing={4}>
          <Text>Total</Text>
          <Heading fontSize={"16px"}>${total}</Heading>
        </HStack>
        <HStack spacing={4}>
          <Text>Amount Available({currencyCode})</Text>
          <Heading fontSize={"24px"}>${amountAvailable}</Heading>
        </HStack>
      </Stack>
    </Fragment>
  );
};

export default CreditNoteItems;
