import {theme as chakraTheme} from "@chakra-ui/react";
import "@fontsource/roboto";
import "@fontsource/roboto/700.css";
import "@fontsource/source-sans-pro";

export const fonts = {
  ...chakraTheme.fonts,
  heading: '"Roboto", arial',
  body: '"Source Sans Pro", sans-serif',
  mono: '"Roboto", arial',
};
