import React, {Fragment, useState} from "react";
import {Table} from "@onemind-services-llc/ui-components";
import CloudInstanceCard from "../../components/DeviceDetails/cloudInstanceOverview";
import {TableHeader} from "../../components/tableHeader";
import {NoDataComponent} from "../../components/noDataComponent";
import useSearchAndSort from "../../hooks/useSearchAndSort";
import {useListCloudInstancesApiCloudInstancesGet} from "../../orval-cml/default/default";
import {columnsList, defaultColumns} from "./cloudInstanceTableColumns";

const sortOptions = [
  {label: "Name", value: "name"},
  {label: "IP", value: "primary_ip"},
  {label: "OS", value: "platform"},
  {label: "Role", value: "role"},
  {label: "Status", value: "status"},
];

const linkOptions = {
  linkText: "Add Cloud Instance",
  linkTo: "/app/add-product/Cloud-Instances",
};
const CloudInstanceTable = () => {
  const {searchKey, setSearchKey, sortString, sortConfig, setSortConfig} = useSearchAndSort();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);

  const {data: {data: {results: cloudInstanceList = [], count: totalCount = 0} = {}} = {}, isLoading} = useListCloudInstancesApiCloudInstancesGet(
    {
      q: searchKey,
      ordering: sortString,
      limit: pageSize,
      offset: (currentPage - 1) * pageSize,
    },
    {
      query: {
        queryKey: ["cloudInstances", searchKey, sortString, currentPage, pageSize],
      },
    }
  );

  return (
    <Fragment>
      <Table
        actionButtons={[cloudInstanceList.length > 0 ? <TableHeader linkText={linkOptions.linkText} linkTo={linkOptions.linkTo} /> : null]}
        card={CloudInstanceCard}
        columns={defaultColumns}
        data={cloudInstanceList}
        isLoading={isLoading}
        noDataComponent={
          <NoDataComponent
            description="It looks like you haven't added any cloud instances yet. Click the button below to add your first cloud instance."
            linkText={linkOptions.linkText}
            linkTo={linkOptions.linkTo}
            title="Get started with your first Cloud Instance"
          />
        }
        paginationProps={{currentPage, setCurrentPage, pageSize, setPageSize, totalCount}}
        searchProps={{searchKey, setSearchKey}}
        sortProps={{sortConfig, setSortConfig, sortOptions}}
        tableConfigProps={{columnsList}}
      />
    </Fragment>
  );
};

export default CloudInstanceTable;
