import React, {useState} from "react";
import {Box, Heading, useColorMode, Table, Tbody, Thead, Tr, Th, Td} from "@chakra-ui/react";
import {Loader, Pagination} from "@onemind-services-llc/ui-components";
import {useListStaticTranslationsApiNatStaticNatGet} from "../../orval-cml/default/default.ts";

const TranslationsCard = (props) => {
  const {params = {}} = props;
  const {colorMode} = useColorMode();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const {
    data: {data: {results: translationsList = [], count: translationsCount = 0} = {}} = {},
    isLoading,
    isFetching,
  } = useListStaticTranslationsApiNatStaticNatGet({
    ...params,
    limit: pageSize,
    offset: (currentPage - 1) * pageSize,
  });
  return (
    <Box
      borderRadius="3px"
      borderWidth="1px"
      borderColor={colorMode === "light" ? "gray.300" : "gray.600"}
      boxShadow={"0px 3px 6px #00000029"}
      padding="20px"
      position={"relative"}
      overflowX="auto"
    >
      {isLoading || isFetching ? <Loader position="absolute" /> : null}
      <Heading fontSize={"24px"} fontWeight={700} marginBottom="10px">
        Translations
      </Heading>
      <Table>
        <Thead>
          <Tr>
            <Th textTransform={"none"}>Inside IP</Th>
            <Th textTransform={"none"}>Inside Port</Th>
            <Th textTransform={"none"}>Outside IP</Th>
            <Th textTransform={"none"}>Outside Port</Th>
          </Tr>
        </Thead>
        {translationsList?.length > 0 && (
          <Tbody>
            {translationsList.map((translation) => (
              <Tr key={translation.id}>
                <Td>{translation?.inside_ip?.display}</Td>
                <Td>{translation?.inside_port}</Td>
                <Td>{translation?.outside_ip?.display}</Td>
                <Td>{translation?.outside_port}</Td>
              </Tr>
            ))}
          </Tbody>
        )}
      </Table>
      {translationsList?.length == 0 && (
        <Box padding="20px" textAlign="center">
          {"No Translations Present"}
        </Box>
      )}
      {translationsList?.length >= 10 && (
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          totalCount={translationsCount}
        />
      )}
    </Box>
  );
};

export default TranslationsCard;
