import React from "react";
import AppLayout from "../layouts/AppLayout";
import {Navigate} from "@tanstack/react-location";
import {authLayoutRoutes} from "./authLayoutRoutes";
import {useAppLayoutRoutes} from "./appLayoutRoutes";

export const useMainRoutes = () => {
  const {appLayoutRoutes} = useAppLayoutRoutes();

  const mainRoutes = [
    {
      path: "auth",
      children: authLayoutRoutes,
    },
    {
      path: "app",
      element: <AppLayout />,
      children: appLayoutRoutes,
    },
    {
      path: "/",
      element: <Navigate to="/auth" />,
    },
    {
      path: "*",
      element: <Navigate to="/auth" />,
    },
  ];

  return {mainRoutes};
};
