import React from "react";
import {Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, useColorModeValue} from "@chakra-ui/react";
import {FlFillIcFluentIosArrowRtl24Filled} from "@onemind-services-llc/react-icons-ng/fl";
import {useLocation} from "@tanstack/react-location";

const BreadCrumb = () => {
  const location = useLocation();
  const color = useColorModeValue("#1E87F0", "#9EC5FE");
  const iconColor = useColorModeValue("gray.700", "white");

  let prevUrl = "";
  const crumbs = location.current.href
    .split("/")
    .filter((crumb) => crumb !== "")
    .map((crumb, idx) => {
      let currentLink = prevUrl + `/${crumb}`;
      prevUrl += `/${crumb}`;

      if (crumb === "app") return null;
      return (
        <BreadcrumbItem key={idx}>
          <BreadcrumbLink href={currentLink} color={color}>
            {crumb.replace(/\b\w/g, (char) => char.toUpperCase()).replace("-", " ")}
          </BreadcrumbLink>
        </BreadcrumbItem>
      );
    })
    .filter((item) => item !== null);

  return (
    <Box
      width={{
        base: "100%",
        sm: "80%",
        md: "82%",
        lg: "86%",
        xl: "86%",
        "2xl": "calc(100% - 250px)",
      }}
      height={"30px"}
      // margin={{
      //   base: "65px 0px 0px 0px",
      //   sm: "65px 0px 0px 20%",
      //   md: "65px 0px 0px 18%",
      //   lg: "65px 0px 0px 14%",
      //   xl: "65px 0px 0px 14%",
      //   "2xl": "65pxs 0px 0px 250px",
      // }}
      minH="30px"
      zIndex="banner"
      // background={"red"}
      // padding={{
      //   base: ".35em 15px",
      //   sm: ".35em 30px",
      // }}
      fontSize={".875rem"}
    >
      <Breadcrumb
        spacing="8px"
        separator={
          <FlFillIcFluentIosArrowRtl24Filled
            color={iconColor}
            style={{
              width: "10px",
            }}
          />
        }
        fontSize={"md"}
      >
        {crumbs}
      </Breadcrumb>
    </Box>
  );
};

export default BreadCrumb;
