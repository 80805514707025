import React from "react";
import {useMatch} from "@tanstack/react-location";
import {SimpleGrid, Box, Stack} from "@chakra-ui/react";
import {Loader} from "@onemind-services-llc/ui-components";
import PrimaryDetails from "../../../../components/DeviceDetails/baremetalOverview";
import Credentials from "./credentials";
import {useGetDeviceApiDcimDevicesIdGet} from "../../../../orval-cml/default/default.ts";
import Translations from "./translations";

const BaremetalOverview = () => {
  const {
    params: {deviceId},
  } = useMatch();

  const {data: {data: deviceDetails = {}} = {}, isLoading} = useGetDeviceApiDcimDevicesIdGet(deviceId, {
    query: {
      queryKey: ["bmDetails", deviceId],
    },
  });
  return (
    <Box margin="20px 0px">
      {isLoading ? <Loader /> : null}
      <SimpleGrid gap={"20px"} columns={{base: 1, lg: 2}}>
        <Stack gap={"10px"}>
          <PrimaryDetails data={deviceDetails} fromTable={false} />
        </Stack>
        <Stack gap={"10px"}>
          <Credentials />
          <Translations />
        </Stack>
      </SimpleGrid>
    </Box>
  );
};

export default BaremetalOverview;
