import React from "react";
import {TableContainer} from "@onemind-services-llc/ui-components";
import ModuleBaysTable from "./moduleBaysTable";
function ModuleBays() {
  return (
    <TableContainer>
      <ModuleBaysTable />
    </TableContainer>
  );
}

export default ModuleBays;
