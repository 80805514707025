import {
  listSubscriptionsBillingSubscriptionsGet,
  getSubscriptionBillingSubscriptionsIdGet,
  getSubscriptionInvoiceBillingSubscriptionsSubscriptionIdInvoicesIdGet,
  getSubscriptionCreditNoteBillingSubscriptionsSubscriptionIdCreditNotesIdGet,
} from "../../orval-cml/default/default";
import {usePrefetchQuery} from "../usePrefetchQuery";

export const useSubscriptionsListLoader = () => {
  const {prefetchQuery} = usePrefetchQuery();

  const subscriptionsListLoader = () => {
    return prefetchQuery({
      queryKey: ["subscriptions"],
      queryFn: () => listSubscriptionsBillingSubscriptionsGet(),
    });
  };

  return {subscriptionsListLoader};
};

export const useSubscriptionDetailsLoader = () => {
  const {prefetchQuery} = usePrefetchQuery();

  const subscriptionDetailsLoader = (subscriptionId) => {
    return prefetchQuery({
      queryKey: ["subscriptionDetails", subscriptionId],
      queryFn: () => getSubscriptionBillingSubscriptionsIdGet(subscriptionId),
    });
  };
  return {subscriptionDetailsLoader};
};

export const useInvoiceDetailsLoader = () => {
  const {prefetchQuery} = usePrefetchQuery();

  const invoiceDetailsLoader = (subscriptionId, invoiceId) => {
    return prefetchQuery({
      queryKey: ["invoiceDetails", subscriptionId, invoiceId],
      queryFn: () => getSubscriptionInvoiceBillingSubscriptionsSubscriptionIdInvoicesIdGet(subscriptionId, invoiceId),
    });
  };

  return {invoiceDetailsLoader};
};

export const useCreditNoteDetailsLoader = () => {
  const {prefetchQuery} = usePrefetchQuery();

  const creditNoteDetailsLoader = (subscriptionId, creditNoteId) => {
    return prefetchQuery({
      queryKey: ["creditNoteDetails", subscriptionId, creditNoteId],
      queryFn: () => getSubscriptionCreditNoteBillingSubscriptionsSubscriptionIdCreditNotesIdGet(subscriptionId, creditNoteId),
    });
  };

  return {creditNoteDetailsLoader};
};
