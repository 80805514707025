/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * API Gateway
 * OpenAPI spec version: 0.13.13
 */
import {useMutation, useQuery} from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  Addons,
  CancelSubscriptionBillingSubscriptionsIdCancelPatchParams,
  Card,
  CardAssignRole,
  Cards,
  CheckoutSubscription,
  CloudInstance,
  CloudInstances,
  ContactResponse,
  ContactSales,
  ContactUs,
  CreateCard,
  CreditNote,
  CreditNotes,
  CustomerContent,
  FormValidationError,
  Frequencies,
  GeneralError,
  HostedPageResponse,
  HubspotUtk,
  Invoice,
  Invoices,
  Jumpboxes,
  LegacySubscription,
  LegacySubscriptions,
  ListAddonsSupportBillingAddonsSupportGetParams,
  ListBookingProductGroupsBookingsProductsGroupsGetParams,
  ListBookingProductsBookingsProductsGetParams,
  ListBookingsBookingsBookingsGetParams,
  ListCloudInstancesApiCloudInstancesGetParams,
  ListDeviceModuleBaysApiDcimModuleBaysGetParams,
  ListDevicesApiDcimDevicesGetParams,
  ListFrequenciesBillingProductsFrequenciesGetParams,
  ListJumpboxesBillingProductsPlansJumpboxesGetParams,
  ListOperatingSystemsBillingProductsPlansOsGetParams,
  ListPlansBillingProductsPlansGetParams,
  ListSecretsApiSecretsSecretsGetParams,
  ListStaticTranslationsApiNatStaticNatGetParams,
  ListSubscriptionsLegacySubscriptionsGetParams,
  NetBoxDevice,
  NetBoxDeviceModuleBays,
  NetBoxDevices,
  NetBoxIPAddress,
  NetBoxSecrets,
  NetBoxStaticNats,
  OperatingSystems,
  Plans,
  ProductFamilies,
  PromotionalCredits,
  Query,
  RefundContent,
  RegisterUser,
  RunHealthQueryHealthQueryPostParams,
  Subscription,
  SubscriptionContent,
  Subscriptions,
  SupportLink,
  UpdateUserMetadataAuthUserMetadataPatchBody,
  User,
  UserCreated,
} from ".././model";
import {customInstance} from "../../services/axios";

/**
 * Get app settings like feature flags, Auth0 domain, client ID, etc.
 * @summary Get Settings
 */
export const getSettingsSettingsGet = (signal?: AbortSignal) => {
  return customInstance<unknown>({url: `/settings/`, method: "GET", signal});
};

export const getGetSettingsSettingsGetQueryKey = () => {
  return [`/settings/`] as const;
};

export const getGetSettingsSettingsGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getSettingsSettingsGet>>,
  TError = FormValidationError | GeneralError,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getSettingsSettingsGet>>, TError, TData>;
}) => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSettingsSettingsGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSettingsSettingsGet>>> = ({signal}) => getSettingsSettingsGet(signal);

  return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSettingsSettingsGet>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetSettingsSettingsGetQueryResult = NonNullable<Awaited<ReturnType<typeof getSettingsSettingsGet>>>;
export type GetSettingsSettingsGetQueryError = FormValidationError | GeneralError;

/**
 * @summary Get Settings
 */

export function useGetSettingsSettingsGet<
  TData = Awaited<ReturnType<typeof getSettingsSettingsGet>>,
  TError = FormValidationError | GeneralError,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getSettingsSettingsGet>>, TError, TData>;
}): UseQueryResult<TData, TError> & {queryKey: QueryKey} {
  const queryOptions = getGetSettingsSettingsGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Device
 */
export const getDeviceApiDcimDevicesIdGet = (id: number, signal?: AbortSignal) => {
  return customInstance<NetBoxDevice>({url: `/api/dcim/devices/${id}/`, method: "GET", signal});
};

export const getGetDeviceApiDcimDevicesIdGetQueryKey = (id: number) => {
  return [`/api/dcim/devices/${id}/`] as const;
};

export const getGetDeviceApiDcimDevicesIdGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getDeviceApiDcimDevicesIdGet>>,
  TError = FormValidationError | GeneralError,
>(
  id: number,
  options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof getDeviceApiDcimDevicesIdGet>>, TError, TData>}
) => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDeviceApiDcimDevicesIdGetQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDeviceApiDcimDevicesIdGet>>> = ({signal}) => getDeviceApiDcimDevicesIdGet(id, signal);

  return {queryKey, queryFn, enabled: !!id, ...queryOptions} as UseQueryOptions<
    Awaited<ReturnType<typeof getDeviceApiDcimDevicesIdGet>>,
    TError,
    TData
  > & {queryKey: QueryKey};
};

export type GetDeviceApiDcimDevicesIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof getDeviceApiDcimDevicesIdGet>>>;
export type GetDeviceApiDcimDevicesIdGetQueryError = FormValidationError | GeneralError;

/**
 * @summary Get Device
 */

export function useGetDeviceApiDcimDevicesIdGet<
  TData = Awaited<ReturnType<typeof getDeviceApiDcimDevicesIdGet>>,
  TError = FormValidationError | GeneralError,
>(
  id: number,
  options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof getDeviceApiDcimDevicesIdGet>>, TError, TData>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} {
  const queryOptions = getGetDeviceApiDcimDevicesIdGetQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary List Devices
 */
export const listDevicesApiDcimDevicesGet = (params?: ListDevicesApiDcimDevicesGetParams, signal?: AbortSignal) => {
  return customInstance<NetBoxDevices>({url: `/api/dcim/devices/`, method: "GET", params, signal});
};

export const getListDevicesApiDcimDevicesGetQueryKey = (params?: ListDevicesApiDcimDevicesGetParams) => {
  return [`/api/dcim/devices/`, ...(params ? [params] : [])] as const;
};

export const getListDevicesApiDcimDevicesGetQueryOptions = <
  TData = Awaited<ReturnType<typeof listDevicesApiDcimDevicesGet>>,
  TError = FormValidationError | GeneralError,
>(
  params?: ListDevicesApiDcimDevicesGetParams,
  options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof listDevicesApiDcimDevicesGet>>, TError, TData>}
) => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListDevicesApiDcimDevicesGetQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listDevicesApiDcimDevicesGet>>> = ({signal}) => listDevicesApiDcimDevicesGet(params, signal);

  return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listDevicesApiDcimDevicesGet>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type ListDevicesApiDcimDevicesGetQueryResult = NonNullable<Awaited<ReturnType<typeof listDevicesApiDcimDevicesGet>>>;
export type ListDevicesApiDcimDevicesGetQueryError = FormValidationError | GeneralError;

/**
 * @summary List Devices
 */

export function useListDevicesApiDcimDevicesGet<
  TData = Awaited<ReturnType<typeof listDevicesApiDcimDevicesGet>>,
  TError = FormValidationError | GeneralError,
>(
  params?: ListDevicesApiDcimDevicesGetParams,
  options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof listDevicesApiDcimDevicesGet>>, TError, TData>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} {
  const queryOptions = getListDevicesApiDcimDevicesGetQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary List Device Module Bays
 */
export const listDeviceModuleBaysApiDcimModuleBaysGet = (params: ListDeviceModuleBaysApiDcimModuleBaysGetParams, signal?: AbortSignal) => {
  return customInstance<NetBoxDeviceModuleBays>({url: `/api/dcim/module-bays/`, method: "GET", params, signal});
};

export const getListDeviceModuleBaysApiDcimModuleBaysGetQueryKey = (params: ListDeviceModuleBaysApiDcimModuleBaysGetParams) => {
  return [`/api/dcim/module-bays/`, ...(params ? [params] : [])] as const;
};

export const getListDeviceModuleBaysApiDcimModuleBaysGetQueryOptions = <
  TData = Awaited<ReturnType<typeof listDeviceModuleBaysApiDcimModuleBaysGet>>,
  TError = FormValidationError | GeneralError,
>(
  params: ListDeviceModuleBaysApiDcimModuleBaysGetParams,
  options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof listDeviceModuleBaysApiDcimModuleBaysGet>>, TError, TData>}
) => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListDeviceModuleBaysApiDcimModuleBaysGetQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listDeviceModuleBaysApiDcimModuleBaysGet>>> = ({signal}) =>
    listDeviceModuleBaysApiDcimModuleBaysGet(params, signal);

  return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
    Awaited<ReturnType<typeof listDeviceModuleBaysApiDcimModuleBaysGet>>,
    TError,
    TData
  > & {queryKey: QueryKey};
};

export type ListDeviceModuleBaysApiDcimModuleBaysGetQueryResult = NonNullable<Awaited<ReturnType<typeof listDeviceModuleBaysApiDcimModuleBaysGet>>>;
export type ListDeviceModuleBaysApiDcimModuleBaysGetQueryError = FormValidationError | GeneralError;

/**
 * @summary List Device Module Bays
 */

export function useListDeviceModuleBaysApiDcimModuleBaysGet<
  TData = Awaited<ReturnType<typeof listDeviceModuleBaysApiDcimModuleBaysGet>>,
  TError = FormValidationError | GeneralError,
>(
  params: ListDeviceModuleBaysApiDcimModuleBaysGetParams,
  options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof listDeviceModuleBaysApiDcimModuleBaysGet>>, TError, TData>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} {
  const queryOptions = getListDeviceModuleBaysApiDcimModuleBaysGetQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Cloud Instance
 */
export const getCloudInstanceApiCloudInstanceIdGet = (id: number, signal?: AbortSignal) => {
  return customInstance<CloudInstance>({url: `/api/cloud-instance/${id}/`, method: "GET", signal});
};

export const getGetCloudInstanceApiCloudInstanceIdGetQueryKey = (id: number) => {
  return [`/api/cloud-instance/${id}/`] as const;
};

export const getGetCloudInstanceApiCloudInstanceIdGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getCloudInstanceApiCloudInstanceIdGet>>,
  TError = FormValidationError | GeneralError,
>(
  id: number,
  options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof getCloudInstanceApiCloudInstanceIdGet>>, TError, TData>}
) => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCloudInstanceApiCloudInstanceIdGetQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCloudInstanceApiCloudInstanceIdGet>>> = ({signal}) =>
    getCloudInstanceApiCloudInstanceIdGet(id, signal);

  return {queryKey, queryFn, enabled: !!id, ...queryOptions} as UseQueryOptions<
    Awaited<ReturnType<typeof getCloudInstanceApiCloudInstanceIdGet>>,
    TError,
    TData
  > & {queryKey: QueryKey};
};

export type GetCloudInstanceApiCloudInstanceIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof getCloudInstanceApiCloudInstanceIdGet>>>;
export type GetCloudInstanceApiCloudInstanceIdGetQueryError = FormValidationError | GeneralError;

/**
 * @summary Get Cloud Instance
 */

export function useGetCloudInstanceApiCloudInstanceIdGet<
  TData = Awaited<ReturnType<typeof getCloudInstanceApiCloudInstanceIdGet>>,
  TError = FormValidationError | GeneralError,
>(
  id: number,
  options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof getCloudInstanceApiCloudInstanceIdGet>>, TError, TData>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} {
  const queryOptions = getGetCloudInstanceApiCloudInstanceIdGetQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary List Cloud Instances
 */
export const listCloudInstancesApiCloudInstancesGet = (params?: ListCloudInstancesApiCloudInstancesGetParams, signal?: AbortSignal) => {
  return customInstance<CloudInstances>({url: `/api/cloud-instances/`, method: "GET", params, signal});
};

export const getListCloudInstancesApiCloudInstancesGetQueryKey = (params?: ListCloudInstancesApiCloudInstancesGetParams) => {
  return [`/api/cloud-instances/`, ...(params ? [params] : [])] as const;
};

export const getListCloudInstancesApiCloudInstancesGetQueryOptions = <
  TData = Awaited<ReturnType<typeof listCloudInstancesApiCloudInstancesGet>>,
  TError = FormValidationError | GeneralError,
>(
  params?: ListCloudInstancesApiCloudInstancesGetParams,
  options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof listCloudInstancesApiCloudInstancesGet>>, TError, TData>}
) => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListCloudInstancesApiCloudInstancesGetQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listCloudInstancesApiCloudInstancesGet>>> = ({signal}) =>
    listCloudInstancesApiCloudInstancesGet(params, signal);

  return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
    Awaited<ReturnType<typeof listCloudInstancesApiCloudInstancesGet>>,
    TError,
    TData
  > & {queryKey: QueryKey};
};

export type ListCloudInstancesApiCloudInstancesGetQueryResult = NonNullable<Awaited<ReturnType<typeof listCloudInstancesApiCloudInstancesGet>>>;
export type ListCloudInstancesApiCloudInstancesGetQueryError = FormValidationError | GeneralError;

/**
 * @summary List Cloud Instances
 */

export function useListCloudInstancesApiCloudInstancesGet<
  TData = Awaited<ReturnType<typeof listCloudInstancesApiCloudInstancesGet>>,
  TError = FormValidationError | GeneralError,
>(
  params?: ListCloudInstancesApiCloudInstancesGetParams,
  options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof listCloudInstancesApiCloudInstancesGet>>, TError, TData>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} {
  const queryOptions = getListCloudInstancesApiCloudInstancesGetQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary List Secrets
 */
export const listSecretsApiSecretsSecretsGet = (params?: ListSecretsApiSecretsSecretsGetParams, signal?: AbortSignal) => {
  return customInstance<NetBoxSecrets>({url: `/api/secrets/secrets/`, method: "GET", params, signal});
};

export const getListSecretsApiSecretsSecretsGetQueryKey = (params?: ListSecretsApiSecretsSecretsGetParams) => {
  return [`/api/secrets/secrets/`, ...(params ? [params] : [])] as const;
};

export const getListSecretsApiSecretsSecretsGetQueryOptions = <
  TData = Awaited<ReturnType<typeof listSecretsApiSecretsSecretsGet>>,
  TError = FormValidationError | GeneralError,
>(
  params?: ListSecretsApiSecretsSecretsGetParams,
  options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof listSecretsApiSecretsSecretsGet>>, TError, TData>}
) => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListSecretsApiSecretsSecretsGetQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listSecretsApiSecretsSecretsGet>>> = ({signal}) =>
    listSecretsApiSecretsSecretsGet(params, signal);

  return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listSecretsApiSecretsSecretsGet>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type ListSecretsApiSecretsSecretsGetQueryResult = NonNullable<Awaited<ReturnType<typeof listSecretsApiSecretsSecretsGet>>>;
export type ListSecretsApiSecretsSecretsGetQueryError = FormValidationError | GeneralError;

/**
 * @summary List Secrets
 */

export function useListSecretsApiSecretsSecretsGet<
  TData = Awaited<ReturnType<typeof listSecretsApiSecretsSecretsGet>>,
  TError = FormValidationError | GeneralError,
>(
  params?: ListSecretsApiSecretsSecretsGetParams,
  options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof listSecretsApiSecretsSecretsGet>>, TError, TData>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} {
  const queryOptions = getListSecretsApiSecretsSecretsGetQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary List Static Translations
 */
export const listStaticTranslationsApiNatStaticNatGet = (params?: ListStaticTranslationsApiNatStaticNatGetParams, signal?: AbortSignal) => {
  return customInstance<NetBoxStaticNats>({url: `/api/nat/static-nat/`, method: "GET", params, signal});
};

export const getListStaticTranslationsApiNatStaticNatGetQueryKey = (params?: ListStaticTranslationsApiNatStaticNatGetParams) => {
  return [`/api/nat/static-nat/`, ...(params ? [params] : [])] as const;
};

export const getListStaticTranslationsApiNatStaticNatGetQueryOptions = <
  TData = Awaited<ReturnType<typeof listStaticTranslationsApiNatStaticNatGet>>,
  TError = FormValidationError | GeneralError,
>(
  params?: ListStaticTranslationsApiNatStaticNatGetParams,
  options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof listStaticTranslationsApiNatStaticNatGet>>, TError, TData>}
) => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListStaticTranslationsApiNatStaticNatGetQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listStaticTranslationsApiNatStaticNatGet>>> = ({signal}) =>
    listStaticTranslationsApiNatStaticNatGet(params, signal);

  return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
    Awaited<ReturnType<typeof listStaticTranslationsApiNatStaticNatGet>>,
    TError,
    TData
  > & {queryKey: QueryKey};
};

export type ListStaticTranslationsApiNatStaticNatGetQueryResult = NonNullable<Awaited<ReturnType<typeof listStaticTranslationsApiNatStaticNatGet>>>;
export type ListStaticTranslationsApiNatStaticNatGetQueryError = FormValidationError | GeneralError;

/**
 * @summary List Static Translations
 */

export function useListStaticTranslationsApiNatStaticNatGet<
  TData = Awaited<ReturnType<typeof listStaticTranslationsApiNatStaticNatGet>>,
  TError = FormValidationError | GeneralError,
>(
  params?: ListStaticTranslationsApiNatStaticNatGetParams,
  options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof listStaticTranslationsApiNatStaticNatGet>>, TError, TData>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} {
  const queryOptions = getListStaticTranslationsApiNatStaticNatGetQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Ip Address
 */
export const getIpAddressApiIpamIpAddressesIdGet = (id: number, signal?: AbortSignal) => {
  return customInstance<NetBoxIPAddress>({url: `/api/ipam/ip-addresses/${id}/`, method: "GET", signal});
};

export const getGetIpAddressApiIpamIpAddressesIdGetQueryKey = (id: number) => {
  return [`/api/ipam/ip-addresses/${id}/`] as const;
};

export const getGetIpAddressApiIpamIpAddressesIdGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getIpAddressApiIpamIpAddressesIdGet>>,
  TError = FormValidationError | GeneralError,
>(
  id: number,
  options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof getIpAddressApiIpamIpAddressesIdGet>>, TError, TData>}
) => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetIpAddressApiIpamIpAddressesIdGetQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getIpAddressApiIpamIpAddressesIdGet>>> = ({signal}) =>
    getIpAddressApiIpamIpAddressesIdGet(id, signal);

  return {queryKey, queryFn, enabled: !!id, ...queryOptions} as UseQueryOptions<
    Awaited<ReturnType<typeof getIpAddressApiIpamIpAddressesIdGet>>,
    TError,
    TData
  > & {queryKey: QueryKey};
};

export type GetIpAddressApiIpamIpAddressesIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof getIpAddressApiIpamIpAddressesIdGet>>>;
export type GetIpAddressApiIpamIpAddressesIdGetQueryError = FormValidationError | GeneralError;

/**
 * @summary Get Ip Address
 */

export function useGetIpAddressApiIpamIpAddressesIdGet<
  TData = Awaited<ReturnType<typeof getIpAddressApiIpamIpAddressesIdGet>>,
  TError = FormValidationError | GeneralError,
>(
  id: number,
  options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof getIpAddressApiIpamIpAddressesIdGet>>, TError, TData>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} {
  const queryOptions = getGetIpAddressApiIpamIpAddressesIdGetQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Register User
 */
export const registerUserAuthRegisterPost = (registerUser: RegisterUser) => {
  return customInstance<UserCreated>({url: `/auth/register/`, method: "POST", headers: {"Content-Type": "application/json"}, data: registerUser});
};

export const getRegisterUserAuthRegisterPostMutationOptions = <TError = FormValidationError | GeneralError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof registerUserAuthRegisterPost>>, TError, {data: RegisterUser}, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof registerUserAuthRegisterPost>>, TError, {data: RegisterUser}, TContext> => {
  const {mutation: mutationOptions} = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof registerUserAuthRegisterPost>>, {data: RegisterUser}> = (props) => {
    const {data} = props ?? {};

    return registerUserAuthRegisterPost(data);
  };

  return {mutationFn, ...mutationOptions};
};

export type RegisterUserAuthRegisterPostMutationResult = NonNullable<Awaited<ReturnType<typeof registerUserAuthRegisterPost>>>;
export type RegisterUserAuthRegisterPostMutationBody = RegisterUser;
export type RegisterUserAuthRegisterPostMutationError = FormValidationError | GeneralError;

/**
 * @summary Register User
 */
export const useRegisterUserAuthRegisterPost = <TError = FormValidationError | GeneralError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof registerUserAuthRegisterPost>>, TError, {data: RegisterUser}, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof registerUserAuthRegisterPost>>, TError, {data: RegisterUser}, TContext> => {
  const mutationOptions = getRegisterUserAuthRegisterPostMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Verify OTP and perform data posting to LDAP server
 * @summary Verify Otp
 */
export const verifyOtpAuthVerifyEmailPost = (registerUser: RegisterUser) => {
  return customInstance<UserCreated>({url: `/auth/verify-email/`, method: "POST", headers: {"Content-Type": "application/json"}, data: registerUser});
};

export const getVerifyOtpAuthVerifyEmailPostMutationOptions = <TError = FormValidationError | GeneralError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof verifyOtpAuthVerifyEmailPost>>, TError, {data: RegisterUser}, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof verifyOtpAuthVerifyEmailPost>>, TError, {data: RegisterUser}, TContext> => {
  const {mutation: mutationOptions} = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof verifyOtpAuthVerifyEmailPost>>, {data: RegisterUser}> = (props) => {
    const {data} = props ?? {};

    return verifyOtpAuthVerifyEmailPost(data);
  };

  return {mutationFn, ...mutationOptions};
};

export type VerifyOtpAuthVerifyEmailPostMutationResult = NonNullable<Awaited<ReturnType<typeof verifyOtpAuthVerifyEmailPost>>>;
export type VerifyOtpAuthVerifyEmailPostMutationBody = RegisterUser;
export type VerifyOtpAuthVerifyEmailPostMutationError = FormValidationError | GeneralError;

/**
 * @summary Verify Otp
 */
export const useVerifyOtpAuthVerifyEmailPost = <TError = FormValidationError | GeneralError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof verifyOtpAuthVerifyEmailPost>>, TError, {data: RegisterUser}, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof verifyOtpAuthVerifyEmailPost>>, TError, {data: RegisterUser}, TContext> => {
  const mutationOptions = getVerifyOtpAuthVerifyEmailPostMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get User Profile
 */
export const getUserProfileAuthMeGet = (signal?: AbortSignal) => {
  return customInstance<User>({url: `/auth/me/`, method: "GET", signal});
};

export const getGetUserProfileAuthMeGetQueryKey = () => {
  return [`/auth/me/`] as const;
};

export const getGetUserProfileAuthMeGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserProfileAuthMeGet>>,
  TError = FormValidationError | GeneralError,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserProfileAuthMeGet>>, TError, TData>;
}) => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserProfileAuthMeGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserProfileAuthMeGet>>> = ({signal}) => getUserProfileAuthMeGet(signal);

  return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUserProfileAuthMeGet>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetUserProfileAuthMeGetQueryResult = NonNullable<Awaited<ReturnType<typeof getUserProfileAuthMeGet>>>;
export type GetUserProfileAuthMeGetQueryError = FormValidationError | GeneralError;

/**
 * @summary Get User Profile
 */

export function useGetUserProfileAuthMeGet<
  TData = Awaited<ReturnType<typeof getUserProfileAuthMeGet>>,
  TError = FormValidationError | GeneralError,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserProfileAuthMeGet>>, TError, TData>;
}): UseQueryResult<TData, TError> & {queryKey: QueryKey} {
  const queryOptions = getGetUserProfileAuthMeGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Update User Profile
 */
export const updateUserProfileAuthMePatch = (hubspotUtk: HubspotUtk) => {
  return customInstance<User>({url: `/auth/me/`, method: "PATCH", headers: {"Content-Type": "application/json"}, data: hubspotUtk});
};

export const getUpdateUserProfileAuthMePatchMutationOptions = <TError = FormValidationError | GeneralError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateUserProfileAuthMePatch>>, TError, {data: HubspotUtk}, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof updateUserProfileAuthMePatch>>, TError, {data: HubspotUtk}, TContext> => {
  const {mutation: mutationOptions} = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateUserProfileAuthMePatch>>, {data: HubspotUtk}> = (props) => {
    const {data} = props ?? {};

    return updateUserProfileAuthMePatch(data);
  };

  return {mutationFn, ...mutationOptions};
};

export type UpdateUserProfileAuthMePatchMutationResult = NonNullable<Awaited<ReturnType<typeof updateUserProfileAuthMePatch>>>;
export type UpdateUserProfileAuthMePatchMutationBody = HubspotUtk;
export type UpdateUserProfileAuthMePatchMutationError = FormValidationError | GeneralError;

/**
 * @summary Update User Profile
 */
export const useUpdateUserProfileAuthMePatch = <TError = FormValidationError | GeneralError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateUserProfileAuthMePatch>>, TError, {data: HubspotUtk}, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof updateUserProfileAuthMePatch>>, TError, {data: HubspotUtk}, TContext> => {
  const mutationOptions = getUpdateUserProfileAuthMePatchMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Update User Metadata
 */
export const updateUserMetadataAuthUserMetadataPatch = (updateUserMetadataAuthUserMetadataPatchBody: UpdateUserMetadataAuthUserMetadataPatchBody) => {
  return customInstance<User>({
    url: `/auth/user/metadata/`,
    method: "PATCH",
    headers: {"Content-Type": "application/json"},
    data: updateUserMetadataAuthUserMetadataPatchBody,
  });
};

export const getUpdateUserMetadataAuthUserMetadataPatchMutationOptions = <TError = FormValidationError | GeneralError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUserMetadataAuthUserMetadataPatch>>,
    TError,
    {data: UpdateUserMetadataAuthUserMetadataPatchBody},
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateUserMetadataAuthUserMetadataPatch>>,
  TError,
  {data: UpdateUserMetadataAuthUserMetadataPatchBody},
  TContext
> => {
  const {mutation: mutationOptions} = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateUserMetadataAuthUserMetadataPatch>>,
    {data: UpdateUserMetadataAuthUserMetadataPatchBody}
  > = (props) => {
    const {data} = props ?? {};

    return updateUserMetadataAuthUserMetadataPatch(data);
  };

  return {mutationFn, ...mutationOptions};
};

export type UpdateUserMetadataAuthUserMetadataPatchMutationResult = NonNullable<Awaited<ReturnType<typeof updateUserMetadataAuthUserMetadataPatch>>>;
export type UpdateUserMetadataAuthUserMetadataPatchMutationBody = UpdateUserMetadataAuthUserMetadataPatchBody;
export type UpdateUserMetadataAuthUserMetadataPatchMutationError = FormValidationError | GeneralError;

/**
 * @summary Update User Metadata
 */
export const useUpdateUserMetadataAuthUserMetadataPatch = <TError = FormValidationError | GeneralError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUserMetadataAuthUserMetadataPatch>>,
    TError,
    {data: UpdateUserMetadataAuthUserMetadataPatchBody},
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateUserMetadataAuthUserMetadataPatch>>,
  TError,
  {data: UpdateUserMetadataAuthUserMetadataPatchBody},
  TContext
> => {
  const mutationOptions = getUpdateUserMetadataAuthUserMetadataPatchMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Run Health Query
 */
export const runHealthQueryHealthQueryPost = (query: Query, params?: RunHealthQueryHealthQueryPostParams) => {
  return customInstance<unknown>({url: `/health/query/`, method: "POST", headers: {"Content-Type": "application/json"}, data: query, params});
};

export const getRunHealthQueryHealthQueryPostMutationOptions = <TError = FormValidationError | GeneralError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof runHealthQueryHealthQueryPost>>,
    TError,
    {data: Query; params?: RunHealthQueryHealthQueryPostParams},
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof runHealthQueryHealthQueryPost>>,
  TError,
  {data: Query; params?: RunHealthQueryHealthQueryPostParams},
  TContext
> => {
  const {mutation: mutationOptions} = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof runHealthQueryHealthQueryPost>>,
    {data: Query; params?: RunHealthQueryHealthQueryPostParams}
  > = (props) => {
    const {data, params} = props ?? {};

    return runHealthQueryHealthQueryPost(data, params);
  };

  return {mutationFn, ...mutationOptions};
};

export type RunHealthQueryHealthQueryPostMutationResult = NonNullable<Awaited<ReturnType<typeof runHealthQueryHealthQueryPost>>>;
export type RunHealthQueryHealthQueryPostMutationBody = Query;
export type RunHealthQueryHealthQueryPostMutationError = FormValidationError | GeneralError;

/**
 * @summary Run Health Query
 */
export const useRunHealthQueryHealthQueryPost = <TError = FormValidationError | GeneralError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof runHealthQueryHealthQueryPost>>,
    TError,
    {data: Query; params?: RunHealthQueryHealthQueryPostParams},
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof runHealthQueryHealthQueryPost>>,
  TError,
  {data: Query; params?: RunHealthQueryHealthQueryPostParams},
  TContext
> => {
  const mutationOptions = getRunHealthQueryHealthQueryPostMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get Cloud Instance Health
 */
export const getCloudInstanceHealthHealthCloudInstanceIdGet = (id: number, signal?: AbortSignal) => {
  return customInstance<unknown>({url: `/health/cloud-instance/${id}/`, method: "GET", signal});
};

export const getGetCloudInstanceHealthHealthCloudInstanceIdGetQueryKey = (id: number) => {
  return [`/health/cloud-instance/${id}/`] as const;
};

export const getGetCloudInstanceHealthHealthCloudInstanceIdGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getCloudInstanceHealthHealthCloudInstanceIdGet>>,
  TError = FormValidationError | GeneralError,
>(
  id: number,
  options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof getCloudInstanceHealthHealthCloudInstanceIdGet>>, TError, TData>}
) => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCloudInstanceHealthHealthCloudInstanceIdGetQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCloudInstanceHealthHealthCloudInstanceIdGet>>> = ({signal}) =>
    getCloudInstanceHealthHealthCloudInstanceIdGet(id, signal);

  return {queryKey, queryFn, enabled: !!id, ...queryOptions} as UseQueryOptions<
    Awaited<ReturnType<typeof getCloudInstanceHealthHealthCloudInstanceIdGet>>,
    TError,
    TData
  > & {queryKey: QueryKey};
};

export type GetCloudInstanceHealthHealthCloudInstanceIdGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCloudInstanceHealthHealthCloudInstanceIdGet>>
>;
export type GetCloudInstanceHealthHealthCloudInstanceIdGetQueryError = FormValidationError | GeneralError;

/**
 * @summary Get Cloud Instance Health
 */

export function useGetCloudInstanceHealthHealthCloudInstanceIdGet<
  TData = Awaited<ReturnType<typeof getCloudInstanceHealthHealthCloudInstanceIdGet>>,
  TError = FormValidationError | GeneralError,
>(
  id: number,
  options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof getCloudInstanceHealthHealthCloudInstanceIdGet>>, TError, TData>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} {
  const queryOptions = getGetCloudInstanceHealthHealthCloudInstanceIdGetQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Webhook Customer
 */
export const webhookCustomerWebhookCbCustomerPost = (customerContent: CustomerContent) => {
  return customInstance<unknown>({
    url: `/webhook/cb/customer/`,
    method: "POST",
    headers: {"Content-Type": "application/json"},
    data: customerContent,
  });
};

export const getWebhookCustomerWebhookCbCustomerPostMutationOptions = <TError = FormValidationError | GeneralError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof webhookCustomerWebhookCbCustomerPost>>, TError, {data: CustomerContent}, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof webhookCustomerWebhookCbCustomerPost>>, TError, {data: CustomerContent}, TContext> => {
  const {mutation: mutationOptions} = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof webhookCustomerWebhookCbCustomerPost>>, {data: CustomerContent}> = (props) => {
    const {data} = props ?? {};

    return webhookCustomerWebhookCbCustomerPost(data);
  };

  return {mutationFn, ...mutationOptions};
};

export type WebhookCustomerWebhookCbCustomerPostMutationResult = NonNullable<Awaited<ReturnType<typeof webhookCustomerWebhookCbCustomerPost>>>;
export type WebhookCustomerWebhookCbCustomerPostMutationBody = CustomerContent;
export type WebhookCustomerWebhookCbCustomerPostMutationError = FormValidationError | GeneralError;

/**
 * @summary Webhook Customer
 */
export const useWebhookCustomerWebhookCbCustomerPost = <TError = FormValidationError | GeneralError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof webhookCustomerWebhookCbCustomerPost>>, TError, {data: CustomerContent}, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof webhookCustomerWebhookCbCustomerPost>>, TError, {data: CustomerContent}, TContext> => {
  const mutationOptions = getWebhookCustomerWebhookCbCustomerPostMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Webhook Subscription
 */
export const webhookSubscriptionWebhookCbSubscriptionPost = (subscriptionContent: SubscriptionContent) => {
  return customInstance<unknown>({
    url: `/webhook/cb/subscription/`,
    method: "POST",
    headers: {"Content-Type": "application/json"},
    data: subscriptionContent,
  });
};

export const getWebhookSubscriptionWebhookCbSubscriptionPostMutationOptions = <
  TError = FormValidationError | GeneralError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof webhookSubscriptionWebhookCbSubscriptionPost>>,
    TError,
    {data: SubscriptionContent},
    TContext
  >;
}): UseMutationOptions<Awaited<ReturnType<typeof webhookSubscriptionWebhookCbSubscriptionPost>>, TError, {data: SubscriptionContent}, TContext> => {
  const {mutation: mutationOptions} = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof webhookSubscriptionWebhookCbSubscriptionPost>>, {data: SubscriptionContent}> = (
    props
  ) => {
    const {data} = props ?? {};

    return webhookSubscriptionWebhookCbSubscriptionPost(data);
  };

  return {mutationFn, ...mutationOptions};
};

export type WebhookSubscriptionWebhookCbSubscriptionPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof webhookSubscriptionWebhookCbSubscriptionPost>>
>;
export type WebhookSubscriptionWebhookCbSubscriptionPostMutationBody = SubscriptionContent;
export type WebhookSubscriptionWebhookCbSubscriptionPostMutationError = FormValidationError | GeneralError;

/**
 * @summary Webhook Subscription
 */
export const useWebhookSubscriptionWebhookCbSubscriptionPost = <TError = FormValidationError | GeneralError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof webhookSubscriptionWebhookCbSubscriptionPost>>,
    TError,
    {data: SubscriptionContent},
    TContext
  >;
}): UseMutationResult<Awaited<ReturnType<typeof webhookSubscriptionWebhookCbSubscriptionPost>>, TError, {data: SubscriptionContent}, TContext> => {
  const mutationOptions = getWebhookSubscriptionWebhookCbSubscriptionPostMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Refund
 */
export const refundWebhookCbRefundPost = (refundContent: RefundContent) => {
  return customInstance<unknown>({url: `/webhook/cb/refund/`, method: "POST", headers: {"Content-Type": "application/json"}, data: refundContent});
};

export const getRefundWebhookCbRefundPostMutationOptions = <TError = FormValidationError | GeneralError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof refundWebhookCbRefundPost>>, TError, {data: RefundContent}, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof refundWebhookCbRefundPost>>, TError, {data: RefundContent}, TContext> => {
  const {mutation: mutationOptions} = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof refundWebhookCbRefundPost>>, {data: RefundContent}> = (props) => {
    const {data} = props ?? {};

    return refundWebhookCbRefundPost(data);
  };

  return {mutationFn, ...mutationOptions};
};

export type RefundWebhookCbRefundPostMutationResult = NonNullable<Awaited<ReturnType<typeof refundWebhookCbRefundPost>>>;
export type RefundWebhookCbRefundPostMutationBody = RefundContent;
export type RefundWebhookCbRefundPostMutationError = FormValidationError | GeneralError;

/**
 * @summary Refund
 */
export const useRefundWebhookCbRefundPost = <TError = FormValidationError | GeneralError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof refundWebhookCbRefundPost>>, TError, {data: RefundContent}, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof refundWebhookCbRefundPost>>, TError, {data: RefundContent}, TContext> => {
  const mutationOptions = getRefundWebhookCbRefundPostMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * List addons for a product family ID and period unit
 * @summary List Addons Support
 */
export const listAddonsSupportBillingAddonsSupportGet = (params: ListAddonsSupportBillingAddonsSupportGetParams, signal?: AbortSignal) => {
  return customInstance<Addons>({url: `/billing/addons/support/`, method: "GET", params, signal});
};

export const getListAddonsSupportBillingAddonsSupportGetQueryKey = (params: ListAddonsSupportBillingAddonsSupportGetParams) => {
  return [`/billing/addons/support/`, ...(params ? [params] : [])] as const;
};

export const getListAddonsSupportBillingAddonsSupportGetQueryOptions = <
  TData = Awaited<ReturnType<typeof listAddonsSupportBillingAddonsSupportGet>>,
  TError = FormValidationError | GeneralError,
>(
  params: ListAddonsSupportBillingAddonsSupportGetParams,
  options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof listAddonsSupportBillingAddonsSupportGet>>, TError, TData>}
) => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListAddonsSupportBillingAddonsSupportGetQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listAddonsSupportBillingAddonsSupportGet>>> = ({signal}) =>
    listAddonsSupportBillingAddonsSupportGet(params, signal);

  return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
    Awaited<ReturnType<typeof listAddonsSupportBillingAddonsSupportGet>>,
    TError,
    TData
  > & {queryKey: QueryKey};
};

export type ListAddonsSupportBillingAddonsSupportGetQueryResult = NonNullable<Awaited<ReturnType<typeof listAddonsSupportBillingAddonsSupportGet>>>;
export type ListAddonsSupportBillingAddonsSupportGetQueryError = FormValidationError | GeneralError;

/**
 * @summary List Addons Support
 */

export function useListAddonsSupportBillingAddonsSupportGet<
  TData = Awaited<ReturnType<typeof listAddonsSupportBillingAddonsSupportGet>>,
  TError = FormValidationError | GeneralError,
>(
  params: ListAddonsSupportBillingAddonsSupportGetParams,
  options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof listAddonsSupportBillingAddonsSupportGet>>, TError, TData>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} {
  const queryOptions = getListAddonsSupportBillingAddonsSupportGetQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Add a card to a customer
 * @summary Add Card
 */
export const addCardBillingCardsAddPost = (createCard: CreateCard) => {
  return customInstance<Card>({url: `/billing/cards/add/`, method: "POST", headers: {"Content-Type": "application/json"}, data: createCard});
};

export const getAddCardBillingCardsAddPostMutationOptions = <TError = FormValidationError | GeneralError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof addCardBillingCardsAddPost>>, TError, {data: CreateCard}, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof addCardBillingCardsAddPost>>, TError, {data: CreateCard}, TContext> => {
  const {mutation: mutationOptions} = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof addCardBillingCardsAddPost>>, {data: CreateCard}> = (props) => {
    const {data} = props ?? {};

    return addCardBillingCardsAddPost(data);
  };

  return {mutationFn, ...mutationOptions};
};

export type AddCardBillingCardsAddPostMutationResult = NonNullable<Awaited<ReturnType<typeof addCardBillingCardsAddPost>>>;
export type AddCardBillingCardsAddPostMutationBody = CreateCard;
export type AddCardBillingCardsAddPostMutationError = FormValidationError | GeneralError;

/**
 * @summary Add Card
 */
export const useAddCardBillingCardsAddPost = <TError = FormValidationError | GeneralError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof addCardBillingCardsAddPost>>, TError, {data: CreateCard}, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof addCardBillingCardsAddPost>>, TError, {data: CreateCard}, TContext> => {
  const mutationOptions = getAddCardBillingCardsAddPostMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Return the list of cards for the user
 * @summary List Cards
 */
export const listCardsBillingCardsGet = (signal?: AbortSignal) => {
  return customInstance<Cards>({url: `/billing/cards/`, method: "GET", signal});
};

export const getListCardsBillingCardsGetQueryKey = () => {
  return [`/billing/cards/`] as const;
};

export const getListCardsBillingCardsGetQueryOptions = <
  TData = Awaited<ReturnType<typeof listCardsBillingCardsGet>>,
  TError = FormValidationError | GeneralError,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof listCardsBillingCardsGet>>, TError, TData>;
}) => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListCardsBillingCardsGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listCardsBillingCardsGet>>> = ({signal}) => listCardsBillingCardsGet(signal);

  return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listCardsBillingCardsGet>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type ListCardsBillingCardsGetQueryResult = NonNullable<Awaited<ReturnType<typeof listCardsBillingCardsGet>>>;
export type ListCardsBillingCardsGetQueryError = FormValidationError | GeneralError;

/**
 * @summary List Cards
 */

export function useListCardsBillingCardsGet<
  TData = Awaited<ReturnType<typeof listCardsBillingCardsGet>>,
  TError = FormValidationError | GeneralError,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof listCardsBillingCardsGet>>, TError, TData>;
}): UseQueryResult<TData, TError> & {queryKey: QueryKey} {
  const queryOptions = getListCardsBillingCardsGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Return the details of a card for the user
 * @summary Get Card
 */
export const getCardBillingCardsIdGet = (id: string, signal?: AbortSignal) => {
  return customInstance<Card>({url: `/billing/cards/${id}/`, method: "GET", signal});
};

export const getGetCardBillingCardsIdGetQueryKey = (id: string) => {
  return [`/billing/cards/${id}/`] as const;
};

export const getGetCardBillingCardsIdGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getCardBillingCardsIdGet>>,
  TError = FormValidationError | GeneralError,
>(
  id: string,
  options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof getCardBillingCardsIdGet>>, TError, TData>}
) => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCardBillingCardsIdGetQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCardBillingCardsIdGet>>> = ({signal}) => getCardBillingCardsIdGet(id, signal);

  return {queryKey, queryFn, enabled: !!id, ...queryOptions} as UseQueryOptions<
    Awaited<ReturnType<typeof getCardBillingCardsIdGet>>,
    TError,
    TData
  > & {queryKey: QueryKey};
};

export type GetCardBillingCardsIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof getCardBillingCardsIdGet>>>;
export type GetCardBillingCardsIdGetQueryError = FormValidationError | GeneralError;

/**
 * @summary Get Card
 */

export function useGetCardBillingCardsIdGet<
  TData = Awaited<ReturnType<typeof getCardBillingCardsIdGet>>,
  TError = FormValidationError | GeneralError,
>(
  id: string,
  options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof getCardBillingCardsIdGet>>, TError, TData>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} {
  const queryOptions = getGetCardBillingCardsIdGetQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Return the details of a card for the user
 * @summary Delete Card
 */
export const deleteCardBillingCardsIdDelete = (id: string) => {
  return customInstance<void>({url: `/billing/cards/${id}/`, method: "DELETE"});
};

export const getDeleteCardBillingCardsIdDeleteMutationOptions = <TError = FormValidationError | GeneralError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteCardBillingCardsIdDelete>>, TError, {id: string}, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof deleteCardBillingCardsIdDelete>>, TError, {id: string}, TContext> => {
  const {mutation: mutationOptions} = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteCardBillingCardsIdDelete>>, {id: string}> = (props) => {
    const {id} = props ?? {};

    return deleteCardBillingCardsIdDelete(id);
  };

  return {mutationFn, ...mutationOptions};
};

export type DeleteCardBillingCardsIdDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCardBillingCardsIdDelete>>>;

export type DeleteCardBillingCardsIdDeleteMutationError = FormValidationError | GeneralError;

/**
 * @summary Delete Card
 */
export const useDeleteCardBillingCardsIdDelete = <TError = FormValidationError | GeneralError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteCardBillingCardsIdDelete>>, TError, {id: string}, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof deleteCardBillingCardsIdDelete>>, TError, {id: string}, TContext> => {
  const mutationOptions = getDeleteCardBillingCardsIdDeleteMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Update a card as primary or backup card
 * @summary Update Card Role
 */
export const updateCardRoleBillingCardsIdAssignRolePatch = (id: string, cardAssignRole: CardAssignRole) => {
  return customInstance<Card>({
    url: `/billing/cards/${id}/assign_role/`,
    method: "PATCH",
    headers: {"Content-Type": "application/json"},
    data: cardAssignRole,
  });
};

export const getUpdateCardRoleBillingCardsIdAssignRolePatchMutationOptions = <
  TError = FormValidationError | GeneralError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCardRoleBillingCardsIdAssignRolePatch>>,
    TError,
    {id: string; data: CardAssignRole},
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateCardRoleBillingCardsIdAssignRolePatch>>,
  TError,
  {id: string; data: CardAssignRole},
  TContext
> => {
  const {mutation: mutationOptions} = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateCardRoleBillingCardsIdAssignRolePatch>>, {id: string; data: CardAssignRole}> = (
    props
  ) => {
    const {id, data} = props ?? {};

    return updateCardRoleBillingCardsIdAssignRolePatch(id, data);
  };

  return {mutationFn, ...mutationOptions};
};

export type UpdateCardRoleBillingCardsIdAssignRolePatchMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateCardRoleBillingCardsIdAssignRolePatch>>
>;
export type UpdateCardRoleBillingCardsIdAssignRolePatchMutationBody = CardAssignRole;
export type UpdateCardRoleBillingCardsIdAssignRolePatchMutationError = FormValidationError | GeneralError;

/**
 * @summary Update Card Role
 */
export const useUpdateCardRoleBillingCardsIdAssignRolePatch = <TError = FormValidationError | GeneralError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCardRoleBillingCardsIdAssignRolePatch>>,
    TError,
    {id: string; data: CardAssignRole},
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateCardRoleBillingCardsIdAssignRolePatch>>,
  TError,
  {id: string; data: CardAssignRole},
  TContext
> => {
  const mutationOptions = getUpdateCardRoleBillingCardsIdAssignRolePatchMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Update Subscription
 */
export const updateSubscriptionBillingCheckoutSubscriptionIdPatch = (id: string, checkoutSubscription: CheckoutSubscription) => {
  return customInstance<HostedPageResponse>({
    url: `/billing/checkout/subscription/${id}/`,
    method: "PATCH",
    headers: {"Content-Type": "application/json"},
    data: checkoutSubscription,
  });
};

export const getUpdateSubscriptionBillingCheckoutSubscriptionIdPatchMutationOptions = <
  TError = FormValidationError | GeneralError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateSubscriptionBillingCheckoutSubscriptionIdPatch>>,
    TError,
    {id: string; data: CheckoutSubscription},
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateSubscriptionBillingCheckoutSubscriptionIdPatch>>,
  TError,
  {id: string; data: CheckoutSubscription},
  TContext
> => {
  const {mutation: mutationOptions} = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateSubscriptionBillingCheckoutSubscriptionIdPatch>>,
    {id: string; data: CheckoutSubscription}
  > = (props) => {
    const {id, data} = props ?? {};

    return updateSubscriptionBillingCheckoutSubscriptionIdPatch(id, data);
  };

  return {mutationFn, ...mutationOptions};
};

export type UpdateSubscriptionBillingCheckoutSubscriptionIdPatchMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateSubscriptionBillingCheckoutSubscriptionIdPatch>>
>;
export type UpdateSubscriptionBillingCheckoutSubscriptionIdPatchMutationBody = CheckoutSubscription;
export type UpdateSubscriptionBillingCheckoutSubscriptionIdPatchMutationError = FormValidationError | GeneralError;

/**
 * @summary Update Subscription
 */
export const useUpdateSubscriptionBillingCheckoutSubscriptionIdPatch = <TError = FormValidationError | GeneralError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateSubscriptionBillingCheckoutSubscriptionIdPatch>>,
    TError,
    {id: string; data: CheckoutSubscription},
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateSubscriptionBillingCheckoutSubscriptionIdPatch>>,
  TError,
  {id: string; data: CheckoutSubscription},
  TContext
> => {
  const mutationOptions = getUpdateSubscriptionBillingCheckoutSubscriptionIdPatchMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Add Subscription
 */
export const addSubscriptionBillingCheckoutSubscriptionPost = (checkoutSubscription: CheckoutSubscription) => {
  return customInstance<HostedPageResponse>({
    url: `/billing/checkout/subscription/`,
    method: "POST",
    headers: {"Content-Type": "application/json"},
    data: checkoutSubscription,
  });
};

export const getAddSubscriptionBillingCheckoutSubscriptionPostMutationOptions = <
  TError = FormValidationError | GeneralError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addSubscriptionBillingCheckoutSubscriptionPost>>,
    TError,
    {data: CheckoutSubscription},
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof addSubscriptionBillingCheckoutSubscriptionPost>>,
  TError,
  {data: CheckoutSubscription},
  TContext
> => {
  const {mutation: mutationOptions} = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof addSubscriptionBillingCheckoutSubscriptionPost>>, {data: CheckoutSubscription}> = (
    props
  ) => {
    const {data} = props ?? {};

    return addSubscriptionBillingCheckoutSubscriptionPost(data);
  };

  return {mutationFn, ...mutationOptions};
};

export type AddSubscriptionBillingCheckoutSubscriptionPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof addSubscriptionBillingCheckoutSubscriptionPost>>
>;
export type AddSubscriptionBillingCheckoutSubscriptionPostMutationBody = CheckoutSubscription;
export type AddSubscriptionBillingCheckoutSubscriptionPostMutationError = FormValidationError | GeneralError;

/**
 * @summary Add Subscription
 */
export const useAddSubscriptionBillingCheckoutSubscriptionPost = <TError = FormValidationError | GeneralError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addSubscriptionBillingCheckoutSubscriptionPost>>,
    TError,
    {data: CheckoutSubscription},
    TContext
  >;
}): UseMutationResult<Awaited<ReturnType<typeof addSubscriptionBillingCheckoutSubscriptionPost>>, TError, {data: CheckoutSubscription}, TContext> => {
  const mutationOptions = getAddSubscriptionBillingCheckoutSubscriptionPostMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Returns the list of product families
 * @summary List Product Families
 */
export const listProductFamiliesBillingProductsProductFamilyGet = (signal?: AbortSignal) => {
  return customInstance<ProductFamilies>({url: `/billing/products/product-family/`, method: "GET", signal});
};

export const getListProductFamiliesBillingProductsProductFamilyGetQueryKey = () => {
  return [`/billing/products/product-family/`] as const;
};

export const getListProductFamiliesBillingProductsProductFamilyGetQueryOptions = <
  TData = Awaited<ReturnType<typeof listProductFamiliesBillingProductsProductFamilyGet>>,
  TError = FormValidationError | GeneralError,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof listProductFamiliesBillingProductsProductFamilyGet>>, TError, TData>;
}) => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListProductFamiliesBillingProductsProductFamilyGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listProductFamiliesBillingProductsProductFamilyGet>>> = ({signal}) =>
    listProductFamiliesBillingProductsProductFamilyGet(signal);

  return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
    Awaited<ReturnType<typeof listProductFamiliesBillingProductsProductFamilyGet>>,
    TError,
    TData
  > & {queryKey: QueryKey};
};

export type ListProductFamiliesBillingProductsProductFamilyGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof listProductFamiliesBillingProductsProductFamilyGet>>
>;
export type ListProductFamiliesBillingProductsProductFamilyGetQueryError = FormValidationError | GeneralError;

/**
 * @summary List Product Families
 */

export function useListProductFamiliesBillingProductsProductFamilyGet<
  TData = Awaited<ReturnType<typeof listProductFamiliesBillingProductsProductFamilyGet>>,
  TError = FormValidationError | GeneralError,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof listProductFamiliesBillingProductsProductFamilyGet>>, TError, TData>;
}): UseQueryResult<TData, TError> & {queryKey: QueryKey} {
  const queryOptions = getListProductFamiliesBillingProductsProductFamilyGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Returns the list of frequencies
 * @summary List Frequencies
 */
export const listFrequenciesBillingProductsFrequenciesGet = (params: ListFrequenciesBillingProductsFrequenciesGetParams, signal?: AbortSignal) => {
  return customInstance<Frequencies>({url: `/billing/products/frequencies/`, method: "GET", params, signal});
};

export const getListFrequenciesBillingProductsFrequenciesGetQueryKey = (params: ListFrequenciesBillingProductsFrequenciesGetParams) => {
  return [`/billing/products/frequencies/`, ...(params ? [params] : [])] as const;
};

export const getListFrequenciesBillingProductsFrequenciesGetQueryOptions = <
  TData = Awaited<ReturnType<typeof listFrequenciesBillingProductsFrequenciesGet>>,
  TError = FormValidationError | GeneralError,
>(
  params: ListFrequenciesBillingProductsFrequenciesGetParams,
  options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof listFrequenciesBillingProductsFrequenciesGet>>, TError, TData>}
) => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListFrequenciesBillingProductsFrequenciesGetQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listFrequenciesBillingProductsFrequenciesGet>>> = ({signal}) =>
    listFrequenciesBillingProductsFrequenciesGet(params, signal);

  return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
    Awaited<ReturnType<typeof listFrequenciesBillingProductsFrequenciesGet>>,
    TError,
    TData
  > & {queryKey: QueryKey};
};

export type ListFrequenciesBillingProductsFrequenciesGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof listFrequenciesBillingProductsFrequenciesGet>>
>;
export type ListFrequenciesBillingProductsFrequenciesGetQueryError = FormValidationError | GeneralError;

/**
 * @summary List Frequencies
 */

export function useListFrequenciesBillingProductsFrequenciesGet<
  TData = Awaited<ReturnType<typeof listFrequenciesBillingProductsFrequenciesGet>>,
  TError = FormValidationError | GeneralError,
>(
  params: ListFrequenciesBillingProductsFrequenciesGetParams,
  options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof listFrequenciesBillingProductsFrequenciesGet>>, TError, TData>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} {
  const queryOptions = getListFrequenciesBillingProductsFrequenciesGetQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary List Plans
 */
export const listPlansBillingProductsPlansGet = (params: ListPlansBillingProductsPlansGetParams, signal?: AbortSignal) => {
  return customInstance<Plans>({url: `/billing/products/plans/`, method: "GET", params, signal});
};

export const getListPlansBillingProductsPlansGetQueryKey = (params: ListPlansBillingProductsPlansGetParams) => {
  return [`/billing/products/plans/`, ...(params ? [params] : [])] as const;
};

export const getListPlansBillingProductsPlansGetQueryOptions = <
  TData = Awaited<ReturnType<typeof listPlansBillingProductsPlansGet>>,
  TError = FormValidationError | GeneralError,
>(
  params: ListPlansBillingProductsPlansGetParams,
  options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof listPlansBillingProductsPlansGet>>, TError, TData>}
) => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListPlansBillingProductsPlansGetQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listPlansBillingProductsPlansGet>>> = ({signal}) =>
    listPlansBillingProductsPlansGet(params, signal);

  return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listPlansBillingProductsPlansGet>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type ListPlansBillingProductsPlansGetQueryResult = NonNullable<Awaited<ReturnType<typeof listPlansBillingProductsPlansGet>>>;
export type ListPlansBillingProductsPlansGetQueryError = FormValidationError | GeneralError;

/**
 * @summary List Plans
 */

export function useListPlansBillingProductsPlansGet<
  TData = Awaited<ReturnType<typeof listPlansBillingProductsPlansGet>>,
  TError = FormValidationError | GeneralError,
>(
  params: ListPlansBillingProductsPlansGetParams,
  options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof listPlansBillingProductsPlansGet>>, TError, TData>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} {
  const queryOptions = getListPlansBillingProductsPlansGetQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Return the list of operating systems for instance provisioning
 * @summary List Operating Systems
 */
export const listOperatingSystemsBillingProductsPlansOsGet = (params: ListOperatingSystemsBillingProductsPlansOsGetParams, signal?: AbortSignal) => {
  return customInstance<OperatingSystems>({url: `/billing/products/plans/os/`, method: "GET", params, signal});
};

export const getListOperatingSystemsBillingProductsPlansOsGetQueryKey = (params: ListOperatingSystemsBillingProductsPlansOsGetParams) => {
  return [`/billing/products/plans/os/`, ...(params ? [params] : [])] as const;
};

export const getListOperatingSystemsBillingProductsPlansOsGetQueryOptions = <
  TData = Awaited<ReturnType<typeof listOperatingSystemsBillingProductsPlansOsGet>>,
  TError = FormValidationError | GeneralError,
>(
  params: ListOperatingSystemsBillingProductsPlansOsGetParams,
  options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof listOperatingSystemsBillingProductsPlansOsGet>>, TError, TData>}
) => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListOperatingSystemsBillingProductsPlansOsGetQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listOperatingSystemsBillingProductsPlansOsGet>>> = ({signal}) =>
    listOperatingSystemsBillingProductsPlansOsGet(params, signal);

  return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
    Awaited<ReturnType<typeof listOperatingSystemsBillingProductsPlansOsGet>>,
    TError,
    TData
  > & {queryKey: QueryKey};
};

export type ListOperatingSystemsBillingProductsPlansOsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof listOperatingSystemsBillingProductsPlansOsGet>>
>;
export type ListOperatingSystemsBillingProductsPlansOsGetQueryError = FormValidationError | GeneralError;

/**
 * @summary List Operating Systems
 */

export function useListOperatingSystemsBillingProductsPlansOsGet<
  TData = Awaited<ReturnType<typeof listOperatingSystemsBillingProductsPlansOsGet>>,
  TError = FormValidationError | GeneralError,
>(
  params: ListOperatingSystemsBillingProductsPlansOsGetParams,
  options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof listOperatingSystemsBillingProductsPlansOsGet>>, TError, TData>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} {
  const queryOptions = getListOperatingSystemsBillingProductsPlansOsGetQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Return the list of jumpboxes for instance provisioning
 * @summary List Jumpboxes
 */
export const listJumpboxesBillingProductsPlansJumpboxesGet = (params: ListJumpboxesBillingProductsPlansJumpboxesGetParams, signal?: AbortSignal) => {
  return customInstance<Jumpboxes>({url: `/billing/products/plans/jumpboxes/`, method: "GET", params, signal});
};

export const getListJumpboxesBillingProductsPlansJumpboxesGetQueryKey = (params: ListJumpboxesBillingProductsPlansJumpboxesGetParams) => {
  return [`/billing/products/plans/jumpboxes/`, ...(params ? [params] : [])] as const;
};

export const getListJumpboxesBillingProductsPlansJumpboxesGetQueryOptions = <
  TData = Awaited<ReturnType<typeof listJumpboxesBillingProductsPlansJumpboxesGet>>,
  TError = FormValidationError | GeneralError,
>(
  params: ListJumpboxesBillingProductsPlansJumpboxesGetParams,
  options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof listJumpboxesBillingProductsPlansJumpboxesGet>>, TError, TData>}
) => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListJumpboxesBillingProductsPlansJumpboxesGetQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listJumpboxesBillingProductsPlansJumpboxesGet>>> = ({signal}) =>
    listJumpboxesBillingProductsPlansJumpboxesGet(params, signal);

  return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
    Awaited<ReturnType<typeof listJumpboxesBillingProductsPlansJumpboxesGet>>,
    TError,
    TData
  > & {queryKey: QueryKey};
};

export type ListJumpboxesBillingProductsPlansJumpboxesGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof listJumpboxesBillingProductsPlansJumpboxesGet>>
>;
export type ListJumpboxesBillingProductsPlansJumpboxesGetQueryError = FormValidationError | GeneralError;

/**
 * @summary List Jumpboxes
 */

export function useListJumpboxesBillingProductsPlansJumpboxesGet<
  TData = Awaited<ReturnType<typeof listJumpboxesBillingProductsPlansJumpboxesGet>>,
  TError = FormValidationError | GeneralError,
>(
  params: ListJumpboxesBillingProductsPlansJumpboxesGetParams,
  options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof listJumpboxesBillingProductsPlansJumpboxesGet>>, TError, TData>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} {
  const queryOptions = getListJumpboxesBillingProductsPlansJumpboxesGetQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Returns the list of Subscriptions
 * @summary List Subscriptions
 */
export const listSubscriptionsBillingSubscriptionsGet = (signal?: AbortSignal) => {
  return customInstance<Subscriptions>({url: `/billing/subscriptions/`, method: "GET", signal});
};

export const getListSubscriptionsBillingSubscriptionsGetQueryKey = () => {
  return [`/billing/subscriptions/`] as const;
};

export const getListSubscriptionsBillingSubscriptionsGetQueryOptions = <
  TData = Awaited<ReturnType<typeof listSubscriptionsBillingSubscriptionsGet>>,
  TError = FormValidationError | GeneralError,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof listSubscriptionsBillingSubscriptionsGet>>, TError, TData>;
}) => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListSubscriptionsBillingSubscriptionsGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listSubscriptionsBillingSubscriptionsGet>>> = ({signal}) =>
    listSubscriptionsBillingSubscriptionsGet(signal);

  return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
    Awaited<ReturnType<typeof listSubscriptionsBillingSubscriptionsGet>>,
    TError,
    TData
  > & {queryKey: QueryKey};
};

export type ListSubscriptionsBillingSubscriptionsGetQueryResult = NonNullable<Awaited<ReturnType<typeof listSubscriptionsBillingSubscriptionsGet>>>;
export type ListSubscriptionsBillingSubscriptionsGetQueryError = FormValidationError | GeneralError;

/**
 * @summary List Subscriptions
 */

export function useListSubscriptionsBillingSubscriptionsGet<
  TData = Awaited<ReturnType<typeof listSubscriptionsBillingSubscriptionsGet>>,
  TError = FormValidationError | GeneralError,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof listSubscriptionsBillingSubscriptionsGet>>, TError, TData>;
}): UseQueryResult<TData, TError> & {queryKey: QueryKey} {
  const queryOptions = getListSubscriptionsBillingSubscriptionsGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Returns the Subscription searched by subscription_id
 * @summary Get Subscription
 */
export const getSubscriptionBillingSubscriptionsIdGet = (id: string, signal?: AbortSignal) => {
  return customInstance<Subscription>({url: `/billing/subscriptions/${id}/`, method: "GET", signal});
};

export const getGetSubscriptionBillingSubscriptionsIdGetQueryKey = (id: string) => {
  return [`/billing/subscriptions/${id}/`] as const;
};

export const getGetSubscriptionBillingSubscriptionsIdGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getSubscriptionBillingSubscriptionsIdGet>>,
  TError = FormValidationError | GeneralError,
>(
  id: string,
  options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof getSubscriptionBillingSubscriptionsIdGet>>, TError, TData>}
) => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSubscriptionBillingSubscriptionsIdGetQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSubscriptionBillingSubscriptionsIdGet>>> = ({signal}) =>
    getSubscriptionBillingSubscriptionsIdGet(id, signal);

  return {queryKey, queryFn, enabled: !!id, ...queryOptions} as UseQueryOptions<
    Awaited<ReturnType<typeof getSubscriptionBillingSubscriptionsIdGet>>,
    TError,
    TData
  > & {queryKey: QueryKey};
};

export type GetSubscriptionBillingSubscriptionsIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof getSubscriptionBillingSubscriptionsIdGet>>>;
export type GetSubscriptionBillingSubscriptionsIdGetQueryError = FormValidationError | GeneralError;

/**
 * @summary Get Subscription
 */

export function useGetSubscriptionBillingSubscriptionsIdGet<
  TData = Awaited<ReturnType<typeof getSubscriptionBillingSubscriptionsIdGet>>,
  TError = FormValidationError | GeneralError,
>(
  id: string,
  options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof getSubscriptionBillingSubscriptionsIdGet>>, TError, TData>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} {
  const queryOptions = getGetSubscriptionBillingSubscriptionsIdGetQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Returns the list of invoices
 * @summary List Subscription Invoices
 */
export const listSubscriptionInvoicesBillingSubscriptionsIdInvoicesGet = (id: string, signal?: AbortSignal) => {
  return customInstance<Invoices>({url: `/billing/subscriptions/${id}/invoices/`, method: "GET", signal});
};

export const getListSubscriptionInvoicesBillingSubscriptionsIdInvoicesGetQueryKey = (id: string) => {
  return [`/billing/subscriptions/${id}/invoices/`] as const;
};

export const getListSubscriptionInvoicesBillingSubscriptionsIdInvoicesGetQueryOptions = <
  TData = Awaited<ReturnType<typeof listSubscriptionInvoicesBillingSubscriptionsIdInvoicesGet>>,
  TError = FormValidationError | GeneralError,
>(
  id: string,
  options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof listSubscriptionInvoicesBillingSubscriptionsIdInvoicesGet>>, TError, TData>}
) => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListSubscriptionInvoicesBillingSubscriptionsIdInvoicesGetQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listSubscriptionInvoicesBillingSubscriptionsIdInvoicesGet>>> = ({signal}) =>
    listSubscriptionInvoicesBillingSubscriptionsIdInvoicesGet(id, signal);

  return {queryKey, queryFn, enabled: !!id, ...queryOptions} as UseQueryOptions<
    Awaited<ReturnType<typeof listSubscriptionInvoicesBillingSubscriptionsIdInvoicesGet>>,
    TError,
    TData
  > & {queryKey: QueryKey};
};

export type ListSubscriptionInvoicesBillingSubscriptionsIdInvoicesGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof listSubscriptionInvoicesBillingSubscriptionsIdInvoicesGet>>
>;
export type ListSubscriptionInvoicesBillingSubscriptionsIdInvoicesGetQueryError = FormValidationError | GeneralError;

/**
 * @summary List Subscription Invoices
 */

export function useListSubscriptionInvoicesBillingSubscriptionsIdInvoicesGet<
  TData = Awaited<ReturnType<typeof listSubscriptionInvoicesBillingSubscriptionsIdInvoicesGet>>,
  TError = FormValidationError | GeneralError,
>(
  id: string,
  options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof listSubscriptionInvoicesBillingSubscriptionsIdInvoicesGet>>, TError, TData>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} {
  const queryOptions = getListSubscriptionInvoicesBillingSubscriptionsIdInvoicesGetQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Returns the list of invoices
 * @summary Get Subscription Invoice
 */
export const getSubscriptionInvoiceBillingSubscriptionsSubscriptionIdInvoicesIdGet = (subscriptionId: string, id: string, signal?: AbortSignal) => {
  return customInstance<Invoice>({url: `/billing/subscriptions/${subscriptionId}/invoices/${id}/`, method: "GET", signal});
};

export const getGetSubscriptionInvoiceBillingSubscriptionsSubscriptionIdInvoicesIdGetQueryKey = (subscriptionId: string, id: string) => {
  return [`/billing/subscriptions/${subscriptionId}/invoices/${id}/`] as const;
};

export const getGetSubscriptionInvoiceBillingSubscriptionsSubscriptionIdInvoicesIdGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getSubscriptionInvoiceBillingSubscriptionsSubscriptionIdInvoicesIdGet>>,
  TError = FormValidationError | GeneralError,
>(
  subscriptionId: string,
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getSubscriptionInvoiceBillingSubscriptionsSubscriptionIdInvoicesIdGet>>, TError, TData>;
  }
) => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSubscriptionInvoiceBillingSubscriptionsSubscriptionIdInvoicesIdGetQueryKey(subscriptionId, id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSubscriptionInvoiceBillingSubscriptionsSubscriptionIdInvoicesIdGet>>> = ({signal}) =>
    getSubscriptionInvoiceBillingSubscriptionsSubscriptionIdInvoicesIdGet(subscriptionId, id, signal);

  return {queryKey, queryFn, enabled: !!(subscriptionId && id), ...queryOptions} as UseQueryOptions<
    Awaited<ReturnType<typeof getSubscriptionInvoiceBillingSubscriptionsSubscriptionIdInvoicesIdGet>>,
    TError,
    TData
  > & {queryKey: QueryKey};
};

export type GetSubscriptionInvoiceBillingSubscriptionsSubscriptionIdInvoicesIdGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSubscriptionInvoiceBillingSubscriptionsSubscriptionIdInvoicesIdGet>>
>;
export type GetSubscriptionInvoiceBillingSubscriptionsSubscriptionIdInvoicesIdGetQueryError = FormValidationError | GeneralError;

/**
 * @summary Get Subscription Invoice
 */

export function useGetSubscriptionInvoiceBillingSubscriptionsSubscriptionIdInvoicesIdGet<
  TData = Awaited<ReturnType<typeof getSubscriptionInvoiceBillingSubscriptionsSubscriptionIdInvoicesIdGet>>,
  TError = FormValidationError | GeneralError,
>(
  subscriptionId: string,
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getSubscriptionInvoiceBillingSubscriptionsSubscriptionIdInvoicesIdGet>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} {
  const queryOptions = getGetSubscriptionInvoiceBillingSubscriptionsSubscriptionIdInvoicesIdGetQueryOptions(subscriptionId, id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Returns the Subscription credit note
 * @summary List Subscription Credit Notes
 */
export const listSubscriptionCreditNotesBillingSubscriptionsIdCreditNotesGet = (id: string, signal?: AbortSignal) => {
  return customInstance<CreditNotes>({url: `/billing/subscriptions/${id}/credit-notes/`, method: "GET", signal});
};

export const getListSubscriptionCreditNotesBillingSubscriptionsIdCreditNotesGetQueryKey = (id: string) => {
  return [`/billing/subscriptions/${id}/credit-notes/`] as const;
};

export const getListSubscriptionCreditNotesBillingSubscriptionsIdCreditNotesGetQueryOptions = <
  TData = Awaited<ReturnType<typeof listSubscriptionCreditNotesBillingSubscriptionsIdCreditNotesGet>>,
  TError = FormValidationError | GeneralError,
>(
  id: string,
  options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof listSubscriptionCreditNotesBillingSubscriptionsIdCreditNotesGet>>, TError, TData>}
) => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListSubscriptionCreditNotesBillingSubscriptionsIdCreditNotesGetQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listSubscriptionCreditNotesBillingSubscriptionsIdCreditNotesGet>>> = ({signal}) =>
    listSubscriptionCreditNotesBillingSubscriptionsIdCreditNotesGet(id, signal);

  return {queryKey, queryFn, enabled: !!id, ...queryOptions} as UseQueryOptions<
    Awaited<ReturnType<typeof listSubscriptionCreditNotesBillingSubscriptionsIdCreditNotesGet>>,
    TError,
    TData
  > & {queryKey: QueryKey};
};

export type ListSubscriptionCreditNotesBillingSubscriptionsIdCreditNotesGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof listSubscriptionCreditNotesBillingSubscriptionsIdCreditNotesGet>>
>;
export type ListSubscriptionCreditNotesBillingSubscriptionsIdCreditNotesGetQueryError = FormValidationError | GeneralError;

/**
 * @summary List Subscription Credit Notes
 */

export function useListSubscriptionCreditNotesBillingSubscriptionsIdCreditNotesGet<
  TData = Awaited<ReturnType<typeof listSubscriptionCreditNotesBillingSubscriptionsIdCreditNotesGet>>,
  TError = FormValidationError | GeneralError,
>(
  id: string,
  options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof listSubscriptionCreditNotesBillingSubscriptionsIdCreditNotesGet>>, TError, TData>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} {
  const queryOptions = getListSubscriptionCreditNotesBillingSubscriptionsIdCreditNotesGetQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Returns the credit notes
 * @summary Get Subscription Credit Note
 */
export const getSubscriptionCreditNoteBillingSubscriptionsSubscriptionIdCreditNotesIdGet = (
  subscriptionId: string,
  id: string,
  signal?: AbortSignal
) => {
  return customInstance<CreditNote>({url: `/billing/subscriptions/${subscriptionId}/credit-notes/${id}/`, method: "GET", signal});
};

export const getGetSubscriptionCreditNoteBillingSubscriptionsSubscriptionIdCreditNotesIdGetQueryKey = (subscriptionId: string, id: string) => {
  return [`/billing/subscriptions/${subscriptionId}/credit-notes/${id}/`] as const;
};

export const getGetSubscriptionCreditNoteBillingSubscriptionsSubscriptionIdCreditNotesIdGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getSubscriptionCreditNoteBillingSubscriptionsSubscriptionIdCreditNotesIdGet>>,
  TError = FormValidationError | GeneralError,
>(
  subscriptionId: string,
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getSubscriptionCreditNoteBillingSubscriptionsSubscriptionIdCreditNotesIdGet>>, TError, TData>;
  }
) => {
  const {query: queryOptions} = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetSubscriptionCreditNoteBillingSubscriptionsSubscriptionIdCreditNotesIdGetQueryKey(subscriptionId, id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSubscriptionCreditNoteBillingSubscriptionsSubscriptionIdCreditNotesIdGet>>> = ({
    signal,
  }) => getSubscriptionCreditNoteBillingSubscriptionsSubscriptionIdCreditNotesIdGet(subscriptionId, id, signal);

  return {queryKey, queryFn, enabled: !!(subscriptionId && id), ...queryOptions} as UseQueryOptions<
    Awaited<ReturnType<typeof getSubscriptionCreditNoteBillingSubscriptionsSubscriptionIdCreditNotesIdGet>>,
    TError,
    TData
  > & {queryKey: QueryKey};
};

export type GetSubscriptionCreditNoteBillingSubscriptionsSubscriptionIdCreditNotesIdGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSubscriptionCreditNoteBillingSubscriptionsSubscriptionIdCreditNotesIdGet>>
>;
export type GetSubscriptionCreditNoteBillingSubscriptionsSubscriptionIdCreditNotesIdGetQueryError = FormValidationError | GeneralError;

/**
 * @summary Get Subscription Credit Note
 */

export function useGetSubscriptionCreditNoteBillingSubscriptionsSubscriptionIdCreditNotesIdGet<
  TData = Awaited<ReturnType<typeof getSubscriptionCreditNoteBillingSubscriptionsSubscriptionIdCreditNotesIdGet>>,
  TError = FormValidationError | GeneralError,
>(
  subscriptionId: string,
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getSubscriptionCreditNoteBillingSubscriptionsSubscriptionIdCreditNotesIdGet>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} {
  const queryOptions = getGetSubscriptionCreditNoteBillingSubscriptionsSubscriptionIdCreditNotesIdGetQueryOptions(subscriptionId, id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Cancel a subscription
 * @summary Cancel Subscription
 */
export const cancelSubscriptionBillingSubscriptionsIdCancelPatch = (
  id: string,
  params: CancelSubscriptionBillingSubscriptionsIdCancelPatchParams
) => {
  return customInstance<Subscription>({url: `/billing/subscriptions/${id}/cancel/`, method: "PATCH", params});
};

export const getCancelSubscriptionBillingSubscriptionsIdCancelPatchMutationOptions = <
  TError = FormValidationError | GeneralError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cancelSubscriptionBillingSubscriptionsIdCancelPatch>>,
    TError,
    {id: string; params: CancelSubscriptionBillingSubscriptionsIdCancelPatchParams},
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof cancelSubscriptionBillingSubscriptionsIdCancelPatch>>,
  TError,
  {id: string; params: CancelSubscriptionBillingSubscriptionsIdCancelPatchParams},
  TContext
> => {
  const {mutation: mutationOptions} = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof cancelSubscriptionBillingSubscriptionsIdCancelPatch>>,
    {id: string; params: CancelSubscriptionBillingSubscriptionsIdCancelPatchParams}
  > = (props) => {
    const {id, params} = props ?? {};

    return cancelSubscriptionBillingSubscriptionsIdCancelPatch(id, params);
  };

  return {mutationFn, ...mutationOptions};
};

export type CancelSubscriptionBillingSubscriptionsIdCancelPatchMutationResult = NonNullable<
  Awaited<ReturnType<typeof cancelSubscriptionBillingSubscriptionsIdCancelPatch>>
>;

export type CancelSubscriptionBillingSubscriptionsIdCancelPatchMutationError = FormValidationError | GeneralError;

/**
 * @summary Cancel Subscription
 */
export const useCancelSubscriptionBillingSubscriptionsIdCancelPatch = <TError = FormValidationError | GeneralError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cancelSubscriptionBillingSubscriptionsIdCancelPatch>>,
    TError,
    {id: string; params: CancelSubscriptionBillingSubscriptionsIdCancelPatchParams},
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof cancelSubscriptionBillingSubscriptionsIdCancelPatch>>,
  TError,
  {id: string; params: CancelSubscriptionBillingSubscriptionsIdCancelPatchParams},
  TContext
> => {
  const mutationOptions = getCancelSubscriptionBillingSubscriptionsIdCancelPatchMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Returns the list of support addons
 * @summary List Subscription Support
 */
export const listSubscriptionSupportBillingSubscriptionsIdSupportGet = (id: string, signal?: AbortSignal) => {
  return customInstance<Addons>({url: `/billing/subscriptions/${id}/support/`, method: "GET", signal});
};

export const getListSubscriptionSupportBillingSubscriptionsIdSupportGetQueryKey = (id: string) => {
  return [`/billing/subscriptions/${id}/support/`] as const;
};

export const getListSubscriptionSupportBillingSubscriptionsIdSupportGetQueryOptions = <
  TData = Awaited<ReturnType<typeof listSubscriptionSupportBillingSubscriptionsIdSupportGet>>,
  TError = FormValidationError | GeneralError,
>(
  id: string,
  options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof listSubscriptionSupportBillingSubscriptionsIdSupportGet>>, TError, TData>}
) => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListSubscriptionSupportBillingSubscriptionsIdSupportGetQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listSubscriptionSupportBillingSubscriptionsIdSupportGet>>> = ({signal}) =>
    listSubscriptionSupportBillingSubscriptionsIdSupportGet(id, signal);

  return {queryKey, queryFn, enabled: !!id, ...queryOptions} as UseQueryOptions<
    Awaited<ReturnType<typeof listSubscriptionSupportBillingSubscriptionsIdSupportGet>>,
    TError,
    TData
  > & {queryKey: QueryKey};
};

export type ListSubscriptionSupportBillingSubscriptionsIdSupportGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof listSubscriptionSupportBillingSubscriptionsIdSupportGet>>
>;
export type ListSubscriptionSupportBillingSubscriptionsIdSupportGetQueryError = FormValidationError | GeneralError;

/**
 * @summary List Subscription Support
 */

export function useListSubscriptionSupportBillingSubscriptionsIdSupportGet<
  TData = Awaited<ReturnType<typeof listSubscriptionSupportBillingSubscriptionsIdSupportGet>>,
  TError = FormValidationError | GeneralError,
>(
  id: string,
  options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof listSubscriptionSupportBillingSubscriptionsIdSupportGet>>, TError, TData>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} {
  const queryOptions = getListSubscriptionSupportBillingSubscriptionsIdSupportGetQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Returns the Customer Credits
 * @summary Get List Customer Credits
 */
export const getListCustomerCreditsBillingCustomersCreditsGet = (signal?: AbortSignal) => {
  return customInstance<PromotionalCredits>({url: `/billing/customers/credits/`, method: "GET", signal});
};

export const getGetListCustomerCreditsBillingCustomersCreditsGetQueryKey = () => {
  return [`/billing/customers/credits/`] as const;
};

export const getGetListCustomerCreditsBillingCustomersCreditsGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getListCustomerCreditsBillingCustomersCreditsGet>>,
  TError = FormValidationError | GeneralError,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getListCustomerCreditsBillingCustomersCreditsGet>>, TError, TData>;
}) => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetListCustomerCreditsBillingCustomersCreditsGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getListCustomerCreditsBillingCustomersCreditsGet>>> = ({signal}) =>
    getListCustomerCreditsBillingCustomersCreditsGet(signal);

  return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
    Awaited<ReturnType<typeof getListCustomerCreditsBillingCustomersCreditsGet>>,
    TError,
    TData
  > & {queryKey: QueryKey};
};

export type GetListCustomerCreditsBillingCustomersCreditsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getListCustomerCreditsBillingCustomersCreditsGet>>
>;
export type GetListCustomerCreditsBillingCustomersCreditsGetQueryError = FormValidationError | GeneralError;

/**
 * @summary Get List Customer Credits
 */

export function useGetListCustomerCreditsBillingCustomersCreditsGet<
  TData = Awaited<ReturnType<typeof getListCustomerCreditsBillingCustomersCreditsGet>>,
  TError = FormValidationError | GeneralError,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getListCustomerCreditsBillingCustomersCreditsGet>>, TError, TData>;
}): UseQueryResult<TData, TError> & {queryKey: QueryKey} {
  const queryOptions = getGetListCustomerCreditsBillingCustomersCreditsGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Support Link
 */
export const getSupportLinkSupportLinkGet = (signal?: AbortSignal) => {
  return customInstance<SupportLink>({url: `/support/link/`, method: "GET", signal});
};

export const getGetSupportLinkSupportLinkGetQueryKey = () => {
  return [`/support/link/`] as const;
};

export const getGetSupportLinkSupportLinkGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getSupportLinkSupportLinkGet>>,
  TError = FormValidationError | GeneralError,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getSupportLinkSupportLinkGet>>, TError, TData>;
}) => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSupportLinkSupportLinkGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSupportLinkSupportLinkGet>>> = ({signal}) => getSupportLinkSupportLinkGet(signal);

  return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSupportLinkSupportLinkGet>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetSupportLinkSupportLinkGetQueryResult = NonNullable<Awaited<ReturnType<typeof getSupportLinkSupportLinkGet>>>;
export type GetSupportLinkSupportLinkGetQueryError = FormValidationError | GeneralError;

/**
 * @summary Get Support Link
 */

export function useGetSupportLinkSupportLinkGet<
  TData = Awaited<ReturnType<typeof getSupportLinkSupportLinkGet>>,
  TError = FormValidationError | GeneralError,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getSupportLinkSupportLinkGet>>, TError, TData>;
}): UseQueryResult<TData, TError> & {queryKey: QueryKey} {
  const queryOptions = getGetSupportLinkSupportLinkGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Send a well formatted email to support alias
 * @summary Post Contact Us
 */
export const postContactUsSupportContactUsPost = (contactUs: ContactUs) => {
  return customInstance<ContactResponse>({
    url: `/support/contact-us/`,
    method: "POST",
    headers: {"Content-Type": "application/json"},
    data: contactUs,
  });
};

export const getPostContactUsSupportContactUsPostMutationOptions = <TError = FormValidationError | GeneralError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postContactUsSupportContactUsPost>>, TError, {data: ContactUs}, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof postContactUsSupportContactUsPost>>, TError, {data: ContactUs}, TContext> => {
  const {mutation: mutationOptions} = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postContactUsSupportContactUsPost>>, {data: ContactUs}> = (props) => {
    const {data} = props ?? {};

    return postContactUsSupportContactUsPost(data);
  };

  return {mutationFn, ...mutationOptions};
};

export type PostContactUsSupportContactUsPostMutationResult = NonNullable<Awaited<ReturnType<typeof postContactUsSupportContactUsPost>>>;
export type PostContactUsSupportContactUsPostMutationBody = ContactUs;
export type PostContactUsSupportContactUsPostMutationError = FormValidationError | GeneralError;

/**
 * @summary Post Contact Us
 */
export const usePostContactUsSupportContactUsPost = <TError = FormValidationError | GeneralError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postContactUsSupportContactUsPost>>, TError, {data: ContactUs}, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof postContactUsSupportContactUsPost>>, TError, {data: ContactUs}, TContext> => {
  const mutationOptions = getPostContactUsSupportContactUsPostMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Send a well formatted email to sales alias
 * @summary Post Contact Sales
 */
export const postContactSalesSupportContactSalesPost = (contactSales: ContactSales) => {
  return customInstance<ContactResponse>({
    url: `/support/contact-sales/`,
    method: "POST",
    headers: {"Content-Type": "application/json"},
    data: contactSales,
  });
};

export const getPostContactSalesSupportContactSalesPostMutationOptions = <TError = FormValidationError | GeneralError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postContactSalesSupportContactSalesPost>>, TError, {data: ContactSales}, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof postContactSalesSupportContactSalesPost>>, TError, {data: ContactSales}, TContext> => {
  const {mutation: mutationOptions} = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postContactSalesSupportContactSalesPost>>, {data: ContactSales}> = (props) => {
    const {data} = props ?? {};

    return postContactSalesSupportContactSalesPost(data);
  };

  return {mutationFn, ...mutationOptions};
};

export type PostContactSalesSupportContactSalesPostMutationResult = NonNullable<Awaited<ReturnType<typeof postContactSalesSupportContactSalesPost>>>;
export type PostContactSalesSupportContactSalesPostMutationBody = ContactSales;
export type PostContactSalesSupportContactSalesPostMutationError = FormValidationError | GeneralError;

/**
 * @summary Post Contact Sales
 */
export const usePostContactSalesSupportContactSalesPost = <TError = FormValidationError | GeneralError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postContactSalesSupportContactSalesPost>>, TError, {data: ContactSales}, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof postContactSalesSupportContactSalesPost>>, TError, {data: ContactSales}, TContext> => {
  const mutationOptions = getPostContactSalesSupportContactSalesPostMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary List Subscriptions
 */
export const listSubscriptionsLegacySubscriptionsGet = (params?: ListSubscriptionsLegacySubscriptionsGetParams, signal?: AbortSignal) => {
  return customInstance<LegacySubscriptions>({url: `/legacy/subscriptions/`, method: "GET", params, signal});
};

export const getListSubscriptionsLegacySubscriptionsGetQueryKey = (params?: ListSubscriptionsLegacySubscriptionsGetParams) => {
  return [`/legacy/subscriptions/`, ...(params ? [params] : [])] as const;
};

export const getListSubscriptionsLegacySubscriptionsGetQueryOptions = <
  TData = Awaited<ReturnType<typeof listSubscriptionsLegacySubscriptionsGet>>,
  TError = FormValidationError | GeneralError,
>(
  params?: ListSubscriptionsLegacySubscriptionsGetParams,
  options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof listSubscriptionsLegacySubscriptionsGet>>, TError, TData>}
) => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListSubscriptionsLegacySubscriptionsGetQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listSubscriptionsLegacySubscriptionsGet>>> = ({signal}) =>
    listSubscriptionsLegacySubscriptionsGet(params, signal);

  return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
    Awaited<ReturnType<typeof listSubscriptionsLegacySubscriptionsGet>>,
    TError,
    TData
  > & {queryKey: QueryKey};
};

export type ListSubscriptionsLegacySubscriptionsGetQueryResult = NonNullable<Awaited<ReturnType<typeof listSubscriptionsLegacySubscriptionsGet>>>;
export type ListSubscriptionsLegacySubscriptionsGetQueryError = FormValidationError | GeneralError;

/**
 * @summary List Subscriptions
 */

export function useListSubscriptionsLegacySubscriptionsGet<
  TData = Awaited<ReturnType<typeof listSubscriptionsLegacySubscriptionsGet>>,
  TError = FormValidationError | GeneralError,
>(
  params?: ListSubscriptionsLegacySubscriptionsGetParams,
  options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof listSubscriptionsLegacySubscriptionsGet>>, TError, TData>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} {
  const queryOptions = getListSubscriptionsLegacySubscriptionsGetQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Subscription
 */
export const getSubscriptionLegacySubscriptionsIdGet = (id: string, signal?: AbortSignal) => {
  return customInstance<LegacySubscription>({url: `/legacy/subscriptions/${id}`, method: "GET", signal});
};

export const getGetSubscriptionLegacySubscriptionsIdGetQueryKey = (id: string) => {
  return [`/legacy/subscriptions/${id}`] as const;
};

export const getGetSubscriptionLegacySubscriptionsIdGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getSubscriptionLegacySubscriptionsIdGet>>,
  TError = FormValidationError | GeneralError,
>(
  id: string,
  options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof getSubscriptionLegacySubscriptionsIdGet>>, TError, TData>}
) => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSubscriptionLegacySubscriptionsIdGetQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSubscriptionLegacySubscriptionsIdGet>>> = ({signal}) =>
    getSubscriptionLegacySubscriptionsIdGet(id, signal);

  return {queryKey, queryFn, enabled: !!id, ...queryOptions} as UseQueryOptions<
    Awaited<ReturnType<typeof getSubscriptionLegacySubscriptionsIdGet>>,
    TError,
    TData
  > & {queryKey: QueryKey};
};

export type GetSubscriptionLegacySubscriptionsIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof getSubscriptionLegacySubscriptionsIdGet>>>;
export type GetSubscriptionLegacySubscriptionsIdGetQueryError = FormValidationError | GeneralError;

/**
 * @summary Get Subscription
 */

export function useGetSubscriptionLegacySubscriptionsIdGet<
  TData = Awaited<ReturnType<typeof getSubscriptionLegacySubscriptionsIdGet>>,
  TError = FormValidationError | GeneralError,
>(
  id: string,
  options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof getSubscriptionLegacySubscriptionsIdGet>>, TError, TData>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} {
  const queryOptions = getGetSubscriptionLegacySubscriptionsIdGetQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Cancel Subscription
 */
export const cancelSubscriptionLegacySubscriptionsIdCancelPatch = (id: string) => {
  return customInstance<LegacySubscription>({url: `/legacy/subscriptions/${id}/cancel/`, method: "PATCH"});
};

export const getCancelSubscriptionLegacySubscriptionsIdCancelPatchMutationOptions = <
  TError = FormValidationError | GeneralError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof cancelSubscriptionLegacySubscriptionsIdCancelPatch>>, TError, {id: string}, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof cancelSubscriptionLegacySubscriptionsIdCancelPatch>>, TError, {id: string}, TContext> => {
  const {mutation: mutationOptions} = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof cancelSubscriptionLegacySubscriptionsIdCancelPatch>>, {id: string}> = (props) => {
    const {id} = props ?? {};

    return cancelSubscriptionLegacySubscriptionsIdCancelPatch(id);
  };

  return {mutationFn, ...mutationOptions};
};

export type CancelSubscriptionLegacySubscriptionsIdCancelPatchMutationResult = NonNullable<
  Awaited<ReturnType<typeof cancelSubscriptionLegacySubscriptionsIdCancelPatch>>
>;

export type CancelSubscriptionLegacySubscriptionsIdCancelPatchMutationError = FormValidationError | GeneralError;

/**
 * @summary Cancel Subscription
 */
export const useCancelSubscriptionLegacySubscriptionsIdCancelPatch = <TError = FormValidationError | GeneralError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof cancelSubscriptionLegacySubscriptionsIdCancelPatch>>, TError, {id: string}, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof cancelSubscriptionLegacySubscriptionsIdCancelPatch>>, TError, {id: string}, TContext> => {
  const mutationOptions = getCancelSubscriptionLegacySubscriptionsIdCancelPatchMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get Booking
 */
export const getBookingBookingsBookingsIdGet = (id: number, signal?: AbortSignal) => {
  return customInstance<unknown>({url: `/bookings/bookings/${id}/`, method: "GET", signal});
};

export const getGetBookingBookingsBookingsIdGetQueryKey = (id: number) => {
  return [`/bookings/bookings/${id}/`] as const;
};

export const getGetBookingBookingsBookingsIdGetQueryOptions = <
  TData = Awaited<ReturnType<typeof getBookingBookingsBookingsIdGet>>,
  TError = FormValidationError | GeneralError,
>(
  id: number,
  options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof getBookingBookingsBookingsIdGet>>, TError, TData>}
) => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBookingBookingsBookingsIdGetQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBookingBookingsBookingsIdGet>>> = ({signal}) =>
    getBookingBookingsBookingsIdGet(id, signal);

  return {queryKey, queryFn, enabled: !!id, ...queryOptions} as UseQueryOptions<
    Awaited<ReturnType<typeof getBookingBookingsBookingsIdGet>>,
    TError,
    TData
  > & {queryKey: QueryKey};
};

export type GetBookingBookingsBookingsIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof getBookingBookingsBookingsIdGet>>>;
export type GetBookingBookingsBookingsIdGetQueryError = FormValidationError | GeneralError;

/**
 * @summary Get Booking
 */

export function useGetBookingBookingsBookingsIdGet<
  TData = Awaited<ReturnType<typeof getBookingBookingsBookingsIdGet>>,
  TError = FormValidationError | GeneralError,
>(
  id: number,
  options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof getBookingBookingsBookingsIdGet>>, TError, TData>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} {
  const queryOptions = getGetBookingBookingsBookingsIdGetQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary List Bookings
 */
export const listBookingsBookingsBookingsGet = (params?: ListBookingsBookingsBookingsGetParams, signal?: AbortSignal) => {
  return customInstance<unknown>({url: `/bookings/bookings/`, method: "GET", params, signal});
};

export const getListBookingsBookingsBookingsGetQueryKey = (params?: ListBookingsBookingsBookingsGetParams) => {
  return [`/bookings/bookings/`, ...(params ? [params] : [])] as const;
};

export const getListBookingsBookingsBookingsGetQueryOptions = <
  TData = Awaited<ReturnType<typeof listBookingsBookingsBookingsGet>>,
  TError = FormValidationError | GeneralError,
>(
  params?: ListBookingsBookingsBookingsGetParams,
  options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof listBookingsBookingsBookingsGet>>, TError, TData>}
) => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListBookingsBookingsBookingsGetQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listBookingsBookingsBookingsGet>>> = ({signal}) =>
    listBookingsBookingsBookingsGet(params, signal);

  return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listBookingsBookingsBookingsGet>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type ListBookingsBookingsBookingsGetQueryResult = NonNullable<Awaited<ReturnType<typeof listBookingsBookingsBookingsGet>>>;
export type ListBookingsBookingsBookingsGetQueryError = FormValidationError | GeneralError;

/**
 * @summary List Bookings
 */

export function useListBookingsBookingsBookingsGet<
  TData = Awaited<ReturnType<typeof listBookingsBookingsBookingsGet>>,
  TError = FormValidationError | GeneralError,
>(
  params?: ListBookingsBookingsBookingsGetParams,
  options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof listBookingsBookingsBookingsGet>>, TError, TData>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} {
  const queryOptions = getListBookingsBookingsBookingsGetQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary List Booking Product Groups
 */
export const listBookingProductGroupsBookingsProductsGroupsGet = (
  params?: ListBookingProductGroupsBookingsProductsGroupsGetParams,
  signal?: AbortSignal
) => {
  return customInstance<unknown>({url: `/bookings/products-groups/`, method: "GET", params, signal});
};

export const getListBookingProductGroupsBookingsProductsGroupsGetQueryKey = (params?: ListBookingProductGroupsBookingsProductsGroupsGetParams) => {
  return [`/bookings/products-groups/`, ...(params ? [params] : [])] as const;
};

export const getListBookingProductGroupsBookingsProductsGroupsGetQueryOptions = <
  TData = Awaited<ReturnType<typeof listBookingProductGroupsBookingsProductsGroupsGet>>,
  TError = FormValidationError | GeneralError,
>(
  params?: ListBookingProductGroupsBookingsProductsGroupsGetParams,
  options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof listBookingProductGroupsBookingsProductsGroupsGet>>, TError, TData>}
) => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListBookingProductGroupsBookingsProductsGroupsGetQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listBookingProductGroupsBookingsProductsGroupsGet>>> = ({signal}) =>
    listBookingProductGroupsBookingsProductsGroupsGet(params, signal);

  return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
    Awaited<ReturnType<typeof listBookingProductGroupsBookingsProductsGroupsGet>>,
    TError,
    TData
  > & {queryKey: QueryKey};
};

export type ListBookingProductGroupsBookingsProductsGroupsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof listBookingProductGroupsBookingsProductsGroupsGet>>
>;
export type ListBookingProductGroupsBookingsProductsGroupsGetQueryError = FormValidationError | GeneralError;

/**
 * @summary List Booking Product Groups
 */

export function useListBookingProductGroupsBookingsProductsGroupsGet<
  TData = Awaited<ReturnType<typeof listBookingProductGroupsBookingsProductsGroupsGet>>,
  TError = FormValidationError | GeneralError,
>(
  params?: ListBookingProductGroupsBookingsProductsGroupsGetParams,
  options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof listBookingProductGroupsBookingsProductsGroupsGet>>, TError, TData>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} {
  const queryOptions = getListBookingProductGroupsBookingsProductsGroupsGetQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary List Booking Products
 */
export const listBookingProductsBookingsProductsGet = (params: ListBookingProductsBookingsProductsGetParams, signal?: AbortSignal) => {
  return customInstance<unknown>({url: `/bookings/products/`, method: "GET", params, signal});
};

export const getListBookingProductsBookingsProductsGetQueryKey = (params: ListBookingProductsBookingsProductsGetParams) => {
  return [`/bookings/products/`, ...(params ? [params] : [])] as const;
};

export const getListBookingProductsBookingsProductsGetQueryOptions = <
  TData = Awaited<ReturnType<typeof listBookingProductsBookingsProductsGet>>,
  TError = FormValidationError | GeneralError,
>(
  params: ListBookingProductsBookingsProductsGetParams,
  options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof listBookingProductsBookingsProductsGet>>, TError, TData>}
) => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListBookingProductsBookingsProductsGetQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listBookingProductsBookingsProductsGet>>> = ({signal}) =>
    listBookingProductsBookingsProductsGet(params, signal);

  return {queryKey, queryFn, ...queryOptions} as UseQueryOptions<
    Awaited<ReturnType<typeof listBookingProductsBookingsProductsGet>>,
    TError,
    TData
  > & {queryKey: QueryKey};
};

export type ListBookingProductsBookingsProductsGetQueryResult = NonNullable<Awaited<ReturnType<typeof listBookingProductsBookingsProductsGet>>>;
export type ListBookingProductsBookingsProductsGetQueryError = FormValidationError | GeneralError;

/**
 * @summary List Booking Products
 */

export function useListBookingProductsBookingsProductsGet<
  TData = Awaited<ReturnType<typeof listBookingProductsBookingsProductsGet>>,
  TError = FormValidationError | GeneralError,
>(
  params: ListBookingProductsBookingsProductsGetParams,
  options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof listBookingProductsBookingsProductsGet>>, TError, TData>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} {
  const queryOptions = getListBookingProductsBookingsProductsGetQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
}
