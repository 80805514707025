import {Fragment} from "react";
import {Box, Heading, Table, Tbody, Td, Tr, useColorMode} from "@chakra-ui/react";
import {useNavigate} from "@tanstack/react-location";

const SubscriptionCard = (props) => {
  const {data = {}, fromTable = true} = props;
  const {colorMode} = useColorMode();
  const navigate = useNavigate();

  const handleCardClick = () => {
    if (fromTable) {
      navigate({to: `/app/subscriptions/${data.id}`});
    }
  };

  return (
    <Box
      borderRadius="3px"
      borderWidth="1px"
      borderColor={colorMode === "light" ? "gray.300" : "gray.600"}
      boxShadow={"0px 3px 6px #00000029"}
      padding="20px"
      onClick={handleCardClick}
    >
      <Heading fontSize={"24px"} fontWeight={700} marginBottom="10px">
        {fromTable ? data.display : "Overview"}
      </Heading>
      <Table>
        <Tbody>
          <Tr>
            <Td>
              <Heading fontSize={"20px"} fontWeight={700}>
                Status
              </Heading>
            </Td>
            <Td>{data.status}</Td>
          </Tr>
          <Tr>
            <Td>
              <Heading fontSize={"20px"} fontWeight={700}>
                Price
              </Heading>
            </Td>
            <Td>{`${data.price} ${data.currency_code}`}</Td>
          </Tr>
          <Tr>
            <Td>
              <Heading fontSize={"20px"} fontWeight={700}>
                Billing period
              </Heading>
            </Td>
            <Td>{`Every ${data.billing_period} ${data.billing_period_unit}`}</Td>
          </Tr>
          <Tr>
            <Td>
              <Heading fontSize={"20px"} fontWeight={700}>
                Start on
              </Heading>
            </Td>
            <Td>{data.start_date}</Td>
          </Tr>
          {data.next_billing_at && (
            <Tr>
              <Td>
                <Heading fontSize={"20px"} fontWeight={700}>
                  Next billing on
                </Heading>
              </Td>
              <Td>{data.next_billing_at}</Td>
            </Tr>
          )}
          {!fromTable && (
            <Fragment>
              <Tr>
                <Td>
                  <Heading fontSize={"20px"} fontWeight={700}>
                    Created on
                  </Heading>
                </Td>
                <Td>{data.created_at}</Td>
              </Tr>
              <Tr>
                <Td>
                  <Heading fontSize={"20px"} fontWeight={700}>
                    Updated on
                  </Heading>
                </Td>
                <Td>{data.updated_at}</Td>
              </Tr>
              {data.due_invoices_count > 0 && (
                <Tr>
                  <Td>
                    <Heading fontSize={"20px"} fontWeight={700}>
                      Due invoices count
                    </Heading>
                  </Td>
                  <Td>{data.due_invoices_count}</Td>
                </Tr>
              )}
              {data.due_since && (
                <Tr>
                  <Td>
                    <Heading fontSize={"20px"} fontWeight={700}>
                      Due since
                    </Heading>
                  </Td>
                  <Td>{data.due_since}</Td>
                </Tr>
              )}
              {data.remaining_billing_cycles > 0 && (
                <Tr>
                  <Td>
                    <Heading fontSize={"20px"} fontWeight={700}>
                      Remaining billing cycles
                    </Heading>
                  </Td>
                  <Td>{data.remaining_billing_cycles}</Td>
                </Tr>
              )}
              {data.total_dues && (
                <Tr>
                  <Td>
                    <Heading fontSize={"20px"} fontWeight={700}>
                      Total dues
                    </Heading>
                  </Td>
                  <Td>{data.total_dues}</Td>
                </Tr>
              )}
            </Fragment>
          )}
        </Tbody>
      </Table>
    </Box>
  );
};

export default SubscriptionCard;
