import {createDataColumn} from "@onemind-services-llc/ui-components";
import {Text} from "@chakra-ui/react";

const RenderAmount = ({data}) => {
  const rowData = data?.row?.original || {};
  const {amount, currency_code, type} = rowData;

  if (type === "decrement") {
    return <Text color={"red.500"}>{`- ${amount} ${currency_code}`}</Text>;
  } else {
    return <Text>{`${amount} ${currency_code}`}</Text>;
  }
};

export const defaultColumns = [
  createDataColumn("id"),
  createDataColumn("amount", {cell: (data) => <RenderAmount data={data} />}),
  createDataColumn("closing_balance", {
    accessorFn: (row) => `${row.closing_balance} ${row.currency_code}`,
  }),
  createDataColumn("credit_type"),
  createDataColumn("description"),
];

export const columnsList = [
  {display: "ID", value: "id", visible: true},
  {display: "Amount", value: "amount", visible: true},
  {display: "Closing Balance", value: "closing_balance", visible: true},
  {display: "Credit Type", value: "credit_type", visible: false},
  {display: "Description", value: "description", visible: true},
];
