import {useMatch} from "@tanstack/react-location";
import {isEmpty} from "lodash";
import {Box, SimpleGrid} from "@chakra-ui/react";
import {Loader} from "@onemind-services-llc/ui-components";
import SubscriptionCard from "../subscriptionCard";
import SubscriptionItems from "./subscriptionItems";
import PaymentMethod from "./subscriptionPaymentMethod";
import {useGetSubscriptionBillingSubscriptionsIdGet} from "../../../../orval-cml/default/default";

const SubscriptionOverView = () => {
  const {
    params: {subscriptionId},
  } = useMatch();

  const {data: {data: subscriptionDetails = {}} = {}, isLoading} = useGetSubscriptionBillingSubscriptionsIdGet(subscriptionId, {
    query: {
      queryKey: ["subscriptionDetails", subscriptionId],
    },
  });

  return (
    <Box margin="20px 0px">
      {isLoading ? <Loader /> : null}
      <SimpleGrid gap={"20px"} columns={{base: 1, lg: 2}}>
        <SubscriptionCard data={subscriptionDetails} fromTable={false} />
        <Box>
          {!isEmpty(subscriptionDetails.payment_method) && (
            <Box mb="15px">
              <PaymentMethod paymentMethod={subscriptionDetails.payment_method} />
            </Box>
          )}
          <SubscriptionItems items={subscriptionDetails.items} currencyCode={subscriptionDetails.currency_code} />
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default SubscriptionOverView;
