import React from "react";
import {useNavigate} from "@tanstack/react-location";
import {Box, Heading, useColorMode, Table, Tbody, Tr, Td, SimpleGrid, Text, Flex, useMediaQuery} from "@chakra-ui/react";

const CloudInstanceOverview = (props) => {
  const {data: cloudInstanceDetails = {}, fromTable = true} = props;
  const {colorMode} = useColorMode();
  const navigate = useNavigate();
  const [isMobile] = useMediaQuery("(max-width: 660px)");

  const handleCardClick = () => {
    if (fromTable) {
      navigate({to: `/app/cloud-instances/${cloudInstanceDetails.id}`});
    }
  };

  return (
    <Box
      borderRadius="3px"
      borderWidth="1px"
      borderColor={colorMode === "light" ? "gray.300" : "gray.600"}
      boxShadow={"0px 3px 6px #00000029"}
      padding="20px"
      onClick={handleCardClick}
    >
      <Heading fontSize={"24px"} fontWeight={700} marginBottom="10px">
        {fromTable ? cloudInstanceDetails.display : "Overview"}
      </Heading>
      <Table>
        <Tbody>
          <Tr>
            <Td>
              <Heading fontSize={"20px"} fontWeight={700}>
                Status
              </Heading>
            </Td>
            <Td>{cloudInstanceDetails.status?.label}</Td>
          </Tr>
          <Tr>
            <Td>
              <Heading fontSize={"20px"} fontWeight={700}>
                OS
              </Heading>
            </Td>
            <Td>{cloudInstanceDetails.platform?.display}</Td>
          </Tr>
          {/**
              <Tr>
            <Td>
              <Heading fontSize={"20px"} fontWeight={700}>
              DNS Name
              </Heading>
            </Td>
            <Td>localhost</Td>
          </Tr>
             */}
          <Tr>
            <Td>
              <Heading fontSize={"20px"} fontWeight={700}>
                {isMobile ? "IP" : "IP Address"}
              </Heading>
            </Td>
            <Td>{cloudInstanceDetails.primary_ip?.display}</Td>
          </Tr>
          <Tr>
            <Td>
              <Heading fontSize={"20px"} fontWeight={700}>
                Role
              </Heading>
            </Td>
            <Td>{cloudInstanceDetails.role?.display}</Td>
          </Tr>
        </Tbody>
      </Table>
      {fromTable && (
        <SimpleGrid columns={3} gap="10px" m="10px 0px">
          <Flex flexDirection="column" alignItems="center">
            <Heading fontSize={"18px"} fontWeight={700}>
              {cloudInstanceDetails.vcpus}
            </Heading>
            <Text>vCPU</Text>
          </Flex>
          <Flex flexDirection="column" alignItems="center" borderColor="gray.800" borderRightWidth="1px" borderLeftWidth="1px">
            <Heading fontSize={"18px"} fontWeight={700}>
              {`${(cloudInstanceDetails.memory || 0) / 1024}GB`}
            </Heading>
            <Text>RAM</Text>
          </Flex>
          <Flex flexDirection="column" alignItems="center">
            <Heading fontSize={"18px"} fontWeight={700}>{`${cloudInstanceDetails.disk || 0}GB`}</Heading>
            <Text>SSD</Text>
          </Flex>
        </SimpleGrid>
      )}
    </Box>
  );
};

export default CloudInstanceOverview;
