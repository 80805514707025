import React from "react";
import {useMatch} from "@tanstack/react-location";
import {SimpleGrid, Box, Stack} from "@chakra-ui/react";
import {Loader} from "@onemind-services-llc/ui-components";
import PrimaryDetails from "../../../../components/DeviceDetails/cloudInstanceOverview";
import Resources from "./Resources";
import Credentials from "./Credentials";
import Translations from "./Translations";
// import Services from "./Services";
import {useGetCloudInstanceApiCloudInstanceIdGet} from "../../../../orval-cml/default/default";

const CloudInstanceMonitorOverview = () => {
  const {
    params: {cloudInstanceId},
  } = useMatch();
  const {data: {data: cloudInstanceDetails = {}} = {}, isLoading} = useGetCloudInstanceApiCloudInstanceIdGet(cloudInstanceId, {
    query: {
      queryKey: ["cloudInstanceDetails", cloudInstanceId],
    },
  });
  return (
    <Box margin="20px 0px">
      {isLoading ? <Loader /> : null}
      <SimpleGrid gap={"20px"} columns={{base: 1, lg: 2}}>
        <Stack gap={"10px"}>
          <PrimaryDetails data={cloudInstanceDetails} fromTable={false} />
          <Resources cloudInstanceDetails={cloudInstanceDetails} />
        </Stack>
        <Stack gap={"10px"}>
          <Credentials />
          <Translations />
          {/*
          <Services />
          */}
        </Stack>
      </SimpleGrid>
    </Box>
  );
};

export default CloudInstanceMonitorOverview;
