import {useState} from "react";
import Payment from "payment";
import {Formik, Form} from "formik";
import Cards from "react-credit-cards-2";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";
import {Input, Select} from "@onemind-services-llc/ui-components";
import {useQueryClient} from "@tanstack/react-query";
import {useAddCardBillingCardsAddPost} from "../../../../orval-cml/default/default";
import {useHandleError} from "../../../../services/handleError";
import {formatExpirationDate, formatCreditCardNumber, formatCVV, formatZipCode} from "./cardUtils";
import {countryList} from "../../../../constants/countryList";
import {getStatesOfCountry} from "../../../../services/stateUtils";
import "react-credit-cards-2/dist/es/styles-compiled.css";

const AddPaymentMethod = (props) => {
  const {isOpen, onClose} = props;
  const {handleError} = useHandleError();
  const queryClient = useQueryClient();
  const [focused, setFocused] = useState();
  const [errors, setErrors] = useState({});

  const initialValues = {
    first_name: "",
    last_name: "",
    number: "",
    cvv: "",
    expiry: "",
    billing_addr1: "",
    billing_addr2: "",
    billing_city: "",
    billing_state: "",
    billing_country: "",
    billing_zip: "",
  };

  const {mutate: addPaymentMethod} = useAddCardBillingCardsAddPost({
    mutation: {
      onSuccess: () => {
        onClose();
        queryClient.invalidateQueries({queryKey: ["paymentMethodsList"]});
      },
      onError: (e) => handleError(e, setErrors),
    },
  });

  const handleSubmit = (data) => {
    const {expiry, number, ...rest} = data;
    const expiryObject = Payment.fns.cardExpiryVal(expiry);
    const payload = {
      ...rest,
      expiry_month: expiryObject.month,
      expiry_year: expiryObject.year,
      number: number.replaceAll(" ", ""),
    };
    addPaymentMethod({data: payload});
  };

  const buttonVariant = useColorModeValue("brand", "solid");
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Card</ModalHeader>
        <ModalCloseButton />

        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {(formik) => {
            return (
              <Form>
                <ModalBody>
                  <Cards
                    cvc={formik.values.cvv}
                    expiry={formik.values.expiry}
                    focused={focused}
                    name={formik.values.first_name + " " + formik.values.last_name}
                    number={formik.values.number}
                  />
                  <Stack mt="20px" direction={"column"}>
                    <Input
                      type="tel"
                      label="Number"
                      {...formik.getFieldProps("number")}
                      onChange={(e) =>
                        formik.handleChange({
                          target: {
                            name: e.target.name,
                            value: formatCreditCardNumber(e.target.value),
                          },
                        })
                      }
                      onFocus={() => setFocused("number")}
                      isInvalid={errors.number}
                      errorMessage={errors.number}
                    />
                    <Stack direction={{base: "column", sm: "row"}}>
                      <Input
                        type="tel"
                        label="Expiry (MM/YYYY)"
                        {...formik.getFieldProps("expiry")}
                        onChange={(e) =>
                          formik.handleChange({
                            target: {
                              name: e.target.name,
                              value: formatExpirationDate(e.target.value),
                            },
                          })
                        }
                        onFocus={() => setFocused("expiry")}
                        isInvalid={errors.expiry_month || errors.expiry_year}
                        errorMessage={errors.expiry_month || errors.expiry_year}
                      />
                      <Input
                        type="tel"
                        label="CVV"
                        {...formik.getFieldProps("cvv")}
                        onChange={(e) =>
                          formik.handleChange({
                            target: {
                              name: e.target.name,
                              value: formatCVV(e.target.value),
                            },
                          })
                        }
                        onFocus={() => setFocused("cvc")}
                        isInvalid={errors.cvv}
                        errorMessage={errors.cvv}
                      />
                    </Stack>
                    <Stack direction={{base: "column", sm: "row"}}>
                      <Input
                        label="First Name"
                        {...formik.getFieldProps("first_name")}
                        onFocus={() => setFocused("name")}
                        isInvalid={errors.first_name}
                        errorMessage={errors.first_name}
                      />
                      <Input
                        label="Last Name"
                        {...formik.getFieldProps("last_name")}
                        onFocus={() => setFocused("name")}
                        isInvalid={errors.last_name}
                        errorMessage={errors.last_name}
                      />
                    </Stack>
                    <Input
                      label="Billing Address"
                      {...formik.getFieldProps("billing_addr1")}
                      isInvalid={errors.billing_addr1}
                      errorMessage={errors.billing_addr1}
                    />
                    <Input
                      label="Billing Address2"
                      {...formik.getFieldProps("billing_addr2")}
                      isInvalid={errors.billing_addr2}
                      errorMessage={errors.billing_addr2}
                    />
                    <Stack direction={{base: "column", sm: "row"}}>
                      <Input
                        label="City"
                        {...formik.getFieldProps("billing_city")}
                        isInvalid={errors.billing_city}
                        errorMessage={errors.billing_city}
                      />
                      <Input
                        label="Zip Code"
                        {...formik.getFieldProps("billing_zip")}
                        onChange={(e) =>
                          formik.handleChange({
                            target: {
                              name: e.target.name,
                              value: formatZipCode(e.target.value),
                            },
                          })
                        }
                        isInvalid={errors.billing_zip}
                        errorMessage={errors.billing_zip}
                      />
                    </Stack>
                    <Stack direction={{base: "column", sm: "row"}}>
                      <Select
                        label="Country"
                        placeholder="Select country"
                        options={countryList}
                        labelKey={"name"}
                        valueKey={"code"}
                        {...formik.getFieldProps("billing_country")}
                        isInvalid={errors.billing_country}
                        errorMessage={errors.billing_country}
                      />
                      <Select
                        label="State"
                        placeholder="Select state"
                        options={getStatesOfCountry(formik.values.billing_country)}
                        {...formik.getFieldProps("billing_state")}
                        isInvalid={errors.billing_state}
                        errorMessage={errors.billing_state}
                        isDisabled={!formik.values.billing_country}
                        helpText={"Please select country before selecting state"}
                      />
                    </Stack>
                  </Stack>
                </ModalBody>
                <ModalFooter>
                  <Button variant={buttonVariant} type="submit" width="100px">
                    Submit
                  </Button>
                </ModalFooter>
              </Form>
            );
          }}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default AddPaymentMethod;
