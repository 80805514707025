import {createDataColumn} from "@onemind-services-llc/ui-components";
import {Link as RLLink} from "@tanstack/react-location";
import {Link} from "@chakra-ui/react";

export const defaultColumns = [
  createDataColumn("id", {
    cell: (data) => (
      <Link to={`/app/legacy/subscriptions/${data.getValue()}`} as={RLLink} color="link.100" preload={1}>
        {data.getValue()}
      </Link>
    ),
  }),
  createDataColumn("status"),
  createDataColumn("currency"),
  createDataColumn("date_created_gmt", {header: () => "Created On"}),
  createDataColumn("date_modified_gmt", {header: () => "Updated On"}),
  createDataColumn("date_completed_gmt", {header: () => "Completed On"}),
  createDataColumn("date_paid_gmt", {header: () => "Paid On"}),
  createDataColumn("discount_total"),
  createDataColumn("total", {
    accessorFn: (row) => row.total + " " + row.currency,
    isPlaceholder: true,
  }),
  createDataColumn("payment_method"),
  createDataColumn("billing_period", {
    accessorFn: (row) => `Every ${row.billing_interval} ${row.billing_period}`,
    isPlaceholder: true,
  }),
  createDataColumn("start_date_gmt", {header: () => "Started On"}),
  createDataColumn("next_payment_date_gmt", {header: () => "Next Billing On"}),
  createDataColumn("last_payment_date_gmt", {header: () => "Last Payment On"}),
  createDataColumn("end_date_gmt", {header: () => "Ends On"}),
];

export const columnsList = [
  {display: "ID", value: "id", visible: true},
  {display: "Status", value: "status", visible: true},
  {display: "Billing period", value: "billing_period", visible: true},
  {display: "Currency", value: "currency", visible: false},
  {display: "Completed on", value: "date_completed_gmt", visible: false},
  {display: "Paid on", value: "date_paid_gmt", visible: false},
  {display: "Discount total", value: "discount_total", visible: false},
  {display: "Total", value: "total", visible: true},
  {display: "Payment method", value: "payment_method", visible: false},
  {display: "Start date", value: "start_date_gmt", visible: true},
  {display: "Next payment date", value: "next_payment_date_gmt", visible: true},
  {display: "Last payment date", value: "last_payment_date_gmt", visible: false},
  {display: "End date", value: "end_date_gmt", visible: false},
  {display: "Created on", value: "date_created_gmt", visible: true},
  {display: "Updated on", value: "date_modified_gmt", visible: true},
];
