import React, {useEffect} from "react";
import {useLocation, useNavigate, useSearch} from "@tanstack/react-location";
import {Loader} from "@onemind-services-llc/ui-components";
import {useGetCookies} from "../hooks/useGetCookies";

const ProtectedRoute = ({component: Component}) => {
  const location = useLocation();
  const {cookies} = useGetCookies();
  const {accessToken} = cookies;
  const navigate = useNavigate();
  const search = useSearch();
  const {next} = search;

  useEffect(() => {
    if (!accessToken) {
      const nextPath = next || location.current.href;
      if (location.current.href.startsWith("/app")) {
        navigate({to: "/auth", search: {next: nextPath}});
      }
    }
  }, [accessToken, location, navigate, next]);

  if (accessToken) {
    return <Component />;
  } else {
    return <Loader />;
  }
};

export default ProtectedRoute;
