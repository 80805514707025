import {useCookies} from "react-cookie";
import {COOKIES_CONSTANTS} from "../constants/cookies";

export const useClearCookies = () => {
  const [, , removeCookie] = useCookies([COOKIES_CONSTANTS.ACCESS_TOKEN, COOKIES_CONSTANTS.AUTH0_USER]);
  const clearCookies = () => {
    const options = {
      path: "/",
      sameSite: true,
    };
    removeCookie(COOKIES_CONSTANTS.ACCESS_TOKEN_KEY, options);
    removeCookie(COOKIES_CONSTANTS.AUTH0_USER_KEY, options);
  };
  return {clearCookies};
};
