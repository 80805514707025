import React from "react";
import {useNavigate} from "@tanstack/react-location";
import {Box, Heading, useColorMode, Table, Tbody, Tr, Td, SimpleGrid, Text, Flex} from "@chakra-ui/react";

const BookingDetailsCard = (props) => {
  const {data: bookingDetails = {}, fromTable = true} = props;
  const {colorMode} = useColorMode();
  const navigate = useNavigate();

  const handleCardClick = () => {
    if (fromTable) {
      navigate({to: `/app/learning-labs/${bookingDetails.id}`});
    }
  };

  return (
    <Box
      borderRadius="3px"
      borderWidth="1px"
      borderColor={colorMode === "light" ? "gray.300" : "gray.600"}
      boxShadow={"0px 3px 6px #00000029"}
      padding="20px"
      onClick={handleCardClick}
    >
      <Heading fontSize={"24px"} fontWeight={700} marginBottom="10px">
        {fromTable ? bookingDetails.display : "Overview"}
      </Heading>
      <Table>
        <Tbody>
          <Tr>
            <Td>
              <Heading fontSize={"20px"} fontWeight={700}>
                ID
              </Heading>
            </Td>
            <Td>{bookingDetails.booking_id}</Td>
          </Tr>
          <Tr>
            <Td>
              <Heading fontSize={"20px"} fontWeight={700}>
                Status
              </Heading>
            </Td>
            <Td>{bookingDetails.status?.label}</Td>
          </Tr>
          <Tr>
            <Td>
              <Heading fontSize={"20px"} fontWeight={700}>
                Start Date
              </Heading>
            </Td>
            <Td>{bookingDetails.start_datetime?.split("T")[0]}</Td>
          </Tr>
          <Tr>
            <Td>
              <Heading fontSize={"20px"} fontWeight={700}>
                End Date
              </Heading>
            </Td>
            <Td>{bookingDetails.end_datetime?.split("T")[0]}</Td>
          </Tr>
        </Tbody>
      </Table>
      {fromTable && (
        <SimpleGrid columns={bookingDetails.booked_products?.length} gap="10px" m="10px 0px">
          <Flex flexDirection="column" alignItems="center">
            {bookingDetails.booked_products?.map((product = {}, index) => (
              <div key={index}>
                <Heading fontWeight={700} fontSize="18px">
                  Product
                </Heading>
                <Text>{product.display}</Text>
              </div>
            ))}
          </Flex>
        </SimpleGrid>
      )}
      {fromTable && (
        <SimpleGrid columns={bookingDetails.devices?.length} gap="10px" m="10px 0px">
          <Flex flexDirection="column" alignItems="center">
            {bookingDetails.devices?.map((device = {}, index) => (
              <div key={index}>
                <Heading fontWeight={700} fontSize="18px">
                  Baremetal
                </Heading>
                <Text onMouseDown={() => window.open(`/app/baremetal/${device.id}`, "_self")} color="link.100">
                  {device.display}
                </Text>
              </div>
            ))}
          </Flex>
        </SimpleGrid>
      )}
      {fromTable && (
        <SimpleGrid columns={bookingDetails.virtual_machines?.length} gap="10px" m="10px 0px">
          <Flex flexDirection="column" alignItems="center">
            {bookingDetails?.virtual_machines.map((cloudInstance = {}, index) => (
              <div key={index}>
                <Heading fontWeight={700} fontSize="18px">
                  Cloud Instances
                </Heading>
                <Text onMouseDown={() => window.open(`/app/cloud-instances/${cloudInstance.id}`, "_self")} color="link.100">
                  {cloudInstance.display}
                </Text>
              </div>
            ))}
          </Flex>
        </SimpleGrid>
      )}
    </Box>
  );
};

export default BookingDetailsCard;
