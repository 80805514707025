import {useMatch} from "@tanstack/react-location";
import {useIsMutating, useQueryClient} from "@tanstack/react-query";
import {
  Heading,
  Flex,
  HStack,
  Button,
  useColorModeValue,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Modal,
  Stack,
  Radio,
  RadioGroup,
} from "@chakra-ui/react";
import {HeaderContainer, Loader, useErrorToast} from "@onemind-services-llc/ui-components";
import {
  useCancelSubscriptionBillingSubscriptionsIdCancelPatch,
  useGetSubscriptionBillingSubscriptionsIdGet,
} from "../../../orval-cml/default/default";
import {useHandleError} from "../../../services/handleError";
import React, {useState} from "react";

const SubscriptionHeader = () => {
  const isMutating = useIsMutating();
  const {handleError} = useHandleError();
  const toast = useErrorToast();
  const queryClient = useQueryClient();
  const [isCancel, setIsCancel] = useState(false);
  const [cancelReasonCode, setCancelReasonCode] = useState(null);

  const {
    params: {subscriptionId},
  } = useMatch();

  const {data: {data: subscriptionDetails = {}} = {}} = useGetSubscriptionBillingSubscriptionsIdGet(subscriptionId, {
    query: {
      queryKey: ["subscriptionDetails", subscriptionId],
    },
  });

  const {display, status} = subscriptionDetails;

  const handleSuccess = () => {
    setIsCancel(false);
    queryClient.invalidateQueries({queryKey: ["subscriptionDetails"]});
  };

  const {mutate: cancelSubscription} = useCancelSubscriptionBillingSubscriptionsIdCancelPatch({
    mutation: {onError: (e) => handleError(e), onSuccess: handleSuccess},
  });

  const buttonVariant = useColorModeValue("brand", "solid");

  const showCancelModal = () => {
    setIsCancel(true);
  };

  const hideCancelModal = () => {
    setIsCancel(false);
  };

  const handledCancelReasonCode = (e) => {
    setCancelReasonCode(e);
  };

  const handleCancelConfirm = () => {
    if (!cancelReasonCode) {
      toast({description: "Please select a reason to cancel the subscription", status: "error"});
      return;
    }
    cancelSubscription({id: subscriptionId, params: {cancel_reason_code: cancelReasonCode}});
  };

  return (
    <>
      <HeaderContainer>
        {isMutating ? <Loader /> : null}
        <Flex flexDirection={"row"} justifyContent="space-between">
          <Heading fontSize={"25px"}>{display}</Heading>
          <HStack>
            {status === "active" && (
              <Button variant={buttonVariant} width="100px" onClick={showCancelModal}>
                Cancel
              </Button>
            )}
          </HStack>
        </Flex>
      </HeaderContainer>
      <Modal isOpen={isCancel} onClose={hideCancelModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Cancel Subscription</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <RadioGroup value={cancelReasonCode} onChange={handledCancelReasonCode}>
              <Stack spacing={3}>
                <Radio value="Product Unsatisfactory">Product Unsatisfactory</Radio>
                <Radio value="Service Unsatisfactory">Service Unsatisfactory</Radio>
                <Radio value="Order Change">Order Change</Radio>
                <Radio value="Not Required">Not Required</Radio>
                <Radio value="Other">Other</Radio>
              </Stack>
            </RadioGroup>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="yellow" mr={3} onClick={hideCancelModal}>
              Cancel
            </Button>
            <Button variant="brand" onClick={handleCancelConfirm}>
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SubscriptionHeader;
