import {Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableContainer, Text} from "@chakra-ui/react";

const InvoiceItems = (props) => {
  const {invoiceDetails = {}} = props;
  const {items = [], total, amount_paid: amountPaid, amount_due: amountDue, currency_code: currencyCode} = invoiceDetails;
  return (
    <TableContainer>
      <Table>
        <Thead>
          <Tr>
            <Th>Items</Th>
            <Th>Units</Th>
            <Th>Unit Price</Th>
            <Th>Amount(USD)</Th>
          </Tr>
        </Thead>
        <Tbody>
          {items.map((item) => {
            return (
              <Tr key={item.id}>
                <Td>
                  <Text>{item.description}</Text>
                  <Text fontSize={"12px"}>{item.entity_description}</Text>
                </Td>
                <Td>{item.quantity}</Td>
                <Td>${item.unit_amount}</Td>
                <Th fontSize={"20px"}>${item.quantity * item.unit_amount}</Th>
              </Tr>
            );
          })}
        </Tbody>
        <Tfoot>
          <Tr>
            <Th border="none"></Th>
            <Th border="none"></Th>
            <Th>Total</Th>
            <Th fontSize={"20px"}>${total}</Th>
          </Tr>
          <Tr>
            <Td border="none"></Td>
            <Td border="none"></Td>
            <Td>Payments</Td>
            <Td fontSize={"20px"}>${amountPaid}</Td>
          </Tr>
          <Tr>
            <Th border="none"></Th>
            <Th border="none"></Th>
            <Th>Amount Due({currencyCode})</Th>
            <Th fontSize={"20px"}>${amountDue}</Th>
          </Tr>
        </Tfoot>
      </Table>
    </TableContainer>
  );
};

export default InvoiceItems;
