import React from "react";
import {Box, Heading, useColorMode, Table, Tbody, Tr, Td} from "@chakra-ui/react";

const SubscriptionPaymentMethod = (props) => {
  const {paymentMethod = {}} = props;
  const {colorMode} = useColorMode();

  return (
    <Box
      borderRadius="3px"
      borderWidth="1px"
      borderColor={colorMode === "light" ? "gray.300" : "gray.600"}
      boxShadow={"0px 3px 6px #00000029"}
      padding="20px"
      overflowX={"auto"}
      position="relative"
    >
      <Heading fontSize={"24px"} fontWeight={700} marginBottom="10px">
        Payment Details
      </Heading>
      <Table>
        <Tbody>
          <Tr>
            <Td>
              <Heading fontSize={"20px"} fontWeight={700}>
                Type
              </Heading>
            </Td>
            <Td>{paymentMethod.type}</Td>
          </Tr>
          <Tr>
            <Td>
              <Heading fontSize={"20px"} fontWeight={700}>
                Card Type
              </Heading>
            </Td>
            <Td>{paymentMethod.card_type}</Td>
          </Tr>
          <Tr>
            <Td>
              <Heading fontSize={"20px"} fontWeight={700}>
                Expiry
              </Heading>
            </Td>
            <Td>{`${paymentMethod.expiry_month} / ${paymentMethod.expiry_year}`}</Td>
          </Tr>
          <Tr>
            <Td>
              <Heading fontSize={"20px"} fontWeight={700}>
                Masked Number
              </Heading>
            </Td>
            <Td>{paymentMethod.masked_number}</Td>
          </Tr>
          <Tr>
            <Td>
              <Heading fontSize={"20px"} fontWeight={700}>
                Status
              </Heading>
            </Td>
            <Td>{paymentMethod.status}</Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );
};

export default SubscriptionPaymentMethod;
