import React from "react";
import {Box, Heading, useColorMode, Icon, Table, Tbody, Tr, Td} from "@chakra-ui/react";
import {FiHardDrive} from "@onemind-services-llc/react-icons-ng/fi";
import {FaMemory} from "@onemind-services-llc/react-icons-ng/fa";
import {BsCpu} from "@onemind-services-llc/react-icons-ng/bs";

const Resources = (props) => {
  const {cloudInstanceDetails = {}} = props;
  const {colorMode} = useColorMode();
  return (
    <Box
      borderRadius="3px"
      borderWidth="1px"
      borderColor={colorMode === "light" ? "gray.300" : "gray.600"}
      boxShadow={"0px 3px 6px #00000029"}
      padding="20px"
    >
      <Heading fontSize={"24px"} fontWeight={700} marginBottom="10px">
        Resources
      </Heading>
      <Table>
        <Tbody>
          <Tr>
            <Td display="flex">
              <Icon as={BsCpu} />
              <Heading pl="10px" fontWeight={700} fontSize={"20px"}>
                Virtual CPUs
              </Heading>
            </Td>
            <Td>{cloudInstanceDetails?.vcpus}</Td>
          </Tr>
          <Tr>
            <Td display="flex">
              <Icon as={FaMemory} />
              <Heading pl="10px" fontWeight={700} fontSize={"20px"}>
                RAM
              </Heading>
            </Td>
            <Td>{(cloudInstanceDetails?.memory || 0) / 1024}GB</Td>
          </Tr>
          <Tr>
            <Td display="flex">
              <Icon as={FiHardDrive} />
              <Heading pl="10px" fontWeight={700} fontSize={"20px"}>
                SSD
              </Heading>
            </Td>
            <Td>{cloudInstanceDetails?.disk || 0}GB</Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );
};

export default Resources;
