import {createDataColumn} from "@onemind-services-llc/ui-components";
import {Link as RLLink, useMatch} from "@tanstack/react-location";
import humanizeString from "string-humanize";
import {Link, Tag} from "@chakra-ui/react";
import {getInvoiceStatusColorScheme} from "./utils/getInvoiceStatusColorScheme";

const RenderLink = ({data}) => {
  const {
    params: {subscriptionId},
  } = useMatch();

  return (
    <Link to={`/app/subscriptions/${subscriptionId}/invoices/${data.getValue()}`} as={RLLink} color="link.100" preload={1}>
      {data.getValue()}
    </Link>
  );
};

const RenderStatus = ({data}) => {
  const status = data.getValue();
  return <Tag colorScheme={getInvoiceStatusColorScheme(status)}>{humanizeString(status)}</Tag>;
};

export const defaultColumns = [
  createDataColumn("id", {
    cell: (data) => <RenderLink data={data} />,
  }),
  createDataColumn("status", {
    cell: (data) => <RenderStatus data={data} />,
  }),
  createDataColumn("amount_paid", {
    accessorFn: (row) => `${row.amount_paid} ${row.currency_code}`,
  }),
  createDataColumn("amount_due", {
    accessorFn: (row) => `${row.amount_due} ${row.currency_code}`,
  }),
];

export const columnsList = [
  {display: "ID", value: "id", visible: true},
  {display: "Status", value: "status", visible: true},
  {display: "Amount Paid", value: "amount_paid", visible: true},
  {display: "Amount Due", value: "amount_due", visible: true},
];
