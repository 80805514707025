import {Box, useDisclosure, useToast} from "@chakra-ui/react";
import {useQueryClient} from "@tanstack/react-query";
import {createDataColumn, createBooleanColumn, RenderActions} from "@onemind-services-llc/ui-components";
import {MdOutlineDomainVerification, MdBackup} from "@onemind-services-llc/react-icons-ng/md";
import {useDeleteCardBillingCardsIdDelete, useUpdateCardRoleBillingCardsIdAssignRolePatch} from "../../../orval-cml/default/default";
import {useHandleError} from "../../../services/handleError";

export const ActionCell = ({paymentMethod = {}}) => {
  const modalDisclosure = useDisclosure();
  const {handleError} = useHandleError();
  const queryClient = useQueryClient();
  const toast = useToast({status: "success"});
  const {mutate: deletePaymentMethod} = useDeleteCardBillingCardsIdDelete({
    mutation: {
      onSuccess: () => {
        modalDisclosure.onClose();
        toast({description: `Payment method ${paymentMethod.display} deleted successfully`});
        queryClient.invalidateQueries({queryKey: ["paymentMethodsList"]});
      },
      onError: (e) => handleError(e),
    },
  });

  const handleSuccess = (response = {}) => {
    const {data = {}} = response;
    const {display, is_backup, is_primary} = data;
    let toasterMessage = "";
    if (is_primary) {
      toasterMessage = `Card ${display} is set as primary`;
    } else if (is_backup) {
      toasterMessage = `Card ${display} is set as backup`;
    } else {
      toasterMessage = `Card ${display} is removed as backup`;
    }
    toast({description: toasterMessage});
    queryClient.invalidateQueries({queryKey: ["paymentMethodsList"]});
  };

  const {mutate: assignRole} = useUpdateCardRoleBillingCardsIdAssignRolePatch({
    mutation: {
      onSuccess: handleSuccess,
      onError: (e) => handleError(e),
    },
  });

  const handleDelete = () => {
    deletePaymentMethod({id: paymentMethod.id});
  };

  let extraOptions = [];

  if (!paymentMethod.is_primary) {
    extraOptions = [
      {
        label: "Set as primary",
        icon: <MdOutlineDomainVerification />,
        onClick: () => assignRole({id: paymentMethod.id, data: {role: "primary"}}),
        "data-cy": "list_set_as_main",
      },
    ];
  }
  if (!paymentMethod.is_primary && !paymentMethod.is_backup) {
    extraOptions.push({
      label: "Set as backup",
      icon: <MdBackup />,
      onClick: () => assignRole({id: paymentMethod.id, data: {role: "backup"}}),
      "data-cy": "list_set_as_backup",
    });
  }

  if (paymentMethod.is_backup) {
    extraOptions.push({
      label: "Remove as backup",
      icon: <MdBackup />,
      onClick: () => assignRole({id: paymentMethod.id, data: {role: "none"}}),
      "data-cy": "list_remove_as_backup",
    });
  }

  return (
    <RenderActions
      name="Payment Method"
      data={paymentMethod}
      withEdit={false}
      handleDelete={handleDelete}
      extraOptions={extraOptions}
      modalDisclosure={modalDisclosure}
    />
  );
};

export const defaultColumns = [
  createDataColumn("name", {
    accessorFn: (row) => `${row.first_name || ""} ${row.last_name || ""}`,
    isPlaceholder: true,
  }),
  createDataColumn("funding_type"),
  createDataColumn("brand"),
  createDataColumn("masked_number"),
  createDataColumn("expiry", {
    accessorFn: (row) => `${row.expiry_month || ""}/${row.expiry_year || ""}`,
    isPlaceholder: true,
  }),
  createDataColumn("status"),
  createBooleanColumn("is_primary", {header: () => "Primary"}),
  createBooleanColumn("is_backup", {header: () => "Backup"}),
  createDataColumn("actions", {
    header: () => "",
    cell: (data) => (
      <Box display="flex" justifyContent="flex-end">
        <ActionCell paymentMethod={data.row.original} />
      </Box>
    ),
    isPlaceholder: true,
  }),
];

export const columnsList = [
  {display: "Name", value: "name", visible: true},
  {display: "Funding Type", value: "funding_type", visible: true},
  {display: "Brand", value: "brand", visible: true},
  {display: "Masked Number", value: "masked_number", visible: true},
  {display: "Expiry", value: "expiry", visible: true},
  {display: "Status", value: "status", visible: true},
  {display: "Primary", value: "is_primary", visible: true},
  {display: "Backup", value: "is_backup", visible: true},
  {display: "Actions", value: "actions", visible: true},
];
