import React, {useState} from "react";
import {useMatch} from "@tanstack/react-location";
import {Box} from "@chakra-ui/react";
import {Cells, DashboardConfig} from "@onemind-services-llc/ui-charts";
import {Loader, HeaderContainer} from "@onemind-services-llc/ui-components";
import AxiosInstance from "../../../../services/axios";
import {useGetCloudInstanceHealthHealthCloudInstanceIdGet} from "../../../../orval-cml/default/default";

const CloudInstanceMonitor = () => {
  const [timeZone, setTimeZone] = useState({
    label: "Local Time",
    value: "Local",
  });
  const [timeRangeStart, setTimeRangeStart] = useState({
    label: "Past 3 hours",
    value: "3h",
    windowPeriod: "1m",
  });
  const [refreshCount, setRefreshCount] = useState(0);

  const {
    params: {cloudInstanceId},
  } = useMatch();

  const {data: {data: {cells = []} = {}} = {}, isLoading, isFetching} = useGetCloudInstanceHealthHealthCloudInstanceIdGet(cloudInstanceId);

  return (
    <Box>
      {isLoading || isFetching ? <Loader /> : null}
      <HeaderContainer>
        <DashboardConfig
          timezone={timeZone}
          handleTimeZoneChange={setTimeZone}
          onRefresh={() => setRefreshCount((prevCount) => prevCount + 1)}
          timeRangeStart={timeRangeStart}
          handleTimeRangeChange={setTimeRangeStart}
        />
      </HeaderContainer>
      <Cells
        cells={cells}
        axiosInstance={AxiosInstance}
        queryURL={`/health/query/?cloud_instance_id=${cloudInstanceId}`}
        refreshCount={refreshCount}
        timeZone={timeZone.value}
        timeRangeStart={timeRangeStart.value}
        windowPeriod={timeRangeStart.windowPeriod}
      />
    </Box>
  );
};

export default CloudInstanceMonitor;
