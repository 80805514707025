import {listCloudInstancesApiCloudInstancesGet, getCloudInstanceApiCloudInstanceIdGet} from "../../orval-cml/default/default";
import {usePrefetchQuery} from "../usePrefetchQuery";

export const useCloudInstanceListLoader = () => {
  const {prefetchQuery} = usePrefetchQuery();

  const cloudInstanceListLoader = () => {
    const searchKey = "";
    const sortString = null;
    const currentPage = 1;
    const pageSize = 25;
    return prefetchQuery({
      queryKey: ["cloudInstances", searchKey, sortString, currentPage, pageSize],
      queryFn: () =>
        listCloudInstancesApiCloudInstancesGet({
          q: searchKey,
          ordering: sortString,
          limit: pageSize,
          offset: (currentPage - 1) * pageSize,
        }),
    });
  };

  return {cloudInstanceListLoader};
};

export const useCloudInstanceDetailsLoader = () => {
  const {prefetchQuery} = usePrefetchQuery();

  const cloudInstanceDetailsLoader = (cloudInstanceId) => {
    return prefetchQuery({
      queryKey: ["cloudInstanceDetails", cloudInstanceId],
      queryFn: () => getCloudInstanceApiCloudInstanceIdGet(cloudInstanceId),
    });
  };

  return {cloudInstanceDetailsLoader};
};
