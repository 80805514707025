import React from "react";
import {useMatch} from "@tanstack/react-location";

import TranslationsCard from "../../../../components/DeviceDetails/translationsCard";
const Translations = () => {
  const {
    params: {deviceId},
  } = useMatch();

  return <TranslationsCard params={{device_id: deviceId}} />;
};

export default Translations;
