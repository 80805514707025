export const stateList = [
  {
    label: "Southern Nations, Nationalities, and Peoples' Region",
    countryCode: "ET",
    value: "SN",
  },
  {
    label: "Somali Region",
    countryCode: "ET",
    value: "SO",
  },
  {
    label: "Amhara Region",
    countryCode: "ET",
    value: "AM",
  },
  {
    label: "Tigray Region",
    countryCode: "ET",
    value: "TI",
  },
  {
    label: "Oromia Region",
    countryCode: "ET",
    value: "OR",
  },
  {
    label: "Afar Region",
    countryCode: "ET",
    value: "AF",
  },
  {
    label: "Harari Region",
    countryCode: "ET",
    value: "HA",
  },
  {
    label: "Dire Dawa",
    countryCode: "ET",
    value: "DD",
  },
  {
    label: "Benishangul-Gumuz Region",
    countryCode: "ET",
    value: "BE",
  },
  {
    label: "Gambela Region",
    countryCode: "ET",
    value: "GA",
  },
  {
    label: "Addis Ababa",
    countryCode: "ET",
    value: "AA",
  },
  {
    label: "Petnjica Municipality",
    countryCode: "ME",
    value: "23",
  },
  {
    label: "Bar Municipality",
    countryCode: "ME",
    value: "02",
  },
  {
    label: "Danilovgrad Municipality",
    countryCode: "ME",
    value: "07",
  },
  {
    label: "Rožaje Municipality",
    countryCode: "ME",
    value: "17",
  },
  {
    label: "Plužine Municipality",
    countryCode: "ME",
    value: "15",
  },
  {
    label: "Nikšić Municipality",
    countryCode: "ME",
    value: "12",
  },
  {
    label: "Šavnik Municipality",
    countryCode: "ME",
    value: "18",
  },
  {
    label: "Plav Municipality",
    countryCode: "ME",
    value: "13",
  },
  {
    label: "Pljevlja Municipality",
    countryCode: "ME",
    value: "14",
  },
  {
    label: "Berane Municipality",
    countryCode: "ME",
    value: "03",
  },
  {
    label: "Mojkovac Municipality",
    countryCode: "ME",
    value: "11",
  },
  {
    label: "Andrijevica Municipality",
    countryCode: "ME",
    value: "01",
  },
  {
    label: "Gusinje Municipality",
    countryCode: "ME",
    value: "22",
  },
  {
    label: "Bijelo Polje Municipality",
    countryCode: "ME",
    value: "04",
  },
  {
    label: "Kotor Municipality",
    countryCode: "ME",
    value: "10",
  },
  {
    label: "Podgorica Municipality",
    countryCode: "ME",
    value: "16",
  },
  {
    label: "Old Royal Capital Cetinje",
    countryCode: "ME",
    value: "06",
  },
  {
    label: "Tivat Municipality",
    countryCode: "ME",
    value: "19",
  },
  {
    label: "Budva Municipality",
    countryCode: "ME",
    value: "05",
  },
  {
    label: "Kolašin Municipality",
    countryCode: "ME",
    value: "09",
  },
  {
    label: "Žabljak Municipality",
    countryCode: "ME",
    value: "21",
  },
  {
    label: "Ulcinj Municipality",
    countryCode: "ME",
    value: "20",
  },
  {
    label: "Kunene Region",
    countryCode: "NA",
    value: "KU",
  },
  {
    label: "Kavango West Region",
    countryCode: "NA",
    value: "KW",
  },
  {
    label: "Kavango East Region",
    countryCode: "NA",
    value: "KE",
  },
  {
    label: "Oshana Region",
    countryCode: "NA",
    value: "ON",
  },
  {
    label: "Hardap Region",
    countryCode: "NA",
    value: "HA",
  },
  {
    label: "Omusati Region",
    countryCode: "NA",
    value: "OS",
  },
  {
    label: "Ohangwena Region",
    countryCode: "NA",
    value: "OW",
  },
  {
    label: "Omaheke Region",
    countryCode: "NA",
    value: "OH",
  },
  {
    label: "Oshikoto Region",
    countryCode: "NA",
    value: "OT",
  },
  {
    label: "Erongo Region",
    countryCode: "NA",
    value: "ER",
  },
  {
    label: "Khomas Region",
    countryCode: "NA",
    value: "KH",
  },
  {
    label: "Karas Region",
    countryCode: "NA",
    value: "KA",
  },
  {
    label: "Otjozondjupa Region",
    countryCode: "NA",
    value: "OD",
  },
  {
    label: "Zambezi Region",
    countryCode: "NA",
    value: "CA",
  },
  {
    label: "Ashanti Region",
    countryCode: "GH",
    value: "AH",
  },
  {
    label: "Western Region",
    countryCode: "GH",
    value: "WP",
  },
  {
    label: "Eastern Region",
    countryCode: "GH",
    value: "EP",
  },
  {
    label: "Northern Region",
    countryCode: "GH",
    value: "NP",
  },
  {
    label: "Central Region",
    countryCode: "GH",
    value: "CP",
  },
  {
    label: "Brong-Ahafo Region",
    countryCode: "GH",
    value: "BA",
  },
  {
    label: "Greater Accra Region",
    countryCode: "GH",
    value: "AA",
  },
  {
    label: "Upper East Region",
    countryCode: "GH",
    value: "UE",
  },
  {
    label: "Volta Region",
    countryCode: "GH",
    value: "TV",
  },
  {
    label: "Upper West Region",
    countryCode: "GH",
    value: "UW",
  },
  {
    label: "San Marino",
    countryCode: "SM",
    value: "07",
  },
  {
    label: "Acquaviva",
    countryCode: "SM",
    value: "01",
  },
  {
    label: "Chiesanuova",
    countryCode: "SM",
    value: "02",
  },
  {
    label: "Borgo Maggiore",
    countryCode: "SM",
    value: "06",
  },
  {
    label: "Faetano",
    countryCode: "SM",
    value: "04",
  },
  {
    label: "Montegiardino",
    countryCode: "SM",
    value: "08",
  },
  {
    label: "Domagnano",
    countryCode: "SM",
    value: "03",
  },
  {
    label: "Serravalle",
    countryCode: "SM",
    value: "09",
  },
  {
    label: "Fiorentino",
    countryCode: "SM",
    value: "05",
  },
  {
    label: "Tillabéri Region",
    countryCode: "NE",
    value: "6",
  },
  {
    label: "Dosso Region",
    countryCode: "NE",
    value: "3",
  },
  {
    label: "Zinder Region",
    countryCode: "NE",
    value: "7",
  },
  {
    label: "Maradi Region",
    countryCode: "NE",
    value: "4",
  },
  {
    label: "Agadez Region",
    countryCode: "NE",
    value: "1",
  },
  {
    label: "Diffa Region",
    countryCode: "NE",
    value: "2",
  },
  {
    label: "Tahoua Region",
    countryCode: "NE",
    value: "5",
  },
  {
    label: "Mqabba",
    countryCode: "MT",
    value: "33",
  },
  {
    label: "San Ġwann",
    countryCode: "MT",
    value: "49",
  },
  {
    label: "Żurrieq",
    countryCode: "MT",
    value: "68",
  },
  {
    label: "Luqa",
    countryCode: "MT",
    value: "25",
  },
  {
    label: "Marsaxlokk",
    countryCode: "MT",
    value: "28",
  },
  {
    label: "Qala",
    countryCode: "MT",
    value: "42",
  },
  {
    label: "Żebbuġ Malta",
    countryCode: "MT",
    value: "66",
  },
  {
    label: "Xgħajra",
    countryCode: "MT",
    value: "63",
  },
  {
    label: "Kirkop",
    countryCode: "MT",
    value: "23",
  },
  {
    label: "Rabat",
    countryCode: "MT",
    value: "46",
  },
  {
    label: "Floriana",
    countryCode: "MT",
    value: "09",
  },
  {
    label: "Żebbuġ Gozo",
    countryCode: "MT",
    value: "65",
  },
  {
    label: "Swieqi",
    countryCode: "MT",
    value: "57",
  },
  {
    label: "Saint Lawrence",
    countryCode: "MT",
    value: "50",
  },
  {
    label: "Birżebbuġa",
    countryCode: "MT",
    value: "05",
  },
  {
    label: "Mdina",
    countryCode: "MT",
    value: "29",
  },
  {
    label: "Santa Venera",
    countryCode: "MT",
    value: "54",
  },
  {
    label: "Kerċem",
    countryCode: "MT",
    value: "22",
  },
  {
    label: "Għarb",
    countryCode: "MT",
    value: "14",
  },
  {
    label: "Iklin",
    countryCode: "MT",
    value: "19",
  },
  {
    label: "Santa Luċija",
    countryCode: "MT",
    value: "53",
  },
  {
    label: "Valletta",
    countryCode: "MT",
    value: "60",
  },
  {
    label: "Msida",
    countryCode: "MT",
    value: "34",
  },
  {
    label: "Birkirkara",
    countryCode: "MT",
    value: "04",
  },
  {
    label: "Siġġiewi",
    countryCode: "MT",
    value: "55",
  },
  {
    label: "Kalkara",
    countryCode: "MT",
    value: "21",
  },
  {
    label: "St. Julian's",
    countryCode: "MT",
    value: "48",
  },
  {
    label: "Victoria",
    countryCode: "MT",
    value: "45",
  },
  {
    label: "Mellieħa",
    countryCode: "MT",
    value: "30",
  },
  {
    label: "Tarxien",
    countryCode: "MT",
    value: "59",
  },
  {
    label: "Sliema",
    countryCode: "MT",
    value: "56",
  },
  {
    label: "Ħamrun",
    countryCode: "MT",
    value: "18",
  },
  {
    label: "Għasri",
    countryCode: "MT",
    value: "16",
  },
  {
    label: "Birgu",
    countryCode: "MT",
    value: "03",
  },
  {
    label: "Balzan",
    countryCode: "MT",
    value: "02",
  },
  {
    label: "Mġarr",
    countryCode: "MT",
    value: "31",
  },
  {
    label: "Attard",
    countryCode: "MT",
    value: "01",
  },
  {
    label: "Qrendi",
    countryCode: "MT",
    value: "44",
  },
  {
    label: "Naxxar",
    countryCode: "MT",
    value: "38",
  },
  {
    label: "Gżira",
    countryCode: "MT",
    value: "12",
  },
  {
    label: "Xagħra",
    countryCode: "MT",
    value: "61",
  },
  {
    label: "Paola",
    countryCode: "MT",
    value: "39",
  },
  {
    label: "Sannat",
    countryCode: "MT",
    value: "52",
  },
  {
    label: "Dingli",
    countryCode: "MT",
    value: "07",
  },
  {
    label: "Gudja",
    countryCode: "MT",
    value: "11",
  },
  {
    label: "Qormi",
    countryCode: "MT",
    value: "43",
  },
  {
    label: "Għargħur",
    countryCode: "MT",
    value: "15",
  },
  {
    label: "Xewkija",
    countryCode: "MT",
    value: "62",
  },
  {
    label: "Ta' Xbiex",
    countryCode: "MT",
    value: "58",
  },
  {
    label: "Żabbar",
    countryCode: "MT",
    value: "64",
  },
  {
    label: "Għaxaq",
    countryCode: "MT",
    value: "17",
  },
  {
    label: "Pembroke",
    countryCode: "MT",
    value: "40",
  },
  {
    label: "Lija",
    countryCode: "MT",
    value: "24",
  },
  {
    label: "Pietà",
    countryCode: "MT",
    value: "41",
  },
  {
    label: "Marsa",
    countryCode: "MT",
    value: "26",
  },
  {
    label: "Fgura",
    countryCode: "MT",
    value: "08",
  },
  {
    label: "Għajnsielem",
    countryCode: "MT",
    value: "13",
  },
  {
    label: "Mtarfa",
    countryCode: "MT",
    value: "35",
  },
  {
    label: "Munxar",
    countryCode: "MT",
    value: "36",
  },
  {
    label: "Nadur",
    countryCode: "MT",
    value: "37",
  },
  {
    label: "Fontana",
    countryCode: "MT",
    value: "10",
  },
  {
    label: "Żejtun",
    countryCode: "MT",
    value: "67",
  },
  {
    label: "Senglea",
    countryCode: "MT",
    value: "20",
  },
  {
    label: "Marsaskala",
    countryCode: "MT",
    value: "27",
  },
  {
    label: "Cospicua",
    countryCode: "MT",
    value: "06",
  },
  {
    label: "St. Paul's Bay",
    countryCode: "MT",
    value: "51",
  },
  {
    label: "Mosta",
    countryCode: "MT",
    value: "32",
  },
  {
    label: "Mangystau Region",
    countryCode: "KZ",
    value: "MAN",
  },
  {
    label: "Kyzylorda Region",
    countryCode: "KZ",
    value: "KZY",
  },
  {
    label: "Almaty Region",
    countryCode: "KZ",
    value: "ALM",
  },
  {
    label: "North Kazakhstan Region",
    countryCode: "KZ",
    value: "SEV",
  },
  {
    label: "Akmola Region",
    countryCode: "KZ",
    value: "AKM",
  },
  {
    label: "Pavlodar Region",
    countryCode: "KZ",
    value: "PAV",
  },
  {
    label: "Jambyl Region",
    countryCode: "KZ",
    value: "ZHA",
  },
  {
    label: "West Kazakhstan Province",
    countryCode: "KZ",
    value: "ZAP",
  },
  {
    label: "Turkestan Region",
    countryCode: "KZ",
    value: "YUZ",
  },
  {
    label: "Karaganda Region",
    countryCode: "KZ",
    value: "KAR",
  },
  {
    label: "Aktobe Region",
    countryCode: "KZ",
    value: "AKT",
  },
  {
    label: "Almaty",
    countryCode: "KZ",
    value: "ALA",
  },
  {
    label: "Atyrau Region",
    countryCode: "KZ",
    value: "ATY",
  },
  {
    label: "East Kazakhstan Region",
    countryCode: "KZ",
    value: "VOS",
  },
  {
    label: "Baikonur",
    countryCode: "KZ",
    value: "BAY",
  },
  {
    label: "Nur-Sultan",
    countryCode: "KZ",
    value: "AST",
  },
  {
    label: "Kostanay Region",
    countryCode: "KZ",
    value: "KUS",
  },
  {
    label: "Kakamega County",
    countryCode: "KE",
    value: "11",
  },
  {
    label: "Kisii County",
    countryCode: "KE",
    value: "16",
  },
  {
    label: "Central Province",
    countryCode: "KE",
    value: "200",
  },
  {
    label: "Busia County",
    countryCode: "KE",
    value: "04",
  },
  {
    label: "North Eastern Province",
    countryCode: "KE",
    value: "500",
  },
  {
    label: "Embu County",
    countryCode: "KE",
    value: "06",
  },
  {
    label: "Laikipia County",
    countryCode: "KE",
    value: "20",
  },
  {
    label: "Nandi District",
    countryCode: "KE",
    value: "32",
  },
  {
    label: "Lamu County",
    countryCode: "KE",
    value: "21",
  },
  {
    label: "Kirinyaga County",
    countryCode: "KE",
    value: "15",
  },
  {
    label: "Bungoma County",
    countryCode: "KE",
    value: "03",
  },
  {
    label: "Uasin Gishu",
    countryCode: "KE",
    value: "44",
  },
  {
    label: "Isiolo County",
    countryCode: "KE",
    value: "09",
  },
  {
    label: "Kisumu County",
    countryCode: "KE",
    value: "17",
  },
  {
    label: "Coast Province",
    countryCode: "KE",
    value: "300",
  },
  {
    label: "Kwale County",
    countryCode: "KE",
    value: "19",
  },
  {
    label: "Kilifi County",
    countryCode: "KE",
    value: "14",
  },
  {
    label: "Narok County",
    countryCode: "KE",
    value: "33",
  },
  {
    label: "Taita–Taveta County",
    countryCode: "KE",
    value: "39",
  },
  {
    label: "Western Province",
    countryCode: "KE",
    value: "800",
  },
  {
    label: "Muranga County",
    countryCode: "KE",
    value: "29",
  },
  {
    label: "Rift Valley Province",
    countryCode: "KE",
    value: "700",
  },
  {
    label: "Nyeri County",
    countryCode: "KE",
    value: "36",
  },
  {
    label: "Baringo County",
    countryCode: "KE",
    value: "01",
  },
  {
    label: "Wajir County",
    countryCode: "KE",
    value: "46",
  },
  {
    label: "Trans-Nzoia District",
    countryCode: "KE",
    value: "42",
  },
  {
    label: "Machakos County",
    countryCode: "KE",
    value: "22",
  },
  {
    label: "Tharaka Nithi County",
    countryCode: "KE",
    value: "41",
  },
  {
    label: "Siaya County",
    countryCode: "KE",
    value: "38",
  },
  {
    label: "Mandera County",
    countryCode: "KE",
    value: "24",
  },
  {
    label: "Makueni County",
    countryCode: "KE",
    value: "23",
  },
  {
    label: "Eastern Province",
    countryCode: "KE",
    value: "400",
  },
  {
    label: "Migori County",
    countryCode: "KE",
    value: "27",
  },
  {
    label: "Nairobi",
    countryCode: "KE",
    value: "110",
  },
  {
    label: "Nyandarua County",
    countryCode: "KE",
    value: "35",
  },
  {
    label: "Kericho County",
    countryCode: "KE",
    value: "12",
  },
  {
    label: "Marsabit County",
    countryCode: "KE",
    value: "25",
  },
  {
    label: "Homa Bay County",
    countryCode: "KE",
    value: "08",
  },
  {
    label: "Garissa County",
    countryCode: "KE",
    value: "07",
  },
  {
    label: "Kajiado County",
    countryCode: "KE",
    value: "10",
  },
  {
    label: "Meru County",
    countryCode: "KE",
    value: "26",
  },
  {
    label: "Kiambu County",
    countryCode: "KE",
    value: "13",
  },
  {
    label: "Mombasa County",
    countryCode: "KE",
    value: "28",
  },
  {
    label: "Elgeyo-Marakwet County",
    countryCode: "KE",
    value: "05",
  },
  {
    label: "Vihiga County",
    countryCode: "KE",
    value: "45",
  },
  {
    label: "Nakuru County",
    countryCode: "KE",
    value: "31",
  },
  {
    label: "Nyanza Province",
    countryCode: "KE",
    value: "600",
  },
  {
    label: "Tana River County",
    countryCode: "KE",
    value: "40",
  },
  {
    label: "Turkana County",
    countryCode: "KE",
    value: "43",
  },
  {
    label: "Samburu County",
    countryCode: "KE",
    value: "37",
  },
  {
    label: "West Pokot County",
    countryCode: "KE",
    value: "47",
  },
  {
    label: "Nyamira County",
    countryCode: "KE",
    value: "34",
  },
  {
    label: "Bomet County",
    countryCode: "KE",
    value: "02",
  },
  {
    label: "Kitui County",
    countryCode: "KE",
    value: "18",
  },
  {
    label: "Bié Province",
    countryCode: "AO",
    value: "BIE",
  },
  {
    label: "Huambo Province",
    countryCode: "AO",
    value: "HUA",
  },
  {
    label: "Zaire Province",
    countryCode: "AO",
    value: "ZAI",
  },
  {
    label: "Cunene Province",
    countryCode: "AO",
    value: "CNN",
  },
  {
    label: "Cuanza Sul",
    countryCode: "AO",
    value: "CUS",
  },
  {
    label: "Cuanza Norte Province",
    countryCode: "AO",
    value: "CNO",
  },
  {
    label: "Benguela Province",
    countryCode: "AO",
    value: "BGU",
  },
  {
    label: "Moxico Province",
    countryCode: "AO",
    value: "MOX",
  },
  {
    label: "Lunda Sul Province",
    countryCode: "AO",
    value: "LSU",
  },
  {
    label: "Bengo Province",
    countryCode: "AO",
    value: "BGO",
  },
  {
    label: "Luanda Province",
    countryCode: "AO",
    value: "LUA",
  },
  {
    label: "Lunda Norte Province",
    countryCode: "AO",
    value: "LNO",
  },
  {
    label: "Uíge Province",
    countryCode: "AO",
    value: "UIG",
  },
  {
    label: "Huíla Province",
    countryCode: "AO",
    value: "HUI",
  },
  {
    label: "Cuando Cubango Province",
    countryCode: "AO",
    value: "CCU",
  },
  {
    label: "Malanje Province",
    countryCode: "AO",
    value: "MAL",
  },
  {
    label: "Cabinda Province",
    countryCode: "AO",
    value: "CAB",
  },
  {
    label: "Gasa District",
    countryCode: "BT",
    value: "GA",
  },
  {
    label: "Tsirang District",
    countryCode: "BT",
    value: "21",
  },
  {
    label: "Wangdue Phodrang District",
    countryCode: "BT",
    value: "24",
  },
  {
    label: "Haa District",
    countryCode: "BT",
    value: "13",
  },
  {
    label: "Zhemgang District",
    countryCode: "BT",
    value: "34",
  },
  {
    label: "Lhuntse District",
    countryCode: "BT",
    value: "44",
  },
  {
    label: "Punakha District",
    countryCode: "BT",
    value: "23",
  },
  {
    label: "Trashigang District",
    countryCode: "BT",
    value: "41",
  },
  {
    label: "Paro District",
    countryCode: "BT",
    value: "11",
  },
  {
    label: "Dagana District",
    countryCode: "BT",
    value: "22",
  },
  {
    label: "Chukha District",
    countryCode: "BT",
    value: "12",
  },
  {
    label: "Bumthang District",
    countryCode: "BT",
    value: "33",
  },
  {
    label: "Thimphu District",
    countryCode: "BT",
    value: "15",
  },
  {
    label: "Mongar District",
    countryCode: "BT",
    value: "42",
  },
  {
    label: "Samdrup Jongkhar District",
    countryCode: "BT",
    value: "45",
  },
  {
    label: "Pemagatshel District",
    countryCode: "BT",
    value: "43",
  },
  {
    label: "Trongsa District",
    countryCode: "BT",
    value: "32",
  },
  {
    label: "Samtse District",
    countryCode: "BT",
    value: "14",
  },
  {
    label: "Sarpang District",
    countryCode: "BT",
    value: "31",
  },
  {
    label: "Tombouctou Region",
    countryCode: "ML",
    value: "6",
  },
  {
    label: "Ségou Region",
    countryCode: "ML",
    value: "4",
  },
  {
    label: "Koulikoro Region",
    countryCode: "ML",
    value: "2",
  },
  {
    label: "Ménaka Region",
    countryCode: "ML",
    value: "9",
  },
  {
    label: "Kayes Region",
    countryCode: "ML",
    value: "1",
  },
  {
    label: "Bamako",
    countryCode: "ML",
    value: "BKO",
  },
  {
    label: "Sikasso Region",
    countryCode: "ML",
    value: "3",
  },
  {
    label: "Mopti Region",
    countryCode: "ML",
    value: "5",
  },
  {
    label: "Taoudénit Region",
    countryCode: "ML",
    value: "10",
  },
  {
    label: "Kidal Region",
    countryCode: "ML",
    value: "8",
  },
  {
    label: "Gao Region",
    countryCode: "ML",
    value: "7",
  },
  {
    label: "Southern Province",
    countryCode: "RW",
    value: "05",
  },
  {
    label: "Western Province",
    countryCode: "RW",
    value: "04",
  },
  {
    label: "Eastern Province",
    countryCode: "RW",
    value: "02",
  },
  {
    label: "Kigali district",
    countryCode: "RW",
    value: "01",
  },
  {
    label: "Northern Province",
    countryCode: "RW",
    value: "03",
  },
  {
    label: "Belize District",
    countryCode: "BZ",
    value: "BZ",
  },
  {
    label: "Stann Creek District",
    countryCode: "BZ",
    value: "SC",
  },
  {
    label: "Corozal District",
    countryCode: "BZ",
    value: "CZL",
  },
  {
    label: "Toledo District",
    countryCode: "BZ",
    value: "TOL",
  },
  {
    label: "Orange Walk District",
    countryCode: "BZ",
    value: "OW",
  },
  {
    label: "Cayo District",
    countryCode: "BZ",
    value: "CY",
  },
  {
    label: "Príncipe Province",
    countryCode: "ST",
    value: "P",
  },
  {
    label: "São Tomé Province",
    countryCode: "ST",
    value: "S",
  },
  {
    label: "Havana Province",
    countryCode: "CU",
    value: "03",
  },
  {
    label: "Santiago de Cuba Province",
    countryCode: "CU",
    value: "13",
  },
  {
    label: "Sancti Spíritus Province",
    countryCode: "CU",
    value: "07",
  },
  {
    label: "Granma Province",
    countryCode: "CU",
    value: "12",
  },
  {
    label: "Mayabeque Province",
    countryCode: "CU",
    value: "16",
  },
  {
    label: "Pinar del Río Province",
    countryCode: "CU",
    value: "01",
  },
  {
    label: "Isla de la Juventud",
    countryCode: "CU",
    value: "99",
  },
  {
    label: "Holguín Province",
    countryCode: "CU",
    value: "11",
  },
  {
    label: "Villa Clara Province",
    countryCode: "CU",
    value: "05",
  },
  {
    label: "Las Tunas Province",
    countryCode: "CU",
    value: "10",
  },
  {
    label: "Ciego de Ávila Province",
    countryCode: "CU",
    value: "08",
  },
  {
    label: "Artemisa Province",
    countryCode: "CU",
    value: "15",
  },
  {
    label: "Matanzas Province",
    countryCode: "CU",
    value: "04",
  },
  {
    label: "Guantánamo Province",
    countryCode: "CU",
    value: "14",
  },
  {
    label: "Camagüey Province",
    countryCode: "CU",
    value: "09",
  },
  {
    label: "Cienfuegos Province",
    countryCode: "CU",
    value: "06",
  },
  {
    label: "Jigawa",
    countryCode: "NG",
    value: "JI",
  },
  {
    label: "Enugu",
    countryCode: "NG",
    value: "EN",
  },
  {
    label: "Kebbi",
    countryCode: "NG",
    value: "KE",
  },
  {
    label: "Benue",
    countryCode: "NG",
    value: "BE",
  },
  {
    label: "Sokoto",
    countryCode: "NG",
    value: "SO",
  },
  {
    label: "Abuja Federal Capital Territory",
    countryCode: "NG",
    value: "FC",
  },
  {
    label: "Kaduna",
    countryCode: "NG",
    value: "KD",
  },
  {
    label: "Kwara",
    countryCode: "NG",
    value: "KW",
  },
  {
    label: "Oyo",
    countryCode: "NG",
    value: "OY",
  },
  {
    label: "Yobe",
    countryCode: "NG",
    value: "YO",
  },
  {
    label: "Kogi",
    countryCode: "NG",
    value: "KO",
  },
  {
    label: "Zamfara",
    countryCode: "NG",
    value: "ZA",
  },
  {
    label: "Kano",
    countryCode: "NG",
    value: "KN",
  },
  {
    label: "Nasarawa",
    countryCode: "NG",
    value: "NA",
  },
  {
    label: "Plateau",
    countryCode: "NG",
    value: "PL",
  },
  {
    label: "Abia",
    countryCode: "NG",
    value: "AB",
  },
  {
    label: "Akwa Ibom",
    countryCode: "NG",
    value: "AK",
  },
  {
    label: "Bayelsa",
    countryCode: "NG",
    value: "BY",
  },
  {
    label: "Lagos",
    countryCode: "NG",
    value: "LA",
  },
  {
    label: "Borno",
    countryCode: "NG",
    value: "BO",
  },
  {
    label: "Imo",
    countryCode: "NG",
    value: "IM",
  },
  {
    label: "Ekiti",
    countryCode: "NG",
    value: "EK",
  },
  {
    label: "Gombe",
    countryCode: "NG",
    value: "GO",
  },
  {
    label: "Ebonyi",
    countryCode: "NG",
    value: "EB",
  },
  {
    label: "Bauchi",
    countryCode: "NG",
    value: "BA",
  },
  {
    label: "Katsina",
    countryCode: "NG",
    value: "KT",
  },
  {
    label: "Cross River",
    countryCode: "NG",
    value: "CR",
  },
  {
    label: "Anambra",
    countryCode: "NG",
    value: "AN",
  },
  {
    label: "Delta",
    countryCode: "NG",
    value: "DE",
  },
  {
    label: "Niger",
    countryCode: "NG",
    value: "NI",
  },
  {
    label: "Edo",
    countryCode: "NG",
    value: "ED",
  },
  {
    label: "Taraba",
    countryCode: "NG",
    value: "TA",
  },
  {
    label: "Adamawa",
    countryCode: "NG",
    value: "AD",
  },
  {
    label: "Ondo",
    countryCode: "NG",
    value: "ON",
  },
  {
    label: "Osun",
    countryCode: "NG",
    value: "OS",
  },
  {
    label: "Ogun",
    countryCode: "NG",
    value: "OG",
  },
  {
    label: "Rukungiri District",
    countryCode: "UG",
    value: "412",
  },
  {
    label: "Kyankwanzi District",
    countryCode: "UG",
    value: "123",
  },
  {
    label: "Kabarole District",
    countryCode: "UG",
    value: "405",
  },
  {
    label: "Mpigi District",
    countryCode: "UG",
    value: "106",
  },
  {
    label: "Apac District",
    countryCode: "UG",
    value: "302",
  },
  {
    label: "Abim District",
    countryCode: "UG",
    value: "314",
  },
  {
    label: "Yumbe District",
    countryCode: "UG",
    value: "313",
  },
  {
    label: "Rukiga District",
    countryCode: "UG",
    value: "431",
  },
  {
    label: "Northern Region",
    countryCode: "UG",
    value: "N",
  },
  {
    label: "Serere District",
    countryCode: "UG",
    value: "232",
  },
  {
    label: "Kamuli District",
    countryCode: "UG",
    value: "205",
  },
  {
    label: "Amuru District",
    countryCode: "UG",
    value: "316",
  },
  {
    label: "Kaberamaido District",
    countryCode: "UG",
    value: "213",
  },
  {
    label: "Namutumba District",
    countryCode: "UG",
    value: "224",
  },
  {
    label: "Kibuku District",
    countryCode: "UG",
    value: "227",
  },
  {
    label: "Ibanda District",
    countryCode: "UG",
    value: "417",
  },
  {
    label: "Iganga District",
    countryCode: "UG",
    value: "203",
  },
  {
    label: "Dokolo District",
    countryCode: "UG",
    value: "317",
  },
  {
    label: "Lira District",
    countryCode: "UG",
    value: "307",
  },
  {
    label: "Bukedea District",
    countryCode: "UG",
    value: "219",
  },
  {
    label: "Alebtong District",
    countryCode: "UG",
    value: "323",
  },
  {
    label: "Koboko District",
    countryCode: "UG",
    value: "319",
  },
  {
    label: "Kiryandongo District",
    countryCode: "UG",
    value: "421",
  },
  {
    label: "Kiboga District",
    countryCode: "UG",
    value: "103",
  },
  {
    label: "Kitgum District",
    countryCode: "UG",
    value: "305",
  },
  {
    label: "Bududa District",
    countryCode: "UG",
    value: "218",
  },
  {
    label: "Mbale District",
    countryCode: "UG",
    value: "209",
  },
  {
    label: "Namayingo District",
    countryCode: "UG",
    value: "230",
  },
  {
    label: "Amuria District",
    countryCode: "UG",
    value: "216",
  },
  {
    label: "Amudat District",
    countryCode: "UG",
    value: "324",
  },
  {
    label: "Masindi District",
    countryCode: "UG",
    value: "409",
  },
  {
    label: "Kiruhura District",
    countryCode: "UG",
    value: "419",
  },
  {
    label: "Masaka District",
    countryCode: "UG",
    value: "105",
  },
  {
    label: "Pakwach District",
    countryCode: "UG",
    value: "332",
  },
  {
    label: "Rubanda District",
    countryCode: "UG",
    value: "429",
  },
  {
    label: "Tororo District",
    countryCode: "UG",
    value: "212",
  },
  {
    label: "Kamwenge District",
    countryCode: "UG",
    value: "413",
  },
  {
    label: "Adjumani District",
    countryCode: "UG",
    value: "301",
  },
  {
    label: "Wakiso District",
    countryCode: "UG",
    value: "113",
  },
  {
    label: "Moyo District",
    countryCode: "UG",
    value: "309",
  },
  {
    label: "Mityana District",
    countryCode: "UG",
    value: "115",
  },
  {
    label: "Butaleja District",
    countryCode: "UG",
    value: "221",
  },
  {
    label: "Gomba District",
    countryCode: "UG",
    value: "121",
  },
  {
    label: "Jinja District",
    countryCode: "UG",
    value: "204",
  },
  {
    label: "Kayunga District",
    countryCode: "UG",
    value: "112",
  },
  {
    label: "Kween District",
    countryCode: "UG",
    value: "228",
  },
  {
    label: "Western Region",
    countryCode: "UG",
    value: "W",
  },
  {
    label: "Mubende District",
    countryCode: "UG",
    value: "107",
  },
  {
    label: "Eastern Region",
    countryCode: "UG",
    value: "E",
  },
  {
    label: "Kanungu District",
    countryCode: "UG",
    value: "414",
  },
  {
    label: "Omoro District",
    countryCode: "UG",
    value: "331",
  },
  {
    label: "Bukomansimbi District",
    countryCode: "UG",
    value: "118",
  },
  {
    label: "Lyantonde District",
    countryCode: "UG",
    value: "114",
  },
  {
    label: "Buikwe District",
    countryCode: "UG",
    value: "117",
  },
  {
    label: "Nwoya District",
    countryCode: "UG",
    value: "328",
  },
  {
    label: "Zombo District",
    countryCode: "UG",
    value: "330",
  },
  {
    label: "Buyende District",
    countryCode: "UG",
    value: "226",
  },
  {
    label: "Bunyangabu District",
    countryCode: "UG",
    value: "430",
  },
  {
    label: "Kampala District",
    countryCode: "UG",
    value: "102",
  },
  {
    label: "Isingiro District",
    countryCode: "UG",
    value: "418",
  },
  {
    label: "Butambala District",
    countryCode: "UG",
    value: "119",
  },
  {
    label: "Bukwo District",
    countryCode: "UG",
    value: "220",
  },
  {
    label: "Bushenyi District",
    countryCode: "UG",
    value: "402",
  },
  {
    label: "Bugiri District",
    countryCode: "UG",
    value: "201",
  },
  {
    label: "Butebo District",
    countryCode: "UG",
    value: "233",
  },
  {
    label: "Buliisa District",
    countryCode: "UG",
    value: "416",
  },
  {
    label: "Otuke District",
    countryCode: "UG",
    value: "329",
  },
  {
    label: "Buhweju District",
    countryCode: "UG",
    value: "420",
  },
  {
    label: "Agago District",
    countryCode: "UG",
    value: "322",
  },
  {
    label: "Nakapiripirit District",
    countryCode: "UG",
    value: "311",
  },
  {
    label: "Kalungu District",
    countryCode: "UG",
    value: "122",
  },
  {
    label: "Moroto District",
    countryCode: "UG",
    value: "308",
  },
  {
    label: "Central Region",
    countryCode: "UG",
    value: "C",
  },
  {
    label: "Oyam District",
    countryCode: "UG",
    value: "321",
  },
  {
    label: "Kaliro District",
    countryCode: "UG",
    value: "222",
  },
  {
    label: "Kakumiro District",
    countryCode: "UG",
    value: "428",
  },
  {
    label: "Namisindwa District",
    countryCode: "UG",
    value: "234",
  },
  {
    label: "Kole District",
    countryCode: "UG",
    value: "325",
  },
  {
    label: "Kyenjojo District",
    countryCode: "UG",
    value: "415",
  },
  {
    label: "Kagadi District",
    countryCode: "UG",
    value: "427",
  },
  {
    label: "Ntungamo District",
    countryCode: "UG",
    value: "411",
  },
  {
    label: "Kalangala District",
    countryCode: "UG",
    value: "101",
  },
  {
    label: "Nakasongola District",
    countryCode: "UG",
    value: "109",
  },
  {
    label: "Sheema District",
    countryCode: "UG",
    value: "426",
  },
  {
    label: "Pader District",
    countryCode: "UG",
    value: "312",
  },
  {
    label: "Kisoro District",
    countryCode: "UG",
    value: "408",
  },
  {
    label: "Mukono District",
    countryCode: "UG",
    value: "108",
  },
  {
    label: "Lamwo District",
    countryCode: "UG",
    value: "326",
  },
  {
    label: "Pallisa District",
    countryCode: "UG",
    value: "210",
  },
  {
    label: "Gulu District",
    countryCode: "UG",
    value: "304",
  },
  {
    label: "Buvuma District",
    countryCode: "UG",
    value: "120",
  },
  {
    label: "Mbarara District",
    countryCode: "UG",
    value: "410",
  },
  {
    label: "Amolatar District",
    countryCode: "UG",
    value: "315",
  },
  {
    label: "Lwengo District",
    countryCode: "UG",
    value: "124",
  },
  {
    label: "Mayuge District",
    countryCode: "UG",
    value: "214",
  },
  {
    label: "Bundibugyo District",
    countryCode: "UG",
    value: "401",
  },
  {
    label: "Katakwi District",
    countryCode: "UG",
    value: "207",
  },
  {
    label: "Maracha District",
    countryCode: "UG",
    value: "320",
  },
  {
    label: "Ntoroko District",
    countryCode: "UG",
    value: "424",
  },
  {
    label: "Nakaseke District",
    countryCode: "UG",
    value: "116",
  },
  {
    label: "Ngora District",
    countryCode: "UG",
    value: "231",
  },
  {
    label: "Kumi District",
    countryCode: "UG",
    value: "208",
  },
  {
    label: "Kabale District",
    countryCode: "UG",
    value: "404",
  },
  {
    label: "Sembabule District",
    countryCode: "UG",
    value: "111",
  },
  {
    label: "Bulambuli District",
    countryCode: "UG",
    value: "225",
  },
  {
    label: "Sironko District",
    countryCode: "UG",
    value: "215",
  },
  {
    label: "Napak District",
    countryCode: "UG",
    value: "327",
  },
  {
    label: "Busia District",
    countryCode: "UG",
    value: "202",
  },
  {
    label: "Kapchorwa District",
    countryCode: "UG",
    value: "206",
  },
  {
    label: "Luwero District",
    countryCode: "UG",
    value: "104",
  },
  {
    label: "Kaabong District",
    countryCode: "UG",
    value: "318",
  },
  {
    label: "Mitooma District",
    countryCode: "UG",
    value: "423",
  },
  {
    label: "Kibaale District",
    countryCode: "UG",
    value: "407",
  },
  {
    label: "Kyegegwa District",
    countryCode: "UG",
    value: "422",
  },
  {
    label: "Manafwa District",
    countryCode: "UG",
    value: "223",
  },
  {
    label: "Rakai District",
    countryCode: "UG",
    value: "110",
  },
  {
    label: "Kasese District",
    countryCode: "UG",
    value: "406",
  },
  {
    label: "Budaka District",
    countryCode: "UG",
    value: "217",
  },
  {
    label: "Rubirizi District",
    countryCode: "UG",
    value: "425",
  },
  {
    label: "Kotido District",
    countryCode: "UG",
    value: "306",
  },
  {
    label: "Soroti District",
    countryCode: "UG",
    value: "211",
  },
  {
    label: "Luuka District",
    countryCode: "UG",
    value: "229",
  },
  {
    label: "Nebbi District",
    countryCode: "UG",
    value: "310",
  },
  {
    label: "Arua District",
    countryCode: "UG",
    value: "303",
  },
  {
    label: "Kyotera District",
    countryCode: "UG",
    value: "125",
  },
  {
    label: "Schellenberg",
    countryCode: "LI",
    value: "08",
  },
  {
    label: "Schaan",
    countryCode: "LI",
    value: "07",
  },
  {
    label: "Eschen",
    countryCode: "LI",
    value: "02",
  },
  {
    label: "Vaduz",
    countryCode: "LI",
    value: "11",
  },
  {
    label: "Ruggell",
    countryCode: "LI",
    value: "06",
  },
  {
    label: "Planken",
    countryCode: "LI",
    value: "05",
  },
  {
    label: "Mauren",
    countryCode: "LI",
    value: "04",
  },
  {
    label: "Triesenberg",
    countryCode: "LI",
    value: "10",
  },
  {
    label: "Gamprin",
    countryCode: "LI",
    value: "03",
  },
  {
    label: "Balzers",
    countryCode: "LI",
    value: "01",
  },
  {
    label: "Triesen",
    countryCode: "LI",
    value: "09",
  },
  {
    label: "Brčko District",
    countryCode: "BA",
    value: "BRC",
  },
  {
    label: "Tuzla Canton",
    countryCode: "BA",
    value: "03",
  },
  {
    label: "Central Bosnia Canton",
    countryCode: "BA",
    value: "06",
  },
  {
    label: "Herzegovina-Neretva Canton",
    countryCode: "BA",
    value: "07",
  },
  {
    label: "Posavina Canton",
    countryCode: "BA",
    value: "02",
  },
  {
    label: "Una-Sana Canton",
    countryCode: "BA",
    value: "01",
  },
  {
    label: "Sarajevo Canton",
    countryCode: "BA",
    value: "09",
  },
  {
    label: "Federation of Bosnia and Herzegovina",
    countryCode: "BA",
    value: "BIH",
  },
  {
    label: "Zenica-Doboj Canton",
    countryCode: "BA",
    value: "04",
  },
  {
    label: "West Herzegovina Canton",
    countryCode: "BA",
    value: "08",
  },
  {
    label: "Republika Srpska",
    countryCode: "BA",
    value: "SRP",
  },
  {
    label: "Canton 10",
    countryCode: "BA",
    value: "10",
  },
  {
    label: "Bosnian Podrinje Canton",
    countryCode: "BA",
    value: "05",
  },
  {
    label: "Dakar",
    countryCode: "SN",
    value: "DK",
  },
  {
    label: "Kolda",
    countryCode: "SN",
    value: "KD",
  },
  {
    label: "Kaffrine",
    countryCode: "SN",
    value: "KA",
  },
  {
    label: "Matam",
    countryCode: "SN",
    value: "MT",
  },
  {
    label: "Saint-Louis",
    countryCode: "SN",
    value: "SL",
  },
  {
    label: "Ziguinchor",
    countryCode: "SN",
    value: "ZG",
  },
  {
    label: "Fatick",
    countryCode: "SN",
    value: "FK",
  },
  {
    label: "Diourbel Region",
    countryCode: "SN",
    value: "DB",
  },
  {
    label: "Kédougou",
    countryCode: "SN",
    value: "KE",
  },
  {
    label: "Sédhiou",
    countryCode: "SN",
    value: "SE",
  },
  {
    label: "Kaolack",
    countryCode: "SN",
    value: "KL",
  },
  {
    label: "Thiès Region",
    countryCode: "SN",
    value: "TH",
  },
  {
    label: "Louga",
    countryCode: "SN",
    value: "LG",
  },
  {
    label: "Tambacounda Region",
    countryCode: "SN",
    value: "TC",
  },
  {
    label: "Encamp",
    countryCode: "AD",
    value: "03",
  },
  {
    label: "Andorra la Vella",
    countryCode: "AD",
    value: "07",
  },
  {
    label: "Canillo",
    countryCode: "AD",
    value: "02",
  },
  {
    label: "Sant Julià de Lòria",
    countryCode: "AD",
    value: "06",
  },
  {
    label: "Ordino",
    countryCode: "AD",
    value: "05",
  },
  {
    label: "Escaldes-Engordany",
    countryCode: "AD",
    value: "08",
  },
  {
    label: "La Massana",
    countryCode: "AD",
    value: "04",
  },
  {
    label: "Mont Buxton",
    countryCode: "SC",
    value: "17",
  },
  {
    label: "La Digue",
    countryCode: "SC",
    value: "15",
  },
  {
    label: "Saint Louis",
    countryCode: "SC",
    value: "22",
  },
  {
    label: "Baie Lazare",
    countryCode: "SC",
    value: "06",
  },
  {
    label: "Mont Fleuri",
    countryCode: "SC",
    value: "18",
  },
  {
    label: "Les Mamelles",
    countryCode: "SC",
    value: "24",
  },
  {
    label: "Grand'Anse Mahé",
    countryCode: "SC",
    value: "13",
  },
  {
    label: "Roche Caiman",
    countryCode: "SC",
    value: "25",
  },
  {
    label: "Anse Royale",
    countryCode: "SC",
    value: "05",
  },
  {
    label: "Glacis",
    countryCode: "SC",
    value: "12",
  },
  {
    label: "Grand'Anse Praslin",
    countryCode: "SC",
    value: "14",
  },
  {
    label: "Bel Ombre",
    countryCode: "SC",
    value: "10",
  },
  {
    label: "Anse-aux-Pins",
    countryCode: "SC",
    value: "01",
  },
  {
    label: "Port Glaud",
    countryCode: "SC",
    value: "21",
  },
  {
    label: "Au Cap",
    countryCode: "SC",
    value: "04",
  },
  {
    label: "Takamaka",
    countryCode: "SC",
    value: "23",
  },
  {
    label: "Pointe La Rue",
    countryCode: "SC",
    value: "20",
  },
  {
    label: "Plaisance",
    countryCode: "SC",
    value: "19",
  },
  {
    label: "Beau Vallon",
    countryCode: "SC",
    value: "08",
  },
  {
    label: "Anse Boileau",
    countryCode: "SC",
    value: "02",
  },
  {
    label: "Baie Sainte Anne",
    countryCode: "SC",
    value: "07",
  },
  {
    label: "Bel Air",
    countryCode: "SC",
    value: "09",
  },
  {
    label: "La Rivière Anglaise",
    countryCode: "SC",
    value: "16",
  },
  {
    label: "Cascade",
    countryCode: "SC",
    value: "11",
  },
  {
    label: "Shaki",
    countryCode: "AZ",
    value: "SA",
  },
  {
    label: "Tartar District",
    countryCode: "AZ",
    value: "TAR",
  },
  {
    label: "Shirvan",
    countryCode: "AZ",
    value: "SR",
  },
  {
    label: "Qazakh District",
    countryCode: "AZ",
    value: "QAZ",
  },
  {
    label: "Sadarak District",
    countryCode: "AZ",
    value: "SAD",
  },
  {
    label: "Yevlakh District",
    countryCode: "AZ",
    value: "YEV",
  },
  {
    label: "Khojali District",
    countryCode: "AZ",
    value: "XCI",
  },
  {
    label: "Kalbajar District",
    countryCode: "AZ",
    value: "KAL",
  },
  {
    label: "Qakh District",
    countryCode: "AZ",
    value: "QAX",
  },
  {
    label: "Fizuli District",
    countryCode: "AZ",
    value: "FUZ",
  },
  {
    label: "Astara District",
    countryCode: "AZ",
    value: "AST",
  },
  {
    label: "Shamakhi District",
    countryCode: "AZ",
    value: "SMI",
  },
  {
    label: "Neftchala District",
    countryCode: "AZ",
    value: "NEF",
  },
  {
    label: "Goychay",
    countryCode: "AZ",
    value: "GOY",
  },
  {
    label: "Bilasuvar District",
    countryCode: "AZ",
    value: "BIL",
  },
  {
    label: "Tovuz District",
    countryCode: "AZ",
    value: "TOV",
  },
  {
    label: "Ordubad District",
    countryCode: "AZ",
    value: "ORD",
  },
  {
    label: "Sharur District",
    countryCode: "AZ",
    value: "SAR",
  },
  {
    label: "Samukh District",
    countryCode: "AZ",
    value: "SMX",
  },
  {
    label: "Khizi District",
    countryCode: "AZ",
    value: "XIZ",
  },
  {
    label: "Yevlakh",
    countryCode: "AZ",
    value: "YE",
  },
  {
    label: "Ujar District",
    countryCode: "AZ",
    value: "UCA",
  },
  {
    label: "Absheron District",
    countryCode: "AZ",
    value: "ABS",
  },
  {
    label: "Lachin District",
    countryCode: "AZ",
    value: "LAC",
  },
  {
    label: "Qabala District",
    countryCode: "AZ",
    value: "QAB",
  },
  {
    label: "Agstafa District",
    countryCode: "AZ",
    value: "AGA",
  },
  {
    label: "Imishli District",
    countryCode: "AZ",
    value: "IMI",
  },
  {
    label: "Salyan District",
    countryCode: "AZ",
    value: "SAL",
  },
  {
    label: "Lerik District",
    countryCode: "AZ",
    value: "LER",
  },
  {
    label: "Agsu District",
    countryCode: "AZ",
    value: "AGU",
  },
  {
    label: "Qubadli District",
    countryCode: "AZ",
    value: "QBI",
  },
  {
    label: "Kurdamir District",
    countryCode: "AZ",
    value: "KUR",
  },
  {
    label: "Yardymli District",
    countryCode: "AZ",
    value: "YAR",
  },
  {
    label: "Goranboy District",
    countryCode: "AZ",
    value: "GOR",
  },
  {
    label: "Baku",
    countryCode: "AZ",
    value: "BA",
  },
  {
    label: "Agdash District",
    countryCode: "AZ",
    value: "AGS",
  },
  {
    label: "Beylagan District",
    countryCode: "AZ",
    value: "BEY",
  },
  {
    label: "Masally District",
    countryCode: "AZ",
    value: "MAS",
  },
  {
    label: "Oghuz District",
    countryCode: "AZ",
    value: "OGU",
  },
  {
    label: "Saatly District",
    countryCode: "AZ",
    value: "SAT",
  },
  {
    label: "Lankaran District",
    countryCode: "AZ",
    value: "LA",
  },
  {
    label: "Agdam District",
    countryCode: "AZ",
    value: "AGM",
  },
  {
    label: "Balakan District",
    countryCode: "AZ",
    value: "BAL",
  },
  {
    label: "Dashkasan District",
    countryCode: "AZ",
    value: "DAS",
  },
  {
    label: "Nakhchivan Autonomous Republic",
    countryCode: "AZ",
    value: "NX",
  },
  {
    label: "Quba District",
    countryCode: "AZ",
    value: "QBA",
  },
  {
    label: "Ismailli District",
    countryCode: "AZ",
    value: "ISM",
  },
  {
    label: "Sabirabad District",
    countryCode: "AZ",
    value: "SAB",
  },
  {
    label: "Zaqatala District",
    countryCode: "AZ",
    value: "ZAQ",
  },
  {
    label: "Kangarli District",
    countryCode: "AZ",
    value: "KAN",
  },
  {
    label: "Martuni",
    countryCode: "AZ",
    value: "XVD",
  },
  {
    label: "Barda District",
    countryCode: "AZ",
    value: "BAR",
  },
  {
    label: "Jabrayil District",
    countryCode: "AZ",
    value: "CAB",
  },
  {
    label: "Hajigabul District",
    countryCode: "AZ",
    value: "HAC",
  },
  {
    label: "Julfa District",
    countryCode: "AZ",
    value: "CUL",
  },
  {
    label: "Gobustan District",
    countryCode: "AZ",
    value: "QOB",
  },
  {
    label: "Goygol District",
    countryCode: "AZ",
    value: "GYG",
  },
  {
    label: "Babek District",
    countryCode: "AZ",
    value: "BAB",
  },
  {
    label: "Zardab District",
    countryCode: "AZ",
    value: "ZAR",
  },
  {
    label: "Aghjabadi District",
    countryCode: "AZ",
    value: "AGC",
  },
  {
    label: "Jalilabad District",
    countryCode: "AZ",
    value: "CAL",
  },
  {
    label: "Shahbuz District",
    countryCode: "AZ",
    value: "SAH",
  },
  {
    label: "Mingachevir",
    countryCode: "AZ",
    value: "MI",
  },
  {
    label: "Zangilan District",
    countryCode: "AZ",
    value: "ZAN",
  },
  {
    label: "Sumqayit",
    countryCode: "AZ",
    value: "SM",
  },
  {
    label: "Shamkir District",
    countryCode: "AZ",
    value: "SKR",
  },
  {
    label: "Siazan District",
    countryCode: "AZ",
    value: "SIY",
  },
  {
    label: "Ganja",
    countryCode: "AZ",
    value: "GA",
  },
  {
    label: "Shaki District",
    countryCode: "AZ",
    value: "SAK",
  },
  {
    label: "Lankaran",
    countryCode: "AZ",
    value: "LAN",
  },
  {
    label: "Qusar District",
    countryCode: "AZ",
    value: "QUS",
  },
  {
    label: "Gədəbəy",
    countryCode: "AZ",
    value: "GAD",
  },
  {
    label: "Khachmaz District",
    countryCode: "AZ",
    value: "XAC",
  },
  {
    label: "Shabran District",
    countryCode: "AZ",
    value: "SBN",
  },
  {
    label: "Shusha District",
    countryCode: "AZ",
    value: "SUS",
  },
  {
    label: "Skrapar District",
    countryCode: "AL",
    value: "SK",
  },
  {
    label: "Kavajë District",
    countryCode: "AL",
    value: "KA",
  },
  {
    label: "Lezhë District",
    countryCode: "AL",
    value: "LE",
  },
  {
    label: "Librazhd District",
    countryCode: "AL",
    value: "LB",
  },
  {
    label: "Korçë District",
    countryCode: "AL",
    value: "KO",
  },
  {
    label: "Elbasan County",
    countryCode: "AL",
    value: "03",
  },
  {
    label: "Lushnjë District",
    countryCode: "AL",
    value: "LU",
  },
  {
    label: "Has District",
    countryCode: "AL",
    value: "HA",
  },
  {
    label: "Kukës County",
    countryCode: "AL",
    value: "07",
  },
  {
    label: "Malësi e Madhe District",
    countryCode: "AL",
    value: "MM",
  },
  {
    label: "Berat County",
    countryCode: "AL",
    value: "01",
  },
  {
    label: "Gjirokastër County",
    countryCode: "AL",
    value: "05",
  },
  {
    label: "Dibër District",
    countryCode: "AL",
    value: "DI",
  },
  {
    label: "Pogradec District",
    countryCode: "AL",
    value: "PG",
  },
  {
    label: "Bulqizë District",
    countryCode: "AL",
    value: "BU",
  },
  {
    label: "Devoll District",
    countryCode: "AL",
    value: "DV",
  },
  {
    label: "Lezhë County",
    countryCode: "AL",
    value: "08",
  },
  {
    label: "Dibër County",
    countryCode: "AL",
    value: "09",
  },
  {
    label: "Shkodër County",
    countryCode: "AL",
    value: "10",
  },
  {
    label: "Kuçovë District",
    countryCode: "AL",
    value: "KC",
  },
  {
    label: "Vlorë District",
    countryCode: "AL",
    value: "VL",
  },
  {
    label: "Krujë District",
    countryCode: "AL",
    value: "KR",
  },
  {
    label: "Tirana County",
    countryCode: "AL",
    value: "11",
  },
  {
    label: "Tepelenë District",
    countryCode: "AL",
    value: "TE",
  },
  {
    label: "Gramsh District",
    countryCode: "AL",
    value: "GR",
  },
  {
    label: "Delvinë District",
    countryCode: "AL",
    value: "DL",
  },
  {
    label: "Peqin District",
    countryCode: "AL",
    value: "PQ",
  },
  {
    label: "Pukë District",
    countryCode: "AL",
    value: "PU",
  },
  {
    label: "Gjirokastër District",
    countryCode: "AL",
    value: "GJ",
  },
  {
    label: "Kurbin District",
    countryCode: "AL",
    value: "KB",
  },
  {
    label: "Kukës District",
    countryCode: "AL",
    value: "KU",
  },
  {
    label: "Sarandë District",
    countryCode: "AL",
    value: "SR",
  },
  {
    label: "Përmet District",
    countryCode: "AL",
    value: "PR",
  },
  {
    label: "Shkodër District",
    countryCode: "AL",
    value: "SH",
  },
  {
    label: "Fier District",
    countryCode: "AL",
    value: "FR",
  },
  {
    label: "Kolonjë District",
    countryCode: "AL",
    value: "ER",
  },
  {
    label: "Berat District",
    countryCode: "AL",
    value: "BR",
  },
  {
    label: "Korçë County",
    countryCode: "AL",
    value: "06",
  },
  {
    label: "Fier County",
    countryCode: "AL",
    value: "04",
  },
  {
    label: "Durrës County",
    countryCode: "AL",
    value: "02",
  },
  {
    label: "Tirana District",
    countryCode: "AL",
    value: "TR",
  },
  {
    label: "Vlorë County",
    countryCode: "AL",
    value: "12",
  },
  {
    label: "Mat District",
    countryCode: "AL",
    value: "MT",
  },
  {
    label: "Tropojë District",
    countryCode: "AL",
    value: "TP",
  },
  {
    label: "Mallakastër District",
    countryCode: "AL",
    value: "MK",
  },
  {
    label: "Mirditë District",
    countryCode: "AL",
    value: "MR",
  },
  {
    label: "Durrës District",
    countryCode: "AL",
    value: "DR",
  },
  {
    label: "Sveti Nikole Municipality",
    countryCode: "MK",
    value: "69",
  },
  {
    label: "Kratovo Municipality",
    countryCode: "MK",
    value: "43",
  },
  {
    label: "Zajas Municipality",
    countryCode: "MK",
    value: "31",
  },
  {
    label: "Staro Nagoričane Municipality",
    countryCode: "MK",
    value: "71",
  },
  {
    label: "Češinovo-Obleševo Municipality",
    countryCode: "MK",
    value: "81",
  },
  {
    label: "Debarca Municipality",
    countryCode: "MK",
    value: "22",
  },
  {
    label: "Probištip Municipality",
    countryCode: "MK",
    value: "63",
  },
  {
    label: "Krivogaštani Municipality",
    countryCode: "MK",
    value: "45",
  },
  {
    label: "Gevgelija Municipality",
    countryCode: "MK",
    value: "18",
  },
  {
    label: "Bogdanci Municipality",
    countryCode: "MK",
    value: "05",
  },
  {
    label: "Vraneštica Municipality",
    countryCode: "MK",
    value: "15",
  },
  {
    label: "Veles Municipality",
    countryCode: "MK",
    value: "13",
  },
  {
    label: "Bosilovo Municipality",
    countryCode: "MK",
    value: "07",
  },
  {
    label: "Mogila Municipality",
    countryCode: "MK",
    value: "53",
  },
  {
    label: "Tearce Municipality",
    countryCode: "MK",
    value: "75",
  },
  {
    label: "Demir Kapija Municipality",
    countryCode: "MK",
    value: "24",
  },
  {
    label: "Aračinovo Municipality",
    countryCode: "MK",
    value: "02",
  },
  {
    label: "Drugovo Municipality",
    countryCode: "MK",
    value: "28",
  },
  {
    label: "Vasilevo Municipality",
    countryCode: "MK",
    value: "11",
  },
  {
    label: "Lipkovo Municipality",
    countryCode: "MK",
    value: "48",
  },
  {
    label: "Brvenica Municipality",
    countryCode: "MK",
    value: "08",
  },
  {
    label: "Štip Municipality",
    countryCode: "MK",
    value: "83",
  },
  {
    label: "Vevčani Municipality",
    countryCode: "MK",
    value: "12",
  },
  {
    label: "Tetovo Municipality",
    countryCode: "MK",
    value: "76",
  },
  {
    label: "Negotino Municipality",
    countryCode: "MK",
    value: "54",
  },
  {
    label: "Konče Municipality",
    countryCode: "MK",
    value: "41",
  },
  {
    label: "Prilep Municipality",
    countryCode: "MK",
    value: "62",
  },
  {
    label: "Saraj Municipality",
    countryCode: "MK",
    value: "68",
  },
  {
    label: "Želino Municipality",
    countryCode: "MK",
    value: "30",
  },
  {
    label: "Mavrovo and Rostuša Municipality",
    countryCode: "MK",
    value: "50",
  },
  {
    label: "Plasnica Municipality",
    countryCode: "MK",
    value: "61",
  },
  {
    label: "Valandovo Municipality",
    countryCode: "MK",
    value: "10",
  },
  {
    label: "Vinica Municipality",
    countryCode: "MK",
    value: "14",
  },
  {
    label: "Zrnovci Municipality",
    countryCode: "MK",
    value: "33",
  },
  {
    label: "Karbinci",
    countryCode: "MK",
    value: "37",
  },
  {
    label: "Dolneni Municipality",
    countryCode: "MK",
    value: "27",
  },
  {
    label: "Čaška Municipality",
    countryCode: "MK",
    value: "80",
  },
  {
    label: "Kriva Palanka Municipality",
    countryCode: "MK",
    value: "44",
  },
  {
    label: "Jegunovce Municipality",
    countryCode: "MK",
    value: "35",
  },
  {
    label: "Bitola Municipality",
    countryCode: "MK",
    value: "04",
  },
  {
    label: "Šuto Orizari Municipality",
    countryCode: "MK",
    value: "84",
  },
  {
    label: "Karpoš Municipality",
    countryCode: "MK",
    value: "38",
  },
  {
    label: "Oslomej Municipality",
    countryCode: "MK",
    value: "57",
  },
  {
    label: "Kumanovo Municipality",
    countryCode: "MK",
    value: "47",
  },
  {
    label: "Greater Skopje",
    countryCode: "MK",
    value: "85",
  },
  {
    label: "Pehčevo Municipality",
    countryCode: "MK",
    value: "60",
  },
  {
    label: "Kisela Voda Municipality",
    countryCode: "MK",
    value: "39",
  },
  {
    label: "Demir Hisar Municipality",
    countryCode: "MK",
    value: "25",
  },
  {
    label: "Kičevo Municipality",
    countryCode: "MK",
    value: "40",
  },
  {
    label: "Vrapčište Municipality",
    countryCode: "MK",
    value: "16",
  },
  {
    label: "Ilinden Municipality",
    countryCode: "MK",
    value: "34",
  },
  {
    label: "Rosoman Municipality",
    countryCode: "MK",
    value: "67",
  },
  {
    label: "Makedonski Brod Municipality",
    countryCode: "MK",
    value: "52",
  },
  {
    label: "Gostivar Municipality",
    countryCode: "MK",
    value: "19",
  },
  {
    label: "Butel Municipality",
    countryCode: "MK",
    value: "09",
  },
  {
    label: "Delčevo Municipality",
    countryCode: "MK",
    value: "23",
  },
  {
    label: "Novaci Municipality",
    countryCode: "MK",
    value: "55",
  },
  {
    label: "Dojran Municipality",
    countryCode: "MK",
    value: "26",
  },
  {
    label: "Petrovec Municipality",
    countryCode: "MK",
    value: "59",
  },
  {
    label: "Ohrid Municipality",
    countryCode: "MK",
    value: "58",
  },
  {
    label: "Struga Municipality",
    countryCode: "MK",
    value: "72",
  },
  {
    label: "Makedonska Kamenica Municipality",
    countryCode: "MK",
    value: "51",
  },
  {
    label: "Centar Municipality",
    countryCode: "MK",
    value: "77",
  },
  {
    label: "Aerodrom Municipality",
    countryCode: "MK",
    value: "01",
  },
  {
    label: "Čair Municipality",
    countryCode: "MK",
    value: "79",
  },
  {
    label: "Lozovo Municipality",
    countryCode: "MK",
    value: "49",
  },
  {
    label: "Zelenikovo Municipality",
    countryCode: "MK",
    value: "32",
  },
  {
    label: "Gazi Baba Municipality",
    countryCode: "MK",
    value: "17",
  },
  {
    label: "Gradsko Municipality",
    countryCode: "MK",
    value: "20",
  },
  {
    label: "Radoviš Municipality",
    countryCode: "MK",
    value: "64",
  },
  {
    label: "Strumica Municipality",
    countryCode: "MK",
    value: "73",
  },
  {
    label: "Studeničani Municipality",
    countryCode: "MK",
    value: "74",
  },
  {
    label: "Resen Municipality",
    countryCode: "MK",
    value: "66",
  },
  {
    label: "Kavadarci Municipality",
    countryCode: "MK",
    value: "36",
  },
  {
    label: "Kruševo Municipality",
    countryCode: "MK",
    value: "46",
  },
  {
    label: "Čučer-Sandevo Municipality",
    countryCode: "MK",
    value: "82",
  },
  {
    label: "Berovo Municipality",
    countryCode: "MK",
    value: "03",
  },
  {
    label: "Rankovce Municipality",
    countryCode: "MK",
    value: "65",
  },
  {
    label: "Novo Selo Municipality",
    countryCode: "MK",
    value: "56",
  },
  {
    label: "Sopište Municipality",
    countryCode: "MK",
    value: "70",
  },
  {
    label: "Centar Župa Municipality",
    countryCode: "MK",
    value: "78",
  },
  {
    label: "Bogovinje Municipality",
    countryCode: "MK",
    value: "06",
  },
  {
    label: "Gjorče Petrov Municipality",
    countryCode: "MK",
    value: "29",
  },
  {
    label: "Kočani Municipality",
    countryCode: "MK",
    value: "42",
  },
  {
    label: "Požega-Slavonia County",
    countryCode: "HR",
    value: "11",
  },
  {
    label: "Split-Dalmatia County",
    countryCode: "HR",
    value: "17",
  },
  {
    label: "Međimurje County",
    countryCode: "HR",
    value: "20",
  },
  {
    label: "Zadar County",
    countryCode: "HR",
    value: "13",
  },
  {
    label: "Dubrovnik-Neretva County",
    countryCode: "HR",
    value: "19",
  },
  {
    label: "Krapina-Zagorje County",
    countryCode: "HR",
    value: "02",
  },
  {
    label: "Šibenik-Knin County",
    countryCode: "HR",
    value: "15",
  },
  {
    label: "Lika-Senj County",
    countryCode: "HR",
    value: "09",
  },
  {
    label: "Virovitica-Podravina County",
    countryCode: "HR",
    value: "10",
  },
  {
    label: "Sisak-Moslavina County",
    countryCode: "HR",
    value: "03",
  },
  {
    label: "Bjelovar-Bilogora County",
    countryCode: "HR",
    value: "07",
  },
  {
    label: "Primorje-Gorski Kotar County",
    countryCode: "HR",
    value: "08",
  },
  {
    label: "Zagreb County",
    countryCode: "HR",
    value: "01",
  },
  {
    label: "Brod-Posavina County",
    countryCode: "HR",
    value: "12",
  },
  {
    label: "Zagreb",
    countryCode: "HR",
    value: "21",
  },
  {
    label: "Varaždin County",
    countryCode: "HR",
    value: "05",
  },
  {
    label: "Osijek-Baranja County",
    countryCode: "HR",
    value: "14",
  },
  {
    label: "Vukovar-Syrmia County",
    countryCode: "HR",
    value: "16",
  },
  {
    label: "Koprivnica-Križevci County",
    countryCode: "HR",
    value: "06",
  },
  {
    label: "Istria County",
    countryCode: "HR",
    value: "18",
  },
  {
    label: "Kyrenia District",
    countryCode: "CY",
    value: "06",
  },
  {
    label: "Nicosia District",
    countryCode: "CY",
    value: "01",
  },
  {
    label: "Paphos District",
    countryCode: "CY",
    value: "05",
  },
  {
    label: "Larnaca District",
    countryCode: "CY",
    value: "03",
  },
  {
    label: "Limassol District",
    countryCode: "CY",
    value: "02",
  },
  {
    label: "Famagusta District",
    countryCode: "CY",
    value: "04",
  },
  {
    label: "Rangpur Division",
    countryCode: "BD",
    value: "F",
  },
  {
    label: "Cox's Bazar District",
    countryCode: "BD",
    value: "11",
  },
  {
    label: "Bandarban District",
    countryCode: "BD",
    value: "01",
  },
  {
    label: "Rajshahi Division",
    countryCode: "BD",
    value: "E",
  },
  {
    label: "Pabna District",
    countryCode: "BD",
    value: "49",
  },
  {
    label: "Sherpur District",
    countryCode: "BD",
    value: "57",
  },
  {
    label: "Bhola District",
    countryCode: "BD",
    value: "07",
  },
  {
    label: "Jessore District",
    countryCode: "BD",
    value: "22",
  },
  {
    label: "Mymensingh Division",
    countryCode: "BD",
    value: "H",
  },
  {
    label: "Rangpur District",
    countryCode: "BD",
    value: "55",
  },
  {
    label: "Dhaka Division",
    countryCode: "BD",
    value: "C",
  },
  {
    label: "Chapai Nawabganj District",
    countryCode: "BD",
    value: "45",
  },
  {
    label: "Faridpur District",
    countryCode: "BD",
    value: "15",
  },
  {
    label: "Comilla District",
    countryCode: "BD",
    value: "08",
  },
  {
    label: "Netrokona District",
    countryCode: "BD",
    value: "41",
  },
  {
    label: "Sylhet Division",
    countryCode: "BD",
    value: "G",
  },
  {
    label: "Mymensingh District",
    countryCode: "BD",
    value: "34",
  },
  {
    label: "Sylhet District",
    countryCode: "BD",
    value: "60",
  },
  {
    label: "Chandpur District",
    countryCode: "BD",
    value: "09",
  },
  {
    label: "Narail District",
    countryCode: "BD",
    value: "43",
  },
  {
    label: "Narayanganj District",
    countryCode: "BD",
    value: "40",
  },
  {
    label: "Dhaka District",
    countryCode: "BD",
    value: "13",
  },
  {
    label: "Nilphamari District",
    countryCode: "BD",
    value: "46",
  },
  {
    label: "Rajbari District",
    countryCode: "BD",
    value: "53",
  },
  {
    label: "Kushtia District",
    countryCode: "BD",
    value: "30",
  },
  {
    label: "Khulna Division",
    countryCode: "BD",
    value: "D",
  },
  {
    label: "Meherpur District",
    countryCode: "BD",
    value: "39",
  },
  {
    label: "Patuakhali District",
    countryCode: "BD",
    value: "51",
  },
  {
    label: "Jhalokati District",
    countryCode: "BD",
    value: "25",
  },
  {
    label: "Kishoreganj District",
    countryCode: "BD",
    value: "26",
  },
  {
    label: "Lalmonirhat District",
    countryCode: "BD",
    value: "32",
  },
  {
    label: "Sirajganj District",
    countryCode: "BD",
    value: "59",
  },
  {
    label: "Tangail District",
    countryCode: "BD",
    value: "63",
  },
  {
    label: "Dinajpur District",
    countryCode: "BD",
    value: "14",
  },
  {
    label: "Barguna District",
    countryCode: "BD",
    value: "02",
  },
  {
    label: "Chittagong District",
    countryCode: "BD",
    value: "10",
  },
  {
    label: "Khagrachari District",
    countryCode: "BD",
    value: "29",
  },
  {
    label: "Natore District",
    countryCode: "BD",
    value: "44",
  },
  {
    label: "Chuadanga District",
    countryCode: "BD",
    value: "12",
  },
  {
    label: "Jhenaidah District",
    countryCode: "BD",
    value: "23",
  },
  {
    label: "Munshiganj District",
    countryCode: "BD",
    value: "35",
  },
  {
    label: "Pirojpur District",
    countryCode: "BD",
    value: "50",
  },
  {
    label: "Gopalganj District",
    countryCode: "BD",
    value: "17",
  },
  {
    label: "Kurigram District",
    countryCode: "BD",
    value: "28",
  },
  {
    label: "Moulvibazar District",
    countryCode: "BD",
    value: "38",
  },
  {
    label: "Gaibandha District",
    countryCode: "BD",
    value: "19",
  },
  {
    label: "Bagerhat District",
    countryCode: "BD",
    value: "05",
  },
  {
    label: "Bogra District",
    countryCode: "BD",
    value: "03",
  },
  {
    label: "Gazipur District",
    countryCode: "BD",
    value: "18",
  },
  {
    label: "Satkhira District",
    countryCode: "BD",
    value: "58",
  },
  {
    label: "Panchagarh District",
    countryCode: "BD",
    value: "52",
  },
  {
    label: "Shariatpur District",
    countryCode: "BD",
    value: "62",
  },
  {
    label: "Bahadia",
    countryCode: "BD",
    value: "33",
  },
  {
    label: "Chittagong Division",
    countryCode: "BD",
    value: "B",
  },
  {
    label: "Thakurgaon District",
    countryCode: "BD",
    value: "64",
  },
  {
    label: "Habiganj District",
    countryCode: "BD",
    value: "20",
  },
  {
    label: "Joypurhat District",
    countryCode: "BD",
    value: "24",
  },
  {
    label: "Barisal Division",
    countryCode: "BD",
    value: "A",
  },
  {
    label: "Jamalpur District",
    countryCode: "BD",
    value: "21",
  },
  {
    label: "Rangamati Hill District",
    countryCode: "BD",
    value: "56",
  },
  {
    label: "Brahmanbaria District",
    countryCode: "BD",
    value: "04",
  },
  {
    label: "Khulna District",
    countryCode: "BD",
    value: "27",
  },
  {
    label: "Sunamganj District",
    countryCode: "BD",
    value: "61",
  },
  {
    label: "Rajshahi District",
    countryCode: "BD",
    value: "54",
  },
  {
    label: "Naogaon District",
    countryCode: "BD",
    value: "48",
  },
  {
    label: "Noakhali District",
    countryCode: "BD",
    value: "47",
  },
  {
    label: "Feni District",
    countryCode: "BD",
    value: "16",
  },
  {
    label: "Madaripur District",
    countryCode: "BD",
    value: "36",
  },
  {
    label: "Barisal District",
    countryCode: "BD",
    value: "06",
  },
  {
    label: "Lakshmipur District",
    countryCode: "BD",
    value: "31",
  },
  {
    label: "Okayama Prefecture",
    countryCode: "JP",
    value: "33",
  },
  {
    label: "Chiba Prefecture",
    countryCode: "JP",
    value: "12",
  },
  {
    label: "Ōita Prefecture",
    countryCode: "JP",
    value: "44",
  },
  {
    label: "Tokyo",
    countryCode: "JP",
    value: "13",
  },
  {
    label: "Nara Prefecture",
    countryCode: "JP",
    value: "29",
  },
  {
    label: "Shizuoka Prefecture",
    countryCode: "JP",
    value: "22",
  },
  {
    label: "Shimane Prefecture",
    countryCode: "JP",
    value: "32",
  },
  {
    label: "Aichi Prefecture",
    countryCode: "JP",
    value: "23",
  },
  {
    label: "Hiroshima Prefecture",
    countryCode: "JP",
    value: "34",
  },
  {
    label: "Akita Prefecture",
    countryCode: "JP",
    value: "05",
  },
  {
    label: "Ishikawa Prefecture",
    countryCode: "JP",
    value: "17",
  },
  {
    label: "Hyōgo Prefecture",
    countryCode: "JP",
    value: "28",
  },
  {
    label: "Hokkaidō Prefecture",
    countryCode: "JP",
    value: "01",
  },
  {
    label: "Mie Prefecture",
    countryCode: "JP",
    value: "24",
  },
  {
    label: "Kyōto Prefecture",
    countryCode: "JP",
    value: "26",
  },
  {
    label: "Yamaguchi Prefecture",
    countryCode: "JP",
    value: "35",
  },
  {
    label: "Tokushima Prefecture",
    countryCode: "JP",
    value: "36",
  },
  {
    label: "Yamagata Prefecture",
    countryCode: "JP",
    value: "06",
  },
  {
    label: "Toyama Prefecture",
    countryCode: "JP",
    value: "16",
  },
  {
    label: "Aomori Prefecture",
    countryCode: "JP",
    value: "02",
  },
  {
    label: "Kagoshima Prefecture",
    countryCode: "JP",
    value: "46",
  },
  {
    label: "Niigata Prefecture",
    countryCode: "JP",
    value: "15",
  },
  {
    label: "Kanagawa Prefecture",
    countryCode: "JP",
    value: "14",
  },
  {
    label: "Nagano Prefecture",
    countryCode: "JP",
    value: "20",
  },
  {
    label: "Wakayama Prefecture",
    countryCode: "JP",
    value: "30",
  },
  {
    label: "Shiga Prefecture",
    countryCode: "JP",
    value: "25",
  },
  {
    label: "Kumamoto Prefecture",
    countryCode: "JP",
    value: "43",
  },
  {
    label: "Fukushima Prefecture",
    countryCode: "JP",
    value: "07",
  },
  {
    label: "Fukui Prefecture",
    countryCode: "JP",
    value: "18",
  },
  {
    label: "Nagasaki Prefecture",
    countryCode: "JP",
    value: "42",
  },
  {
    label: "Tottori Prefecture",
    countryCode: "JP",
    value: "31",
  },
  {
    label: "Ibaraki Prefecture",
    countryCode: "JP",
    value: "08",
  },
  {
    label: "Yamanashi Prefecture",
    countryCode: "JP",
    value: "19",
  },
  {
    label: "Okinawa Prefecture",
    countryCode: "JP",
    value: "47",
  },
  {
    label: "Tochigi Prefecture",
    countryCode: "JP",
    value: "09",
  },
  {
    label: "Miyazaki Prefecture",
    countryCode: "JP",
    value: "45",
  },
  {
    label: "Iwate Prefecture",
    countryCode: "JP",
    value: "03",
  },
  {
    label: "Miyagi Prefecture",
    countryCode: "JP",
    value: "04",
  },
  {
    label: "Gifu Prefecture",
    countryCode: "JP",
    value: "21",
  },
  {
    label: "Ōsaka Prefecture",
    countryCode: "JP",
    value: "27",
  },
  {
    label: "Saitama Prefecture",
    countryCode: "JP",
    value: "11",
  },
  {
    label: "Fukuoka Prefecture",
    countryCode: "JP",
    value: "40",
  },
  {
    label: "Gunma Prefecture",
    countryCode: "JP",
    value: "10",
  },
  {
    label: "Saga Prefecture",
    countryCode: "JP",
    value: "41",
  },
  {
    label: "Kagawa Prefecture",
    countryCode: "JP",
    value: "37",
  },
  {
    label: "Ehime Prefecture",
    countryCode: "JP",
    value: "38",
  },
  {
    label: "Ontario",
    countryCode: "CA",
    value: "ON",
  },
  {
    label: "Manitoba",
    countryCode: "CA",
    value: "MB",
  },
  {
    label: "New Brunswick",
    countryCode: "CA",
    value: "NB",
  },
  {
    label: "Yukon",
    countryCode: "CA",
    value: "YT",
  },
  {
    label: "Saskatchewan",
    countryCode: "CA",
    value: "SK",
  },
  {
    label: "Prince Edward Island",
    countryCode: "CA",
    value: "PE",
  },
  {
    label: "Alberta",
    countryCode: "CA",
    value: "AB",
  },
  {
    label: "Quebec",
    countryCode: "CA",
    value: "QC",
  },
  {
    label: "Nova Scotia",
    countryCode: "CA",
    value: "NS",
  },
  {
    label: "British Columbia",
    countryCode: "CA",
    value: "BC",
  },
  {
    label: "Nunavut",
    countryCode: "CA",
    value: "NU",
  },
  {
    label: "Newfoundland and Labrador",
    countryCode: "CA",
    value: "NL",
  },
  {
    label: "Northwest Territories",
    countryCode: "CA",
    value: "NT",
  },
  {
    label: "White Nile",
    countryCode: "SD",
    value: "NW",
  },
  {
    label: "Red Sea",
    countryCode: "SD",
    value: "RS",
  },
  {
    label: "Khartoum",
    countryCode: "SD",
    value: "KH",
  },
  {
    label: "Sennar",
    countryCode: "SD",
    value: "SI",
  },
  {
    label: "South Kordofan",
    countryCode: "SD",
    value: "KS",
  },
  {
    label: "Kassala",
    countryCode: "SD",
    value: "KA",
  },
  {
    label: "Al Jazirah",
    countryCode: "SD",
    value: "GZ",
  },
  {
    label: "Al Qadarif",
    countryCode: "SD",
    value: "GD",
  },
  {
    label: "Blue Nile",
    countryCode: "SD",
    value: "NB",
  },
  {
    label: "West Darfur",
    countryCode: "SD",
    value: "DW",
  },
  {
    label: "West Kordofan",
    countryCode: "SD",
    value: "GK",
  },
  {
    label: "North Darfur",
    countryCode: "SD",
    value: "DN",
  },
  {
    label: "River Nile",
    countryCode: "SD",
    value: "NR",
  },
  {
    label: "East Darfur",
    countryCode: "SD",
    value: "DE",
  },
  {
    label: "North Kordofan",
    countryCode: "SD",
    value: "KN",
  },
  {
    label: "South Darfur",
    countryCode: "SD",
    value: "DS",
  },
  {
    label: "Northern",
    countryCode: "SD",
    value: "NO",
  },
  {
    label: "Central Darfur",
    countryCode: "SD",
    value: "DC",
  },
  {
    label: "Khelvachauri Municipality",
    countryCode: "GE",
    value: "29",
  },
  {
    label: "Senaki Municipality",
    countryCode: "GE",
    value: "50",
  },
  {
    label: "Tbilisi",
    countryCode: "GE",
    value: "TB",
  },
  {
    label: "Adjara",
    countryCode: "GE",
    value: "AJ",
  },
  {
    label: "Autonomous Republic of Abkhazia",
    countryCode: "GE",
    value: "AB",
  },
  {
    label: "Mtskheta-Mtianeti",
    countryCode: "GE",
    value: "MM",
  },
  {
    label: "Shida Kartli",
    countryCode: "GE",
    value: "SK",
  },
  {
    label: "Kvemo Kartli",
    countryCode: "GE",
    value: "KK",
  },
  {
    label: "Imereti",
    countryCode: "GE",
    value: "IM",
  },
  {
    label: "Samtskhe-Javakheti",
    countryCode: "GE",
    value: "SJ",
  },
  {
    label: "Guria",
    countryCode: "GE",
    value: "GU",
  },
  {
    label: "Samegrelo-Zemo Svaneti",
    countryCode: "GE",
    value: "SZ",
  },
  {
    label: "Racha-Lechkhumi and Kvemo Svaneti",
    countryCode: "GE",
    value: "RL",
  },
  {
    label: "Kakheti",
    countryCode: "GE",
    value: "KA",
  },
  {
    label: "Northern Province",
    countryCode: "SL",
    value: "N",
  },
  {
    label: "Southern Province",
    countryCode: "SL",
    value: "S",
  },
  {
    label: "Western Area",
    countryCode: "SL",
    value: "W",
  },
  {
    label: "Eastern Province",
    countryCode: "SL",
    value: "E",
  },
  {
    label: "Hiran",
    countryCode: "SO",
    value: "HI",
  },
  {
    label: "Mudug",
    countryCode: "SO",
    value: "MU",
  },
  {
    label: "Bakool",
    countryCode: "SO",
    value: "BK",
  },
  {
    label: "Galguduud",
    countryCode: "SO",
    value: "GA",
  },
  {
    label: "Sanaag Region",
    countryCode: "SO",
    value: "SA",
  },
  {
    label: "Nugal",
    countryCode: "SO",
    value: "NU",
  },
  {
    label: "Lower Shebelle",
    countryCode: "SO",
    value: "SH",
  },
  {
    label: "Middle Juba",
    countryCode: "SO",
    value: "JD",
  },
  {
    label: "Middle Shebelle",
    countryCode: "SO",
    value: "SD",
  },
  {
    label: "Lower Juba",
    countryCode: "SO",
    value: "JH",
  },
  {
    label: "Awdal Region",
    countryCode: "SO",
    value: "AW",
  },
  {
    label: "Bay",
    countryCode: "SO",
    value: "BY",
  },
  {
    label: "Banaadir",
    countryCode: "SO",
    value: "BN",
  },
  {
    label: "Gedo",
    countryCode: "SO",
    value: "GE",
  },
  {
    label: "Togdheer Region",
    countryCode: "SO",
    value: "TO",
  },
  {
    label: "Bari",
    countryCode: "SO",
    value: "BR",
  },
  {
    label: "Northern Cape",
    countryCode: "ZA",
    value: "NC",
  },
  {
    label: "Free State",
    countryCode: "ZA",
    value: "FS",
  },
  {
    label: "Limpopo",
    countryCode: "ZA",
    value: "LP",
  },
  {
    label: "North West",
    countryCode: "ZA",
    value: "NW",
  },
  {
    label: "KwaZulu-Natal",
    countryCode: "ZA",
    value: "KZN",
  },
  {
    label: "Gauteng",
    countryCode: "ZA",
    value: "GP",
  },
  {
    label: "Mpumalanga",
    countryCode: "ZA",
    value: "MP",
  },
  {
    label: "Eastern Cape",
    countryCode: "ZA",
    value: "EC",
  },
  {
    label: "Western Cape",
    countryCode: "ZA",
    value: "WC",
  },
  {
    label: "Chontales Department",
    countryCode: "NI",
    value: "CO",
  },
  {
    label: "Managua Department",
    countryCode: "NI",
    value: "MN",
  },
  {
    label: "Rivas Department",
    countryCode: "NI",
    value: "RI",
  },
  {
    label: "Granada Department",
    countryCode: "NI",
    value: "GR",
  },
  {
    label: "León Department",
    countryCode: "NI",
    value: "LE",
  },
  {
    label: "Estelí Department",
    countryCode: "NI",
    value: "ES",
  },
  {
    label: "Boaco Department",
    countryCode: "NI",
    value: "BO",
  },
  {
    label: "Matagalpa Department",
    countryCode: "NI",
    value: "MT",
  },
  {
    label: "Madriz Department",
    countryCode: "NI",
    value: "MD",
  },
  {
    label: "Río San Juan Department",
    countryCode: "NI",
    value: "SJ",
  },
  {
    label: "Carazo Department",
    countryCode: "NI",
    value: "CA",
  },
  {
    label: "North Caribbean Coast Autonomous Region",
    countryCode: "NI",
    value: "AN",
  },
  {
    label: "South Caribbean Coast Autonomous Region",
    countryCode: "NI",
    value: "AS",
  },
  {
    label: "Masaya Department",
    countryCode: "NI",
    value: "MS",
  },
  {
    label: "Chinandega Department",
    countryCode: "NI",
    value: "CI",
  },
  {
    label: "Jinotega Department",
    countryCode: "NI",
    value: "JI",
  },
  {
    label: "Karak Governorate",
    countryCode: "JO",
    value: "KA",
  },
  {
    label: "Tafilah Governorate",
    countryCode: "JO",
    value: "AT",
  },
  {
    label: "Madaba Governorate",
    countryCode: "JO",
    value: "MD",
  },
  {
    label: "Aqaba Governorate",
    countryCode: "JO",
    value: "AQ",
  },
  {
    label: "Irbid Governorate",
    countryCode: "JO",
    value: "IR",
  },
  {
    label: "Balqa Governorate",
    countryCode: "JO",
    value: "BA",
  },
  {
    label: "Mafraq Governorate",
    countryCode: "JO",
    value: "MA",
  },
  {
    label: "Ajloun Governorate",
    countryCode: "JO",
    value: "AJ",
  },
  {
    label: "Ma'an Governorate",
    countryCode: "JO",
    value: "MN",
  },
  {
    label: "Amman Governorate",
    countryCode: "JO",
    value: "AM",
  },
  {
    label: "Jerash Governorate",
    countryCode: "JO",
    value: "JA",
  },
  {
    label: "Zarqa Governorate",
    countryCode: "JO",
    value: "AZ",
  },
  {
    label: "Manzini District",
    countryCode: "SZ",
    value: "MA",
  },
  {
    label: "Hhohho District",
    countryCode: "SZ",
    value: "HH",
  },
  {
    label: "Lubombo District",
    countryCode: "SZ",
    value: "LU",
  },
  {
    label: "Shiselweni District",
    countryCode: "SZ",
    value: "SH",
  },
  {
    label: "Al Jahra Governorate",
    countryCode: "KW",
    value: "JA",
  },
  {
    label: "Hawalli Governorate",
    countryCode: "KW",
    value: "HA",
  },
  {
    label: "Mubarak Al-Kabeer Governorate",
    countryCode: "KW",
    value: "MU",
  },
  {
    label: "Al Farwaniyah Governorate",
    countryCode: "KW",
    value: "FA",
  },
  {
    label: "Capital Governorate",
    countryCode: "KW",
    value: "KU",
  },
  {
    label: "Al Ahmadi Governorate",
    countryCode: "KW",
    value: "AH",
  },
  {
    label: "Luang Prabang Province",
    countryCode: "LA",
    value: "LP",
  },
  {
    label: "Vientiane Prefecture",
    countryCode: "LA",
    value: "VT",
  },
  {
    label: "Vientiane Province",
    countryCode: "LA",
    value: "VI",
  },
  {
    label: "Salavan Province",
    countryCode: "LA",
    value: "SL",
  },
  {
    label: "Attapeu Province",
    countryCode: "LA",
    value: "AT",
  },
  {
    label: "Xaisomboun Province",
    countryCode: "LA",
    value: "XS",
  },
  {
    label: "Sekong Province",
    countryCode: "LA",
    value: "XE",
  },
  {
    label: "Bolikhamsai Province",
    countryCode: "LA",
    value: "BL",
  },
  {
    label: "Khammouane Province",
    countryCode: "LA",
    value: "KH",
  },
  {
    label: "Phongsaly Province",
    countryCode: "LA",
    value: "PH",
  },
  {
    label: "Oudomxay Province",
    countryCode: "LA",
    value: "OU",
  },
  {
    label: "Houaphanh Province",
    countryCode: "LA",
    value: "HO",
  },
  {
    label: "Savannakhet Province",
    countryCode: "LA",
    value: "SV",
  },
  {
    label: "Bokeo Province",
    countryCode: "LA",
    value: "BK",
  },
  {
    label: "Luang Namtha Province",
    countryCode: "LA",
    value: "LM",
  },
  {
    label: "Sainyabuli Province",
    countryCode: "LA",
    value: "XA",
  },
  {
    label: "Xaisomboun",
    countryCode: "LA",
    value: "XN",
  },
  {
    label: "Xiangkhouang Province",
    countryCode: "LA",
    value: "XI",
  },
  {
    label: "Champasak Province",
    countryCode: "LA",
    value: "CH",
  },
  {
    label: "Talas Region",
    countryCode: "KG",
    value: "T",
  },
  {
    label: "Batken Region",
    countryCode: "KG",
    value: "B",
  },
  {
    label: "Naryn Region",
    countryCode: "KG",
    value: "N",
  },
  {
    label: "Jalal-Abad Region",
    countryCode: "KG",
    value: "J",
  },
  {
    label: "Bishkek",
    countryCode: "KG",
    value: "GB",
  },
  {
    label: "Issyk-Kul Region",
    countryCode: "KG",
    value: "Y",
  },
  {
    label: "Osh",
    countryCode: "KG",
    value: "GO",
  },
  {
    label: "Chuy Region",
    countryCode: "KG",
    value: "C",
  },
  {
    label: "Osh Region",
    countryCode: "KG",
    value: "O",
  },
  {
    label: "Trøndelag",
    countryCode: "NO",
    value: "50",
  },
  {
    label: "Oslo",
    countryCode: "NO",
    value: "03",
  },
  {
    label: "Vestfold",
    countryCode: "NO",
    value: "07",
  },
  {
    label: "Oppland",
    countryCode: "NO",
    value: "05",
  },
  {
    label: "Sør-Trøndelag",
    countryCode: "NO",
    value: "16",
  },
  {
    label: "Buskerud",
    countryCode: "NO",
    value: "06",
  },
  {
    label: "Nord-Trøndelag",
    countryCode: "NO",
    value: "17",
  },
  {
    label: "Svalbard",
    countryCode: "NO",
    value: "21",
  },
  {
    label: "Vest-Agder",
    countryCode: "NO",
    value: "10",
  },
  {
    label: "Troms",
    countryCode: "NO",
    value: "19",
  },
  {
    label: "Finnmark",
    countryCode: "NO",
    value: "20",
  },
  {
    label: "Akershus",
    countryCode: "NO",
    value: "02",
  },
  {
    label: "Sogn og Fjordane",
    countryCode: "NO",
    value: "14",
  },
  {
    label: "Hedmark",
    countryCode: "NO",
    value: "04",
  },
  {
    label: "Møre og Romsdal",
    countryCode: "NO",
    value: "15",
  },
  {
    label: "Rogaland",
    countryCode: "NO",
    value: "11",
  },
  {
    label: "Østfold",
    countryCode: "NO",
    value: "01",
  },
  {
    label: "Hordaland",
    countryCode: "NO",
    value: "12",
  },
  {
    label: "Telemark",
    countryCode: "NO",
    value: "08",
  },
  {
    label: "Nordland",
    countryCode: "NO",
    value: "18",
  },
  {
    label: "Jan Mayen",
    countryCode: "NO",
    value: "22",
  },
  {
    label: "Hódmezővásárhely",
    countryCode: "HU",
    value: "HV",
  },
  {
    label: "Érd",
    countryCode: "HU",
    value: "ER",
  },
  {
    label: "Szeged",
    countryCode: "HU",
    value: "SD",
  },
  {
    label: "Nagykanizsa",
    countryCode: "HU",
    value: "NK",
  },
  {
    label: "Csongrád County",
    countryCode: "HU",
    value: "CS",
  },
  {
    label: "Debrecen",
    countryCode: "HU",
    value: "DE",
  },
  {
    label: "Székesfehérvár",
    countryCode: "HU",
    value: "SF",
  },
  {
    label: "Nyíregyháza",
    countryCode: "HU",
    value: "NY",
  },
  {
    label: "Somogy County",
    countryCode: "HU",
    value: "SO",
  },
  {
    label: "Békéscsaba",
    countryCode: "HU",
    value: "BC",
  },
  {
    label: "Eger",
    countryCode: "HU",
    value: "EG",
  },
  {
    label: "Tolna County",
    countryCode: "HU",
    value: "TO",
  },
  {
    label: "Vas County",
    countryCode: "HU",
    value: "VA",
  },
  {
    label: "Heves County",
    countryCode: "HU",
    value: "HE",
  },
  {
    label: "Győr",
    countryCode: "HU",
    value: "GY",
  },
  {
    label: "Győr-Moson-Sopron County",
    countryCode: "HU",
    value: "GS",
  },
  {
    label: "Jász-Nagykun-Szolnok County",
    countryCode: "HU",
    value: "JN",
  },
  {
    label: "Fejér County",
    countryCode: "HU",
    value: "FE",
  },
  {
    label: "Szabolcs-Szatmár-Bereg County",
    countryCode: "HU",
    value: "SZ",
  },
  {
    label: "Zala County",
    countryCode: "HU",
    value: "ZA",
  },
  {
    label: "Szolnok",
    countryCode: "HU",
    value: "SK",
  },
  {
    label: "Bács-Kiskun County",
    countryCode: "HU",
    value: "BK",
  },
  {
    label: "Dunaújváros",
    countryCode: "HU",
    value: "DU",
  },
  {
    label: "Zalaegerszeg",
    countryCode: "HU",
    value: "ZE",
  },
  {
    label: "Nógrád County",
    countryCode: "HU",
    value: "NO",
  },
  {
    label: "Szombathely",
    countryCode: "HU",
    value: "SH",
  },
  {
    label: "Pécs",
    countryCode: "HU",
    value: "PS",
  },
  {
    label: "Veszprém County",
    countryCode: "HU",
    value: "VE",
  },
  {
    label: "Baranya County",
    countryCode: "HU",
    value: "BA",
  },
  {
    label: "Kecskemét",
    countryCode: "HU",
    value: "KM",
  },
  {
    label: "Sopron",
    countryCode: "HU",
    value: "SN",
  },
  {
    label: "Borsod-Abaúj-Zemplén County",
    countryCode: "HU",
    value: "BZ",
  },
  {
    label: "Pest County",
    countryCode: "HU",
    value: "PE",
  },
  {
    label: "Békés County",
    countryCode: "HU",
    value: "BE",
  },
  {
    label: "Szekszárd",
    countryCode: "HU",
    value: "SS",
  },
  {
    label: "Veszprém",
    countryCode: "HU",
    value: "VM",
  },
  {
    label: "Hajdú-Bihar County",
    countryCode: "HU",
    value: "HB",
  },
  {
    label: "Budapest",
    countryCode: "HU",
    value: "BU",
  },
  {
    label: "Miskolc",
    countryCode: "HU",
    value: "MI",
  },
  {
    label: "Tatabánya",
    countryCode: "HU",
    value: "TB",
  },
  {
    label: "Kaposvár",
    countryCode: "HU",
    value: "KV",
  },
  {
    label: "Salgótarján",
    countryCode: "HU",
    value: "ST",
  },
  {
    label: "County Tipperary",
    countryCode: "IE",
    value: "TA",
  },
  {
    label: "County Sligo",
    countryCode: "IE",
    value: "SO",
  },
  {
    label: "County Donegal",
    countryCode: "IE",
    value: "DL",
  },
  {
    label: "County Dublin",
    countryCode: "IE",
    value: "D",
  },
  {
    label: "Leinster",
    countryCode: "IE",
    value: "L",
  },
  {
    label: "County Cork",
    countryCode: "IE",
    value: "CO",
  },
  {
    label: "County Monaghan",
    countryCode: "IE",
    value: "MN",
  },
  {
    label: "County Longford",
    countryCode: "IE",
    value: "LD",
  },
  {
    label: "County Kerry",
    countryCode: "IE",
    value: "KY",
  },
  {
    label: "County Offaly",
    countryCode: "IE",
    value: "OY",
  },
  {
    label: "County Galway",
    countryCode: "IE",
    value: "G",
  },
  {
    label: "Munster",
    countryCode: "IE",
    value: "M",
  },
  {
    label: "County Roscommon",
    countryCode: "IE",
    value: "RN",
  },
  {
    label: "County Kildare",
    countryCode: "IE",
    value: "KE",
  },
  {
    label: "County Louth",
    countryCode: "IE",
    value: "LH",
  },
  {
    label: "County Mayo",
    countryCode: "IE",
    value: "MO",
  },
  {
    label: "County Wicklow",
    countryCode: "IE",
    value: "WW",
  },
  {
    label: "Ulster",
    countryCode: "IE",
    value: "U",
  },
  {
    label: "Connacht",
    countryCode: "IE",
    value: "C",
  },
  {
    label: "County Cavan",
    countryCode: "IE",
    value: "CN",
  },
  {
    label: "County Waterford",
    countryCode: "IE",
    value: "WD",
  },
  {
    label: "County Kilkenny",
    countryCode: "IE",
    value: "KK",
  },
  {
    label: "County Clare",
    countryCode: "IE",
    value: "CE",
  },
  {
    label: "County Meath",
    countryCode: "IE",
    value: "MH",
  },
  {
    label: "County Wexford",
    countryCode: "IE",
    value: "WX",
  },
  {
    label: "County Limerick",
    countryCode: "IE",
    value: "LK",
  },
  {
    label: "County Carlow",
    countryCode: "IE",
    value: "CW",
  },
  {
    label: "County Laois",
    countryCode: "IE",
    value: "LS",
  },
  {
    label: "County Westmeath",
    countryCode: "IE",
    value: "WH",
  },
  {
    label: "Djelfa",
    countryCode: "DZ",
    value: "17",
  },
  {
    label: "El Oued",
    countryCode: "DZ",
    value: "39",
  },
  {
    label: "El Tarf",
    countryCode: "DZ",
    value: "36",
  },
  {
    label: "Oran",
    countryCode: "DZ",
    value: "31",
  },
  {
    label: "Naama",
    countryCode: "DZ",
    value: "45",
  },
  {
    label: "Annaba",
    countryCode: "DZ",
    value: "23",
  },
  {
    label: "Bouïra",
    countryCode: "DZ",
    value: "10",
  },
  {
    label: "Chlef",
    countryCode: "DZ",
    value: "02",
  },
  {
    label: "Tiaret",
    countryCode: "DZ",
    value: "14",
  },
  {
    label: "Tlemcen",
    countryCode: "DZ",
    value: "13",
  },
  {
    label: "Béchar",
    countryCode: "DZ",
    value: "08",
  },
  {
    label: "Médéa",
    countryCode: "DZ",
    value: "26",
  },
  {
    label: "Skikda",
    countryCode: "DZ",
    value: "21",
  },
  {
    label: "Blida",
    countryCode: "DZ",
    value: "09",
  },
  {
    label: "Illizi",
    countryCode: "DZ",
    value: "33",
  },
  {
    label: "Jijel",
    countryCode: "DZ",
    value: "18",
  },
  {
    label: "Biskra",
    countryCode: "DZ",
    value: "07",
  },
  {
    label: "Tipasa",
    countryCode: "DZ",
    value: "42",
  },
  {
    label: "Bordj Bou Arréridj",
    countryCode: "DZ",
    value: "34",
  },
  {
    label: "Tébessa",
    countryCode: "DZ",
    value: "12",
  },
  {
    label: "Adrar",
    countryCode: "DZ",
    value: "01",
  },
  {
    label: "Aïn Defla",
    countryCode: "DZ",
    value: "44",
  },
  {
    label: "Tindouf",
    countryCode: "DZ",
    value: "37",
  },
  {
    label: "Constantine",
    countryCode: "DZ",
    value: "25",
  },
  {
    label: "Aïn Témouchent",
    countryCode: "DZ",
    value: "46",
  },
  {
    label: "Saïda",
    countryCode: "DZ",
    value: "20",
  },
  {
    label: "Mascara",
    countryCode: "DZ",
    value: "29",
  },
  {
    label: "Boumerdès",
    countryCode: "DZ",
    value: "35",
  },
  {
    label: "Khenchela",
    countryCode: "DZ",
    value: "40",
  },
  {
    label: "Ghardaïa",
    countryCode: "DZ",
    value: "47",
  },
  {
    label: "Béjaïa",
    countryCode: "DZ",
    value: "06",
  },
  {
    label: "El Bayadh",
    countryCode: "DZ",
    value: "32",
  },
  {
    label: "Relizane",
    countryCode: "DZ",
    value: "48",
  },
  {
    label: "Tizi Ouzou",
    countryCode: "DZ",
    value: "15",
  },
  {
    label: "Mila",
    countryCode: "DZ",
    value: "43",
  },
  {
    label: "Tissemsilt",
    countryCode: "DZ",
    value: "38",
  },
  {
    label: "M'Sila",
    countryCode: "DZ",
    value: "28",
  },
  {
    label: "Tamanghasset",
    countryCode: "DZ",
    value: "11",
  },
  {
    label: "Oum El Bouaghi",
    countryCode: "DZ",
    value: "04",
  },
  {
    label: "Guelma",
    countryCode: "DZ",
    value: "24",
  },
  {
    label: "Laghouat",
    countryCode: "DZ",
    value: "03",
  },
  {
    label: "Ouargla",
    countryCode: "DZ",
    value: "30",
  },
  {
    label: "Mostaganem",
    countryCode: "DZ",
    value: "27",
  },
  {
    label: "Sétif",
    countryCode: "DZ",
    value: "19",
  },
  {
    label: "Batna",
    countryCode: "DZ",
    value: "05",
  },
  {
    label: "Souk Ahras",
    countryCode: "DZ",
    value: "41",
  },
  {
    label: "Algiers",
    countryCode: "DZ",
    value: "16",
  },
  {
    label: "Burgos Province",
    countryCode: "ES",
    value: "BU",
  },
  {
    label: "Salamanca Province",
    countryCode: "ES",
    value: "SA",
  },
  {
    label: "Palencia Province",
    countryCode: "ES",
    value: "P",
  },
  {
    label: "Madrid",
    countryCode: "ES",
    value: "MD",
  },
  {
    label: "Melilla",
    countryCode: "ES",
    value: "ML",
  },
  {
    label: "Asturias",
    countryCode: "ES",
    value: "AS",
  },
  {
    label: "Zamora Province",
    countryCode: "ES",
    value: "ZA",
  },
  {
    label: "Galicia",
    countryCode: "ES",
    value: "GA",
  },
  {
    label: "Cantabria",
    countryCode: "ES",
    value: "CB",
  },
  {
    label: "La Rioja",
    countryCode: "ES",
    value: "RI",
  },
  {
    label: "Balearic Islands",
    countryCode: "ES",
    value: "PM",
  },
  {
    label: "Valencia",
    countryCode: "ES",
    value: "VC",
  },
  {
    label: "Murcia",
    countryCode: "ES",
    value: "MC",
  },
  {
    label: "Aragon",
    countryCode: "ES",
    value: "AR",
  },
  {
    label: "Valladolid Province",
    countryCode: "ES",
    value: "VA",
  },
  {
    label: "Castile and León",
    countryCode: "ES",
    value: "CL",
  },
  {
    label: "Canary Islands",
    countryCode: "ES",
    value: "CN",
  },
  {
    label: "Ávila",
    countryCode: "ES",
    value: "AV",
  },
  {
    label: "Extremadura",
    countryCode: "ES",
    value: "EX",
  },
  {
    label: "Basque Country",
    countryCode: "ES",
    value: "PV",
  },
  {
    label: "Segovia Province",
    countryCode: "ES",
    value: "SG",
  },
  {
    label: "Andalusia",
    countryCode: "ES",
    value: "AN",
  },
  {
    label: "Léon",
    countryCode: "ES",
    value: "LE",
  },
  {
    label: "Catalonia",
    countryCode: "ES",
    value: "CT",
  },
  {
    label: "Navarra",
    countryCode: "ES",
    value: "NC",
  },
  {
    label: "Castilla La Mancha",
    countryCode: "ES",
    value: "CM",
  },
  {
    label: "Ceuta",
    countryCode: "ES",
    value: "CE",
  },
  {
    label: "Soria Province",
    countryCode: "ES",
    value: "SO",
  },
  {
    label: "Guanacaste Province",
    countryCode: "CR",
    value: "G",
  },
  {
    label: "Puntarenas Province",
    countryCode: "CR",
    value: "P",
  },
  {
    label: "Provincia de Cartago",
    countryCode: "CR",
    value: "C",
  },
  {
    label: "Heredia Province",
    countryCode: "CR",
    value: "H",
  },
  {
    label: "Limón Province",
    countryCode: "CR",
    value: "L",
  },
  {
    label: "San José Province",
    countryCode: "CR",
    value: "SJ",
  },
  {
    label: "Alajuela Province",
    countryCode: "CR",
    value: "A",
  },
  {
    label: "Brunei-Muara District",
    countryCode: "BN",
    value: "BM",
  },
  {
    label: "Belait District",
    countryCode: "BN",
    value: "BE",
  },
  {
    label: "Temburong District",
    countryCode: "BN",
    value: "TE",
  },
  {
    label: "Tutong District",
    countryCode: "BN",
    value: "TU",
  },
  {
    label: "Saint Philip",
    countryCode: "BB",
    value: "10",
  },
  {
    label: "Saint Lucy",
    countryCode: "BB",
    value: "07",
  },
  {
    label: "Saint Peter",
    countryCode: "BB",
    value: "09",
  },
  {
    label: "Saint Joseph",
    countryCode: "BB",
    value: "06",
  },
  {
    label: "Saint James",
    countryCode: "BB",
    value: "04",
  },
  {
    label: "Saint Thomas",
    countryCode: "BB",
    value: "11",
  },
  {
    label: "Saint George",
    countryCode: "BB",
    value: "03",
  },
  {
    label: "Saint John",
    countryCode: "BB",
    value: "05",
  },
  {
    label: "Christ Church",
    countryCode: "BB",
    value: "01",
  },
  {
    label: "Saint Andrew",
    countryCode: "BB",
    value: "02",
  },
  {
    label: "Saint Michael",
    countryCode: "BB",
    value: "08",
  },
  {
    label: "Ta'izz Governorate",
    countryCode: "YE",
    value: "TA",
  },
  {
    label: "Sana'a",
    countryCode: "YE",
    value: "SA",
  },
  {
    label: "Ibb Governorate",
    countryCode: "YE",
    value: "IB",
  },
  {
    label: "Ma'rib Governorate",
    countryCode: "YE",
    value: "MA",
  },
  {
    label: "Al Mahwit Governorate",
    countryCode: "YE",
    value: "MW",
  },
  {
    label: "Sana'a Governorate",
    countryCode: "YE",
    value: "SN",
  },
  {
    label: "Abyan Governorate",
    countryCode: "YE",
    value: "AB",
  },
  {
    label: "Hadhramaut Governorate",
    countryCode: "YE",
    value: "HD",
  },
  {
    label: "Socotra Governorate",
    countryCode: "YE",
    value: "SU",
  },
  {
    label: "Al Bayda' Governorate",
    countryCode: "YE",
    value: "BA",
  },
  {
    label: "Al Hudaydah Governorate",
    countryCode: "YE",
    value: "HU",
  },
  {
    label: "'Adan Governorate",
    countryCode: "YE",
    value: "AD",
  },
  {
    label: "Al Jawf Governorate",
    countryCode: "YE",
    value: "JA",
  },
  {
    label: "Hajjah Governorate",
    countryCode: "YE",
    value: "HJ",
  },
  {
    label: "Lahij Governorate",
    countryCode: "YE",
    value: "LA",
  },
  {
    label: "Dhamar Governorate",
    countryCode: "YE",
    value: "DH",
  },
  {
    label: "Shabwah Governorate",
    countryCode: "YE",
    value: "SH",
  },
  {
    label: "Raymah Governorate",
    countryCode: "YE",
    value: "RA",
  },
  {
    label: "Saada Governorate",
    countryCode: "YE",
    value: "SD",
  },
  {
    label: "'Amran Governorate",
    countryCode: "YE",
    value: "AM",
  },
  {
    label: "Al Mahrah Governorate",
    countryCode: "YE",
    value: "MR",
  },
  {
    label: "Sangha-Mbaéré",
    countryCode: "CF",
    value: "SE",
  },
  {
    label: "Nana-Grébizi Economic Prefecture",
    countryCode: "CF",
    value: "KB",
  },
  {
    label: "Ouham Prefecture",
    countryCode: "CF",
    value: "AC",
  },
  {
    label: "Ombella-M'Poko Prefecture",
    countryCode: "CF",
    value: "MP",
  },
  {
    label: "Lobaye Prefecture",
    countryCode: "CF",
    value: "LB",
  },
  {
    label: "Mambéré-Kadéï",
    countryCode: "CF",
    value: "HS",
  },
  {
    label: "Haut-Mbomou Prefecture",
    countryCode: "CF",
    value: "HM",
  },
  {
    label: "Bamingui-Bangoran Prefecture",
    countryCode: "CF",
    value: "BB",
  },
  {
    label: "Nana-Mambéré Prefecture",
    countryCode: "CF",
    value: "NM",
  },
  {
    label: "Vakaga Prefecture",
    countryCode: "CF",
    value: "VK",
  },
  {
    label: "Bangui",
    countryCode: "CF",
    value: "BGF",
  },
  {
    label: "Kémo Prefecture",
    countryCode: "CF",
    value: "KG",
  },
  {
    label: "Basse-Kotto Prefecture",
    countryCode: "CF",
    value: "BK",
  },
  {
    label: "Ouaka Prefecture",
    countryCode: "CF",
    value: "UK",
  },
  {
    label: "Mbomou Prefecture",
    countryCode: "CF",
    value: "MB",
  },
  {
    label: "Ouham-Pendé Prefecture",
    countryCode: "CF",
    value: "OP",
  },
  {
    label: "Haute-Kotto Prefecture",
    countryCode: "CF",
    value: "HK",
  },
  {
    label: "Romblon",
    countryCode: "PH",
    value: "ROM",
  },
  {
    label: "Bukidnon",
    countryCode: "PH",
    value: "BUK",
  },
  {
    label: "Rizal",
    countryCode: "PH",
    value: "RIZ",
  },
  {
    label: "Bohol",
    countryCode: "PH",
    value: "BOH",
  },
  {
    label: "Quirino",
    countryCode: "PH",
    value: "QUI",
  },
  {
    label: "Biliran",
    countryCode: "PH",
    value: "BIL",
  },
  {
    label: "Quezon",
    countryCode: "PH",
    value: "QUE",
  },
  {
    label: "Siquijor",
    countryCode: "PH",
    value: "SIG",
  },
  {
    label: "Sarangani",
    countryCode: "PH",
    value: "SAR",
  },
  {
    label: "Bulacan",
    countryCode: "PH",
    value: "BUL",
  },
  {
    label: "Cagayan",
    countryCode: "PH",
    value: "CAG",
  },
  {
    label: "South Cotabato",
    countryCode: "PH",
    value: "SCO",
  },
  {
    label: "Sorsogon",
    countryCode: "PH",
    value: "SOR",
  },
  {
    label: "Sultan Kudarat",
    countryCode: "PH",
    value: "SUK",
  },
  {
    label: "Camarines Norte",
    countryCode: "PH",
    value: "CAN",
  },
  {
    label: "Southern Leyte",
    countryCode: "PH",
    value: "SLE",
  },
  {
    label: "Camiguin",
    countryCode: "PH",
    value: "CAM",
  },
  {
    label: "Surigao del Norte",
    countryCode: "PH",
    value: "SUN",
  },
  {
    label: "Camarines Sur",
    countryCode: "PH",
    value: "CAS",
  },
  {
    label: "Sulu",
    countryCode: "PH",
    value: "SLU",
  },
  {
    label: "Davao Oriental",
    countryCode: "PH",
    value: "DAO",
  },
  {
    label: "Eastern Samar",
    countryCode: "PH",
    value: "EAS",
  },
  {
    label: "Dinagat Islands",
    countryCode: "PH",
    value: "DIN",
  },
  {
    label: "Capiz",
    countryCode: "PH",
    value: "CAP",
  },
  {
    label: "Tawi-Tawi",
    countryCode: "PH",
    value: "TAW",
  },
  {
    label: "Calabarzon",
    countryCode: "PH",
    value: "40",
  },
  {
    label: "Tarlac",
    countryCode: "PH",
    value: "TAR",
  },
  {
    label: "Surigao del Sur",
    countryCode: "PH",
    value: "SUR",
  },
  {
    label: "Zambales",
    countryCode: "PH",
    value: "ZMB",
  },
  {
    label: "Ilocos Norte",
    countryCode: "PH",
    value: "ILN",
  },
  {
    label: "Mimaropa",
    countryCode: "PH",
    value: "41",
  },
  {
    label: "Ifugao",
    countryCode: "PH",
    value: "IFU",
  },
  {
    label: "Catanduanes",
    countryCode: "PH",
    value: "CAT",
  },
  {
    label: "Zamboanga del Norte",
    countryCode: "PH",
    value: "ZAN",
  },
  {
    label: "Guimaras",
    countryCode: "PH",
    value: "GUI",
  },
  {
    label: "Bicol Region",
    countryCode: "PH",
    value: "05",
  },
  {
    label: "Western Visayas",
    countryCode: "PH",
    value: "06",
  },
  {
    label: "Cebu",
    countryCode: "PH",
    value: "CEB",
  },
  {
    label: "Cavite",
    countryCode: "PH",
    value: "CAV",
  },
  {
    label: "Central Visayas",
    countryCode: "PH",
    value: "07",
  },
  {
    label: "Davao Occidental",
    countryCode: "PH",
    value: "DVO",
  },
  {
    label: "Soccsksargen",
    countryCode: "PH",
    value: "12",
  },
  {
    label: "Compostela Valley",
    countryCode: "PH",
    value: "COM",
  },
  {
    label: "Kalinga",
    countryCode: "PH",
    value: "KAL",
  },
  {
    label: "Isabela",
    countryCode: "PH",
    value: "ISA",
  },
  {
    label: "Caraga",
    countryCode: "PH",
    value: "13",
  },
  {
    label: "Iloilo",
    countryCode: "PH",
    value: "ILI",
  },
  {
    label: "Autonomous Region in Muslim Mindanao",
    countryCode: "PH",
    value: "14",
  },
  {
    label: "La Union",
    countryCode: "PH",
    value: "LUN",
  },
  {
    label: "Davao del Sur",
    countryCode: "PH",
    value: "DAS",
  },
  {
    label: "Davao del Norte",
    countryCode: "PH",
    value: "DAV",
  },
  {
    label: "Cotabato",
    countryCode: "PH",
    value: "NCO",
  },
  {
    label: "Ilocos Sur",
    countryCode: "PH",
    value: "ILS",
  },
  {
    label: "Eastern Visayas",
    countryCode: "PH",
    value: "08",
  },
  {
    label: "Agusan del Norte",
    countryCode: "PH",
    value: "AGN",
  },
  {
    label: "Abra",
    countryCode: "PH",
    value: "ABR",
  },
  {
    label: "Zamboanga Peninsula",
    countryCode: "PH",
    value: "09",
  },
  {
    label: "Agusan del Sur",
    countryCode: "PH",
    value: "AGS",
  },
  {
    label: "Lanao del Norte",
    countryCode: "PH",
    value: "LAN",
  },
  {
    label: "Laguna",
    countryCode: "PH",
    value: "LAG",
  },
  {
    label: "Marinduque",
    countryCode: "PH",
    value: "MAD",
  },
  {
    label: "Maguindanao",
    countryCode: "PH",
    value: "MAG",
  },
  {
    label: "Aklan",
    countryCode: "PH",
    value: "AKL",
  },
  {
    label: "Leyte",
    countryCode: "PH",
    value: "LEY",
  },
  {
    label: "Lanao del Sur",
    countryCode: "PH",
    value: "LAS",
  },
  {
    label: "Apayao",
    countryCode: "PH",
    value: "APA",
  },
  {
    label: "Cordillera Administrative Region",
    countryCode: "PH",
    value: "15",
  },
  {
    label: "Antique",
    countryCode: "PH",
    value: "ANT",
  },
  {
    label: "Albay",
    countryCode: "PH",
    value: "ALB",
  },
  {
    label: "Masbate",
    countryCode: "PH",
    value: "MAS",
  },
  {
    label: "Northern Mindanao",
    countryCode: "PH",
    value: "10",
  },
  {
    label: "Davao Region",
    countryCode: "PH",
    value: "11",
  },
  {
    label: "Aurora",
    countryCode: "PH",
    value: "AUR",
  },
  {
    label: "Cagayan Valley",
    countryCode: "PH",
    value: "02",
  },
  {
    label: "Misamis Occidental",
    countryCode: "PH",
    value: "MSC",
  },
  {
    label: "Bataan",
    countryCode: "PH",
    value: "BAN",
  },
  {
    label: "Central Luzon",
    countryCode: "PH",
    value: "03",
  },
  {
    label: "Basilan",
    countryCode: "PH",
    value: "BAS",
  },
  {
    label: "Metro Manila",
    countryCode: "PH",
    value: "NCR",
  },
  {
    label: "Misamis Oriental",
    countryCode: "PH",
    value: "MSR",
  },
  {
    label: "Northern Samar",
    countryCode: "PH",
    value: "NSA",
  },
  {
    label: "Negros Oriental",
    countryCode: "PH",
    value: "NER",
  },
  {
    label: "Negros Occidental",
    countryCode: "PH",
    value: "NEC",
  },
  {
    label: "Batanes",
    countryCode: "PH",
    value: "BTN",
  },
  {
    label: "Mountain Province",
    countryCode: "PH",
    value: "MOU",
  },
  {
    label: "Oriental Mindoro",
    countryCode: "PH",
    value: "MDR",
  },
  {
    label: "Ilocos Region",
    countryCode: "PH",
    value: "01",
  },
  {
    label: "Occidental Mindoro",
    countryCode: "PH",
    value: "MDC",
  },
  {
    label: "Zamboanga del Sur",
    countryCode: "PH",
    value: "ZAS",
  },
  {
    label: "Nueva Vizcaya",
    countryCode: "PH",
    value: "NUV",
  },
  {
    label: "Batangas",
    countryCode: "PH",
    value: "BTG",
  },
  {
    label: "Nueva Ecija",
    countryCode: "PH",
    value: "NUE",
  },
  {
    label: "Palawan",
    countryCode: "PH",
    value: "PLW",
  },
  {
    label: "Zamboanga Sibugay",
    countryCode: "PH",
    value: "ZSI",
  },
  {
    label: "Benguet",
    countryCode: "PH",
    value: "BEN",
  },
  {
    label: "Pangasinan",
    countryCode: "PH",
    value: "PAN",
  },
  {
    label: "Pampanga",
    countryCode: "PH",
    value: "PAM",
  },
  {
    label: "Northern District",
    countryCode: "IL",
    value: "Z",
  },
  {
    label: "Central District",
    countryCode: "IL",
    value: "M",
  },
  {
    label: "Southern District",
    countryCode: "IL",
    value: "D",
  },
  {
    label: "Haifa District",
    countryCode: "IL",
    value: "HA",
  },
  {
    label: "Jerusalem District",
    countryCode: "IL",
    value: "JM",
  },
  {
    label: "Tel Aviv District",
    countryCode: "IL",
    value: "TA",
  },
  {
    label: "Limburg",
    countryCode: "BE",
    value: "VLI",
  },
  {
    label: "Flanders",
    countryCode: "BE",
    value: "VLG",
  },
  {
    label: "Flemish Brabant",
    countryCode: "BE",
    value: "VBR",
  },
  {
    label: "Hainaut",
    countryCode: "BE",
    value: "WHT",
  },
  {
    label: "Brussels-Capital Region",
    countryCode: "BE",
    value: "BRU",
  },
  {
    label: "East Flanders",
    countryCode: "BE",
    value: "VOV",
  },
  {
    label: "Namur",
    countryCode: "BE",
    value: "WNA",
  },
  {
    label: "Luxembourg",
    countryCode: "BE",
    value: "WLX",
  },
  {
    label: "Wallonia",
    countryCode: "BE",
    value: "WAL",
  },
  {
    label: "Antwerp",
    countryCode: "BE",
    value: "VAN",
  },
  {
    label: "Walloon Brabant",
    countryCode: "BE",
    value: "WBR",
  },
  {
    label: "West Flanders",
    countryCode: "BE",
    value: "VWV",
  },
  {
    label: "Liège",
    countryCode: "BE",
    value: "WLG",
  },
  {
    label: "Darién Province",
    countryCode: "PA",
    value: "5",
  },
  {
    label: "Colón Province",
    countryCode: "PA",
    value: "3",
  },
  {
    label: "Coclé Province",
    countryCode: "PA",
    value: "2",
  },
  {
    label: "Guna Yala",
    countryCode: "PA",
    value: "KY",
  },
  {
    label: "Herrera Province",
    countryCode: "PA",
    value: "6",
  },
  {
    label: "Los Santos Province",
    countryCode: "PA",
    value: "7",
  },
  {
    label: "Ngöbe-Buglé Comarca",
    countryCode: "PA",
    value: "NB",
  },
  {
    label: "Veraguas Province",
    countryCode: "PA",
    value: "9",
  },
  {
    label: "Bocas del Toro Province",
    countryCode: "PA",
    value: "1",
  },
  {
    label: "Panamá Oeste Province",
    countryCode: "PA",
    value: "10",
  },
  {
    label: "Panamá Province",
    countryCode: "PA",
    value: "8",
  },
  {
    label: "Emberá-Wounaan Comarca",
    countryCode: "PA",
    value: "EM",
  },
  {
    label: "Chiriquí Province",
    countryCode: "PA",
    value: "4",
  },
  {
    label: "Howland Island",
    countryCode: "US",
    value: "UM-84",
  },
  {
    label: "Delaware",
    countryCode: "US",
    value: "DE",
  },
  {
    label: "Alaska",
    countryCode: "US",
    value: "AK",
  },
  {
    label: "Maryland",
    countryCode: "US",
    value: "MD",
  },
  {
    label: "Baker Island",
    countryCode: "US",
    value: "UM-81",
  },
  {
    label: "Kingman Reef",
    countryCode: "US",
    value: "UM-89",
  },
  {
    label: "New Hampshire",
    countryCode: "US",
    value: "NH",
  },
  {
    label: "Wake Island",
    countryCode: "US",
    value: "UM-79",
  },
  {
    label: "Kansas",
    countryCode: "US",
    value: "KS",
  },
  {
    label: "Texas",
    countryCode: "US",
    value: "TX",
  },
  {
    label: "Nebraska",
    countryCode: "US",
    value: "NE",
  },
  {
    label: "Vermont",
    countryCode: "US",
    value: "VT",
  },
  {
    label: "Jarvis Island",
    countryCode: "US",
    value: "UM-86",
  },
  {
    label: "Hawaii",
    countryCode: "US",
    value: "HI",
  },
  {
    label: "Guam",
    countryCode: "US",
    value: "GU",
  },
  {
    label: "United States Virgin Islands",
    countryCode: "US",
    value: "VI",
  },
  {
    label: "Utah",
    countryCode: "US",
    value: "UT",
  },
  {
    label: "Oregon",
    countryCode: "US",
    value: "OR",
  },
  {
    label: "California",
    countryCode: "US",
    value: "CA",
  },
  {
    label: "New Jersey",
    countryCode: "US",
    value: "NJ",
  },
  {
    label: "North Dakota",
    countryCode: "US",
    value: "ND",
  },
  {
    label: "Kentucky",
    countryCode: "US",
    value: "KY",
  },
  {
    label: "Minnesota",
    countryCode: "US",
    value: "MN",
  },
  {
    label: "Oklahoma",
    countryCode: "US",
    value: "OK",
  },
  {
    label: "Pennsylvania",
    countryCode: "US",
    value: "PA",
  },
  {
    label: "New Mexico",
    countryCode: "US",
    value: "NM",
  },
  {
    label: "American Samoa",
    countryCode: "US",
    value: "AS",
  },
  {
    label: "Illinois",
    countryCode: "US",
    value: "IL",
  },
  {
    label: "Michigan",
    countryCode: "US",
    value: "MI",
  },
  {
    label: "Virginia",
    countryCode: "US",
    value: "VA",
  },
  {
    label: "Johnston Atoll",
    countryCode: "US",
    value: "UM-67",
  },
  {
    label: "West Virginia",
    countryCode: "US",
    value: "WV",
  },
  {
    label: "Mississippi",
    countryCode: "US",
    value: "MS",
  },
  {
    label: "Northern Mariana Islands",
    countryCode: "US",
    value: "MP",
  },
  {
    label: "United States Minor Outlying Islands",
    countryCode: "US",
    value: "UM",
  },
  {
    label: "Massachusetts",
    countryCode: "US",
    value: "MA",
  },
  {
    label: "Arizona",
    countryCode: "US",
    value: "AZ",
  },
  {
    label: "Connecticut",
    countryCode: "US",
    value: "CT",
  },
  {
    label: "Florida",
    countryCode: "US",
    value: "FL",
  },
  {
    label: "District of Columbia",
    countryCode: "US",
    value: "DC",
  },
  {
    label: "Midway Atoll",
    countryCode: "US",
    value: "UM-71",
  },
  {
    label: "Navassa Island",
    countryCode: "US",
    value: "UM-76",
  },
  {
    label: "Indiana",
    countryCode: "US",
    value: "IN",
  },
  {
    label: "Wisconsin",
    countryCode: "US",
    value: "WI",
  },
  {
    label: "Wyoming",
    countryCode: "US",
    value: "WY",
  },
  {
    label: "South Carolina",
    countryCode: "US",
    value: "SC",
  },
  {
    label: "Arkansas",
    countryCode: "US",
    value: "AR",
  },
  {
    label: "South Dakota",
    countryCode: "US",
    value: "SD",
  },
  {
    label: "Montana",
    countryCode: "US",
    value: "MT",
  },
  {
    label: "North Carolina",
    countryCode: "US",
    value: "NC",
  },
  {
    label: "Palmyra Atoll",
    countryCode: "US",
    value: "UM-95",
  },
  {
    label: "Puerto Rico",
    countryCode: "US",
    value: "PR",
  },
  {
    label: "Colorado",
    countryCode: "US",
    value: "CO",
  },
  {
    label: "Missouri",
    countryCode: "US",
    value: "MO",
  },
  {
    label: "New York",
    countryCode: "US",
    value: "NY",
  },
  {
    label: "Maine",
    countryCode: "US",
    value: "ME",
  },
  {
    label: "Tennessee",
    countryCode: "US",
    value: "TN",
  },
  {
    label: "Georgia",
    countryCode: "US",
    value: "GA",
  },
  {
    label: "Alabama",
    countryCode: "US",
    value: "AL",
  },
  {
    label: "Louisiana",
    countryCode: "US",
    value: "LA",
  },
  {
    label: "Nevada",
    countryCode: "US",
    value: "NV",
  },
  {
    label: "Iowa",
    countryCode: "US",
    value: "IA",
  },
  {
    label: "Idaho",
    countryCode: "US",
    value: "ID",
  },
  {
    label: "Rhode Island",
    countryCode: "US",
    value: "RI",
  },
  {
    label: "Washington",
    countryCode: "US",
    value: "WA",
  },
  {
    label: "Shinyanga",
    countryCode: "TZ",
    value: "22",
  },
  {
    label: "Simiyu",
    countryCode: "TZ",
    value: "30",
  },
  {
    label: "Kagera",
    countryCode: "TZ",
    value: "05",
  },
  {
    label: "Dodoma",
    countryCode: "TZ",
    value: "03",
  },
  {
    label: "Kilimanjaro",
    countryCode: "TZ",
    value: "09",
  },
  {
    label: "Mara",
    countryCode: "TZ",
    value: "13",
  },
  {
    label: "Tabora",
    countryCode: "TZ",
    value: "24",
  },
  {
    label: "Morogoro",
    countryCode: "TZ",
    value: "16",
  },
  {
    label: "Zanzibar South",
    countryCode: "TZ",
    value: "11",
  },
  {
    label: "Pemba South",
    countryCode: "TZ",
    value: "10",
  },
  {
    label: "Zanzibar North",
    countryCode: "TZ",
    value: "07",
  },
  {
    label: "Singida",
    countryCode: "TZ",
    value: "23",
  },
  {
    label: "Zanzibar West",
    countryCode: "TZ",
    value: "15",
  },
  {
    label: "Mtwara",
    countryCode: "TZ",
    value: "17",
  },
  {
    label: "Rukwa",
    countryCode: "TZ",
    value: "20",
  },
  {
    label: "Kigoma",
    countryCode: "TZ",
    value: "08",
  },
  {
    label: "Mwanza",
    countryCode: "TZ",
    value: "18",
  },
  {
    label: "Njombe",
    countryCode: "TZ",
    value: "29",
  },
  {
    label: "Geita",
    countryCode: "TZ",
    value: "27",
  },
  {
    label: "Katavi",
    countryCode: "TZ",
    value: "28",
  },
  {
    label: "Lindi",
    countryCode: "TZ",
    value: "12",
  },
  {
    label: "Manyara",
    countryCode: "TZ",
    value: "26",
  },
  {
    label: "Pwani",
    countryCode: "TZ",
    value: "19",
  },
  {
    label: "Ruvuma",
    countryCode: "TZ",
    value: "21",
  },
  {
    label: "Tanga",
    countryCode: "TZ",
    value: "25",
  },
  {
    label: "Pemba North",
    countryCode: "TZ",
    value: "06",
  },
  {
    label: "Iringa",
    countryCode: "TZ",
    value: "04",
  },
  {
    label: "Dar es Salaam",
    countryCode: "TZ",
    value: "02",
  },
  {
    label: "Arusha",
    countryCode: "TZ",
    value: "01",
  },
  {
    label: "Eastern Finland Province",
    countryCode: "FI",
    value: "IS",
  },
  {
    label: "Tavastia Proper",
    countryCode: "FI",
    value: "06",
  },
  {
    label: "Central Ostrobothnia",
    countryCode: "FI",
    value: "07",
  },
  {
    label: "Southern Savonia",
    countryCode: "FI",
    value: "04",
  },
  {
    label: "Kainuu",
    countryCode: "FI",
    value: "05",
  },
  {
    label: "South Karelia",
    countryCode: "FI",
    value: "02",
  },
  {
    label: "Southern Ostrobothnia",
    countryCode: "FI",
    value: "03",
  },
  {
    label: "Oulu Province",
    countryCode: "FI",
    value: "OL",
  },
  {
    label: "Lapland",
    countryCode: "FI",
    value: "LL",
  },
  {
    label: "Satakunta",
    countryCode: "FI",
    value: "17",
  },
  {
    label: "Päijänne Tavastia",
    countryCode: "FI",
    value: "16",
  },
  {
    label: "Northern Savonia",
    countryCode: "FI",
    value: "15",
  },
  {
    label: "North Karelia",
    countryCode: "FI",
    value: "13",
  },
  {
    label: "Northern Ostrobothnia",
    countryCode: "FI",
    value: "14",
  },
  {
    label: "Pirkanmaa",
    countryCode: "FI",
    value: "11",
  },
  {
    label: "Finland Proper",
    countryCode: "FI",
    value: "19",
  },
  {
    label: "Ostrobothnia",
    countryCode: "FI",
    value: "12",
  },
  {
    label: "Åland Islands",
    countryCode: "FI",
    value: "01",
  },
  {
    label: "Uusimaa",
    countryCode: "FI",
    value: "18",
  },
  {
    label: "Central Finland",
    countryCode: "FI",
    value: "08",
  },
  {
    label: "Kymenlaakso",
    countryCode: "FI",
    value: "09",
  },
  {
    label: "Canton of Diekirch",
    countryCode: "LU",
    value: "DI",
  },
  {
    label: "Luxembourg District",
    countryCode: "LU",
    value: "L",
  },
  {
    label: "Canton of Echternach",
    countryCode: "LU",
    value: "EC",
  },
  {
    label: "Canton of Redange",
    countryCode: "LU",
    value: "RD",
  },
  {
    label: "Canton of Esch-sur-Alzette",
    countryCode: "LU",
    value: "ES",
  },
  {
    label: "Canton of Capellen",
    countryCode: "LU",
    value: "CA",
  },
  {
    label: "Canton of Remich",
    countryCode: "LU",
    value: "RM",
  },
  {
    label: "Grevenmacher District",
    countryCode: "LU",
    value: "G",
  },
  {
    label: "Canton of Clervaux",
    countryCode: "LU",
    value: "CL",
  },
  {
    label: "Canton of Mersch",
    countryCode: "LU",
    value: "ME",
  },
  {
    label: "Canton of Vianden",
    countryCode: "LU",
    value: "VD",
  },
  {
    label: "Diekirch District",
    countryCode: "LU",
    value: "D",
  },
  {
    label: "Canton of Grevenmacher",
    countryCode: "LU",
    value: "GR",
  },
  {
    label: "Canton of Wiltz",
    countryCode: "LU",
    value: "WI",
  },
  {
    label: "Canton of Luxembourg",
    countryCode: "LU",
    value: "LU",
  },
  {
    label: "Region Zealand",
    countryCode: "DK",
    value: "85",
  },
  {
    label: "Region of Southern Denmark",
    countryCode: "DK",
    value: "83",
  },
  {
    label: "Capital Region of Denmark",
    countryCode: "DK",
    value: "84",
  },
  {
    label: "Central Denmark Region",
    countryCode: "DK",
    value: "82",
  },
  {
    label: "North Denmark Region",
    countryCode: "DK",
    value: "81",
  },
  {
    label: "Gävleborg County",
    countryCode: "SE",
    value: "X",
  },
  {
    label: "Dalarna County",
    countryCode: "SE",
    value: "W",
  },
  {
    label: "Värmland County",
    countryCode: "SE",
    value: "S",
  },
  {
    label: "Östergötland County",
    countryCode: "SE",
    value: "E",
  },
  {
    label: "Blekinge",
    countryCode: "SE",
    value: "K",
  },
  {
    label: "Norrbotten County",
    countryCode: "SE",
    value: "BD",
  },
  {
    label: "Örebro County",
    countryCode: "SE",
    value: "T",
  },
  {
    label: "Södermanland County",
    countryCode: "SE",
    value: "D",
  },
  {
    label: "Skåne County",
    countryCode: "SE",
    value: "M",
  },
  {
    label: "Kronoberg County",
    countryCode: "SE",
    value: "G",
  },
  {
    label: "Västerbotten County",
    countryCode: "SE",
    value: "AC",
  },
  {
    label: "Kalmar County",
    countryCode: "SE",
    value: "H",
  },
  {
    label: "Uppsala County",
    countryCode: "SE",
    value: "C",
  },
  {
    label: "Gotland County",
    countryCode: "SE",
    value: "I",
  },
  {
    label: "Västra Götaland County",
    countryCode: "SE",
    value: "O",
  },
  {
    label: "Halland County",
    countryCode: "SE",
    value: "N",
  },
  {
    label: "Västmanland County",
    countryCode: "SE",
    value: "U",
  },
  {
    label: "Jönköping County",
    countryCode: "SE",
    value: "F",
  },
  {
    label: "Stockholm County",
    countryCode: "SE",
    value: "AB",
  },
  {
    label: "Västernorrland County",
    countryCode: "SE",
    value: "Y",
  },
  {
    label: "Plungė District Municipality",
    countryCode: "LT",
    value: "35",
  },
  {
    label: "Šiauliai District Municipality",
    countryCode: "LT",
    value: "44",
  },
  {
    label: "Jurbarkas District Municipality",
    countryCode: "LT",
    value: "12",
  },
  {
    label: "Kaunas County",
    countryCode: "LT",
    value: "KU",
  },
  {
    label: "Mažeikiai District Municipality",
    countryCode: "LT",
    value: "26",
  },
  {
    label: "Panevėžys County",
    countryCode: "LT",
    value: "PN",
  },
  {
    label: "Elektrėnai municipality",
    countryCode: "LT",
    value: "08",
  },
  {
    label: "Švenčionys District Municipality",
    countryCode: "LT",
    value: "49",
  },
  {
    label: "Akmenė District Municipality",
    countryCode: "LT",
    value: "01",
  },
  {
    label: "Ignalina District Municipality",
    countryCode: "LT",
    value: "09",
  },
  {
    label: "Neringa Municipality",
    countryCode: "LT",
    value: "28",
  },
  {
    label: "Visaginas Municipality",
    countryCode: "LT",
    value: "59",
  },
  {
    label: "Kaunas District Municipality",
    countryCode: "LT",
    value: "16",
  },
  {
    label: "Biržai District Municipality",
    countryCode: "LT",
    value: "06",
  },
  {
    label: "Jonava District Municipality",
    countryCode: "LT",
    value: "10",
  },
  {
    label: "Radviliškis District Municipality",
    countryCode: "LT",
    value: "37",
  },
  {
    label: "Telšiai County",
    countryCode: "LT",
    value: "TE",
  },
  {
    label: "Marijampolė County",
    countryCode: "LT",
    value: "MR",
  },
  {
    label: "Kretinga District Municipality",
    countryCode: "LT",
    value: "22",
  },
  {
    label: "Tauragė District Municipality",
    countryCode: "LT",
    value: "50",
  },
  {
    label: "Tauragė County",
    countryCode: "LT",
    value: "TA",
  },
  {
    label: "Alytus County",
    countryCode: "LT",
    value: "AL",
  },
  {
    label: "Kazlų Rūda municipality",
    countryCode: "LT",
    value: "17",
  },
  {
    label: "Šakiai District Municipality",
    countryCode: "LT",
    value: "41",
  },
  {
    label: "Šalčininkai District Municipality",
    countryCode: "LT",
    value: "42",
  },
  {
    label: "Prienai District Municipality",
    countryCode: "LT",
    value: "36",
  },
  {
    label: "Druskininkai municipality",
    countryCode: "LT",
    value: "07",
  },
  {
    label: "Kaunas City Municipality",
    countryCode: "LT",
    value: "15",
  },
  {
    label: "Joniškis District Municipality",
    countryCode: "LT",
    value: "11",
  },
  {
    label: "Molėtai District Municipality",
    countryCode: "LT",
    value: "27",
  },
  {
    label: "Kaišiadorys District Municipality",
    countryCode: "LT",
    value: "13",
  },
  {
    label: "Kėdainiai District Municipality",
    countryCode: "LT",
    value: "18",
  },
  {
    label: "Kupiškis District Municipality",
    countryCode: "LT",
    value: "23",
  },
  {
    label: "Šiauliai County",
    countryCode: "LT",
    value: "SA",
  },
  {
    label: "Raseiniai District Municipality",
    countryCode: "LT",
    value: "38",
  },
  {
    label: "Palanga City Municipality",
    countryCode: "LT",
    value: "31",
  },
  {
    label: "Panevėžys City Municipality",
    countryCode: "LT",
    value: "32",
  },
  {
    label: "Rietavas municipality",
    countryCode: "LT",
    value: "39",
  },
  {
    label: "Kalvarija municipality",
    countryCode: "LT",
    value: "14",
  },
  {
    label: "Vilnius District Municipality",
    countryCode: "LT",
    value: "58",
  },
  {
    label: "Trakai District Municipality",
    countryCode: "LT",
    value: "52",
  },
  {
    label: "Širvintos District Municipality",
    countryCode: "LT",
    value: "47",
  },
  {
    label: "Pakruojis District Municipality",
    countryCode: "LT",
    value: "30",
  },
  {
    label: "Ukmergė District Municipality",
    countryCode: "LT",
    value: "53",
  },
  {
    label: "Klaipeda City Municipality",
    countryCode: "LT",
    value: "20",
  },
  {
    label: "Utena District Municipality",
    countryCode: "LT",
    value: "54",
  },
  {
    label: "Alytus District Municipality",
    countryCode: "LT",
    value: "03",
  },
  {
    label: "Klaipėda County",
    countryCode: "LT",
    value: "KL",
  },
  {
    label: "Vilnius County",
    countryCode: "LT",
    value: "VL",
  },
  {
    label: "Varėna District Municipality",
    countryCode: "LT",
    value: "55",
  },
  {
    label: "Birštonas Municipality",
    countryCode: "LT",
    value: "05",
  },
  {
    label: "Klaipėda District Municipality",
    countryCode: "LT",
    value: "21",
  },
  {
    label: "Alytus City Municipality",
    countryCode: "LT",
    value: "02",
  },
  {
    label: "Vilnius City Municipality",
    countryCode: "LT",
    value: "57",
  },
  {
    label: "Šilutė District Municipality",
    countryCode: "LT",
    value: "46",
  },
  {
    label: "Telšiai District Municipality",
    countryCode: "LT",
    value: "51",
  },
  {
    label: "Šiauliai City Municipality",
    countryCode: "LT",
    value: "43",
  },
  {
    label: "Marijampolė Municipality",
    countryCode: "LT",
    value: "25",
  },
  {
    label: "Lazdijai District Municipality",
    countryCode: "LT",
    value: "24",
  },
  {
    label: "Pagėgiai municipality",
    countryCode: "LT",
    value: "29",
  },
  {
    label: "Šilalė District Municipality",
    countryCode: "LT",
    value: "45",
  },
  {
    label: "Panevėžys District Municipality",
    countryCode: "LT",
    value: "33",
  },
  {
    label: "Rokiškis District Municipality",
    countryCode: "LT",
    value: "40",
  },
  {
    label: "Pasvalys District Municipality",
    countryCode: "LT",
    value: "34",
  },
  {
    label: "Skuodas District Municipality",
    countryCode: "LT",
    value: "48",
  },
  {
    label: "Kelmė District Municipality",
    countryCode: "LT",
    value: "19",
  },
  {
    label: "Zarasai District Municipality",
    countryCode: "LT",
    value: "60",
  },
  {
    label: "Vilkaviškis District Municipality",
    countryCode: "LT",
    value: "56",
  },
  {
    label: "Utena County",
    countryCode: "LT",
    value: "UT",
  },
  {
    label: "Opole Voivodeship",
    countryCode: "PL",
    value: "OP",
  },
  {
    label: "Silesian Voivodeship",
    countryCode: "PL",
    value: "SL",
  },
  {
    label: "Pomeranian Voivodeship",
    countryCode: "PL",
    value: "PM",
  },
  {
    label: "Kuyavian-Pomeranian Voivodeship",
    countryCode: "PL",
    value: "KP",
  },
  {
    label: "Podkarpackie Voivodeship",
    countryCode: "PL",
    value: "PK",
  },
  {
    label: "Warmian-Masurian Voivodeship",
    countryCode: "PL",
    value: "WN",
  },
  {
    label: "Lower Silesian Voivodeship",
    countryCode: "PL",
    value: "DS",
  },
  {
    label: "Świętokrzyskie Voivodeship",
    countryCode: "PL",
    value: "SK",
  },
  {
    label: "Lubusz Voivodeship",
    countryCode: "PL",
    value: "LB",
  },
  {
    label: "Podlaskie Voivodeship",
    countryCode: "PL",
    value: "PD",
  },
  {
    label: "West Pomeranian Voivodeship",
    countryCode: "PL",
    value: "ZP",
  },
  {
    label: "Greater Poland Voivodeship",
    countryCode: "PL",
    value: "WP",
  },
  {
    label: "Lesser Poland Voivodeship",
    countryCode: "PL",
    value: "MA",
  },
  {
    label: "Łódź Voivodeship",
    countryCode: "PL",
    value: "LD",
  },
  {
    label: "Masovian Voivodeship",
    countryCode: "PL",
    value: "MZ",
  },
  {
    label: "Lublin Voivodeship",
    countryCode: "PL",
    value: "LU",
  },
  {
    label: "Aargau",
    countryCode: "CH",
    value: "AG",
  },
  {
    label: "Canton of Fribourg",
    countryCode: "CH",
    value: "FR",
  },
  {
    label: "Basel-Landschaft",
    countryCode: "CH",
    value: "BL",
  },
  {
    label: "Uri",
    countryCode: "CH",
    value: "UR",
  },
  {
    label: "Ticino",
    countryCode: "CH",
    value: "TI",
  },
  {
    label: "Canton of St. Gallen",
    countryCode: "CH",
    value: "SG",
  },
  {
    label: "canton of Bern",
    countryCode: "CH",
    value: "BE",
  },
  {
    label: "Canton of Zug",
    countryCode: "CH",
    value: "ZG",
  },
  {
    label: "Canton of Geneva",
    countryCode: "CH",
    value: "GE",
  },
  {
    label: "Canton of Valais",
    countryCode: "CH",
    value: "VS",
  },
  {
    label: "Appenzell Innerrhoden",
    countryCode: "CH",
    value: "AI",
  },
  {
    label: "Obwalden",
    countryCode: "CH",
    value: "OW",
  },
  {
    label: "Canton of Vaud",
    countryCode: "CH",
    value: "VD",
  },
  {
    label: "Nidwalden",
    countryCode: "CH",
    value: "NW",
  },
  {
    label: "Schwyz",
    countryCode: "CH",
    value: "SZ",
  },
  {
    label: "Canton of Schaffhausen",
    countryCode: "CH",
    value: "SH",
  },
  {
    label: "Appenzell Ausserrhoden",
    countryCode: "CH",
    value: "AR",
  },
  {
    label: "canton of Zürich",
    countryCode: "CH",
    value: "ZH",
  },
  {
    label: "Thurgau",
    countryCode: "CH",
    value: "TG",
  },
  {
    label: "Canton of Jura",
    countryCode: "CH",
    value: "JU",
  },
  {
    label: "Canton of Neuchâtel",
    countryCode: "CH",
    value: "NE",
  },
  {
    label: "Graubünden",
    countryCode: "CH",
    value: "GR",
  },
  {
    label: "Glarus",
    countryCode: "CH",
    value: "GL",
  },
  {
    label: "Canton of Solothurn",
    countryCode: "CH",
    value: "SO",
  },
  {
    label: "Canton of Lucerne",
    countryCode: "CH",
    value: "LU",
  },
  {
    label: "Tuscany",
    countryCode: "IT",
    value: "52",
  },
  {
    label: "Province of Padua",
    countryCode: "IT",
    value: "PD",
  },
  {
    label: "Province of Parma",
    countryCode: "IT",
    value: "PR",
  },
  {
    label: "Libero consorzio comunale di Siracusa",
    countryCode: "IT",
    value: "SR",
  },
  {
    label: "Metropolitan City of Palermo",
    countryCode: "IT",
    value: "PA",
  },
  {
    label: "Campania",
    countryCode: "IT",
    value: "72",
  },
  {
    label: "Marche",
    countryCode: "IT",
    value: "57",
  },
  {
    label: "Metropolitan City of Reggio Calabria",
    countryCode: "IT",
    value: "RC",
  },
  {
    label: "Province of Ancona",
    countryCode: "IT",
    value: "AN",
  },
  {
    label: "Metropolitan City of Venice",
    countryCode: "IT",
    value: "VE",
  },
  {
    label: "Province of Latina",
    countryCode: "IT",
    value: "LT",
  },
  {
    label: "Province of Lecce",
    countryCode: "IT",
    value: "LE",
  },
  {
    label: "Province of Pavia",
    countryCode: "IT",
    value: "PV",
  },
  {
    label: "Province of Lecco",
    countryCode: "IT",
    value: "LC",
  },
  {
    label: "Lazio",
    countryCode: "IT",
    value: "62",
  },
  {
    label: "Abruzzo",
    countryCode: "IT",
    value: "65",
  },
  {
    label: "Metropolitan City of Florence",
    countryCode: "IT",
    value: "FI",
  },
  {
    label: "Province of Ascoli Piceno",
    countryCode: "IT",
    value: "AP",
  },
  {
    label: "Metropolitan City of Cagliari",
    countryCode: "IT",
    value: "CA",
  },
  {
    label: "Umbria",
    countryCode: "IT",
    value: "55",
  },
  {
    label: "Metropolitan City of Bologna",
    countryCode: "IT",
    value: "BO",
  },
  {
    label: "Province of Pisa",
    countryCode: "IT",
    value: "PI",
  },
  {
    label: "Province of Barletta-Andria-Trani",
    countryCode: "IT",
    value: "BT",
  },
  {
    label: "Province of Pistoia",
    countryCode: "IT",
    value: "PT",
  },
  {
    label: "Apulia",
    countryCode: "IT",
    value: "75",
  },
  {
    label: "Province of Belluno",
    countryCode: "IT",
    value: "BL",
  },
  {
    label: "Province of Pordenone",
    countryCode: "IT",
    value: "PN",
  },
  {
    label: "Province of Perugia",
    countryCode: "IT",
    value: "PG",
  },
  {
    label: "Province of Avellino",
    countryCode: "IT",
    value: "AV",
  },
  {
    label: "Pesaro and Urbino Province",
    countryCode: "IT",
    value: "PU",
  },
  {
    label: "Province of Pescara",
    countryCode: "IT",
    value: "PE",
  },
  {
    label: "Molise",
    countryCode: "IT",
    value: "67",
  },
  {
    label: "Province of Piacenza",
    countryCode: "IT",
    value: "PC",
  },
  {
    label: "Province of Potenza",
    countryCode: "IT",
    value: "PZ",
  },
  {
    label: "Metropolitan City of Milan",
    countryCode: "IT",
    value: "MI",
  },
  {
    label: "Metropolitan City of Genoa",
    countryCode: "IT",
    value: "GE",
  },
  {
    label: "Province of Prato",
    countryCode: "IT",
    value: "PO",
  },
  {
    label: "Benevento Province",
    countryCode: "IT",
    value: "BN",
  },
  {
    label: "Piedmont",
    countryCode: "IT",
    value: "21",
  },
  {
    label: "Calabria",
    countryCode: "IT",
    value: "78",
  },
  {
    label: "Province of Bergamo",
    countryCode: "IT",
    value: "BG",
  },
  {
    label: "Lombardy",
    countryCode: "IT",
    value: "25",
  },
  {
    label: "Basilicata",
    countryCode: "IT",
    value: "77",
  },
  {
    label: "Province of Ravenna",
    countryCode: "IT",
    value: "RA",
  },
  {
    label: "Province of Reggio Emilia",
    countryCode: "IT",
    value: "RE",
  },
  {
    label: "Sicily",
    countryCode: "IT",
    value: "82",
  },
  {
    label: "Metropolitan City of Turin",
    countryCode: "IT",
    value: "TO",
  },
  {
    label: "Metropolitan City of Rome",
    countryCode: "IT",
    value: "RM",
  },
  {
    label: "Province of Rieti",
    countryCode: "IT",
    value: "RI",
  },
  {
    label: "Province of Rimini",
    countryCode: "IT",
    value: "RN",
  },
  {
    label: "Province of Brindisi",
    countryCode: "IT",
    value: "BR",
  },
  {
    label: "Sardinia",
    countryCode: "IT",
    value: "88",
  },
  {
    label: "Aosta Valley",
    countryCode: "IT",
    value: "23",
  },
  {
    label: "Province of Brescia",
    countryCode: "IT",
    value: "BS",
  },
  {
    label: "Libero consorzio comunale di Caltanissetta",
    countryCode: "IT",
    value: "CL",
  },
  {
    label: "Province of Rovigo",
    countryCode: "IT",
    value: "RO",
  },
  {
    label: "Province of Salerno",
    countryCode: "IT",
    value: "SA",
  },
  {
    label: "Province of Campobasso",
    countryCode: "IT",
    value: "CB",
  },
  {
    label: "Province of Sassari",
    countryCode: "IT",
    value: "SS",
  },
  {
    label: "Libero consorzio comunale di Enna",
    countryCode: "IT",
    value: "EN",
  },
  {
    label: "Metropolitan City of Naples",
    countryCode: "IT",
    value: "NA",
  },
  {
    label: "Trentino-South Tyrol",
    countryCode: "IT",
    value: "32",
  },
  {
    label: "Province of Verbano-Cusio-Ossola",
    countryCode: "IT",
    value: "VB",
  },
  {
    label: "Libero consorzio comunale di Agrigento",
    countryCode: "IT",
    value: "AG",
  },
  {
    label: "Province of Catanzaro",
    countryCode: "IT",
    value: "CZ",
  },
  {
    label: "Libero consorzio comunale di Ragusa",
    countryCode: "IT",
    value: "RG",
  },
  {
    label: "Province of Carbonia-Iglesias",
    countryCode: "IT",
    value: "CI",
  },
  {
    label: "Province of Caserta",
    countryCode: "IT",
    value: "CE",
  },
  {
    label: "Province of Savona",
    countryCode: "IT",
    value: "SV",
  },
  {
    label: "Libero consorzio comunale di Trapani",
    countryCode: "IT",
    value: "TP",
  },
  {
    label: "Province of Siena",
    countryCode: "IT",
    value: "SI",
  },
  {
    label: "Province of Viterbo",
    countryCode: "IT",
    value: "VT",
  },
  {
    label: "Province of Verona",
    countryCode: "IT",
    value: "VR",
  },
  {
    label: "Province of Vibo Valentia",
    countryCode: "IT",
    value: "VV",
  },
  {
    label: "Province of Vicenza",
    countryCode: "IT",
    value: "VI",
  },
  {
    label: "Province of Chieti",
    countryCode: "IT",
    value: "CH",
  },
  {
    label: "Province of Como",
    countryCode: "IT",
    value: "CO",
  },
  {
    label: "Province of Sondrio",
    countryCode: "IT",
    value: "SO",
  },
  {
    label: "Province of Cosenza",
    countryCode: "IT",
    value: "CS",
  },
  {
    label: "Province of Taranto",
    countryCode: "IT",
    value: "TA",
  },
  {
    label: "Province of Fermo",
    countryCode: "IT",
    value: "FM",
  },
  {
    label: "Province of Livorno",
    countryCode: "IT",
    value: "LI",
  },
  {
    label: "Province of Ferrara",
    countryCode: "IT",
    value: "FE",
  },
  {
    label: "Province of Lodi",
    countryCode: "IT",
    value: "LO",
  },
  {
    label: "Trentino",
    countryCode: "IT",
    value: "TN",
  },
  {
    label: "Province of Lucca",
    countryCode: "IT",
    value: "LU",
  },
  {
    label: "Province of Macerata",
    countryCode: "IT",
    value: "MC",
  },
  {
    label: "Province of Cremona",
    countryCode: "IT",
    value: "CR",
  },
  {
    label: "Province of Teramo",
    countryCode: "IT",
    value: "TE",
  },
  {
    label: "Veneto",
    countryCode: "IT",
    value: "34",
  },
  {
    label: "Province of Crotone",
    countryCode: "IT",
    value: "KR",
  },
  {
    label: "Province of Terni",
    countryCode: "IT",
    value: "TR",
  },
  {
    label: "Friuli–Venezia Giulia",
    countryCode: "IT",
    value: "36",
  },
  {
    label: "Province of Modena",
    countryCode: "IT",
    value: "MO",
  },
  {
    label: "Province of Mantua",
    countryCode: "IT",
    value: "MN",
  },
  {
    label: "Province of Massa and Carrara",
    countryCode: "IT",
    value: "MS",
  },
  {
    label: "Province of Matera",
    countryCode: "IT",
    value: "MT",
  },
  {
    label: "Province of Medio Campidano",
    countryCode: "IT",
    value: "VS",
  },
  {
    label: "Province of Treviso",
    countryCode: "IT",
    value: "TV",
  },
  {
    label: "Province of Trieste",
    countryCode: "IT",
    value: "TS",
  },
  {
    label: "Province of Udine",
    countryCode: "IT",
    value: "UD",
  },
  {
    label: "Province of Varese",
    countryCode: "IT",
    value: "VA",
  },
  {
    label: "Metropolitan City of Catania",
    countryCode: "IT",
    value: "CT",
  },
  {
    label: "South Tyrol",
    countryCode: "IT",
    value: "BZ",
  },
  {
    label: "Liguria",
    countryCode: "IT",
    value: "42",
  },
  {
    label: "Province of Monza and Brianza",
    countryCode: "IT",
    value: "MB",
  },
  {
    label: "Metropolitan City of Messina",
    countryCode: "IT",
    value: "ME",
  },
  {
    label: "Province of Foggia",
    countryCode: "IT",
    value: "FG",
  },
  {
    label: "Metropolitan City of Bari",
    countryCode: "IT",
    value: "BA",
  },
  {
    label: "Emilia-Romagna",
    countryCode: "IT",
    value: "45",
  },
  {
    label: "Province of Novara",
    countryCode: "IT",
    value: "NO",
  },
  {
    label: "Province of Cuneo",
    countryCode: "IT",
    value: "CN",
  },
  {
    label: "Province of Frosinone",
    countryCode: "IT",
    value: "FR",
  },
  {
    label: "Province of Gorizia",
    countryCode: "IT",
    value: "GO",
  },
  {
    label: "Province of Biella",
    countryCode: "IT",
    value: "BI",
  },
  {
    label: "Province of Forlì-Cesena",
    countryCode: "IT",
    value: "FC",
  },
  {
    label: "Province of Asti",
    countryCode: "IT",
    value: "AT",
  },
  {
    label: "Province of L'Aquila",
    countryCode: "IT",
    value: "AQ",
  },
  {
    label: "Province of Ogliastra",
    countryCode: "IT",
    value: "OG",
  },
  {
    label: "Province of Alessandria",
    countryCode: "IT",
    value: "AL",
  },
  {
    label: "Province of Olbia-Tempio",
    countryCode: "IT",
    value: "OT",
  },
  {
    label: "Province of Vercelli",
    countryCode: "IT",
    value: "VC",
  },
  {
    label: "Province of Oristano",
    countryCode: "IT",
    value: "OR",
  },
  {
    label: "Province of Grosseto",
    countryCode: "IT",
    value: "GR",
  },
  {
    label: "Province of Imperia",
    countryCode: "IT",
    value: "IM",
  },
  {
    label: "Province of Isernia",
    countryCode: "IT",
    value: "IS",
  },
  {
    label: "Province of Nuoro",
    countryCode: "IT",
    value: "NU",
  },
  {
    label: "Province of La Spezia",
    countryCode: "IT",
    value: "SP",
  },
  {
    label: "Sumatera Utara",
    countryCode: "ID",
    value: "SU",
  },
  {
    label: "Bengkulu",
    countryCode: "ID",
    value: "BE",
  },
  {
    label: "Kalimantan Tengah",
    countryCode: "ID",
    value: "KT",
  },
  {
    label: "Sulawesi Selatan",
    countryCode: "ID",
    value: "SN",
  },
  {
    label: "Sulawesi Tenggara",
    countryCode: "ID",
    value: "SG",
  },
  {
    label: "Papua",
    countryCode: "ID",
    value: "PA",
  },
  {
    label: "Papua Barat",
    countryCode: "ID",
    value: "PB",
  },
  {
    label: "Maluku",
    countryCode: "ID",
    value: "MA",
  },
  {
    label: "Maluku Utara",
    countryCode: "ID",
    value: "MU",
  },
  {
    label: "Jawa Tengah",
    countryCode: "ID",
    value: "JT",
  },
  {
    label: "Kalimantan Timur",
    countryCode: "ID",
    value: "KI",
  },
  {
    label: "DKI Jakarta",
    countryCode: "ID",
    value: "JK",
  },
  {
    label: "Kalimantan Barat",
    countryCode: "ID",
    value: "KA",
  },
  {
    label: "Kepulauan Riau",
    countryCode: "ID",
    value: "KR",
  },
  {
    label: "Sulawesi Utara",
    countryCode: "ID",
    value: "SA",
  },
  {
    label: "Riau",
    countryCode: "ID",
    value: "RI",
  },
  {
    label: "Banten",
    countryCode: "ID",
    value: "BT",
  },
  {
    label: "Lampung",
    countryCode: "ID",
    value: "LA",
  },
  {
    label: "Gorontalo",
    countryCode: "ID",
    value: "GO",
  },
  {
    label: "Sulawesi Tengah",
    countryCode: "ID",
    value: "ST",
  },
  {
    label: "Nusa Tenggara Barat",
    countryCode: "ID",
    value: "NB",
  },
  {
    label: "Jambi",
    countryCode: "ID",
    value: "JA",
  },
  {
    label: "Sumatera Selatan",
    countryCode: "ID",
    value: "SS",
  },
  {
    label: "Sulawesi Barat",
    countryCode: "ID",
    value: "SR",
  },
  {
    label: "Nusa Tenggara Timur",
    countryCode: "ID",
    value: "NT",
  },
  {
    label: "Kalimantan Selatan",
    countryCode: "ID",
    value: "KS",
  },
  {
    label: "Kepulauan Bangka Belitung",
    countryCode: "ID",
    value: "BB",
  },
  {
    label: "Aceh",
    countryCode: "ID",
    value: "AC",
  },
  {
    label: "Kalimantan Utara",
    countryCode: "ID",
    value: "KU",
  },
  {
    label: "Jawa Barat",
    countryCode: "ID",
    value: "JB",
  },
  {
    label: "Bali",
    countryCode: "ID",
    value: "BA",
  },
  {
    label: "Jawa Timur",
    countryCode: "ID",
    value: "JI",
  },
  {
    label: "Sumatera Barat",
    countryCode: "ID",
    value: "SB",
  },
  {
    label: "DI Yogyakarta",
    countryCode: "ID",
    value: "YO",
  },
  {
    label: "Phoenix Islands",
    countryCode: "KI",
    value: "P",
  },
  {
    label: "Gilbert Islands",
    countryCode: "KI",
    value: "G",
  },
  {
    label: "Line Islands",
    countryCode: "KI",
    value: "L",
  },
  {
    label: "Primorsky Krai",
    countryCode: "RU",
    value: "PRI",
  },
  {
    label: "Novgorod Oblast",
    countryCode: "RU",
    value: "NGR",
  },
  {
    label: "Jewish Autonomous Oblast",
    countryCode: "RU",
    value: "YEV",
  },
  {
    label: "Nenets Autonomous Okrug",
    countryCode: "RU",
    value: "NEN",
  },
  {
    label: "Rostov Oblast",
    countryCode: "RU",
    value: "ROS",
  },
  {
    label: "Khanty-Mansi Autonomous Okrug",
    countryCode: "RU",
    value: "KHM",
  },
  {
    label: "Magadan Oblast",
    countryCode: "RU",
    value: "MAG",
  },
  {
    label: "Krasnoyarsk Krai",
    countryCode: "RU",
    value: "KYA",
  },
  {
    label: "Republic of Karelia",
    countryCode: "RU",
    value: "KR",
  },
  {
    label: "Republic of Buryatia",
    countryCode: "RU",
    value: "BU",
  },
  {
    label: "Murmansk Oblast",
    countryCode: "RU",
    value: "MUR",
  },
  {
    label: "Kaluga Oblast",
    countryCode: "RU",
    value: "KLU",
  },
  {
    label: "Chelyabinsk Oblast",
    countryCode: "RU",
    value: "CHE",
  },
  {
    label: "Omsk Oblast",
    countryCode: "RU",
    value: "OMS",
  },
  {
    label: "Yamalo-Nenets Autonomous Okrug",
    countryCode: "RU",
    value: "YAN",
  },
  {
    label: "Sakha Republic",
    countryCode: "RU",
    value: "SA",
  },
  {
    label: "Arkhangelsk",
    countryCode: "RU",
    value: "ARK",
  },
  {
    label: "Republic of Dagestan",
    countryCode: "RU",
    value: "DA",
  },
  {
    label: "Yaroslavl Oblast",
    countryCode: "RU",
    value: "YAR",
  },
  {
    label: "Republic of Adygea",
    countryCode: "RU",
    value: "AD",
  },
  {
    label: "Republic of North Ossetia-Alania",
    countryCode: "RU",
    value: "SE",
  },
  {
    label: "Republic of Bashkortostan",
    countryCode: "RU",
    value: "BA",
  },
  {
    label: "Kursk Oblast",
    countryCode: "RU",
    value: "KRS",
  },
  {
    label: "Ulyanovsk Oblast",
    countryCode: "RU",
    value: "ULY",
  },
  {
    label: "Nizhny Novgorod Oblast",
    countryCode: "RU",
    value: "NIZ",
  },
  {
    label: "Amur Oblast",
    countryCode: "RU",
    value: "AMU",
  },
  {
    label: "Chukotka Autonomous Okrug",
    countryCode: "RU",
    value: "CHU",
  },
  {
    label: "Tver Oblast",
    countryCode: "RU",
    value: "TVE",
  },
  {
    label: "Republic of Tatarstan",
    countryCode: "RU",
    value: "TA",
  },
  {
    label: "Samara Oblast",
    countryCode: "RU",
    value: "SAM",
  },
  {
    label: "Pskov Oblast",
    countryCode: "RU",
    value: "PSK",
  },
  {
    label: "Ivanovo Oblast",
    countryCode: "RU",
    value: "IVA",
  },
  {
    label: "Kamchatka Krai",
    countryCode: "RU",
    value: "KAM",
  },
  {
    label: "Astrakhan Oblast",
    countryCode: "RU",
    value: "AST",
  },
  {
    label: "Bryansk Oblast",
    countryCode: "RU",
    value: "BRY",
  },
  {
    label: "Stavropol Krai",
    countryCode: "RU",
    value: "STA",
  },
  {
    label: "Karachay-Cherkess Republic",
    countryCode: "RU",
    value: "KC",
  },
  {
    label: "Mari El Republic",
    countryCode: "RU",
    value: "ME",
  },
  {
    label: "Perm Krai",
    countryCode: "RU",
    value: "PER",
  },
  {
    label: "Tomsk Oblast",
    countryCode: "RU",
    value: "TOM",
  },
  {
    label: "Khabarovsk Krai",
    countryCode: "RU",
    value: "KHA",
  },
  {
    label: "Vologda Oblast",
    countryCode: "RU",
    value: "VLG",
  },
  {
    label: "Sakhalin",
    countryCode: "RU",
    value: "SAK",
  },
  {
    label: "Altai Republic",
    countryCode: "RU",
    value: "AL",
  },
  {
    label: "Republic of Khakassia",
    countryCode: "RU",
    value: "KK",
  },
  {
    label: "Tambov Oblast",
    countryCode: "RU",
    value: "TAM",
  },
  {
    label: "Saint Petersburg",
    countryCode: "RU",
    value: "SPE",
  },
  {
    label: "Irkutsk",
    countryCode: "RU",
    value: "IRK",
  },
  {
    label: "Vladimir Oblast",
    countryCode: "RU",
    value: "VLA",
  },
  {
    label: "Moscow Oblast",
    countryCode: "RU",
    value: "MOS",
  },
  {
    label: "Republic of Kalmykia",
    countryCode: "RU",
    value: "KL",
  },
  {
    label: "Republic of Ingushetia",
    countryCode: "RU",
    value: "IN",
  },
  {
    label: "Smolensk Oblast",
    countryCode: "RU",
    value: "SMO",
  },
  {
    label: "Orenburg Oblast",
    countryCode: "RU",
    value: "ORE",
  },
  {
    label: "Saratov Oblast",
    countryCode: "RU",
    value: "SAR",
  },
  {
    label: "Novosibirsk",
    countryCode: "RU",
    value: "NVS",
  },
  {
    label: "Lipetsk Oblast",
    countryCode: "RU",
    value: "LIP",
  },
  {
    label: "Kirov Oblast",
    countryCode: "RU",
    value: "KIR",
  },
  {
    label: "Krasnodar Krai",
    countryCode: "RU",
    value: "KDA",
  },
  {
    label: "Kabardino-Balkar Republic",
    countryCode: "RU",
    value: "KB",
  },
  {
    label: "Chechen Republic",
    countryCode: "RU",
    value: "CE",
  },
  {
    label: "Sverdlovsk",
    countryCode: "RU",
    value: "SVE",
  },
  {
    label: "Tula Oblast",
    countryCode: "RU",
    value: "TUL",
  },
  {
    label: "Leningrad Oblast",
    countryCode: "RU",
    value: "LEN",
  },
  {
    label: "Kemerovo Oblast",
    countryCode: "RU",
    value: "KEM",
  },
  {
    label: "Republic of Mordovia",
    countryCode: "RU",
    value: "MO",
  },
  {
    label: "Komi Republic",
    countryCode: "RU",
    value: "KO",
  },
  {
    label: "Tuva Republic",
    countryCode: "RU",
    value: "TY",
  },
  {
    label: "Moscow",
    countryCode: "RU",
    value: "MOW",
  },
  {
    label: "Kaliningrad",
    countryCode: "RU",
    value: "KGD",
  },
  {
    label: "Belgorod Oblast",
    countryCode: "RU",
    value: "BEL",
  },
  {
    label: "Zabaykalsky Krai",
    countryCode: "RU",
    value: "ZAB",
  },
  {
    label: "Ryazan Oblast",
    countryCode: "RU",
    value: "RYA",
  },
  {
    label: "Voronezh Oblast",
    countryCode: "RU",
    value: "VOR",
  },
  {
    label: "Tyumen Oblast",
    countryCode: "RU",
    value: "TYU",
  },
  {
    label: "Oryol Oblast",
    countryCode: "RU",
    value: "ORL",
  },
  {
    label: "Penza Oblast",
    countryCode: "RU",
    value: "PNZ",
  },
  {
    label: "Kostroma Oblast",
    countryCode: "RU",
    value: "KOS",
  },
  {
    label: "Altai Krai",
    countryCode: "RU",
    value: "ALT",
  },
  {
    label: "Sevastopol",
    countryCode: "RU",
    value: "UA-40",
  },
  {
    label: "Udmurt Republic",
    countryCode: "RU",
    value: "UD",
  },
  {
    label: "Chuvash Republic",
    countryCode: "RU",
    value: "CU",
  },
  {
    label: "Kurgan Oblast",
    countryCode: "RU",
    value: "KGN",
  },
  {
    label: "Lomaiviti",
    countryCode: "FJ",
    value: "06",
  },
  {
    label: "Ba",
    countryCode: "FJ",
    value: "01",
  },
  {
    label: "Tailevu",
    countryCode: "FJ",
    value: "14",
  },
  {
    label: "Nadroga-Navosa",
    countryCode: "FJ",
    value: "08",
  },
  {
    label: "Rewa",
    countryCode: "FJ",
    value: "12",
  },
  {
    label: "Northern Division",
    countryCode: "FJ",
    value: "N",
  },
  {
    label: "Macuata",
    countryCode: "FJ",
    value: "07",
  },
  {
    label: "Western Division",
    countryCode: "FJ",
    value: "W",
  },
  {
    label: "Cakaudrove",
    countryCode: "FJ",
    value: "03",
  },
  {
    label: "Serua",
    countryCode: "FJ",
    value: "13",
  },
  {
    label: "Ra",
    countryCode: "FJ",
    value: "11",
  },
  {
    label: "Naitasiri",
    countryCode: "FJ",
    value: "09",
  },
  {
    label: "Namosi",
    countryCode: "FJ",
    value: "10",
  },
  {
    label: "Central Division",
    countryCode: "FJ",
    value: "C",
  },
  {
    label: "Bua",
    countryCode: "FJ",
    value: "02",
  },
  {
    label: "Rotuma",
    countryCode: "FJ",
    value: "R",
  },
  {
    label: "Eastern Division",
    countryCode: "FJ",
    value: "E",
  },
  {
    label: "Lau",
    countryCode: "FJ",
    value: "05",
  },
  {
    label: "Kadavu",
    countryCode: "FJ",
    value: "04",
  },
  {
    label: "Labuan",
    countryCode: "MY",
    value: "15",
  },
  {
    label: "Sabah",
    countryCode: "MY",
    value: "12",
  },
  {
    label: "Sarawak",
    countryCode: "MY",
    value: "13",
  },
  {
    label: "Perlis",
    countryCode: "MY",
    value: "09",
  },
  {
    label: "Penang",
    countryCode: "MY",
    value: "07",
  },
  {
    label: "Pahang",
    countryCode: "MY",
    value: "06",
  },
  {
    label: "Malacca",
    countryCode: "MY",
    value: "04",
  },
  {
    label: "Terengganu",
    countryCode: "MY",
    value: "11",
  },
  {
    label: "Perak",
    countryCode: "MY",
    value: "08",
  },
  {
    label: "Selangor",
    countryCode: "MY",
    value: "10",
  },
  {
    label: "Putrajaya",
    countryCode: "MY",
    value: "16",
  },
  {
    label: "Kelantan",
    countryCode: "MY",
    value: "03",
  },
  {
    label: "Kedah",
    countryCode: "MY",
    value: "02",
  },
  {
    label: "Negeri Sembilan",
    countryCode: "MY",
    value: "05",
  },
  {
    label: "Kuala Lumpur",
    countryCode: "MY",
    value: "14",
  },
  {
    label: "Johor",
    countryCode: "MY",
    value: "01",
  },
  {
    label: "Mashonaland East Province",
    countryCode: "ZW",
    value: "ME",
  },
  {
    label: "Matabeleland South Province",
    countryCode: "ZW",
    value: "MS",
  },
  {
    label: "Mashonaland West Province",
    countryCode: "ZW",
    value: "MW",
  },
  {
    label: "Matabeleland North Province",
    countryCode: "ZW",
    value: "MN",
  },
  {
    label: "Mashonaland Central Province",
    countryCode: "ZW",
    value: "MC",
  },
  {
    label: "Bulawayo Province",
    countryCode: "ZW",
    value: "BU",
  },
  {
    label: "Midlands Province",
    countryCode: "ZW",
    value: "MI",
  },
  {
    label: "Harare Province",
    countryCode: "ZW",
    value: "HA",
  },
  {
    label: "Manicaland",
    countryCode: "ZW",
    value: "MA",
  },
  {
    label: "Masvingo Province",
    countryCode: "ZW",
    value: "MV",
  },
  {
    label: "Bulgan Province",
    countryCode: "MN",
    value: "067",
  },
  {
    label: "Darkhan-Uul Province",
    countryCode: "MN",
    value: "037",
  },
  {
    label: "Dornod Province",
    countryCode: "MN",
    value: "061",
  },
  {
    label: "Khovd Province",
    countryCode: "MN",
    value: "043",
  },
  {
    label: "Övörkhangai Province",
    countryCode: "MN",
    value: "055",
  },
  {
    label: "Orkhon Province",
    countryCode: "MN",
    value: "035",
  },
  {
    label: "Ömnögovi Province",
    countryCode: "MN",
    value: "053",
  },
  {
    label: "Töv Province",
    countryCode: "MN",
    value: "047",
  },
  {
    label: "Bayan-Ölgii Province",
    countryCode: "MN",
    value: "071",
  },
  {
    label: "Dundgovi Province",
    countryCode: "MN",
    value: "059",
  },
  {
    label: "Uvs Province",
    countryCode: "MN",
    value: "046",
  },
  {
    label: "Govi-Altai Province",
    countryCode: "MN",
    value: "065",
  },
  {
    label: "Arkhangai Province",
    countryCode: "MN",
    value: "073",
  },
  {
    label: "Khentii Province",
    countryCode: "MN",
    value: "039",
  },
  {
    label: "Khövsgöl Province",
    countryCode: "MN",
    value: "041",
  },
  {
    label: "Bayankhongor Province",
    countryCode: "MN",
    value: "069",
  },
  {
    label: "Sükhbaatar Province",
    countryCode: "MN",
    value: "051",
  },
  {
    label: "Govisümber Province",
    countryCode: "MN",
    value: "064",
  },
  {
    label: "Zavkhan Province",
    countryCode: "MN",
    value: "057",
  },
  {
    label: "Selenge Province",
    countryCode: "MN",
    value: "049",
  },
  {
    label: "Dornogovi Province",
    countryCode: "MN",
    value: "063",
  },
  {
    label: "Northern Province",
    countryCode: "ZM",
    value: "05",
  },
  {
    label: "Western Province",
    countryCode: "ZM",
    value: "01",
  },
  {
    label: "Copperbelt Province",
    countryCode: "ZM",
    value: "08",
  },
  {
    label: "Northwestern Province",
    countryCode: "ZM",
    value: "06",
  },
  {
    label: "Central Province",
    countryCode: "ZM",
    value: "02",
  },
  {
    label: "Luapula Province",
    countryCode: "ZM",
    value: "04",
  },
  {
    label: "Lusaka Province",
    countryCode: "ZM",
    value: "09",
  },
  {
    label: "Muchinga Province",
    countryCode: "ZM",
    value: "10",
  },
  {
    label: "Southern Province",
    countryCode: "ZM",
    value: "07",
  },
  {
    label: "Eastern Province",
    countryCode: "ZM",
    value: "03",
  },
  {
    label: "Capital Governorate",
    countryCode: "BH",
    value: "13",
  },
  {
    label: "Southern Governorate",
    countryCode: "BH",
    value: "14",
  },
  {
    label: "Northern Governorate",
    countryCode: "BH",
    value: "17",
  },
  {
    label: "Muharraq Governorate",
    countryCode: "BH",
    value: "15",
  },
  {
    label: "Central Governorate",
    countryCode: "BH",
    value: "16",
  },
  {
    label: "Rio de Janeiro",
    countryCode: "BR",
    value: "RJ",
  },
  {
    label: "Minas Gerais",
    countryCode: "BR",
    value: "MG",
  },
  {
    label: "Amapá",
    countryCode: "BR",
    value: "AP",
  },
  {
    label: "Goiás",
    countryCode: "BR",
    value: "GO",
  },
  {
    label: "Rio Grande do Sul",
    countryCode: "BR",
    value: "RS",
  },
  {
    label: "Bahia",
    countryCode: "BR",
    value: "BA",
  },
  {
    label: "Sergipe",
    countryCode: "BR",
    value: "SE",
  },
  {
    label: "Amazonas",
    countryCode: "BR",
    value: "AM",
  },
  {
    label: "Paraíba",
    countryCode: "BR",
    value: "PB",
  },
  {
    label: "Pernambuco",
    countryCode: "BR",
    value: "PE",
  },
  {
    label: "Alagoas",
    countryCode: "BR",
    value: "AL",
  },
  {
    label: "Piauí",
    countryCode: "BR",
    value: "PI",
  },
  {
    label: "Pará",
    countryCode: "BR",
    value: "PA",
  },
  {
    label: "Mato Grosso do Sul",
    countryCode: "BR",
    value: "MS",
  },
  {
    label: "Mato Grosso",
    countryCode: "BR",
    value: "MT",
  },
  {
    label: "Acre",
    countryCode: "BR",
    value: "AC",
  },
  {
    label: "Rondônia",
    countryCode: "BR",
    value: "RO",
  },
  {
    label: "Santa Catarina",
    countryCode: "BR",
    value: "SC",
  },
  {
    label: "Maranhão",
    countryCode: "BR",
    value: "MA",
  },
  {
    label: "Ceará",
    countryCode: "BR",
    value: "CE",
  },
  {
    label: "Distrito Federal",
    countryCode: "BR",
    value: "DF",
  },
  {
    label: "Espírito Santo",
    countryCode: "BR",
    value: "ES",
  },
  {
    label: "Rio Grande do Norte",
    countryCode: "BR",
    value: "RN",
  },
  {
    label: "Tocantins",
    countryCode: "BR",
    value: "TO",
  },
  {
    label: "São Paulo",
    countryCode: "BR",
    value: "SP",
  },
  {
    label: "Paraná",
    countryCode: "BR",
    value: "PR",
  },
  {
    label: "Aragatsotn Region",
    countryCode: "AM",
    value: "AG",
  },
  {
    label: "Ararat Province",
    countryCode: "AM",
    value: "AR",
  },
  {
    label: "Vayots Dzor Region",
    countryCode: "AM",
    value: "VD",
  },
  {
    label: "Armavir Region",
    countryCode: "AM",
    value: "AV",
  },
  {
    label: "Syunik Province",
    countryCode: "AM",
    value: "SU",
  },
  {
    label: "Gegharkunik Province",
    countryCode: "AM",
    value: "GR",
  },
  {
    label: "Lori Region",
    countryCode: "AM",
    value: "LO",
  },
  {
    label: "Yerevan",
    countryCode: "AM",
    value: "ER",
  },
  {
    label: "Shirak Region",
    countryCode: "AM",
    value: "SH",
  },
  {
    label: "Tavush Region",
    countryCode: "AM",
    value: "TV",
  },
  {
    label: "Kotayk Region",
    countryCode: "AM",
    value: "KT",
  },
  {
    label: "Cojedes",
    countryCode: "VE",
    value: "H",
  },
  {
    label: "Falcón",
    countryCode: "VE",
    value: "I",
  },
  {
    label: "Portuguesa",
    countryCode: "VE",
    value: "P",
  },
  {
    label: "Miranda",
    countryCode: "VE",
    value: "M",
  },
  {
    label: "Lara",
    countryCode: "VE",
    value: "K",
  },
  {
    label: "Bolívar",
    countryCode: "VE",
    value: "F",
  },
  {
    label: "Carabobo",
    countryCode: "VE",
    value: "G",
  },
  {
    label: "Yaracuy",
    countryCode: "VE",
    value: "U",
  },
  {
    label: "Zulia",
    countryCode: "VE",
    value: "V",
  },
  {
    label: "Trujillo",
    countryCode: "VE",
    value: "T",
  },
  {
    label: "Amazonas",
    countryCode: "VE",
    value: "Z",
  },
  {
    label: "Guárico",
    countryCode: "VE",
    value: "J",
  },
  {
    label: "Federal Dependencies of Venezuela",
    countryCode: "VE",
    value: "W",
  },
  {
    label: "Aragua",
    countryCode: "VE",
    value: "D",
  },
  {
    label: "Táchira",
    countryCode: "VE",
    value: "S",
  },
  {
    label: "Barinas",
    countryCode: "VE",
    value: "E",
  },
  {
    label: "Anzoátegui",
    countryCode: "VE",
    value: "B",
  },
  {
    label: "Delta Amacuro",
    countryCode: "VE",
    value: "Y",
  },
  {
    label: "Nueva Esparta",
    countryCode: "VE",
    value: "O",
  },
  {
    label: "Mérida",
    countryCode: "VE",
    value: "L",
  },
  {
    label: "Monagas",
    countryCode: "VE",
    value: "N",
  },
  {
    label: "Vargas",
    countryCode: "VE",
    value: "X",
  },
  {
    label: "Sucre",
    countryCode: "VE",
    value: "R",
  },
  {
    label: "Carinthia",
    countryCode: "AT",
    value: "2",
  },
  {
    label: "Upper Austria",
    countryCode: "AT",
    value: "4",
  },
  {
    label: "Styria",
    countryCode: "AT",
    value: "6",
  },
  {
    label: "Vienna",
    countryCode: "AT",
    value: "9",
  },
  {
    label: "Salzburg",
    countryCode: "AT",
    value: "5",
  },
  {
    label: "Burgenland",
    countryCode: "AT",
    value: "1",
  },
  {
    label: "Vorarlberg",
    countryCode: "AT",
    value: "8",
  },
  {
    label: "Tyrol",
    countryCode: "AT",
    value: "7",
  },
  {
    label: "Lower Austria",
    countryCode: "AT",
    value: "3",
  },
  {
    label: "Mid-Western Region",
    countryCode: "NP",
    value: "2",
  },
  {
    label: "Western Region",
    countryCode: "NP",
    value: "3",
  },
  {
    label: "Far-Western Development Region",
    countryCode: "NP",
    value: "5",
  },
  {
    label: "Eastern Development Region",
    countryCode: "NP",
    value: "4",
  },
  {
    label: "Mechi Zone",
    countryCode: "NP",
    value: "ME",
  },
  {
    label: "Bheri Zone",
    countryCode: "NP",
    value: "BH",
  },
  {
    label: "Kosi Zone",
    countryCode: "NP",
    value: "KO",
  },
  {
    label: "Central Region",
    countryCode: "NP",
    value: "1",
  },
  {
    label: "Lumbini Zone",
    countryCode: "NP",
    value: "LU",
  },
  {
    label: "Narayani Zone",
    countryCode: "NP",
    value: "NA",
  },
  {
    label: "Janakpur Zone",
    countryCode: "NP",
    value: "JA",
  },
  {
    label: "Rapti Zone",
    countryCode: "NP",
    value: "RA",
  },
  {
    label: "Seti Zone",
    countryCode: "NP",
    value: "SE",
  },
  {
    label: "Karnali Zone",
    countryCode: "NP",
    value: "KA",
  },
  {
    label: "Dhaulagiri Zone",
    countryCode: "NP",
    value: "DH",
  },
  {
    label: "Gandaki Zone",
    countryCode: "NP",
    value: "GA",
  },
  {
    label: "Bagmati Zone",
    countryCode: "NP",
    value: "BA",
  },
  {
    label: "Mahakali Zone",
    countryCode: "NP",
    value: "MA",
  },
  {
    label: "Sagarmatha Zone",
    countryCode: "NP",
    value: "SA",
  },
  {
    label: "Unity",
    countryCode: "SS",
    value: "UY",
  },
  {
    label: "Upper Nile",
    countryCode: "SS",
    value: "NU",
  },
  {
    label: "Warrap",
    countryCode: "SS",
    value: "WR",
  },
  {
    label: "Northern Bahr el Ghazal",
    countryCode: "SS",
    value: "BN",
  },
  {
    label: "Western Equatoria",
    countryCode: "SS",
    value: "EW",
  },
  {
    label: "Lakes",
    countryCode: "SS",
    value: "LK",
  },
  {
    label: "Western Bahr el Ghazal",
    countryCode: "SS",
    value: "BW",
  },
  {
    label: "Central Equatoria",
    countryCode: "SS",
    value: "EC",
  },
  {
    label: "Eastern Equatoria",
    countryCode: "SS",
    value: "EE",
  },
  {
    label: "Jonglei State",
    countryCode: "SS",
    value: "JG",
  },
  {
    label: "Karditsa Regional Unit",
    countryCode: "GR",
    value: "41",
  },
  {
    label: "West Greece Region",
    countryCode: "GR",
    value: "G",
  },
  {
    label: "Thessaloniki Regional Unit",
    countryCode: "GR",
    value: "54",
  },
  {
    label: "Arcadia Prefecture",
    countryCode: "GR",
    value: "12",
  },
  {
    label: "Imathia Regional Unit",
    countryCode: "GR",
    value: "53",
  },
  {
    label: "Kastoria Regional Unit",
    countryCode: "GR",
    value: "56",
  },
  {
    label: "Euboea",
    countryCode: "GR",
    value: "04",
  },
  {
    label: "Grevena Prefecture",
    countryCode: "GR",
    value: "51",
  },
  {
    label: "Preveza Prefecture",
    countryCode: "GR",
    value: "34",
  },
  {
    label: "Lefkada Regional Unit",
    countryCode: "GR",
    value: "24",
  },
  {
    label: "Argolis Regional Unit",
    countryCode: "GR",
    value: "11",
  },
  {
    label: "Laconia",
    countryCode: "GR",
    value: "16",
  },
  {
    label: "Pella Regional Unit",
    countryCode: "GR",
    value: "59",
  },
  {
    label: "West Macedonia Region",
    countryCode: "GR",
    value: "C",
  },
  {
    label: "Crete Region",
    countryCode: "GR",
    value: "M",
  },
  {
    label: "Epirus Region",
    countryCode: "GR",
    value: "D",
  },
  {
    label: "Kilkis Regional Unit",
    countryCode: "GR",
    value: "57",
  },
  {
    label: "Kozani Prefecture",
    countryCode: "GR",
    value: "58",
  },
  {
    label: "Ioannina Regional Unit",
    countryCode: "GR",
    value: "33",
  },
  {
    label: "Phthiotis Prefecture",
    countryCode: "GR",
    value: "06",
  },
  {
    label: "Chania Regional Unit",
    countryCode: "GR",
    value: "94",
  },
  {
    label: "Achaea Regional Unit",
    countryCode: "GR",
    value: "13",
  },
  {
    label: "East Macedonia and Thrace",
    countryCode: "GR",
    value: "A",
  },
  {
    label: "South Aegean",
    countryCode: "GR",
    value: "L",
  },
  {
    label: "Peloponnese Region",
    countryCode: "GR",
    value: "J",
  },
  {
    label: "East Attica Regional Unit",
    countryCode: "GR",
    value: "A2",
  },
  {
    label: "Serres Prefecture",
    countryCode: "GR",
    value: "62",
  },
  {
    label: "Attica Region",
    countryCode: "GR",
    value: "I",
  },
  {
    label: "Aetolia-Acarnania Regional Unit",
    countryCode: "GR",
    value: "01",
  },
  {
    label: "Corfu Prefecture",
    countryCode: "GR",
    value: "22",
  },
  {
    label: "Central Macedonia",
    countryCode: "GR",
    value: "B",
  },
  {
    label: "Boeotia Regional Unit",
    countryCode: "GR",
    value: "03",
  },
  {
    label: "Kefalonia Prefecture",
    countryCode: "GR",
    value: "23",
  },
  {
    label: "Central Greece Region",
    countryCode: "GR",
    value: "H",
  },
  {
    label: "Corinthia Regional Unit",
    countryCode: "GR",
    value: "15",
  },
  {
    label: "Drama Regional Unit",
    countryCode: "GR",
    value: "52",
  },
  {
    label: "Ionian Islands Region",
    countryCode: "GR",
    value: "F",
  },
  {
    label: "Larissa Prefecture",
    countryCode: "GR",
    value: "42",
  },
  {
    label: "Kayin State",
    countryCode: "MM",
    value: "13",
  },
  {
    label: "Mandalay Region",
    countryCode: "MM",
    value: "04",
  },
  {
    label: "Yangon Region",
    countryCode: "MM",
    value: "06",
  },
  {
    label: "Magway Region",
    countryCode: "MM",
    value: "03",
  },
  {
    label: "Chin State",
    countryCode: "MM",
    value: "14",
  },
  {
    label: "Rakhine State",
    countryCode: "MM",
    value: "16",
  },
  {
    label: "Shan State",
    countryCode: "MM",
    value: "17",
  },
  {
    label: "Tanintharyi Region",
    countryCode: "MM",
    value: "05",
  },
  {
    label: "Bago",
    countryCode: "MM",
    value: "02",
  },
  {
    label: "Ayeyarwady Region",
    countryCode: "MM",
    value: "07",
  },
  {
    label: "Kachin State",
    countryCode: "MM",
    value: "11",
  },
  {
    label: "Kayah State",
    countryCode: "MM",
    value: "12",
  },
  {
    label: "Sagaing Region",
    countryCode: "MM",
    value: "01",
  },
  {
    label: "Naypyidaw Union Territory",
    countryCode: "MM",
    value: "18",
  },
  {
    label: "Mon State",
    countryCode: "MM",
    value: "15",
  },
  {
    label: "Bartın Province",
    countryCode: "TR",
    value: "74",
  },
  {
    label: "Kütahya Province",
    countryCode: "TR",
    value: "43",
  },
  {
    label: "Sakarya Province",
    countryCode: "TR",
    value: "54",
  },
  {
    label: "Edirne Province",
    countryCode: "TR",
    value: "22",
  },
  {
    label: "Van Province",
    countryCode: "TR",
    value: "65",
  },
  {
    label: "Bingöl Province",
    countryCode: "TR",
    value: "12",
  },
  {
    label: "Kilis Province",
    countryCode: "TR",
    value: "79",
  },
  {
    label: "Adıyaman Province",
    countryCode: "TR",
    value: "02",
  },
  {
    label: "Mersin Province",
    countryCode: "TR",
    value: "33",
  },
  {
    label: "Denizli Province",
    countryCode: "TR",
    value: "20",
  },
  {
    label: "Malatya Province",
    countryCode: "TR",
    value: "44",
  },
  {
    label: "Elazığ Province",
    countryCode: "TR",
    value: "23",
  },
  {
    label: "Erzincan Province",
    countryCode: "TR",
    value: "24",
  },
  {
    label: "Amasya Province",
    countryCode: "TR",
    value: "05",
  },
  {
    label: "Muş Province",
    countryCode: "TR",
    value: "49",
  },
  {
    label: "Bursa Province",
    countryCode: "TR",
    value: "16",
  },
  {
    label: "Eskişehir Province",
    countryCode: "TR",
    value: "26",
  },
  {
    label: "Erzurum Province",
    countryCode: "TR",
    value: "25",
  },
  {
    label: "Iğdır Province",
    countryCode: "TR",
    value: "76",
  },
  {
    label: "Tekirdağ Province",
    countryCode: "TR",
    value: "59",
  },
  {
    label: "Çankırı Province",
    countryCode: "TR",
    value: "18",
  },
  {
    label: "Antalya Province",
    countryCode: "TR",
    value: "07",
  },
  {
    label: "Istanbul Province",
    countryCode: "TR",
    value: "34",
  },
  {
    label: "Konya Province",
    countryCode: "TR",
    value: "42",
  },
  {
    label: "Bolu Province",
    countryCode: "TR",
    value: "14",
  },
  {
    label: "Çorum Province",
    countryCode: "TR",
    value: "19",
  },
  {
    label: "Ordu Province",
    countryCode: "TR",
    value: "52",
  },
  {
    label: "Balıkesir Province",
    countryCode: "TR",
    value: "10",
  },
  {
    label: "Kırklareli Province",
    countryCode: "TR",
    value: "39",
  },
  {
    label: "Bayburt Province",
    countryCode: "TR",
    value: "69",
  },
  {
    label: "Kırıkkale Province",
    countryCode: "TR",
    value: "71",
  },
  {
    label: "Afyonkarahisar Province",
    countryCode: "TR",
    value: "03",
  },
  {
    label: "Kırşehir Province",
    countryCode: "TR",
    value: "40",
  },
  {
    label: "Sivas Province",
    countryCode: "TR",
    value: "58",
  },
  {
    label: "Muğla Province",
    countryCode: "TR",
    value: "48",
  },
  {
    label: "Şanlıurfa Province",
    countryCode: "TR",
    value: "63",
  },
  {
    label: "Karaman Province",
    countryCode: "TR",
    value: "70",
  },
  {
    label: "Ardahan Province",
    countryCode: "TR",
    value: "75",
  },
  {
    label: "Giresun Province",
    countryCode: "TR",
    value: "28",
  },
  {
    label: "Aydın Province",
    countryCode: "TR",
    value: "09",
  },
  {
    label: "Yozgat Province",
    countryCode: "TR",
    value: "66",
  },
  {
    label: "Niğde Province",
    countryCode: "TR",
    value: "51",
  },
  {
    label: "Hakkâri Province",
    countryCode: "TR",
    value: "30",
  },
  {
    label: "Artvin Province",
    countryCode: "TR",
    value: "08",
  },
  {
    label: "Tunceli Province",
    countryCode: "TR",
    value: "62",
  },
  {
    label: "Ağrı Province",
    countryCode: "TR",
    value: "04",
  },
  {
    label: "Batman Province",
    countryCode: "TR",
    value: "72",
  },
  {
    label: "Kocaeli Province",
    countryCode: "TR",
    value: "41",
  },
  {
    label: "Nevşehir Province",
    countryCode: "TR",
    value: "50",
  },
  {
    label: "Kastamonu Province",
    countryCode: "TR",
    value: "37",
  },
  {
    label: "Manisa Province",
    countryCode: "TR",
    value: "45",
  },
  {
    label: "Tokat Province",
    countryCode: "TR",
    value: "60",
  },
  {
    label: "Kayseri Province",
    countryCode: "TR",
    value: "38",
  },
  {
    label: "Uşak Province",
    countryCode: "TR",
    value: "64",
  },
  {
    label: "Düzce Province",
    countryCode: "TR",
    value: "81",
  },
  {
    label: "Gaziantep Province",
    countryCode: "TR",
    value: "27",
  },
  {
    label: "Gümüşhane Province",
    countryCode: "TR",
    value: "29",
  },
  {
    label: "İzmir Province",
    countryCode: "TR",
    value: "35",
  },
  {
    label: "Trabzon Province",
    countryCode: "TR",
    value: "61",
  },
  {
    label: "Siirt Province",
    countryCode: "TR",
    value: "56",
  },
  {
    label: "Kars Province",
    countryCode: "TR",
    value: "36",
  },
  {
    label: "Burdur Province",
    countryCode: "TR",
    value: "15",
  },
  {
    label: "Aksaray Province",
    countryCode: "TR",
    value: "68",
  },
  {
    label: "Hatay Province",
    countryCode: "TR",
    value: "31",
  },
  {
    label: "Adana Province",
    countryCode: "TR",
    value: "01",
  },
  {
    label: "Zonguldak Province",
    countryCode: "TR",
    value: "67",
  },
  {
    label: "Osmaniye Province",
    countryCode: "TR",
    value: "80",
  },
  {
    label: "Bitlis Province",
    countryCode: "TR",
    value: "13",
  },
  {
    label: "Çanakkale Province",
    countryCode: "TR",
    value: "17",
  },
  {
    label: "Ankara Province",
    countryCode: "TR",
    value: "06",
  },
  {
    label: "Yalova Province",
    countryCode: "TR",
    value: "77",
  },
  {
    label: "Rize Province",
    countryCode: "TR",
    value: "53",
  },
  {
    label: "Samsun Province",
    countryCode: "TR",
    value: "55",
  },
  {
    label: "Bilecik Province",
    countryCode: "TR",
    value: "11",
  },
  {
    label: "Isparta Province",
    countryCode: "TR",
    value: "32",
  },
  {
    label: "Karabük Province",
    countryCode: "TR",
    value: "78",
  },
  {
    label: "Mardin Province",
    countryCode: "TR",
    value: "47",
  },
  {
    label: "Şırnak Province",
    countryCode: "TR",
    value: "73",
  },
  {
    label: "Diyarbakır Province",
    countryCode: "TR",
    value: "21",
  },
  {
    label: "Kahramanmaraş Province",
    countryCode: "TR",
    value: "46",
  },
  {
    label: "Lisbon",
    countryCode: "PT",
    value: "11",
  },
  {
    label: "Bragança",
    countryCode: "PT",
    value: "04",
  },
  {
    label: "Beja",
    countryCode: "PT",
    value: "02",
  },
  {
    label: "Madeira",
    countryCode: "PT",
    value: "30",
  },
  {
    label: "Portalegre",
    countryCode: "PT",
    value: "12",
  },
  {
    label: "Açores",
    countryCode: "PT",
    value: "20",
  },
  {
    label: "Vila Real",
    countryCode: "PT",
    value: "17",
  },
  {
    label: "Aveiro",
    countryCode: "PT",
    value: "01",
  },
  {
    label: "Évora",
    countryCode: "PT",
    value: "07",
  },
  {
    label: "Viseu",
    countryCode: "PT",
    value: "18",
  },
  {
    label: "Santarém",
    countryCode: "PT",
    value: "14",
  },
  {
    label: "Faro",
    countryCode: "PT",
    value: "08",
  },
  {
    label: "Leiria",
    countryCode: "PT",
    value: "10",
  },
  {
    label: "Castelo Branco",
    countryCode: "PT",
    value: "05",
  },
  {
    label: "Setúbal",
    countryCode: "PT",
    value: "15",
  },
  {
    label: "Porto",
    countryCode: "PT",
    value: "13",
  },
  {
    label: "Braga",
    countryCode: "PT",
    value: "03",
  },
  {
    label: "Viana do Castelo",
    countryCode: "PT",
    value: "16",
  },
  {
    label: "Coimbra",
    countryCode: "PT",
    value: "06",
  },
  {
    label: "Zhejiang",
    countryCode: "CN",
    value: "ZJ",
  },
  {
    label: "Fujian",
    countryCode: "CN",
    value: "FJ",
  },
  {
    label: "Shanghai",
    countryCode: "CN",
    value: "SH",
  },
  {
    label: "Jiangsu",
    countryCode: "CN",
    value: "JS",
  },
  {
    label: "Anhui",
    countryCode: "CN",
    value: "AH",
  },
  {
    label: "Shandong",
    countryCode: "CN",
    value: "SD",
  },
  {
    label: "Jilin",
    countryCode: "CN",
    value: "JL",
  },
  {
    label: "Shanxi",
    countryCode: "CN",
    value: "SX",
  },
  {
    label: "Taiwan Province, People's Republic of China",
    countryCode: "CN",
    value: "TW",
  },
  {
    label: "Jiangxi",
    countryCode: "CN",
    value: "JX",
  },
  {
    label: "Beijing",
    countryCode: "CN",
    value: "BJ",
  },
  {
    label: "Hunan",
    countryCode: "CN",
    value: "HN",
  },
  {
    label: "Henan",
    countryCode: "CN",
    value: "HA",
  },
  {
    label: "Yunnan",
    countryCode: "CN",
    value: "YN",
  },
  {
    label: "Guizhou",
    countryCode: "CN",
    value: "GZ",
  },
  {
    label: "Ningxia Hui Autonomous Region",
    countryCode: "CN",
    value: "NX",
  },
  {
    label: "Xinjiang",
    countryCode: "CN",
    value: "XJ",
  },
  {
    label: "Tibet Autonomous Region",
    countryCode: "CN",
    value: "XZ",
  },
  {
    label: "Heilongjiang",
    countryCode: "CN",
    value: "HL",
  },
  {
    label: "Macau",
    countryCode: "CN",
    value: "MO",
  },
  {
    label: "Hong Kong",
    countryCode: "CN",
    value: "HK",
  },
  {
    label: "Liaoning",
    countryCode: "CN",
    value: "LN",
  },
  {
    label: "Inner Mongolia",
    countryCode: "CN",
    value: "NM",
  },
  {
    label: "Qinghai",
    countryCode: "CN",
    value: "QH",
  },
  {
    label: "Chongqing",
    countryCode: "CN",
    value: "CQ",
  },
  {
    label: "Shaanxi",
    countryCode: "CN",
    value: "SN",
  },
  {
    label: "Hainan",
    countryCode: "CN",
    value: "HI",
  },
  {
    label: "Hubei",
    countryCode: "CN",
    value: "HB",
  },
  {
    label: "Gansu",
    countryCode: "CN",
    value: "GS",
  },
  {
    label: "Keelung",
    countryCode: "CN",
    value: "TW-KEE",
  },
  {
    label: "Sichuan",
    countryCode: "CN",
    value: "SC",
  },
  {
    label: "Guangxi Zhuang Autonomous Region",
    countryCode: "CN",
    value: "GX",
  },
  {
    label: "Guangdong",
    countryCode: "CN",
    value: "GD",
  },
  {
    label: "Hebei",
    countryCode: "CN",
    value: "HE",
  },
  {
    label: "South Governorate",
    countryCode: "LB",
    value: "JA",
  },
  {
    label: "Mount Lebanon Governorate",
    countryCode: "LB",
    value: "JL",
  },
  {
    label: "Baalbek-Hermel Governorate",
    countryCode: "LB",
    value: "BH",
  },
  {
    label: "North Governorate",
    countryCode: "LB",
    value: "AS",
  },
  {
    label: "Akkar Governorate",
    countryCode: "LB",
    value: "AK",
  },
  {
    label: "Beirut Governorate",
    countryCode: "LB",
    value: "BA",
  },
  {
    label: "Beqaa Governorate",
    countryCode: "LB",
    value: "BI",
  },
  {
    label: "Nabatieh Governorate",
    countryCode: "LB",
    value: "NA",
  },
  {
    label: "Isle of Wight",
    countryCode: "GB",
    value: "IOW",
  },
  {
    label: "St Helens",
    countryCode: "GB",
    value: "SHN",
  },
  {
    label: "London Borough of Brent",
    countryCode: "GB",
    value: "BEN",
  },
  {
    label: "Walsall",
    countryCode: "GB",
    value: "WLL",
  },
  {
    label: "Trafford",
    countryCode: "GB",
    value: "TRF",
  },
  {
    label: "City of Southampton",
    countryCode: "GB",
    value: "STH",
  },
  {
    label: "Sheffield",
    countryCode: "GB",
    value: "SHF",
  },
  {
    label: "West Sussex",
    countryCode: "GB",
    value: "WSX",
  },
  {
    label: "City of Peterborough",
    countryCode: "GB",
    value: "PTE",
  },
  {
    label: "Caerphilly County Borough",
    countryCode: "GB",
    value: "CAY",
  },
  {
    label: "Vale of Glamorgan",
    countryCode: "GB",
    value: "VGL",
  },
  {
    label: "Shetland Islands",
    countryCode: "GB",
    value: "ZET",
  },
  {
    label: "Rhondda Cynon Taf",
    countryCode: "GB",
    value: "RCT",
  },
  {
    label: "Poole",
    countryCode: "GB",
    value: "POL",
  },
  {
    label: "Central Bedfordshire",
    countryCode: "GB",
    value: "CBF",
  },
  {
    label: "Down District Council",
    countryCode: "GB",
    value: "DOW",
  },
  {
    label: "City of Portsmouth",
    countryCode: "GB",
    value: "POR",
  },
  {
    label: "London Borough of Haringey",
    countryCode: "GB",
    value: "HRY",
  },
  {
    label: "London Borough of Bexley",
    countryCode: "GB",
    value: "BEX",
  },
  {
    label: "Rotherham",
    countryCode: "GB",
    value: "ROT",
  },
  {
    label: "Hartlepool",
    countryCode: "GB",
    value: "HPL",
  },
  {
    label: "Telford and Wrekin",
    countryCode: "GB",
    value: "TFW",
  },
  {
    label: "Belfast district",
    countryCode: "GB",
    value: "BFS",
  },
  {
    label: "Cornwall",
    countryCode: "GB",
    value: "CON",
  },
  {
    label: "London Borough of Sutton",
    countryCode: "GB",
    value: "STN",
  },
  {
    label: "Omagh District Council",
    countryCode: "GB",
    value: "OMH",
  },
  {
    label: "Banbridge",
    countryCode: "GB",
    value: "BNB",
  },
  {
    label: "Causeway Coast and Glens",
    countryCode: "GB",
    value: "CCG",
  },
  {
    label: "Newtownabbey Borough Council",
    countryCode: "GB",
    value: "NTA",
  },
  {
    label: "City of Leicester",
    countryCode: "GB",
    value: "LCE",
  },
  {
    label: "London Borough of Islington",
    countryCode: "GB",
    value: "ISL",
  },
  {
    label: "Metropolitan Borough of Wigan",
    countryCode: "GB",
    value: "WGN",
  },
  {
    label: "Oxfordshire",
    countryCode: "GB",
    value: "OXF",
  },
  {
    label: "Magherafelt District Council",
    countryCode: "GB",
    value: "MFT",
  },
  {
    label: "Southend-on-Sea",
    countryCode: "GB",
    value: "SOS",
  },
  {
    label: "Armagh, Banbridge and Craigavon",
    countryCode: "GB",
    value: "ABC",
  },
  {
    label: "Perth and Kinross",
    countryCode: "GB",
    value: "PKN",
  },
  {
    label: "London Borough of Waltham Forest",
    countryCode: "GB",
    value: "WFT",
  },
  {
    label: "Rochdale",
    countryCode: "GB",
    value: "RCH",
  },
  {
    label: "Merthyr Tydfil County Borough",
    countryCode: "GB",
    value: "MTY",
  },
  {
    label: "Blackburn with Darwen",
    countryCode: "GB",
    value: "BBD",
  },
  {
    label: "Knowsley",
    countryCode: "GB",
    value: "KWL",
  },
  {
    label: "Armagh City and District Council",
    countryCode: "GB",
    value: "ARM",
  },
  {
    label: "Middlesbrough",
    countryCode: "GB",
    value: "MDB",
  },
  {
    label: "East Renfrewshire",
    countryCode: "GB",
    value: "ERW",
  },
  {
    label: "Cumbria",
    countryCode: "GB",
    value: "CMA",
  },
  {
    label: "Scotland",
    countryCode: "GB",
    value: "SCT",
  },
  {
    label: "England",
    countryCode: "GB",
    value: "ENG",
  },
  {
    label: "Northern Ireland",
    countryCode: "GB",
    value: "NIR",
  },
  {
    label: "Wales",
    countryCode: "GB",
    value: "WLS",
  },
  {
    label: "Bath and North East Somerset",
    countryCode: "GB",
    value: "BAS",
  },
  {
    label: "Liverpool",
    countryCode: "GB",
    value: "LIV",
  },
  {
    label: "Sandwell",
    countryCode: "GB",
    value: "SAW",
  },
  {
    label: "Bournemouth",
    countryCode: "GB",
    value: "BMH",
  },
  {
    label: "Isles of Scilly",
    countryCode: "GB",
    value: "IOS",
  },
  {
    label: "Falkirk",
    countryCode: "GB",
    value: "FAL",
  },
  {
    label: "Dorset",
    countryCode: "GB",
    value: "DOR",
  },
  {
    label: "Scottish Borders",
    countryCode: "GB",
    value: "SCB",
  },
  {
    label: "London Borough of Havering",
    countryCode: "GB",
    value: "HAV",
  },
  {
    label: "Moyle District Council",
    countryCode: "GB",
    value: "MYL",
  },
  {
    label: "London Borough of Camden",
    countryCode: "GB",
    value: "CMD",
  },
  {
    label: "Newry and Mourne District Council",
    countryCode: "GB",
    value: "NYM",
  },
  {
    label: "Neath Port Talbot County Borough",
    countryCode: "GB",
    value: "NTL",
  },
  {
    label: "Conwy County Borough",
    countryCode: "GB",
    value: "CWY",
  },
  {
    label: "Outer Hebrides",
    countryCode: "GB",
    value: "ELS",
  },
  {
    label: "West Lothian",
    countryCode: "GB",
    value: "WLN",
  },
  {
    label: "Lincolnshire",
    countryCode: "GB",
    value: "LIN",
  },
  {
    label: "London Borough of Barking and Dagenham",
    countryCode: "GB",
    value: "BDG",
  },
  {
    label: "City of Westminster",
    countryCode: "GB",
    value: "WSM",
  },
  {
    label: "London Borough of Lewisham",
    countryCode: "GB",
    value: "LEW",
  },
  {
    label: "City of Nottingham",
    countryCode: "GB",
    value: "NGM",
  },
  {
    label: "Moray",
    countryCode: "GB",
    value: "MRY",
  },
  {
    label: "Ballymoney",
    countryCode: "GB",
    value: "BLY",
  },
  {
    label: "South Lanarkshire",
    countryCode: "GB",
    value: "SLK",
  },
  {
    label: "Ballymena Borough",
    countryCode: "GB",
    value: "BLA",
  },
  {
    label: "Doncaster",
    countryCode: "GB",
    value: "DNC",
  },
  {
    label: "Northumberland",
    countryCode: "GB",
    value: "NBL",
  },
  {
    label: "Fermanagh and Omagh",
    countryCode: "GB",
    value: "FMO",
  },
  {
    label: "Tameside",
    countryCode: "GB",
    value: "TAM",
  },
  {
    label: "Royal Borough of Kensington and Chelsea",
    countryCode: "GB",
    value: "KEC",
  },
  {
    label: "Hertfordshire",
    countryCode: "GB",
    value: "HRT",
  },
  {
    label: "East Riding of Yorkshire",
    countryCode: "GB",
    value: "ERY",
  },
  {
    label: "Kirklees",
    countryCode: "GB",
    value: "KIR",
  },
  {
    label: "City of Sunderland",
    countryCode: "GB",
    value: "SND",
  },
  {
    label: "Gloucestershire",
    countryCode: "GB",
    value: "GLS",
  },
  {
    label: "East Ayrshire",
    countryCode: "GB",
    value: "EAY",
  },
  {
    label: "United Kingdom",
    countryCode: "GB",
    value: "UKM",
  },
  {
    label: "London Borough of Hillingdon",
    countryCode: "GB",
    value: "HIL",
  },
  {
    label: "South Ayrshire",
    countryCode: "GB",
    value: "SAY",
  },
  {
    label: "Ascension Island",
    countryCode: "GB",
    value: "SH-AC",
  },
  {
    label: "Gwynedd",
    countryCode: "GB",
    value: "GWN",
  },
  {
    label: "London Borough of Hounslow",
    countryCode: "GB",
    value: "HNS",
  },
  {
    label: "Medway",
    countryCode: "GB",
    value: "MDW",
  },
  {
    label: "Limavady Borough Council",
    countryCode: "GB",
    value: "LMV",
  },
  {
    label: "Highland",
    countryCode: "GB",
    value: "HLD",
  },
  {
    label: "North East Lincolnshire",
    countryCode: "GB",
    value: "NEL",
  },
  {
    label: "London Borough of Harrow",
    countryCode: "GB",
    value: "HRW",
  },
  {
    label: "Somerset",
    countryCode: "GB",
    value: "SOM",
  },
  {
    label: "Angus",
    countryCode: "GB",
    value: "ANS",
  },
  {
    label: "Inverclyde",
    countryCode: "GB",
    value: "IVC",
  },
  {
    label: "Darlington",
    countryCode: "GB",
    value: "DAL",
  },
  {
    label: "London Borough of Tower Hamlets",
    countryCode: "GB",
    value: "TWH",
  },
  {
    label: "Wiltshire",
    countryCode: "GB",
    value: "WIL",
  },
  {
    label: "Argyll and Bute",
    countryCode: "GB",
    value: "AGB",
  },
  {
    label: "Strabane District Council",
    countryCode: "GB",
    value: "STB",
  },
  {
    label: "Stockport",
    countryCode: "GB",
    value: "SKP",
  },
  {
    label: "Brighton and Hove",
    countryCode: "GB",
    value: "BNH",
  },
  {
    label: "London Borough of Lambeth",
    countryCode: "GB",
    value: "LBH",
  },
  {
    label: "London Borough of Redbridge",
    countryCode: "GB",
    value: "RDB",
  },
  {
    label: "Manchester",
    countryCode: "GB",
    value: "MAN",
  },
  {
    label: "Mid Ulster",
    countryCode: "GB",
    value: "MUL",
  },
  {
    label: "South Gloucestershire",
    countryCode: "GB",
    value: "SGC",
  },
  {
    label: "Aberdeenshire",
    countryCode: "GB",
    value: "ABD",
  },
  {
    label: "Monmouthshire",
    countryCode: "GB",
    value: "MON",
  },
  {
    label: "Derbyshire",
    countryCode: "GB",
    value: "DBY",
  },
  {
    label: "Glasgow",
    countryCode: "GB",
    value: "GLG",
  },
  {
    label: "Buckinghamshire",
    countryCode: "GB",
    value: "BKM",
  },
  {
    label: "County Durham",
    countryCode: "GB",
    value: "DUR",
  },
  {
    label: "Shropshire",
    countryCode: "GB",
    value: "SHR",
  },
  {
    label: "Wirral",
    countryCode: "GB",
    value: "WRL",
  },
  {
    label: "South Tyneside",
    countryCode: "GB",
    value: "STY",
  },
  {
    label: "Essex",
    countryCode: "GB",
    value: "ESS",
  },
  {
    label: "London Borough of Hackney",
    countryCode: "GB",
    value: "HCK",
  },
  {
    label: "Antrim and Newtownabbey",
    countryCode: "GB",
    value: "ANN",
  },
  {
    label: "City of Bristol",
    countryCode: "GB",
    value: "BST",
  },
  {
    label: "East Sussex",
    countryCode: "GB",
    value: "ESX",
  },
  {
    label: "Dumfries and Galloway",
    countryCode: "GB",
    value: "DGY",
  },
  {
    label: "Milton Keynes",
    countryCode: "GB",
    value: "MIK",
  },
  {
    label: "Derry City Council",
    countryCode: "GB",
    value: "DRY",
  },
  {
    label: "London Borough of Newham",
    countryCode: "GB",
    value: "NWM",
  },
  {
    label: "Wokingham",
    countryCode: "GB",
    value: "WOK",
  },
  {
    label: "Warrington",
    countryCode: "GB",
    value: "WRT",
  },
  {
    label: "Stockton-on-Tees",
    countryCode: "GB",
    value: "STT",
  },
  {
    label: "Swindon",
    countryCode: "GB",
    value: "SWD",
  },
  {
    label: "Cambridgeshire",
    countryCode: "GB",
    value: "CAM",
  },
  {
    label: "City of London",
    countryCode: "GB",
    value: "LND",
  },
  {
    label: "Birmingham",
    countryCode: "GB",
    value: "BIR",
  },
  {
    label: "City of York",
    countryCode: "GB",
    value: "YOR",
  },
  {
    label: "Slough",
    countryCode: "GB",
    value: "SLG",
  },
  {
    label: "Edinburgh",
    countryCode: "GB",
    value: "EDH",
  },
  {
    label: "Mid and East Antrim",
    countryCode: "GB",
    value: "MEA",
  },
  {
    label: "North Somerset",
    countryCode: "GB",
    value: "NSM",
  },
  {
    label: "Gateshead",
    countryCode: "GB",
    value: "GAT",
  },
  {
    label: "London Borough of Southwark",
    countryCode: "GB",
    value: "SWK",
  },
  {
    label: "City and County of Swansea",
    countryCode: "GB",
    value: "SWA",
  },
  {
    label: "London Borough of Wandsworth",
    countryCode: "GB",
    value: "WND",
  },
  {
    label: "Hampshire",
    countryCode: "GB",
    value: "HAM",
  },
  {
    label: "Wrexham County Borough",
    countryCode: "GB",
    value: "WRX",
  },
  {
    label: "Flintshire",
    countryCode: "GB",
    value: "FLN",
  },
  {
    label: "Coventry",
    countryCode: "GB",
    value: "COV",
  },
  {
    label: "Carrickfergus Borough Council",
    countryCode: "GB",
    value: "CKF",
  },
  {
    label: "West Dunbartonshire",
    countryCode: "GB",
    value: "WDU",
  },
  {
    label: "Powys",
    countryCode: "GB",
    value: "POW",
  },
  {
    label: "Cheshire West and Chester",
    countryCode: "GB",
    value: "CHW",
  },
  {
    label: "Renfrewshire",
    countryCode: "GB",
    value: "RFW",
  },
  {
    label: "Cheshire East",
    countryCode: "GB",
    value: "CHE",
  },
  {
    label: "Cookstown District Council",
    countryCode: "GB",
    value: "CKT",
  },
  {
    label: "Derry City and Strabane",
    countryCode: "GB",
    value: "DRS",
  },
  {
    label: "Staffordshire",
    countryCode: "GB",
    value: "STS",
  },
  {
    label: "London Borough of Hammersmith and Fulham",
    countryCode: "GB",
    value: "HMF",
  },
  {
    label: "Craigavon Borough Council",
    countryCode: "GB",
    value: "CGV",
  },
  {
    label: "Clackmannanshire",
    countryCode: "GB",
    value: "CLK",
  },
  {
    label: "Blackpool",
    countryCode: "GB",
    value: "BPL",
  },
  {
    label: "Bridgend County Borough",
    countryCode: "GB",
    value: "BGE",
  },
  {
    label: "North Lincolnshire",
    countryCode: "GB",
    value: "NLN",
  },
  {
    label: "East Dunbartonshire",
    countryCode: "GB",
    value: "EDU",
  },
  {
    label: "Reading",
    countryCode: "GB",
    value: "RDG",
  },
  {
    label: "Nottinghamshire",
    countryCode: "GB",
    value: "NTT",
  },
  {
    label: "Dudley",
    countryCode: "GB",
    value: "DUD",
  },
  {
    label: "Newcastle upon Tyne",
    countryCode: "GB",
    value: "NET",
  },
  {
    label: "Bury",
    countryCode: "GB",
    value: "BUR",
  },
  {
    label: "Lisburn and Castlereagh",
    countryCode: "GB",
    value: "LBC",
  },
  {
    label: "Coleraine Borough Council",
    countryCode: "GB",
    value: "CLR",
  },
  {
    label: "East Lothian",
    countryCode: "GB",
    value: "ELN",
  },
  {
    label: "Aberdeen",
    countryCode: "GB",
    value: "ABE",
  },
  {
    label: "Kent",
    countryCode: "GB",
    value: "KEN",
  },
  {
    label: "Wakefield",
    countryCode: "GB",
    value: "WKF",
  },
  {
    label: "Halton",
    countryCode: "GB",
    value: "HAL",
  },
  {
    label: "Suffolk",
    countryCode: "GB",
    value: "SFK",
  },
  {
    label: "Thurrock",
    countryCode: "GB",
    value: "THR",
  },
  {
    label: "Solihull",
    countryCode: "GB",
    value: "SOL",
  },
  {
    label: "Bracknell Forest",
    countryCode: "GB",
    value: "BRC",
  },
  {
    label: "West Berkshire",
    countryCode: "GB",
    value: "WBK",
  },
  {
    label: "Rutland",
    countryCode: "GB",
    value: "RUT",
  },
  {
    label: "Norfolk",
    countryCode: "GB",
    value: "NFK",
  },
  {
    label: "Orkney Islands",
    countryCode: "GB",
    value: "ORK",
  },
  {
    label: "City of Kingston upon Hull",
    countryCode: "GB",
    value: "KHL",
  },
  {
    label: "London Borough of Enfield",
    countryCode: "GB",
    value: "ENF",
  },
  {
    label: "Oldham",
    countryCode: "GB",
    value: "OLD",
  },
  {
    label: "Torbay",
    countryCode: "GB",
    value: "TOB",
  },
  {
    label: "Fife",
    countryCode: "GB",
    value: "FIF",
  },
  {
    label: "Northamptonshire",
    countryCode: "GB",
    value: "NTH",
  },
  {
    label: "Royal Borough of Kingston upon Thames",
    countryCode: "GB",
    value: "KTT",
  },
  {
    label: "Windsor and Maidenhead",
    countryCode: "GB",
    value: "WNM",
  },
  {
    label: "London Borough of Merton",
    countryCode: "GB",
    value: "MRT",
  },
  {
    label: "Carmarthenshire",
    countryCode: "GB",
    value: "CMN",
  },
  {
    label: "City of Derby",
    countryCode: "GB",
    value: "DER",
  },
  {
    label: "Pembrokeshire",
    countryCode: "GB",
    value: "PEM",
  },
  {
    label: "North Lanarkshire",
    countryCode: "GB",
    value: "NLK",
  },
  {
    label: "Stirling",
    countryCode: "GB",
    value: "STG",
  },
  {
    label: "City of Wolverhampton",
    countryCode: "GB",
    value: "WLV",
  },
  {
    label: "London Borough of Bromley",
    countryCode: "GB",
    value: "BRY",
  },
  {
    label: "Devon",
    countryCode: "GB",
    value: "DEV",
  },
  {
    label: "Royal Borough of Greenwich",
    countryCode: "GB",
    value: "GRE",
  },
  {
    label: "Salford",
    countryCode: "GB",
    value: "SLF",
  },
  {
    label: "Lisburn City Council",
    countryCode: "GB",
    value: "LSB",
  },
  {
    label: "Lancashire",
    countryCode: "GB",
    value: "LAN",
  },
  {
    label: "Torfaen",
    countryCode: "GB",
    value: "TOF",
  },
  {
    label: "Denbighshire",
    countryCode: "GB",
    value: "DEN",
  },
  {
    label: "Ards",
    countryCode: "GB",
    value: "ARD",
  },
  {
    label: "Barnsley",
    countryCode: "GB",
    value: "BNS",
  },
  {
    label: "Herefordshire",
    countryCode: "GB",
    value: "HEF",
  },
  {
    label: "London Borough of Richmond upon Thames",
    countryCode: "GB",
    value: "RIC",
  },
  {
    label: "Saint Helena",
    countryCode: "GB",
    value: "SH-HL",
  },
  {
    label: "Leeds",
    countryCode: "GB",
    value: "LDS",
  },
  {
    label: "Bolton",
    countryCode: "GB",
    value: "BOL",
  },
  {
    label: "Warwickshire",
    countryCode: "GB",
    value: "WAR",
  },
  {
    label: "City of Stoke-on-Trent",
    countryCode: "GB",
    value: "STE",
  },
  {
    label: "Bedford",
    countryCode: "GB",
    value: "BDF",
  },
  {
    label: "Dungannon and South Tyrone Borough Council",
    countryCode: "GB",
    value: "DGN",
  },
  {
    label: "Ceredigion",
    countryCode: "GB",
    value: "CGN",
  },
  {
    label: "Worcestershire",
    countryCode: "GB",
    value: "WOR",
  },
  {
    label: "Dundee",
    countryCode: "GB",
    value: "DND",
  },
  {
    label: "London Borough of Croydon",
    countryCode: "GB",
    value: "CRY",
  },
  {
    label: "North Down Borough Council",
    countryCode: "GB",
    value: "NDN",
  },
  {
    label: "City of Plymouth",
    countryCode: "GB",
    value: "PLY",
  },
  {
    label: "Larne Borough Council",
    countryCode: "GB",
    value: "LRN",
  },
  {
    label: "Leicestershire",
    countryCode: "GB",
    value: "LEC",
  },
  {
    label: "Calderdale",
    countryCode: "GB",
    value: "CLD",
  },
  {
    label: "Sefton",
    countryCode: "GB",
    value: "SFT",
  },
  {
    label: "Midlothian",
    countryCode: "GB",
    value: "MLN",
  },
  {
    label: "London Borough of Barnet",
    countryCode: "GB",
    value: "BNE",
  },
  {
    label: "North Tyneside",
    countryCode: "GB",
    value: "NTY",
  },
  {
    label: "North Yorkshire",
    countryCode: "GB",
    value: "NYK",
  },
  {
    label: "Ards and North Down",
    countryCode: "GB",
    value: "AND",
  },
  {
    label: "Newport",
    countryCode: "GB",
    value: "NWP",
  },
  {
    label: "Castlereagh",
    countryCode: "GB",
    value: "CSR",
  },
  {
    label: "Surrey",
    countryCode: "GB",
    value: "SRY",
  },
  {
    label: "Redcar and Cleveland",
    countryCode: "GB",
    value: "RCC",
  },
  {
    label: "City and County of Cardiff",
    countryCode: "GB",
    value: "CRF",
  },
  {
    label: "Bradford",
    countryCode: "GB",
    value: "BRD",
  },
  {
    label: "Blaenau Gwent County Borough",
    countryCode: "GB",
    value: "BGW",
  },
  {
    label: "Fermanagh District Council",
    countryCode: "GB",
    value: "FER",
  },
  {
    label: "London Borough of Ealing",
    countryCode: "GB",
    value: "EAL",
  },
  {
    label: "Antrim",
    countryCode: "GB",
    value: "ANT",
  },
  {
    label: "Newry, Mourne and Down",
    countryCode: "GB",
    value: "NMD",
  },
  {
    label: "North Ayrshire",
    countryCode: "GB",
    value: "NAY",
  },
  {
    label: "Tashkent",
    countryCode: "UZ",
    value: "TK",
  },
  {
    label: "Namangan Region",
    countryCode: "UZ",
    value: "NG",
  },
  {
    label: "Fergana Region",
    countryCode: "UZ",
    value: "FA",
  },
  {
    label: "Xorazm Region",
    countryCode: "UZ",
    value: "XO",
  },
  {
    label: "Andijan Region",
    countryCode: "UZ",
    value: "AN",
  },
  {
    label: "Bukhara Region",
    countryCode: "UZ",
    value: "BU",
  },
  {
    label: "Navoiy Region",
    countryCode: "UZ",
    value: "NW",
  },
  {
    label: "Qashqadaryo Region",
    countryCode: "UZ",
    value: "QA",
  },
  {
    label: "Samarqand Region",
    countryCode: "UZ",
    value: "SA",
  },
  {
    label: "Jizzakh Region",
    countryCode: "UZ",
    value: "JI",
  },
  {
    label: "Surxondaryo Region",
    countryCode: "UZ",
    value: "SU",
  },
  {
    label: "Sirdaryo Region",
    countryCode: "UZ",
    value: "SI",
  },
  {
    label: "Karakalpakstan",
    countryCode: "UZ",
    value: "QR",
  },
  {
    label: "Tashkent Region",
    countryCode: "UZ",
    value: "TO",
  },
  {
    label: "Ariana Governorate",
    countryCode: "TN",
    value: "12",
  },
  {
    label: "Bizerte Governorate",
    countryCode: "TN",
    value: "23",
  },
  {
    label: "Jendouba Governorate",
    countryCode: "TN",
    value: "32",
  },
  {
    label: "Monastir Governorate",
    countryCode: "TN",
    value: "52",
  },
  {
    label: "Tunis Governorate",
    countryCode: "TN",
    value: "11",
  },
  {
    label: "Manouba Governorate",
    countryCode: "TN",
    value: "14",
  },
  {
    label: "Gafsa Governorate",
    countryCode: "TN",
    value: "71",
  },
  {
    label: "Sfax Governorate",
    countryCode: "TN",
    value: "61",
  },
  {
    label: "Gabès Governorate",
    countryCode: "TN",
    value: "81",
  },
  {
    label: "Tataouine Governorate",
    countryCode: "TN",
    value: "83",
  },
  {
    label: "Medenine Governorate",
    countryCode: "TN",
    value: "82",
  },
  {
    label: "Kef Governorate",
    countryCode: "TN",
    value: "33",
  },
  {
    label: "Kebili Governorate",
    countryCode: "TN",
    value: "73",
  },
  {
    label: "Siliana Governorate",
    countryCode: "TN",
    value: "34",
  },
  {
    label: "Kairouan Governorate",
    countryCode: "TN",
    value: "41",
  },
  {
    label: "Zaghouan Governorate",
    countryCode: "TN",
    value: "22",
  },
  {
    label: "Ben Arous Governorate",
    countryCode: "TN",
    value: "13",
  },
  {
    label: "Sidi Bouzid Governorate",
    countryCode: "TN",
    value: "43",
  },
  {
    label: "Mahdia Governorate",
    countryCode: "TN",
    value: "53",
  },
  {
    label: "Tozeur Governorate",
    countryCode: "TN",
    value: "72",
  },
  {
    label: "Kasserine Governorate",
    countryCode: "TN",
    value: "42",
  },
  {
    label: "Sousse Governorate",
    countryCode: "TN",
    value: "51",
  },
  {
    label: "Kassrine",
    countryCode: "TN",
    value: "31",
  },
  {
    label: "Ratak Chain",
    countryCode: "MH",
    value: "T",
  },
  {
    label: "Ralik Chain",
    countryCode: "MH",
    value: "L",
  },
  {
    label: "Centrale Region",
    countryCode: "TG",
    value: "C",
  },
  {
    label: "Maritime",
    countryCode: "TG",
    value: "M",
  },
  {
    label: "Plateaux Region",
    countryCode: "TG",
    value: "P",
  },
  {
    label: "Savanes Region",
    countryCode: "TG",
    value: "S",
  },
  {
    label: "Kara Region",
    countryCode: "TG",
    value: "K",
  },
  {
    label: "Chuuk State",
    countryCode: "FM",
    value: "TRK",
  },
  {
    label: "Pohnpei State",
    countryCode: "FM",
    value: "PNI",
  },
  {
    label: "Yap State",
    countryCode: "FM",
    value: "YAP",
  },
  {
    label: "Kosrae State",
    countryCode: "FM",
    value: "KSA",
  },
  {
    label: "Vaavu Atoll",
    countryCode: "MV",
    value: "04",
  },
  {
    label: "Shaviyani Atoll",
    countryCode: "MV",
    value: "24",
  },
  {
    label: "Haa Alif Atoll",
    countryCode: "MV",
    value: "07",
  },
  {
    label: "Alif Alif Atoll",
    countryCode: "MV",
    value: "02",
  },
  {
    label: "North Province",
    countryCode: "MV",
    value: "NO",
  },
  {
    label: "North Central Province",
    countryCode: "MV",
    value: "NC",
  },
  {
    label: "Dhaalu Atoll",
    countryCode: "MV",
    value: "17",
  },
  {
    label: "Thaa Atoll",
    countryCode: "MV",
    value: "08",
  },
  {
    label: "Noonu Atoll",
    countryCode: "MV",
    value: "25",
  },
  {
    label: "Upper South Province",
    countryCode: "MV",
    value: "US",
  },
  {
    label: "Addu Atoll",
    countryCode: "MV",
    value: "01",
  },
  {
    label: "Gnaviyani Atoll",
    countryCode: "MV",
    value: "29",
  },
  {
    label: "Kaafu Atoll",
    countryCode: "MV",
    value: "26",
  },
  {
    label: "Haa Dhaalu Atoll",
    countryCode: "MV",
    value: "23",
  },
  {
    label: "Gaafu Alif Atoll",
    countryCode: "MV",
    value: "27",
  },
  {
    label: "Faafu Atoll",
    countryCode: "MV",
    value: "14",
  },
  {
    label: "Alif Dhaal Atoll",
    countryCode: "MV",
    value: "00",
  },
  {
    label: "Laamu Atoll",
    countryCode: "MV",
    value: "05",
  },
  {
    label: "Raa Atoll",
    countryCode: "MV",
    value: "13",
  },
  {
    label: "Gaafu Dhaalu Atoll",
    countryCode: "MV",
    value: "28",
  },
  {
    label: "Central Province",
    countryCode: "MV",
    value: "CE",
  },
  {
    label: "South Province",
    countryCode: "MV",
    value: "SU",
  },
  {
    label: "South Central Province",
    countryCode: "MV",
    value: "SC",
  },
  {
    label: "Lhaviyani Atoll",
    countryCode: "MV",
    value: "03",
  },
  {
    label: "Meemu Atoll",
    countryCode: "MV",
    value: "12",
  },
  {
    label: "Malé",
    countryCode: "MV",
    value: "MLE",
  },
  {
    label: "Utrecht",
    countryCode: "NL",
    value: "UT",
  },
  {
    label: "Gelderland",
    countryCode: "NL",
    value: "GE",
  },
  {
    label: "North Holland",
    countryCode: "NL",
    value: "NH",
  },
  {
    label: "Drenthe",
    countryCode: "NL",
    value: "DR",
  },
  {
    label: "South Holland",
    countryCode: "NL",
    value: "ZH",
  },
  {
    label: "Limburg",
    countryCode: "NL",
    value: "LI",
  },
  {
    label: "Sint Eustatius",
    countryCode: "NL",
    value: "BQ3",
  },
  {
    label: "Groningen",
    countryCode: "NL",
    value: "GR",
  },
  {
    label: "Overijssel",
    countryCode: "NL",
    value: "OV",
  },
  {
    label: "Flevoland",
    countryCode: "NL",
    value: "FL",
  },
  {
    label: "Zeeland",
    countryCode: "NL",
    value: "ZE",
  },
  {
    label: "Saba",
    countryCode: "NL",
    value: "BQ2",
  },
  {
    label: "Friesland",
    countryCode: "NL",
    value: "FR",
  },
  {
    label: "North Brabant",
    countryCode: "NL",
    value: "NB",
  },
  {
    label: "Bonaire",
    countryCode: "NL",
    value: "BQ1",
  },
  {
    label: "Savanes Region",
    countryCode: "CI",
    value: "03",
  },
  {
    label: "Agnéby",
    countryCode: "CI",
    value: "16",
  },
  {
    label: "Lagunes District",
    countryCode: "CI",
    value: "LG",
  },
  {
    label: "Sud-Bandama",
    countryCode: "CI",
    value: "15",
  },
  {
    label: "Montagnes District",
    countryCode: "CI",
    value: "MG",
  },
  {
    label: "Moyen-Comoé",
    countryCode: "CI",
    value: "05",
  },
  {
    label: "Marahoué Region",
    countryCode: "CI",
    value: "12",
  },
  {
    label: "Lacs District",
    countryCode: "CI",
    value: "LC",
  },
  {
    label: "Fromager",
    countryCode: "CI",
    value: "18",
  },
  {
    label: "Abidjan",
    countryCode: "CI",
    value: "AB",
  },
  {
    label: "Bas-Sassandra Region",
    countryCode: "CI",
    value: "09",
  },
  {
    label: "Bafing Region",
    countryCode: "CI",
    value: "17",
  },
  {
    label: "Vallée du Bandama District",
    countryCode: "CI",
    value: "VB",
  },
  {
    label: "Haut-Sassandra",
    countryCode: "CI",
    value: "02",
  },
  {
    label: "Lagunes region",
    countryCode: "CI",
    value: "01",
  },
  {
    label: "Lacs Region",
    countryCode: "CI",
    value: "07",
  },
  {
    label: "Zanzan Region",
    countryCode: "CI",
    value: "ZZ",
  },
  {
    label: "Denguélé Region",
    countryCode: "CI",
    value: "10",
  },
  {
    label: "Bas-Sassandra District",
    countryCode: "CI",
    value: "BS",
  },
  {
    label: "Denguélé District",
    countryCode: "CI",
    value: "DN",
  },
  {
    label: "Dix-Huit Montagnes",
    countryCode: "CI",
    value: "06",
  },
  {
    label: "Moyen-Cavally",
    countryCode: "CI",
    value: "19",
  },
  {
    label: "Vallée du Bandama Region",
    countryCode: "CI",
    value: "04",
  },
  {
    label: "Sassandra-Marahoué District",
    countryCode: "CI",
    value: "SM",
  },
  {
    label: "Worodougou",
    countryCode: "CI",
    value: "14",
  },
  {
    label: "Woroba District",
    countryCode: "CI",
    value: "WR",
  },
  {
    label: "Gôh-Djiboua District",
    countryCode: "CI",
    value: "GD",
  },
  {
    label: "Sud-Comoé",
    countryCode: "CI",
    value: "13",
  },
  {
    label: "Yamoussoukro",
    countryCode: "CI",
    value: "YM",
  },
  {
    label: "Comoé District",
    countryCode: "CI",
    value: "CM",
  },
  {
    label: "N'zi-Comoé",
    countryCode: "CI",
    value: "11",
  },
  {
    label: "Far North",
    countryCode: "CM",
    value: "EN",
  },
  {
    label: "Northwest",
    countryCode: "CM",
    value: "NW",
  },
  {
    label: "Southwest",
    countryCode: "CM",
    value: "SW",
  },
  {
    label: "South",
    countryCode: "CM",
    value: "SU",
  },
  {
    label: "Centre",
    countryCode: "CM",
    value: "CE",
  },
  {
    label: "East",
    countryCode: "CM",
    value: "ES",
  },
  {
    label: "Littoral",
    countryCode: "CM",
    value: "LT",
  },
  {
    label: "Adamawa",
    countryCode: "CM",
    value: "AD",
  },
  {
    label: "West",
    countryCode: "CM",
    value: "OU",
  },
  {
    label: "North",
    countryCode: "CM",
    value: "NO",
  },
  {
    label: "Banjul",
    countryCode: "GM",
    value: "B",
  },
  {
    label: "West Coast Division",
    countryCode: "GM",
    value: "W",
  },
  {
    label: "Upper River Division",
    countryCode: "GM",
    value: "U",
  },
  {
    label: "Central River Division",
    countryCode: "GM",
    value: "M",
  },
  {
    label: "Lower River Division",
    countryCode: "GM",
    value: "L",
  },
  {
    label: "North Bank Division",
    countryCode: "GM",
    value: "N",
  },
  {
    label: "Beyla Prefecture",
    countryCode: "GN",
    value: "BE",
  },
  {
    label: "Mandiana Prefecture",
    countryCode: "GN",
    value: "MD",
  },
  {
    label: "Yomou Prefecture",
    countryCode: "GN",
    value: "YO",
  },
  {
    label: "Fria Prefecture",
    countryCode: "GN",
    value: "FR",
  },
  {
    label: "Boké Region",
    countryCode: "GN",
    value: "B",
  },
  {
    label: "Labé Region",
    countryCode: "GN",
    value: "L",
  },
  {
    label: "Nzérékoré Prefecture",
    countryCode: "GN",
    value: "NZ",
  },
  {
    label: "Dabola Prefecture",
    countryCode: "GN",
    value: "DB",
  },
  {
    label: "Labé Prefecture",
    countryCode: "GN",
    value: "LA",
  },
  {
    label: "Dubréka Prefecture",
    countryCode: "GN",
    value: "DU",
  },
  {
    label: "Faranah Prefecture",
    countryCode: "GN",
    value: "FA",
  },
  {
    label: "Forécariah Prefecture",
    countryCode: "GN",
    value: "FO",
  },
  {
    label: "Nzérékoré Region",
    countryCode: "GN",
    value: "N",
  },
  {
    label: "Gaoual Prefecture",
    countryCode: "GN",
    value: "GA",
  },
  {
    label: "Conakry",
    countryCode: "GN",
    value: "C",
  },
  {
    label: "Télimélé Prefecture",
    countryCode: "GN",
    value: "TE",
  },
  {
    label: "Dinguiraye Prefecture",
    countryCode: "GN",
    value: "DI",
  },
  {
    label: "Mamou Prefecture",
    countryCode: "GN",
    value: "MM",
  },
  {
    label: "Lélouma Prefecture",
    countryCode: "GN",
    value: "LE",
  },
  {
    label: "Kissidougou Prefecture",
    countryCode: "GN",
    value: "KS",
  },
  {
    label: "Koubia Prefecture",
    countryCode: "GN",
    value: "KB",
  },
  {
    label: "Kindia Prefecture",
    countryCode: "GN",
    value: "KD",
  },
  {
    label: "Pita Prefecture",
    countryCode: "GN",
    value: "PI",
  },
  {
    label: "Kouroussa Prefecture",
    countryCode: "GN",
    value: "KO",
  },
  {
    label: "Tougué Prefecture",
    countryCode: "GN",
    value: "TO",
  },
  {
    label: "Kankan Region",
    countryCode: "GN",
    value: "K",
  },
  {
    label: "Mamou Region",
    countryCode: "GN",
    value: "M",
  },
  {
    label: "Boffa Prefecture",
    countryCode: "GN",
    value: "BF",
  },
  {
    label: "Mali Prefecture",
    countryCode: "GN",
    value: "ML",
  },
  {
    label: "Kindia Region",
    countryCode: "GN",
    value: "D",
  },
  {
    label: "Macenta Prefecture",
    countryCode: "GN",
    value: "MC",
  },
  {
    label: "Koundara Prefecture",
    countryCode: "GN",
    value: "KN",
  },
  {
    label: "Kankan Prefecture",
    countryCode: "GN",
    value: "KA",
  },
  {
    label: "Coyah Prefecture",
    countryCode: "GN",
    value: "CO",
  },
  {
    label: "Dalaba Prefecture",
    countryCode: "GN",
    value: "DL",
  },
  {
    label: "Siguiri Prefecture",
    countryCode: "GN",
    value: "SI",
  },
  {
    label: "Lola Prefecture",
    countryCode: "GN",
    value: "LO",
  },
  {
    label: "Boké Prefecture",
    countryCode: "GN",
    value: "BK",
  },
  {
    label: "Kérouané Prefecture",
    countryCode: "GN",
    value: "KE",
  },
  {
    label: "Guéckédou Prefecture",
    countryCode: "GN",
    value: "GU",
  },
  {
    label: "Tombali Region",
    countryCode: "GW",
    value: "TO",
  },
  {
    label: "Cacheu Region",
    countryCode: "GW",
    value: "CA",
  },
  {
    label: "Biombo Region",
    countryCode: "GW",
    value: "BM",
  },
  {
    label: "Quinara Region",
    countryCode: "GW",
    value: "QU",
  },
  {
    label: "Sul Province",
    countryCode: "GW",
    value: "S",
  },
  {
    label: "Norte Province",
    countryCode: "GW",
    value: "N",
  },
  {
    label: "Oio Region",
    countryCode: "GW",
    value: "OI",
  },
  {
    label: "Gabú Region",
    countryCode: "GW",
    value: "GA",
  },
  {
    label: "Bafatá",
    countryCode: "GW",
    value: "BA",
  },
  {
    label: "Leste Province",
    countryCode: "GW",
    value: "L",
  },
  {
    label: "Bolama Region",
    countryCode: "GW",
    value: "BL",
  },
  {
    label: "Woleu-Ntem Province",
    countryCode: "GA",
    value: "9",
  },
  {
    label: "Ogooué-Ivindo Province",
    countryCode: "GA",
    value: "6",
  },
  {
    label: "Nyanga Province",
    countryCode: "GA",
    value: "5",
  },
  {
    label: "Haut-Ogooué Province",
    countryCode: "GA",
    value: "2",
  },
  {
    label: "Estuaire Province",
    countryCode: "GA",
    value: "1",
  },
  {
    label: "Ogooué-Maritime Province",
    countryCode: "GA",
    value: "8",
  },
  {
    label: "Ogooué-Lolo Province",
    countryCode: "GA",
    value: "7",
  },
  {
    label: "Moyen-Ogooué Province",
    countryCode: "GA",
    value: "3",
  },
  {
    label: "Ngounié Province",
    countryCode: "GA",
    value: "4",
  },
  {
    label: "Tshuapa",
    countryCode: "CD",
    value: "TU",
  },
  {
    label: "Tanganyika",
    countryCode: "CD",
    value: "TA",
  },
  {
    label: "Haut-Uélé",
    countryCode: "CD",
    value: "HU",
  },
  {
    label: "Kasaï Oriental",
    countryCode: "CD",
    value: "KE",
  },
  {
    label: "Sud-Kivu",
    countryCode: "CD",
    value: "SK",
  },
  {
    label: "Nord-Ubangi",
    countryCode: "CD",
    value: "NU",
  },
  {
    label: "Kwango",
    countryCode: "CD",
    value: "KG",
  },
  {
    label: "Kinshasa",
    countryCode: "CD",
    value: "KN",
  },
  {
    label: "Kasaï Central",
    countryCode: "CD",
    value: "KC",
  },
  {
    label: "Sankuru",
    countryCode: "CD",
    value: "SA",
  },
  {
    label: "Équateur",
    countryCode: "CD",
    value: "EQ",
  },
  {
    label: "Maniema",
    countryCode: "CD",
    value: "MA",
  },
  {
    label: "Kongo Central",
    countryCode: "CD",
    value: "BC",
  },
  {
    label: "Lomami",
    countryCode: "CD",
    value: "LO",
  },
  {
    label: "Sud-Ubangi",
    countryCode: "CD",
    value: "SU",
  },
  {
    label: "Nord-Kivu",
    countryCode: "CD",
    value: "NK",
  },
  {
    label: "Haut-Katanga",
    countryCode: "CD",
    value: "HK",
  },
  {
    label: "Ituri",
    countryCode: "CD",
    value: "IT",
  },
  {
    label: "Mongala",
    countryCode: "CD",
    value: "MO",
  },
  {
    label: "Bas-Uélé",
    countryCode: "CD",
    value: "BU",
  },
  {
    label: "Mai-Ndombe",
    countryCode: "CD",
    value: "MN",
  },
  {
    label: "Tshopo",
    countryCode: "CD",
    value: "TO",
  },
  {
    label: "Kasaï",
    countryCode: "CD",
    value: "KS",
  },
  {
    label: "Haut-Lomami",
    countryCode: "CD",
    value: "HL",
  },
  {
    label: "Kwilu",
    countryCode: "CD",
    value: "KL",
  },
  {
    label: "Cuyuni-Mazaruni",
    countryCode: "GY",
    value: "CU",
  },
  {
    label: "Potaro-Siparuni",
    countryCode: "GY",
    value: "PT",
  },
  {
    label: "Mahaica-Berbice",
    countryCode: "GY",
    value: "MA",
  },
  {
    label: "Upper Demerara-Berbice",
    countryCode: "GY",
    value: "UD",
  },
  {
    label: "Barima-Waini",
    countryCode: "GY",
    value: "BA",
  },
  {
    label: "Pomeroon-Supenaam",
    countryCode: "GY",
    value: "PM",
  },
  {
    label: "East Berbice-Corentyne",
    countryCode: "GY",
    value: "EB",
  },
  {
    label: "Demerara-Mahaica",
    countryCode: "GY",
    value: "DE",
  },
  {
    label: "Essequibo Islands-West Demerara",
    countryCode: "GY",
    value: "ES",
  },
  {
    label: "Upper Takutu-Upper Essequibo",
    countryCode: "GY",
    value: "UT",
  },
  {
    label: "Presidente Hayes Department",
    countryCode: "PY",
    value: "15",
  },
  {
    label: "Canindeyú",
    countryCode: "PY",
    value: "14",
  },
  {
    label: "Guairá Department",
    countryCode: "PY",
    value: "4",
  },
  {
    label: "Caaguazú",
    countryCode: "PY",
    value: "5",
  },
  {
    label: "Paraguarí Department",
    countryCode: "PY",
    value: "9",
  },
  {
    label: "Caazapá",
    countryCode: "PY",
    value: "6",
  },
  {
    label: "San Pedro Department",
    countryCode: "PY",
    value: "2",
  },
  {
    label: "Central Department",
    countryCode: "PY",
    value: "11",
  },
  {
    label: "Itapúa",
    countryCode: "PY",
    value: "7",
  },
  {
    label: "Concepción Department",
    countryCode: "PY",
    value: "1",
  },
  {
    label: "Boquerón Department",
    countryCode: "PY",
    value: "19",
  },
  {
    label: "Ñeembucú Department",
    countryCode: "PY",
    value: "12",
  },
  {
    label: "Amambay Department",
    countryCode: "PY",
    value: "13",
  },
  {
    label: "Cordillera Department",
    countryCode: "PY",
    value: "3",
  },
  {
    label: "Alto Paraná Department",
    countryCode: "PY",
    value: "10",
  },
  {
    label: "Alto Paraguay Department",
    countryCode: "PY",
    value: "16",
  },
  {
    label: "Misiones Department",
    countryCode: "PY",
    value: "8",
  },
  {
    label: "Jaffna District",
    countryCode: "LK",
    value: "41",
  },
  {
    label: "Kandy District",
    countryCode: "LK",
    value: "21",
  },
  {
    label: "Kalutara District",
    countryCode: "LK",
    value: "13",
  },
  {
    label: "Badulla District",
    countryCode: "LK",
    value: "81",
  },
  {
    label: "Hambantota District",
    countryCode: "LK",
    value: "33",
  },
  {
    label: "Galle District",
    countryCode: "LK",
    value: "31",
  },
  {
    label: "Kilinochchi District",
    countryCode: "LK",
    value: "42",
  },
  {
    label: "Nuwara Eliya District",
    countryCode: "LK",
    value: "23",
  },
  {
    label: "Trincomalee District",
    countryCode: "LK",
    value: "53",
  },
  {
    label: "Puttalam District",
    countryCode: "LK",
    value: "62",
  },
  {
    label: "Kegalle District",
    countryCode: "LK",
    value: "92",
  },
  {
    label: "Central Province",
    countryCode: "LK",
    value: "2",
  },
  {
    label: "Ampara District",
    countryCode: "LK",
    value: "52",
  },
  {
    label: "North Central Province",
    countryCode: "LK",
    value: "7",
  },
  {
    label: "Southern Province",
    countryCode: "LK",
    value: "3",
  },
  {
    label: "Western Province",
    countryCode: "LK",
    value: "1",
  },
  {
    label: "Sabaragamuwa Province",
    countryCode: "LK",
    value: "9",
  },
  {
    label: "Gampaha District",
    countryCode: "LK",
    value: "12",
  },
  {
    label: "Mannar District",
    countryCode: "LK",
    value: "43",
  },
  {
    label: "Matara District",
    countryCode: "LK",
    value: "32",
  },
  {
    label: "Ratnapura district",
    countryCode: "LK",
    value: "91",
  },
  {
    label: "Eastern Province",
    countryCode: "LK",
    value: "5",
  },
  {
    label: "Vavuniya District",
    countryCode: "LK",
    value: "44",
  },
  {
    label: "Matale District",
    countryCode: "LK",
    value: "22",
  },
  {
    label: "Uva Province",
    countryCode: "LK",
    value: "8",
  },
  {
    label: "Polonnaruwa District",
    countryCode: "LK",
    value: "72",
  },
  {
    label: "Northern Province",
    countryCode: "LK",
    value: "4",
  },
  {
    label: "Mullaitivu District",
    countryCode: "LK",
    value: "45",
  },
  {
    label: "Colombo District",
    countryCode: "LK",
    value: "11",
  },
  {
    label: "Anuradhapura District",
    countryCode: "LK",
    value: "71",
  },
  {
    label: "North Western Province",
    countryCode: "LK",
    value: "6",
  },
  {
    label: "Batticaloa District",
    countryCode: "LK",
    value: "51",
  },
  {
    label: "Monaragala District",
    countryCode: "LK",
    value: "82",
  },
  {
    label: "Mohéli",
    countryCode: "KM",
    value: "M",
  },
  {
    label: "Anjouan",
    countryCode: "KM",
    value: "A",
  },
  {
    label: "Grande Comore",
    countryCode: "KM",
    value: "G",
  },
  {
    label: "Atacama",
    countryCode: "CL",
    value: "AT",
  },
  {
    label: "Región Metropolitana de Santiago",
    countryCode: "CL",
    value: "RM",
  },
  {
    label: "Coquimbo",
    countryCode: "CL",
    value: "CO",
  },
  {
    label: "La Araucanía",
    countryCode: "CL",
    value: "AR",
  },
  {
    label: "Biobío",
    countryCode: "CL",
    value: "BI",
  },
  {
    label: "Aisén del General Carlos Ibañez del Campo",
    countryCode: "CL",
    value: "AI",
  },
  {
    label: "Arica y Parinacota",
    countryCode: "CL",
    value: "AP",
  },
  {
    label: "Valparaíso",
    countryCode: "CL",
    value: "VS",
  },
  {
    label: "Ñuble",
    countryCode: "CL",
    value: "NB",
  },
  {
    label: "Antofagasta",
    countryCode: "CL",
    value: "AN",
  },
  {
    label: "Maule",
    countryCode: "CL",
    value: "ML",
  },
  {
    label: "Los Ríos",
    countryCode: "CL",
    value: "LR",
  },
  {
    label: "Los Lagos",
    countryCode: "CL",
    value: "LL",
  },
  {
    label: "Magallanes y de la Antártica Chilena",
    countryCode: "CL",
    value: "MA",
  },
  {
    label: "Tarapacá",
    countryCode: "CL",
    value: "TA",
  },
  {
    label: "Libertador General Bernardo O'Higgins",
    countryCode: "CL",
    value: "LI",
  },
  {
    label: "Commewijne District",
    countryCode: "SR",
    value: "CM",
  },
  {
    label: "Nickerie District",
    countryCode: "SR",
    value: "NI",
  },
  {
    label: "Para District",
    countryCode: "SR",
    value: "PR",
  },
  {
    label: "Coronie District",
    countryCode: "SR",
    value: "CR",
  },
  {
    label: "Paramaribo District",
    countryCode: "SR",
    value: "PM",
  },
  {
    label: "Wanica District",
    countryCode: "SR",
    value: "WA",
  },
  {
    label: "Marowijne District",
    countryCode: "SR",
    value: "MA",
  },
  {
    label: "Brokopondo District",
    countryCode: "SR",
    value: "BR",
  },
  {
    label: "Sipaliwini District",
    countryCode: "SR",
    value: "SI",
  },
  {
    label: "Saramacca District",
    countryCode: "SR",
    value: "SA",
  },
  {
    label: "Riyadh",
    countryCode: "SA",
    value: "01",
  },
  {
    label: "Makkah",
    countryCode: "SA",
    value: "02",
  },
  {
    label: "Al Madinah",
    countryCode: "SA",
    value: "03",
  },
  {
    label: "Tabuk",
    countryCode: "SA",
    value: "07",
  },
  {
    label: "'Asir",
    countryCode: "SA",
    value: "14",
  },
  {
    label: "Northern Borders",
    countryCode: "SA",
    value: "08",
  },
  {
    label: "Ha'il",
    countryCode: "SA",
    value: "06",
  },
  {
    label: "Eastern Province",
    countryCode: "SA",
    value: "04",
  },
  {
    label: "Al Jawf",
    countryCode: "SA",
    value: "12",
  },
  {
    label: "Jizan",
    countryCode: "SA",
    value: "09",
  },
  {
    label: "Al Bahah",
    countryCode: "SA",
    value: "11",
  },
  {
    label: "Najran",
    countryCode: "SA",
    value: "10",
  },
  {
    label: "Al-Qassim",
    countryCode: "SA",
    value: "05",
  },
  {
    label: "Plateaux Department",
    countryCode: "CG",
    value: "14",
  },
  {
    label: "Pointe-Noire",
    countryCode: "CG",
    value: "16",
  },
  {
    label: "Cuvette Department",
    countryCode: "CG",
    value: "8",
  },
  {
    label: "Likouala Department",
    countryCode: "CG",
    value: "7",
  },
  {
    label: "Bouenza Department",
    countryCode: "CG",
    value: "11",
  },
  {
    label: "Kouilou Department",
    countryCode: "CG",
    value: "5",
  },
  {
    label: "Lékoumou Department",
    countryCode: "CG",
    value: "2",
  },
  {
    label: "Cuvette-Ouest Department",
    countryCode: "CG",
    value: "15",
  },
  {
    label: "Brazzaville",
    countryCode: "CG",
    value: "BZV",
  },
  {
    label: "Sangha Department",
    countryCode: "CG",
    value: "13",
  },
  {
    label: "Niari Department",
    countryCode: "CG",
    value: "9",
  },
  {
    label: "Pool Department",
    countryCode: "CG",
    value: "12",
  },
  {
    label: "Quindío",
    countryCode: "CO",
    value: "QUI",
  },
  {
    label: "Cundinamarca",
    countryCode: "CO",
    value: "CUN",
  },
  {
    label: "Chocó",
    countryCode: "CO",
    value: "CHO",
  },
  {
    label: "Norte de Santander",
    countryCode: "CO",
    value: "NSA",
  },
  {
    label: "Meta",
    countryCode: "CO",
    value: "MET",
  },
  {
    label: "Risaralda",
    countryCode: "CO",
    value: "RIS",
  },
  {
    label: "Atlántico",
    countryCode: "CO",
    value: "ATL",
  },
  {
    label: "Arauca",
    countryCode: "CO",
    value: "ARA",
  },
  {
    label: "Guainía",
    countryCode: "CO",
    value: "GUA",
  },
  {
    label: "Tolima",
    countryCode: "CO",
    value: "TOL",
  },
  {
    label: "Cauca",
    countryCode: "CO",
    value: "CAU",
  },
  {
    label: "Vaupés",
    countryCode: "CO",
    value: "VAU",
  },
  {
    label: "Magdalena",
    countryCode: "CO",
    value: "MAG",
  },
  {
    label: "Caldas",
    countryCode: "CO",
    value: "CAL",
  },
  {
    label: "Guaviare",
    countryCode: "CO",
    value: "GUV",
  },
  {
    label: "La Guajira",
    countryCode: "CO",
    value: "LAG",
  },
  {
    label: "Antioquia",
    countryCode: "CO",
    value: "ANT",
  },
  {
    label: "Caquetá",
    countryCode: "CO",
    value: "CAQ",
  },
  {
    label: "Casanare",
    countryCode: "CO",
    value: "CAS",
  },
  {
    label: "Bolívar",
    countryCode: "CO",
    value: "BOL",
  },
  {
    label: "Vichada",
    countryCode: "CO",
    value: "VID",
  },
  {
    label: "Amazonas",
    countryCode: "CO",
    value: "AMA",
  },
  {
    label: "Putumayo",
    countryCode: "CO",
    value: "PUT",
  },
  {
    label: "Nariño",
    countryCode: "CO",
    value: "NAR",
  },
  {
    label: "Córdoba",
    countryCode: "CO",
    value: "COR",
  },
  {
    label: "Cesar",
    countryCode: "CO",
    value: "CES",
  },
  {
    label: "San Andrés, Providencia y Santa Catalina",
    countryCode: "CO",
    value: "SAP",
  },
  {
    label: "Santander",
    countryCode: "CO",
    value: "SAN",
  },
  {
    label: "Sucre",
    countryCode: "CO",
    value: "SUC",
  },
  {
    label: "Boyacá",
    countryCode: "CO",
    value: "BOY",
  },
  {
    label: "Valle del Cauca",
    countryCode: "CO",
    value: "VAC",
  },
  {
    label: "Galápagos Province",
    countryCode: "EC",
    value: "W",
  },
  {
    label: "Sucumbíos Province",
    countryCode: "EC",
    value: "U",
  },
  {
    label: "Pastaza Province",
    countryCode: "EC",
    value: "Y",
  },
  {
    label: "Tungurahua Province",
    countryCode: "EC",
    value: "T",
  },
  {
    label: "Zamora-Chinchipe Province",
    countryCode: "EC",
    value: "Z",
  },
  {
    label: "Los Ríos Province",
    countryCode: "EC",
    value: "R",
  },
  {
    label: "Imbabura Province",
    countryCode: "EC",
    value: "I",
  },
  {
    label: "Santa Elena Province",
    countryCode: "EC",
    value: "SE",
  },
  {
    label: "Manabí Province",
    countryCode: "EC",
    value: "M",
  },
  {
    label: "Guayas Province",
    countryCode: "EC",
    value: "G",
  },
  {
    label: "Carchi Province",
    countryCode: "EC",
    value: "C",
  },
  {
    label: "Napo Province",
    countryCode: "EC",
    value: "N",
  },
  {
    label: "Cañar Province",
    countryCode: "EC",
    value: "F",
  },
  {
    label: "Morona-Santiago Province",
    countryCode: "EC",
    value: "S",
  },
  {
    label: "Santo Domingo de los Tsáchilas Province",
    countryCode: "EC",
    value: "SD",
  },
  {
    label: "Bolívar Province",
    countryCode: "EC",
    value: "B",
  },
  {
    label: "Cotopaxi Province",
    countryCode: "EC",
    value: "X",
  },
  {
    label: "Esmeraldas",
    countryCode: "EC",
    value: "E",
  },
  {
    label: "Azuay Province",
    countryCode: "EC",
    value: "A",
  },
  {
    label: "El Oro Province",
    countryCode: "EC",
    value: "O",
  },
  {
    label: "Chimborazo Province",
    countryCode: "EC",
    value: "H",
  },
  {
    label: "Orellana Province",
    countryCode: "EC",
    value: "D",
  },
  {
    label: "Pichincha Province",
    countryCode: "EC",
    value: "P",
  },
  {
    label: "Obock Region",
    countryCode: "DJ",
    value: "OB",
  },
  {
    label: "Djibouti",
    countryCode: "DJ",
    value: "DJ",
  },
  {
    label: "Dikhil Region",
    countryCode: "DJ",
    value: "DI",
  },
  {
    label: "Tadjourah Region",
    countryCode: "DJ",
    value: "TA",
  },
  {
    label: "Arta Region",
    countryCode: "DJ",
    value: "AR",
  },
  {
    label: "Ali Sabieh Region",
    countryCode: "DJ",
    value: "AS",
  },
  {
    label: "Hama Governorate",
    countryCode: "SY",
    value: "HM",
  },
  {
    label: "Rif Dimashq Governorate",
    countryCode: "SY",
    value: "RD",
  },
  {
    label: "As-Suwayda Governorate",
    countryCode: "SY",
    value: "SU",
  },
  {
    label: "Deir ez-Zor Governorate",
    countryCode: "SY",
    value: "DY",
  },
  {
    label: "Latakia Governorate",
    countryCode: "SY",
    value: "LA",
  },
  {
    label: "Damascus Governorate",
    countryCode: "SY",
    value: "DI",
  },
  {
    label: "Idlib Governorate",
    countryCode: "SY",
    value: "ID",
  },
  {
    label: "Al-Hasakah Governorate",
    countryCode: "SY",
    value: "HA",
  },
  {
    label: "Homs Governorate",
    countryCode: "SY",
    value: "HI",
  },
  {
    label: "Quneitra Governorate",
    countryCode: "SY",
    value: "QU",
  },
  {
    label: "Al-Raqqah Governorate",
    countryCode: "SY",
    value: "RA",
  },
  {
    label: "Daraa Governorate",
    countryCode: "SY",
    value: "DR",
  },
  {
    label: "Aleppo Governorate",
    countryCode: "SY",
    value: "HL",
  },
  {
    label: "Tartus Governorate",
    countryCode: "SY",
    value: "TA",
  },
  {
    label: "Fianarantsoa Province",
    countryCode: "MG",
    value: "F",
  },
  {
    label: "Toliara Province",
    countryCode: "MG",
    value: "U",
  },
  {
    label: "Antsiranana Province",
    countryCode: "MG",
    value: "D",
  },
  {
    label: "Antananarivo Province",
    countryCode: "MG",
    value: "T",
  },
  {
    label: "Toamasina Province",
    countryCode: "MG",
    value: "A",
  },
  {
    label: "Mahajanga Province",
    countryCode: "MG",
    value: "M",
  },
  {
    label: "Mogilev Region",
    countryCode: "BY",
    value: "MA",
  },
  {
    label: "Gomel Region",
    countryCode: "BY",
    value: "HO",
  },
  {
    label: "Grodno Region",
    countryCode: "BY",
    value: "HR",
  },
  {
    label: "Minsk Region",
    countryCode: "BY",
    value: "MI",
  },
  {
    label: "Minsk",
    countryCode: "BY",
    value: "HM",
  },
  {
    label: "Brest Region",
    countryCode: "BY",
    value: "BR",
  },
  {
    label: "Vitebsk Region",
    countryCode: "BY",
    value: "VI",
  },
  {
    label: "Murqub",
    countryCode: "LY",
    value: "MB",
  },
  {
    label: "Nuqat al Khams",
    countryCode: "LY",
    value: "NQ",
  },
  {
    label: "Zawiya District",
    countryCode: "LY",
    value: "ZA",
  },
  {
    label: "Al Wahat District",
    countryCode: "LY",
    value: "WA",
  },
  {
    label: "Sabha District",
    countryCode: "LY",
    value: "SB",
  },
  {
    label: "Derna District",
    countryCode: "LY",
    value: "DR",
  },
  {
    label: "Murzuq District",
    countryCode: "LY",
    value: "MQ",
  },
  {
    label: "Marj District",
    countryCode: "LY",
    value: "MJ",
  },
  {
    label: "Ghat District",
    countryCode: "LY",
    value: "GT",
  },
  {
    label: "Jufra",
    countryCode: "LY",
    value: "JU",
  },
  {
    label: "Tripoli District",
    countryCode: "LY",
    value: "TB",
  },
  {
    label: "Kufra District",
    countryCode: "LY",
    value: "KF",
  },
  {
    label: "Wadi al Hayaa District",
    countryCode: "LY",
    value: "WD",
  },
  {
    label: "Jabal al Gharbi District",
    countryCode: "LY",
    value: "JG",
  },
  {
    label: "Wadi al Shatii District",
    countryCode: "LY",
    value: "WS",
  },
  {
    label: "Nalut District",
    countryCode: "LY",
    value: "NL",
  },
  {
    label: "Sirte District",
    countryCode: "LY",
    value: "SR",
  },
  {
    label: "Misrata District",
    countryCode: "LY",
    value: "MI",
  },
  {
    label: "Jafara",
    countryCode: "LY",
    value: "JI",
  },
  {
    label: "Jabal al Akhdar",
    countryCode: "LY",
    value: "JA",
  },
  {
    label: "Benghazi",
    countryCode: "LY",
    value: "BA",
  },
  {
    label: "Ribeira Brava Municipality",
    countryCode: "CV",
    value: "RB",
  },
  {
    label: "Tarrafal",
    countryCode: "CV",
    value: "TA",
  },
  {
    label: "Ribeira Grande de Santiago",
    countryCode: "CV",
    value: "RS",
  },
  {
    label: "Santa Catarina",
    countryCode: "CV",
    value: "CA",
  },
  {
    label: "São Domingos",
    countryCode: "CV",
    value: "SD",
  },
  {
    label: "Mosteiros",
    countryCode: "CV",
    value: "MO",
  },
  {
    label: "Praia",
    countryCode: "CV",
    value: "PR",
  },
  {
    label: "Porto Novo",
    countryCode: "CV",
    value: "PN",
  },
  {
    label: "São Miguel",
    countryCode: "CV",
    value: "SM",
  },
  {
    label: "Maio Municipality",
    countryCode: "CV",
    value: "MA",
  },
  {
    label: "Sotavento Islands",
    countryCode: "CV",
    value: "S",
  },
  {
    label: "São Lourenço dos Órgãos",
    countryCode: "CV",
    value: "SO",
  },
  {
    label: "Barlavento Islands",
    countryCode: "CV",
    value: "B",
  },
  {
    label: "Santa Catarina do Fogo",
    countryCode: "CV",
    value: "CF",
  },
  {
    label: "Brava",
    countryCode: "CV",
    value: "BR",
  },
  {
    label: "Paul",
    countryCode: "CV",
    value: "PA",
  },
  {
    label: "Sal",
    countryCode: "CV",
    value: "SL",
  },
  {
    label: "Boa Vista",
    countryCode: "CV",
    value: "BV",
  },
  {
    label: "São Filipe",
    countryCode: "CV",
    value: "SF",
  },
  {
    label: "São Vicente",
    countryCode: "CV",
    value: "SV",
  },
  {
    label: "Ribeira Grande",
    countryCode: "CV",
    value: "RG",
  },
  {
    label: "Tarrafal de São Nicolau",
    countryCode: "CV",
    value: "TS",
  },
  {
    label: "Santa Cruz",
    countryCode: "CV",
    value: "CR",
  },
  {
    label: "Schleswig-Holstein",
    countryCode: "DE",
    value: "SH",
  },
  {
    label: "Baden-Württemberg",
    countryCode: "DE",
    value: "BW",
  },
  {
    label: "Mecklenburg-Vorpommern",
    countryCode: "DE",
    value: "MV",
  },
  {
    label: "Lower Saxony",
    countryCode: "DE",
    value: "NI",
  },
  {
    label: "Bavaria",
    countryCode: "DE",
    value: "BY",
  },
  {
    label: "Berlin",
    countryCode: "DE",
    value: "BE",
  },
  {
    label: "Saxony-Anhalt",
    countryCode: "DE",
    value: "ST",
  },
  {
    label: "Brandenburg",
    countryCode: "DE",
    value: "BB",
  },
  {
    label: "Bremen",
    countryCode: "DE",
    value: "HB",
  },
  {
    label: "Thuringia",
    countryCode: "DE",
    value: "TH",
  },
  {
    label: "Hamburg",
    countryCode: "DE",
    value: "HH",
  },
  {
    label: "North Rhine-Westphalia",
    countryCode: "DE",
    value: "NW",
  },
  {
    label: "Hesse",
    countryCode: "DE",
    value: "HE",
  },
  {
    label: "Rhineland-Palatinate",
    countryCode: "DE",
    value: "RP",
  },
  {
    label: "Saarland",
    countryCode: "DE",
    value: "SL",
  },
  {
    label: "Saxony",
    countryCode: "DE",
    value: "SN",
  },
  {
    label: "Mafeteng District",
    countryCode: "LS",
    value: "E",
  },
  {
    label: "Mohale's Hoek District",
    countryCode: "LS",
    value: "F",
  },
  {
    label: "Mokhotlong District",
    countryCode: "LS",
    value: "J",
  },
  {
    label: "Qacha's Nek District",
    countryCode: "LS",
    value: "H",
  },
  {
    label: "Leribe District",
    countryCode: "LS",
    value: "C",
  },
  {
    label: "Quthing District",
    countryCode: "LS",
    value: "G",
  },
  {
    label: "Maseru District",
    countryCode: "LS",
    value: "A",
  },
  {
    label: "Butha-Buthe District",
    countryCode: "LS",
    value: "B",
  },
  {
    label: "Berea District",
    countryCode: "LS",
    value: "D",
  },
  {
    label: "Thaba-Tseka District",
    countryCode: "LS",
    value: "K",
  },
  {
    label: "Montserrado County",
    countryCode: "LR",
    value: "MO",
  },
  {
    label: "River Cess County",
    countryCode: "LR",
    value: "RI",
  },
  {
    label: "Bong County",
    countryCode: "LR",
    value: "BG",
  },
  {
    label: "Sinoe County",
    countryCode: "LR",
    value: "SI",
  },
  {
    label: "Grand Cape Mount County",
    countryCode: "LR",
    value: "CM",
  },
  {
    label: "Lofa County",
    countryCode: "LR",
    value: "LO",
  },
  {
    label: "River Gee County",
    countryCode: "LR",
    value: "RG",
  },
  {
    label: "Grand Gedeh County",
    countryCode: "LR",
    value: "GG",
  },
  {
    label: "Grand Bassa County",
    countryCode: "LR",
    value: "GB",
  },
  {
    label: "Bomi County",
    countryCode: "LR",
    value: "BM",
  },
  {
    label: "Maryland County",
    countryCode: "LR",
    value: "MY",
  },
  {
    label: "Margibi County",
    countryCode: "LR",
    value: "MG",
  },
  {
    label: "Gbarpolu County",
    countryCode: "LR",
    value: "GP",
  },
  {
    label: "Grand Kru County",
    countryCode: "LR",
    value: "GK",
  },
  {
    label: "Nimba",
    countryCode: "LR",
    value: "NI",
  },
  {
    label: "Ad Dhahirah Governorate",
    countryCode: "OM",
    value: "ZA",
  },
  {
    label: "Al Batinah North Governorate",
    countryCode: "OM",
    value: "BS",
  },
  {
    label: "Al Batinah South Governorate",
    countryCode: "OM",
    value: "BJ",
  },
  {
    label: "Al Batinah Region",
    countryCode: "OM",
    value: "BA",
  },
  {
    label: "Ash Sharqiyah Region",
    countryCode: "OM",
    value: "SH",
  },
  {
    label: "Musandam Governorate",
    countryCode: "OM",
    value: "MU",
  },
  {
    label: "Ash Sharqiyah North Governorate",
    countryCode: "OM",
    value: "SS",
  },
  {
    label: "Ash Sharqiyah South Governorate",
    countryCode: "OM",
    value: "SJ",
  },
  {
    label: "Muscat Governorate",
    countryCode: "OM",
    value: "MA",
  },
  {
    label: "Al Wusta Governorate",
    countryCode: "OM",
    value: "WU",
  },
  {
    label: "Dhofar Governorate",
    countryCode: "OM",
    value: "ZU",
  },
  {
    label: "Ad Dakhiliyah Governorate",
    countryCode: "OM",
    value: "DA",
  },
  {
    label: "Al Buraimi Governorate",
    countryCode: "OM",
    value: "BU",
  },
  {
    label: "Ngamiland",
    countryCode: "BW",
    value: "NG",
  },
  {
    label: "Ghanzi District",
    countryCode: "BW",
    value: "GH",
  },
  {
    label: "Kgatleng District",
    countryCode: "BW",
    value: "KL",
  },
  {
    label: "Southern District",
    countryCode: "BW",
    value: "SO",
  },
  {
    label: "South-East District",
    countryCode: "BW",
    value: "SE",
  },
  {
    label: "North-West District",
    countryCode: "BW",
    value: "NW",
  },
  {
    label: "Kgalagadi District",
    countryCode: "BW",
    value: "KG",
  },
  {
    label: "Central District",
    countryCode: "BW",
    value: "CE",
  },
  {
    label: "North-East District",
    countryCode: "BW",
    value: "NE",
  },
  {
    label: "Kweneng District",
    countryCode: "BW",
    value: "KW",
  },
  {
    label: "Collines Department",
    countryCode: "BJ",
    value: "CO",
  },
  {
    label: "Kouffo Department",
    countryCode: "BJ",
    value: "KO",
  },
  {
    label: "Donga Department",
    countryCode: "BJ",
    value: "DO",
  },
  {
    label: "Zou Department",
    countryCode: "BJ",
    value: "ZO",
  },
  {
    label: "Plateau Department",
    countryCode: "BJ",
    value: "PL",
  },
  {
    label: "Mono Department",
    countryCode: "BJ",
    value: "MO",
  },
  {
    label: "Atakora Department",
    countryCode: "BJ",
    value: "AK",
  },
  {
    label: "Alibori Department",
    countryCode: "BJ",
    value: "AL",
  },
  {
    label: "Borgou Department",
    countryCode: "BJ",
    value: "BO",
  },
  {
    label: "Atlantique Department",
    countryCode: "BJ",
    value: "AQ",
  },
  {
    label: "Ouémé Department",
    countryCode: "BJ",
    value: "OU",
  },
  {
    label: "Littoral Department",
    countryCode: "BJ",
    value: "LI",
  },
  {
    label: "Machinga District",
    countryCode: "MW",
    value: "MH",
  },
  {
    label: "Zomba District",
    countryCode: "MW",
    value: "ZO",
  },
  {
    label: "Mwanza District",
    countryCode: "MW",
    value: "MW",
  },
  {
    label: "Nsanje District",
    countryCode: "MW",
    value: "NS",
  },
  {
    label: "Salima District",
    countryCode: "MW",
    value: "SA",
  },
  {
    label: "Chitipa district",
    countryCode: "MW",
    value: "CT",
  },
  {
    label: "Ntcheu District",
    countryCode: "MW",
    value: "NU",
  },
  {
    label: "Rumphi District",
    countryCode: "MW",
    value: "RU",
  },
  {
    label: "Dowa District",
    countryCode: "MW",
    value: "DO",
  },
  {
    label: "Karonga District",
    countryCode: "MW",
    value: "KR",
  },
  {
    label: "Central Region",
    countryCode: "MW",
    value: "C",
  },
  {
    label: "Likoma District",
    countryCode: "MW",
    value: "LK",
  },
  {
    label: "Kasungu District",
    countryCode: "MW",
    value: "KS",
  },
  {
    label: "Nkhata Bay District",
    countryCode: "MW",
    value: "NB",
  },
  {
    label: "Balaka District",
    countryCode: "MW",
    value: "BA",
  },
  {
    label: "Dedza District",
    countryCode: "MW",
    value: "DE",
  },
  {
    label: "Thyolo District",
    countryCode: "MW",
    value: "TH",
  },
  {
    label: "Mchinji District",
    countryCode: "MW",
    value: "MC",
  },
  {
    label: "Nkhotakota District",
    countryCode: "MW",
    value: "NK",
  },
  {
    label: "Lilongwe District",
    countryCode: "MW",
    value: "LI",
  },
  {
    label: "Blantyre District",
    countryCode: "MW",
    value: "BL",
  },
  {
    label: "Mulanje District",
    countryCode: "MW",
    value: "MU",
  },
  {
    label: "Mzimba District",
    countryCode: "MW",
    value: "MZ",
  },
  {
    label: "Northern Region",
    countryCode: "MW",
    value: "N",
  },
  {
    label: "Southern Region",
    countryCode: "MW",
    value: "S",
  },
  {
    label: "Chikwawa District",
    countryCode: "MW",
    value: "CK",
  },
  {
    label: "Phalombe District",
    countryCode: "MW",
    value: "PH",
  },
  {
    label: "Chiradzulu District",
    countryCode: "MW",
    value: "CR",
  },
  {
    label: "Mangochi District",
    countryCode: "MW",
    value: "MG",
  },
  {
    label: "Ntchisi District",
    countryCode: "MW",
    value: "NI",
  },
  {
    label: "Kénédougou Province",
    countryCode: "BF",
    value: "KEN",
  },
  {
    label: "Namentenga Province",
    countryCode: "BF",
    value: "NAM",
  },
  {
    label: "Sahel Region",
    countryCode: "BF",
    value: "12",
  },
  {
    label: "Centre-Ouest Region",
    countryCode: "BF",
    value: "06",
  },
  {
    label: "Nahouri Province",
    countryCode: "BF",
    value: "NAO",
  },
  {
    label: "Passoré Province",
    countryCode: "BF",
    value: "PAS",
  },
  {
    label: "Zoundwéogo Province",
    countryCode: "BF",
    value: "ZOU",
  },
  {
    label: "Sissili Province",
    countryCode: "BF",
    value: "SIS",
  },
  {
    label: "Banwa Province",
    countryCode: "BF",
    value: "BAN",
  },
  {
    label: "Bougouriba Province",
    countryCode: "BF",
    value: "BGR",
  },
  {
    label: "Gnagna Province",
    countryCode: "BF",
    value: "GNA",
  },
  {
    label: "Mouhoun",
    countryCode: "BF",
    value: "MOU",
  },
  {
    label: "Yagha Province",
    countryCode: "BF",
    value: "YAG",
  },
  {
    label: "Plateau-Central Region",
    countryCode: "BF",
    value: "11",
  },
  {
    label: "Sanmatenga Province",
    countryCode: "BF",
    value: "SMT",
  },
  {
    label: "Centre-Nord Region",
    countryCode: "BF",
    value: "05",
  },
  {
    label: "Tapoa Province",
    countryCode: "BF",
    value: "TAP",
  },
  {
    label: "Houet Province",
    countryCode: "BF",
    value: "HOU",
  },
  {
    label: "Zondoma Province",
    countryCode: "BF",
    value: "ZON",
  },
  {
    label: "Boulgou",
    countryCode: "BF",
    value: "BLG",
  },
  {
    label: "Komondjari Province",
    countryCode: "BF",
    value: "KMD",
  },
  {
    label: "Koulpélogo Province",
    countryCode: "BF",
    value: "KOP",
  },
  {
    label: "Tuy Province",
    countryCode: "BF",
    value: "TUI",
  },
  {
    label: "Ioba Province",
    countryCode: "BF",
    value: "IOB",
  },
  {
    label: "Centre",
    countryCode: "BF",
    value: "03",
  },
  {
    label: "Sourou Province",
    countryCode: "BF",
    value: "SOR",
  },
  {
    label: "Boucle du Mouhoun Region",
    countryCode: "BF",
    value: "01",
  },
  {
    label: "Séno Province",
    countryCode: "BF",
    value: "SEN",
  },
  {
    label: "Sud-Ouest Region",
    countryCode: "BF",
    value: "13",
  },
  {
    label: "Oubritenga Province",
    countryCode: "BF",
    value: "OUB",
  },
  {
    label: "Nayala Province",
    countryCode: "BF",
    value: "NAY",
  },
  {
    label: "Gourma Province",
    countryCode: "BF",
    value: "GOU",
  },
  {
    label: "Oudalan Province",
    countryCode: "BF",
    value: "OUD",
  },
  {
    label: "Ziro Province",
    countryCode: "BF",
    value: "ZIR",
  },
  {
    label: "Kossi Province",
    countryCode: "BF",
    value: "KOS",
  },
  {
    label: "Kourwéogo Province",
    countryCode: "BF",
    value: "KOW",
  },
  {
    label: "Ganzourgou Province",
    countryCode: "BF",
    value: "GAN",
  },
  {
    label: "Centre-Sud Region",
    countryCode: "BF",
    value: "07",
  },
  {
    label: "Yatenga Province",
    countryCode: "BF",
    value: "YAT",
  },
  {
    label: "Loroum Province",
    countryCode: "BF",
    value: "LOR",
  },
  {
    label: "Bazèga Province",
    countryCode: "BF",
    value: "BAZ",
  },
  {
    label: "Cascades Region",
    countryCode: "BF",
    value: "02",
  },
  {
    label: "Sanguié Province",
    countryCode: "BF",
    value: "SNG",
  },
  {
    label: "Bam Province",
    countryCode: "BF",
    value: "BAM",
  },
  {
    label: "Noumbiel Province",
    countryCode: "BF",
    value: "NOU",
  },
  {
    label: "Kompienga Province",
    countryCode: "BF",
    value: "KMP",
  },
  {
    label: "Est Region",
    countryCode: "BF",
    value: "08",
  },
  {
    label: "Léraba Province",
    countryCode: "BF",
    value: "LER",
  },
  {
    label: "Balé Province",
    countryCode: "BF",
    value: "BAL",
  },
  {
    label: "Kouritenga Province",
    countryCode: "BF",
    value: "KOT",
  },
  {
    label: "Centre-Est Region",
    countryCode: "BF",
    value: "04",
  },
  {
    label: "Poni Province",
    countryCode: "BF",
    value: "PON",
  },
  {
    label: "Nord Region, Burkina Faso",
    countryCode: "BF",
    value: "10",
  },
  {
    label: "Hauts-Bassins Region",
    countryCode: "BF",
    value: "09",
  },
  {
    label: "Soum Province",
    countryCode: "BF",
    value: "SOM",
  },
  {
    label: "Comoé Province",
    countryCode: "BF",
    value: "COM",
  },
  {
    label: "Kadiogo Province",
    countryCode: "BF",
    value: "KAD",
  },
  {
    label: "Islamabad Capital Territory",
    countryCode: "PK",
    value: "IS",
  },
  {
    label: "Gilgit-Baltistan",
    countryCode: "PK",
    value: "GB",
  },
  {
    label: "Khyber Pakhtunkhwa",
    countryCode: "PK",
    value: "KP",
  },
  {
    label: "Azad Kashmir",
    countryCode: "PK",
    value: "JK",
  },
  {
    label: "Federally Administered Tribal Areas",
    countryCode: "PK",
    value: "TA",
  },
  {
    label: "Balochistan",
    countryCode: "PK",
    value: "BA",
  },
  {
    label: "Sindh",
    countryCode: "PK",
    value: "SD",
  },
  {
    label: "Punjab",
    countryCode: "PK",
    value: "PB",
  },
  {
    label: "Al Rayyan Municipality",
    countryCode: "QA",
    value: "RA",
  },
  {
    label: "Al-Shahaniya",
    countryCode: "QA",
    value: "SH",
  },
  {
    label: "Al Wakrah",
    countryCode: "QA",
    value: "WA",
  },
  {
    label: "Madinat ash Shamal",
    countryCode: "QA",
    value: "MS",
  },
  {
    label: "Doha",
    countryCode: "QA",
    value: "DA",
  },
  {
    label: "Al Daayen",
    countryCode: "QA",
    value: "ZA",
  },
  {
    label: "Al Khor",
    countryCode: "QA",
    value: "KH",
  },
  {
    label: "Umm Salal Municipality",
    countryCode: "QA",
    value: "US",
  },
  {
    label: "Rumonge Province",
    countryCode: "BI",
    value: "RM",
  },
  {
    label: "Muyinga Province",
    countryCode: "BI",
    value: "MY",
  },
  {
    label: "Mwaro Province",
    countryCode: "BI",
    value: "MW",
  },
  {
    label: "Makamba Province",
    countryCode: "BI",
    value: "MA",
  },
  {
    label: "Rutana Province",
    countryCode: "BI",
    value: "RT",
  },
  {
    label: "Cibitoke Province",
    countryCode: "BI",
    value: "CI",
  },
  {
    label: "Ruyigi Province",
    countryCode: "BI",
    value: "RY",
  },
  {
    label: "Kayanza Province",
    countryCode: "BI",
    value: "KY",
  },
  {
    label: "Muramvya Province",
    countryCode: "BI",
    value: "MU",
  },
  {
    label: "Karuzi Province",
    countryCode: "BI",
    value: "KR",
  },
  {
    label: "Kirundo Province",
    countryCode: "BI",
    value: "KI",
  },
  {
    label: "Bubanza Province",
    countryCode: "BI",
    value: "BB",
  },
  {
    label: "Gitega Province",
    countryCode: "BI",
    value: "GI",
  },
  {
    label: "Bujumbura Mairie Province",
    countryCode: "BI",
    value: "BM",
  },
  {
    label: "Ngozi Province",
    countryCode: "BI",
    value: "NG",
  },
  {
    label: "Bujumbura Rural Province",
    countryCode: "BI",
    value: "BL",
  },
  {
    label: "Cankuzo Province",
    countryCode: "BI",
    value: "CA",
  },
  {
    label: "Bururi Province",
    countryCode: "BI",
    value: "BR",
  },
  {
    label: "Flores Department",
    countryCode: "UY",
    value: "FS",
  },
  {
    label: "San José Department",
    countryCode: "UY",
    value: "SJ",
  },
  {
    label: "Artigas Department",
    countryCode: "UY",
    value: "AR",
  },
  {
    label: "Maldonado Department",
    countryCode: "UY",
    value: "MA",
  },
  {
    label: "Rivera Department",
    countryCode: "UY",
    value: "RV",
  },
  {
    label: "Colonia Department",
    countryCode: "UY",
    value: "CO",
  },
  {
    label: "Durazno Department",
    countryCode: "UY",
    value: "DU",
  },
  {
    label: "Río Negro Department",
    countryCode: "UY",
    value: "RN",
  },
  {
    label: "Cerro Largo Department",
    countryCode: "UY",
    value: "CL",
  },
  {
    label: "Paysandú Department",
    countryCode: "UY",
    value: "PA",
  },
  {
    label: "Canelones Department",
    countryCode: "UY",
    value: "CA",
  },
  {
    label: "Treinta y Tres Department",
    countryCode: "UY",
    value: "TT",
  },
  {
    label: "Lavalleja Department",
    countryCode: "UY",
    value: "LA",
  },
  {
    label: "Rocha Department",
    countryCode: "UY",
    value: "RO",
  },
  {
    label: "Florida Department",
    countryCode: "UY",
    value: "FD",
  },
  {
    label: "Montevideo Department",
    countryCode: "UY",
    value: "MO",
  },
  {
    label: "Soriano Department",
    countryCode: "UY",
    value: "SO",
  },
  {
    label: "Salto Department",
    countryCode: "UY",
    value: "SA",
  },
  {
    label: "Tacuarembó Department",
    countryCode: "UY",
    value: "TA",
  },
  {
    label: "Kafr el-Sheikh Governorate",
    countryCode: "EG",
    value: "KFS",
  },
  {
    label: "Cairo Governorate",
    countryCode: "EG",
    value: "C",
  },
  {
    label: "Damietta Governorate",
    countryCode: "EG",
    value: "DT",
  },
  {
    label: "Aswan Governorate",
    countryCode: "EG",
    value: "ASN",
  },
  {
    label: "Sohag Governorate",
    countryCode: "EG",
    value: "SHG",
  },
  {
    label: "North Sinai Governorate",
    countryCode: "EG",
    value: "SIN",
  },
  {
    label: "Monufia Governorate",
    countryCode: "EG",
    value: "MNF",
  },
  {
    label: "Port Said Governorate",
    countryCode: "EG",
    value: "PTS",
  },
  {
    label: "Beni Suef Governorate",
    countryCode: "EG",
    value: "BNS",
  },
  {
    label: "Matrouh Governorate",
    countryCode: "EG",
    value: "MT",
  },
  {
    label: "Qalyubia Governorate",
    countryCode: "EG",
    value: "KB",
  },
  {
    label: "Suez Governorate",
    countryCode: "EG",
    value: "SUZ",
  },
  {
    label: "Gharbia Governorate",
    countryCode: "EG",
    value: "GH",
  },
  {
    label: "Alexandria Governorate",
    countryCode: "EG",
    value: "ALX",
  },
  {
    label: "Asyut Governorate",
    countryCode: "EG",
    value: "AST",
  },
  {
    label: "South Sinai Governorate",
    countryCode: "EG",
    value: "JS",
  },
  {
    label: "Faiyum Governorate",
    countryCode: "EG",
    value: "FYM",
  },
  {
    label: "Giza Governorate",
    countryCode: "EG",
    value: "GZ",
  },
  {
    label: "Red Sea Governorate",
    countryCode: "EG",
    value: "BA",
  },
  {
    label: "Beheira Governorate",
    countryCode: "EG",
    value: "BH",
  },
  {
    label: "Luxor Governorate",
    countryCode: "EG",
    value: "LX",
  },
  {
    label: "Minya Governorate",
    countryCode: "EG",
    value: "MN",
  },
  {
    label: "Ismailia Governorate",
    countryCode: "EG",
    value: "IS",
  },
  {
    label: "Dakahlia Governorate",
    countryCode: "EG",
    value: "DK",
  },
  {
    label: "New Valley Governorate",
    countryCode: "EG",
    value: "WAD",
  },
  {
    label: "Qena Governorate",
    countryCode: "EG",
    value: "KN",
  },
  {
    label: "Agaléga",
    countryCode: "MU",
    value: "AG",
  },
  {
    label: "Rodrigues",
    countryCode: "MU",
    value: "RO",
  },
  {
    label: "Pamplemousses District",
    countryCode: "MU",
    value: "PA",
  },
  {
    label: "Cargados Carajos",
    countryCode: "MU",
    value: "CC",
  },
  {
    label: "Vacoas-Phoenix",
    countryCode: "MU",
    value: "VP",
  },
  {
    label: "Moka District",
    countryCode: "MU",
    value: "MO",
  },
  {
    label: "Flacq District",
    countryCode: "MU",
    value: "FL",
  },
  {
    label: "Curepipe",
    countryCode: "MU",
    value: "CU",
  },
  {
    label: "Port Louis",
    countryCode: "MU",
    value: "PU",
  },
  {
    label: "Savanne District",
    countryCode: "MU",
    value: "SA",
  },
  {
    label: "Quatre Bornes",
    countryCode: "MU",
    value: "QB",
  },
  {
    label: "Rivière Noire District",
    countryCode: "MU",
    value: "BL",
  },
  {
    label: "Port Louis District",
    countryCode: "MU",
    value: "PL",
  },
  {
    label: "Rivière du Rempart District",
    countryCode: "MU",
    value: "RR",
  },
  {
    label: "Beau Bassin-Rose Hill",
    countryCode: "MU",
    value: "BR",
  },
  {
    label: "Plaines Wilhems District",
    countryCode: "MU",
    value: "PW",
  },
  {
    label: "Grand Port District",
    countryCode: "MU",
    value: "GP",
  },
  {
    label: "Guelmim",
    countryCode: "MA",
    value: "GUE",
  },
  {
    label: "Aousserd (EH)",
    countryCode: "MA",
    value: "AOU",
  },
  {
    label: "Al Hoceïma",
    countryCode: "MA",
    value: "HOC",
  },
  {
    label: "Larache",
    countryCode: "MA",
    value: "LAR",
  },
  {
    label: "Ouarzazate",
    countryCode: "MA",
    value: "OUA",
  },
  {
    label: "Boulemane",
    countryCode: "MA",
    value: "BOM",
  },
  {
    label: "L'Oriental",
    countryCode: "MA",
    value: "02",
  },
  {
    label: "Béni Mellal",
    countryCode: "MA",
    value: "BEM",
  },
  {
    label: "Chichaoua",
    countryCode: "MA",
    value: "CHI",
  },
  {
    label: "Boujdour (EH)",
    countryCode: "MA",
    value: "BOD",
  },
  {
    label: "Khémisset",
    countryCode: "MA",
    value: "KHE",
  },
  {
    label: "Tiznit",
    countryCode: "MA",
    value: "TIZ",
  },
  {
    label: "Béni Mellal-Khénifra",
    countryCode: "MA",
    value: "05",
  },
  {
    label: "Sidi Kacem",
    countryCode: "MA",
    value: "SIK",
  },
  {
    label: "El Jadida",
    countryCode: "MA",
    value: "JDI",
  },
  {
    label: "Nador",
    countryCode: "MA",
    value: "NAD",
  },
  {
    label: "Settat",
    countryCode: "MA",
    value: "SET",
  },
  {
    label: "Zagora",
    countryCode: "MA",
    value: "ZAG",
  },
  {
    label: "Médiouna",
    countryCode: "MA",
    value: "MED",
  },
  {
    label: "Berkane",
    countryCode: "MA",
    value: "BER",
  },
  {
    label: "Tan-Tan (EH-partial)",
    countryCode: "MA",
    value: "TNT",
  },
  {
    label: "Nouaceur",
    countryCode: "MA",
    value: "NOU",
  },
  {
    label: "Marrakesh-Safi",
    countryCode: "MA",
    value: "07",
  },
  {
    label: "Sefrou",
    countryCode: "MA",
    value: "SEF",
  },
  {
    label: "Drâa-Tafilalet",
    countryCode: "MA",
    value: "08",
  },
  {
    label: "El Hajeb",
    countryCode: "MA",
    value: "HAJ",
  },
  {
    label: "Es-Semara (EH-partial)",
    countryCode: "MA",
    value: "ESM",
  },
  {
    label: "Laâyoune (EH)",
    countryCode: "MA",
    value: "LAA",
  },
  {
    label: "Inezgane-Ait Melloul",
    countryCode: "MA",
    value: "INE",
  },
  {
    label: "Souss-Massa",
    countryCode: "MA",
    value: "09",
  },
  {
    label: "Taza",
    countryCode: "MA",
    value: "TAZ",
  },
  {
    label: "Assa-Zag (EH-partial)",
    countryCode: "MA",
    value: "ASZ",
  },
  {
    label: "Laâyoune-Sakia El Hamra (EH-partial)",
    countryCode: "MA",
    value: "11",
  },
  {
    label: "Errachidia",
    countryCode: "MA",
    value: "ERR",
  },
  {
    label: "Fahs-Anjra",
    countryCode: "MA",
    value: "FAH",
  },
  {
    label: "Figuig",
    countryCode: "MA",
    value: "FIG",
  },
  {
    label: "Chtouka-Ait Baha",
    countryCode: "MA",
    value: "CHT",
  },
  {
    label: "Casablanca-Settat",
    countryCode: "MA",
    value: "06",
  },
  {
    label: "Benslimane",
    countryCode: "MA",
    value: "BES",
  },
  {
    label: "Guelmim-Oued Noun (EH-partial)",
    countryCode: "MA",
    value: "10",
  },
  {
    label: "Dakhla-Oued Ed-Dahab (EH)",
    countryCode: "MA",
    value: "12",
  },
  {
    label: "Jerada",
    countryCode: "MA",
    value: "JRA",
  },
  {
    label: "Kénitra",
    countryCode: "MA",
    value: "KEN",
  },
  {
    label: "El Kelâa des Sraghna",
    countryCode: "MA",
    value: "KES",
  },
  {
    label: "Chefchaouen",
    countryCode: "MA",
    value: "CHE",
  },
  {
    label: "Safi",
    countryCode: "MA",
    value: "SAF",
  },
  {
    label: "Tata",
    countryCode: "MA",
    value: "TAT",
  },
  {
    label: "Fès-Meknès",
    countryCode: "MA",
    value: "03",
  },
  {
    label: "Taroudannt",
    countryCode: "MA",
    value: "TAR",
  },
  {
    label: "Moulay Yacoub",
    countryCode: "MA",
    value: "MOU",
  },
  {
    label: "Essaouira",
    countryCode: "MA",
    value: "ESI",
  },
  {
    label: "Khénifra",
    countryCode: "MA",
    value: "KHN",
  },
  {
    label: "Tétouan",
    countryCode: "MA",
    value: "TET",
  },
  {
    label: "Oued Ed-Dahab (EH)",
    countryCode: "MA",
    value: "OUD",
  },
  {
    label: "Al Haouz",
    countryCode: "MA",
    value: "HAO",
  },
  {
    label: "Azilal",
    countryCode: "MA",
    value: "AZI",
  },
  {
    label: "Taourirt",
    countryCode: "MA",
    value: "TAI",
  },
  {
    label: "Taounate",
    countryCode: "MA",
    value: "TAO",
  },
  {
    label: "Tanger-Tétouan-Al Hoceïma",
    countryCode: "MA",
    value: "01",
  },
  {
    label: "Ifrane",
    countryCode: "MA",
    value: "IFR",
  },
  {
    label: "Khouribga",
    countryCode: "MA",
    value: "KHO",
  },
  {
    label: "Cabo Delgado Province",
    countryCode: "MZ",
    value: "P",
  },
  {
    label: "Zambezia Province",
    countryCode: "MZ",
    value: "Q",
  },
  {
    label: "Gaza Province",
    countryCode: "MZ",
    value: "G",
  },
  {
    label: "Inhambane Province",
    countryCode: "MZ",
    value: "I",
  },
  {
    label: "Sofala Province",
    countryCode: "MZ",
    value: "S",
  },
  {
    label: "Maputo Province",
    countryCode: "MZ",
    value: "L",
  },
  {
    label: "Niassa Province",
    countryCode: "MZ",
    value: "A",
  },
  {
    label: "Tete Province",
    countryCode: "MZ",
    value: "T",
  },
  {
    label: "Maputo",
    countryCode: "MZ",
    value: "MPM",
  },
  {
    label: "Nampula Province",
    countryCode: "MZ",
    value: "N",
  },
  {
    label: "Manica Province",
    countryCode: "MZ",
    value: "B",
  },
  {
    label: "Hodh Ech Chargui Region",
    countryCode: "MR",
    value: "01",
  },
  {
    label: "Brakna Region",
    countryCode: "MR",
    value: "05",
  },
  {
    label: "Tiris Zemmour Region",
    countryCode: "MR",
    value: "11",
  },
  {
    label: "Gorgol Region",
    countryCode: "MR",
    value: "04",
  },
  {
    label: "Inchiri Region",
    countryCode: "MR",
    value: "12",
  },
  {
    label: "Nouakchott-Nord Region",
    countryCode: "MR",
    value: "14",
  },
  {
    label: "Adrar Region",
    countryCode: "MR",
    value: "07",
  },
  {
    label: "Tagant Region",
    countryCode: "MR",
    value: "09",
  },
  {
    label: "Dakhlet Nouadhibou",
    countryCode: "MR",
    value: "08",
  },
  {
    label: "Nouakchott-Sud Region",
    countryCode: "MR",
    value: "15",
  },
  {
    label: "Trarza Region",
    countryCode: "MR",
    value: "06",
  },
  {
    label: "Assaba Region",
    countryCode: "MR",
    value: "03",
  },
  {
    label: "Guidimaka Region",
    countryCode: "MR",
    value: "10",
  },
  {
    label: "Hodh El Gharbi Region",
    countryCode: "MR",
    value: "02",
  },
  {
    label: "Nouakchott-Ouest Region",
    countryCode: "MR",
    value: "13",
  },
  {
    label: "Western Tobago",
    countryCode: "TT",
    value: "WTO",
  },
  {
    label: "Couva-Tabaquite-Talparo Regional Corporation",
    countryCode: "TT",
    value: "CTT",
  },
  {
    label: "Eastern Tobago",
    countryCode: "TT",
    value: "ETO",
  },
  {
    label: "Rio Claro-Mayaro Regional Corporation",
    countryCode: "TT",
    value: "MRC",
  },
  {
    label: "San Juan-Laventille Regional Corporation",
    countryCode: "TT",
    value: "SJL",
  },
  {
    label: "Tunapuna-Piarco Regional Corporation",
    countryCode: "TT",
    value: "TUP",
  },
  {
    label: "San Fernando",
    countryCode: "TT",
    value: "SFO",
  },
  {
    label: "Point Fortin",
    countryCode: "TT",
    value: "PTF",
  },
  {
    label: "Sangre Grande Regional Corporation",
    countryCode: "TT",
    value: "SGE",
  },
  {
    label: "Arima",
    countryCode: "TT",
    value: "ARI",
  },
  {
    label: "Port of Spain",
    countryCode: "TT",
    value: "POS",
  },
  {
    label: "Siparia Regional Corporation",
    countryCode: "TT",
    value: "SIP",
  },
  {
    label: "Penal-Debe Regional Corporation",
    countryCode: "TT",
    value: "PED",
  },
  {
    label: "Chaguanas",
    countryCode: "TT",
    value: "CHA",
  },
  {
    label: "Diego Martin Regional Corporation",
    countryCode: "TT",
    value: "DMN",
  },
  {
    label: "Princes Town Regional Corporation",
    countryCode: "TT",
    value: "PRT",
  },
  {
    label: "Mary Region",
    countryCode: "TM",
    value: "M",
  },
  {
    label: "Lebap Region",
    countryCode: "TM",
    value: "L",
  },
  {
    label: "Ashgabat",
    countryCode: "TM",
    value: "S",
  },
  {
    label: "Balkan Region",
    countryCode: "TM",
    value: "B",
  },
  {
    label: "Daşoguz Region",
    countryCode: "TM",
    value: "D",
  },
  {
    label: "Ahal Region",
    countryCode: "TM",
    value: "A",
  },
  {
    label: "Beni Department",
    countryCode: "BO",
    value: "B",
  },
  {
    label: "Oruro Department",
    countryCode: "BO",
    value: "O",
  },
  {
    label: "Santa Cruz Department",
    countryCode: "BO",
    value: "S",
  },
  {
    label: "Tarija Department",
    countryCode: "BO",
    value: "T",
  },
  {
    label: "Pando Department",
    countryCode: "BO",
    value: "N",
  },
  {
    label: "La Paz Department",
    countryCode: "BO",
    value: "L",
  },
  {
    label: "Cochabamba Department",
    countryCode: "BO",
    value: "C",
  },
  {
    label: "Chuquisaca Department",
    countryCode: "BO",
    value: "H",
  },
  {
    label: "Potosí Department",
    countryCode: "BO",
    value: "P",
  },
  {
    label: "Saint George Parish",
    countryCode: "VC",
    value: "04",
  },
  {
    label: "Saint Patrick Parish",
    countryCode: "VC",
    value: "05",
  },
  {
    label: "Saint Andrew Parish",
    countryCode: "VC",
    value: "02",
  },
  {
    label: "Saint David Parish",
    countryCode: "VC",
    value: "03",
  },
  {
    label: "Grenadines Parish",
    countryCode: "VC",
    value: "06",
  },
  {
    label: "Charlotte Parish",
    countryCode: "VC",
    value: "01",
  },
  {
    label: "Sharjah Emirate",
    countryCode: "AE",
    value: "SH",
  },
  {
    label: "Dubai",
    countryCode: "AE",
    value: "DU",
  },
  {
    label: "Umm al-Quwain",
    countryCode: "AE",
    value: "UQ",
  },
  {
    label: "Fujairah",
    countryCode: "AE",
    value: "FU",
  },
  {
    label: "Ras al-Khaimah",
    countryCode: "AE",
    value: "RK",
  },
  {
    label: "Ajman Emirate",
    countryCode: "AE",
    value: "AJ",
  },
  {
    label: "Abu Dhabi Emirate",
    countryCode: "AE",
    value: "AZ",
  },
  {
    label: "districts of Republican Subordination",
    countryCode: "TJ",
    value: "RA",
  },
  {
    label: "Khatlon Province",
    countryCode: "TJ",
    value: "KT",
  },
  {
    label: "Gorno-Badakhshan Autonomous Province",
    countryCode: "TJ",
    value: "GB",
  },
  {
    label: "Sughd Province",
    countryCode: "TJ",
    value: "SU",
  },
  {
    label: "Tainan County",
    countryCode: "TW",
    value: "TNQ",
  },
  {
    label: "Yilan County",
    countryCode: "TW",
    value: "ILA",
  },
  {
    label: "Penghu County",
    countryCode: "TW",
    value: "PEN",
  },
  {
    label: "Changhua County",
    countryCode: "TW",
    value: "CHA",
  },
  {
    label: "Pingtung County",
    countryCode: "TW",
    value: "PIF",
  },
  {
    label: "Taichung",
    countryCode: "TW",
    value: "TXG",
  },
  {
    label: "Nantou County",
    countryCode: "TW",
    value: "NAN",
  },
  {
    label: "Chiayi County",
    countryCode: "TW",
    value: "CYI",
  },
  {
    label: "Kaohsiung County",
    countryCode: "TW",
    value: "KHQ",
  },
  {
    label: "Taitung County",
    countryCode: "TW",
    value: "TTT",
  },
  {
    label: "Hualien County",
    countryCode: "TW",
    value: "HUA",
  },
  {
    label: "Kaohsiung",
    countryCode: "TW",
    value: "KHH",
  },
  {
    label: "Miaoli County",
    countryCode: "TW",
    value: "MIA",
  },
  {
    label: "Taichung County",
    countryCode: "TW",
    value: "TXQ",
  },
  {
    label: "Kinmen",
    countryCode: "TW",
    value: "KIN",
  },
  {
    label: "Yunlin County",
    countryCode: "TW",
    value: "YUN",
  },
  {
    label: "Hsinchu",
    countryCode: "TW",
    value: "HSZ",
  },
  {
    label: "Chiayi City",
    countryCode: "TW",
    value: "CYQ",
  },
  {
    label: "Taoyuan City",
    countryCode: "TW",
    value: "TAO",
  },
  {
    label: "Lienchiang County",
    countryCode: "TW",
    value: "LIE",
  },
  {
    label: "Tainan",
    countryCode: "TW",
    value: "TNN",
  },
  {
    label: "Taipei",
    countryCode: "TW",
    value: "TPE",
  },
  {
    label: "Hsinchu County",
    countryCode: "TW",
    value: "HSQ",
  },
  {
    label: "Northern Red Sea Region",
    countryCode: "ER",
    value: "SK",
  },
  {
    label: "Anseba Region",
    countryCode: "ER",
    value: "AN",
  },
  {
    label: "Maekel Region",
    countryCode: "ER",
    value: "MA",
  },
  {
    label: "Debub Region",
    countryCode: "ER",
    value: "DU",
  },
  {
    label: "Gash-Barka Region",
    countryCode: "ER",
    value: "GB",
  },
  {
    label: "Southern Red Sea Region",
    countryCode: "ER",
    value: "DK",
  },
  {
    label: "Southern Peninsula Region",
    countryCode: "IS",
    value: "2",
  },
  {
    label: "Capital Region",
    countryCode: "IS",
    value: "1",
  },
  {
    label: "Westfjords",
    countryCode: "IS",
    value: "4",
  },
  {
    label: "Eastern Region",
    countryCode: "IS",
    value: "7",
  },
  {
    label: "Southern Region",
    countryCode: "IS",
    value: "8",
  },
  {
    label: "Northwestern Region",
    countryCode: "IS",
    value: "5",
  },
  {
    label: "Western Region",
    countryCode: "IS",
    value: "3",
  },
  {
    label: "Northeastern Region",
    countryCode: "IS",
    value: "6",
  },
  {
    label: "Río Muni",
    countryCode: "GQ",
    value: "C",
  },
  {
    label: "Kié-Ntem Province",
    countryCode: "GQ",
    value: "KN",
  },
  {
    label: "Wele-Nzas Province",
    countryCode: "GQ",
    value: "WN",
  },
  {
    label: "Litoral Province",
    countryCode: "GQ",
    value: "LI",
  },
  {
    label: "Insular Region",
    countryCode: "GQ",
    value: "I",
  },
  {
    label: "Bioko Sur Province",
    countryCode: "GQ",
    value: "BS",
  },
  {
    label: "Annobón Province",
    countryCode: "GQ",
    value: "AN",
  },
  {
    label: "Centro Sur Province",
    countryCode: "GQ",
    value: "CS",
  },
  {
    label: "Bioko Norte Province",
    countryCode: "GQ",
    value: "BN",
  },
  {
    label: "Chihuahua",
    countryCode: "MX",
    value: "CHH",
  },
  {
    label: "Oaxaca",
    countryCode: "MX",
    value: "OAX",
  },
  {
    label: "Sinaloa",
    countryCode: "MX",
    value: "SIN",
  },
  {
    label: "México",
    countryCode: "MX",
    value: "MEX",
  },
  {
    label: "Chiapas",
    countryCode: "MX",
    value: "CHP",
  },
  {
    label: "Nuevo León",
    countryCode: "MX",
    value: "NLE",
  },
  {
    label: "Durango",
    countryCode: "MX",
    value: "DUR",
  },
  {
    label: "Tabasco",
    countryCode: "MX",
    value: "TAB",
  },
  {
    label: "Querétaro",
    countryCode: "MX",
    value: "QUE",
  },
  {
    label: "Aguascalientes",
    countryCode: "MX",
    value: "AGU",
  },
  {
    label: "Baja California",
    countryCode: "MX",
    value: "BCN",
  },
  {
    label: "Tlaxcala",
    countryCode: "MX",
    value: "TLA",
  },
  {
    label: "Guerrero",
    countryCode: "MX",
    value: "GRO",
  },
  {
    label: "Baja California Sur",
    countryCode: "MX",
    value: "BCS",
  },
  {
    label: "San Luis Potosí",
    countryCode: "MX",
    value: "SLP",
  },
  {
    label: "Zacatecas",
    countryCode: "MX",
    value: "ZAC",
  },
  {
    label: "Tamaulipas",
    countryCode: "MX",
    value: "TAM",
  },
  {
    label: "Veracruz",
    countryCode: "MX",
    value: "VER",
  },
  {
    label: "Morelos",
    countryCode: "MX",
    value: "MOR",
  },
  {
    label: "Yucatán",
    countryCode: "MX",
    value: "YUC",
  },
  {
    label: "Quintana Roo",
    countryCode: "MX",
    value: "ROO",
  },
  {
    label: "Sonora",
    countryCode: "MX",
    value: "SON",
  },
  {
    label: "Guanajuato",
    countryCode: "MX",
    value: "GUA",
  },
  {
    label: "Hidalgo",
    countryCode: "MX",
    value: "HID",
  },
  {
    label: "Coahuila",
    countryCode: "MX",
    value: "COA",
  },
  {
    label: "Colima",
    countryCode: "MX",
    value: "COL",
  },
  {
    label: "Mexico City",
    countryCode: "MX",
    value: "CMX",
  },
  {
    label: "Michoacán",
    countryCode: "MX",
    value: "MIC",
  },
  {
    label: "Campeche",
    countryCode: "MX",
    value: "CAM",
  },
  {
    label: "Puebla",
    countryCode: "MX",
    value: "PUE",
  },
  {
    label: "Nayarit",
    countryCode: "MX",
    value: "NAY",
  },
  {
    label: "Krabi",
    countryCode: "TH",
    value: "81",
  },
  {
    label: "Ranong",
    countryCode: "TH",
    value: "85",
  },
  {
    label: "Nong Bua Lam Phu",
    countryCode: "TH",
    value: "39",
  },
  {
    label: "Samut Prakan",
    countryCode: "TH",
    value: "11",
  },
  {
    label: "Surat Thani",
    countryCode: "TH",
    value: "84",
  },
  {
    label: "Lamphun",
    countryCode: "TH",
    value: "51",
  },
  {
    label: "Nong Khai",
    countryCode: "TH",
    value: "43",
  },
  {
    label: "Khon Kaen",
    countryCode: "TH",
    value: "40",
  },
  {
    label: "Chanthaburi",
    countryCode: "TH",
    value: "22",
  },
  {
    label: "Saraburi",
    countryCode: "TH",
    value: "19",
  },
  {
    label: "Phatthalung",
    countryCode: "TH",
    value: "93",
  },
  {
    label: "Uttaradit",
    countryCode: "TH",
    value: "53",
  },
  {
    label: "Sing Buri",
    countryCode: "TH",
    value: "17",
  },
  {
    label: "Chiang Mai",
    countryCode: "TH",
    value: "50",
  },
  {
    label: "Nakhon Sawan",
    countryCode: "TH",
    value: "60",
  },
  {
    label: "Yala",
    countryCode: "TH",
    value: "95",
  },
  {
    label: "Phra Nakhon Si Ayutthaya",
    countryCode: "TH",
    value: "14",
  },
  {
    label: "Nonthaburi",
    countryCode: "TH",
    value: "12",
  },
  {
    label: "Trat",
    countryCode: "TH",
    value: "23",
  },
  {
    label: "Nakhon Ratchasima",
    countryCode: "TH",
    value: "30",
  },
  {
    label: "Chiang Rai",
    countryCode: "TH",
    value: "57",
  },
  {
    label: "Ratchaburi",
    countryCode: "TH",
    value: "70",
  },
  {
    label: "Pathum Thani",
    countryCode: "TH",
    value: "13",
  },
  {
    label: "Sakon Nakhon",
    countryCode: "TH",
    value: "47",
  },
  {
    label: "Samut Songkhram",
    countryCode: "TH",
    value: "75",
  },
  {
    label: "Nakhon Pathom",
    countryCode: "TH",
    value: "73",
  },
  {
    label: "Samut Sakhon",
    countryCode: "TH",
    value: "74",
  },
  {
    label: "Mae Hong Son",
    countryCode: "TH",
    value: "58",
  },
  {
    label: "Phitsanulok",
    countryCode: "TH",
    value: "65",
  },
  {
    label: "Pattaya",
    countryCode: "TH",
    value: "S",
  },
  {
    label: "Prachuap Khiri Khan",
    countryCode: "TH",
    value: "77",
  },
  {
    label: "Loei",
    countryCode: "TH",
    value: "42",
  },
  {
    label: "Roi Et",
    countryCode: "TH",
    value: "45",
  },
  {
    label: "Kanchanaburi",
    countryCode: "TH",
    value: "71",
  },
  {
    label: "Ubon Ratchathani",
    countryCode: "TH",
    value: "34",
  },
  {
    label: "Chon Buri",
    countryCode: "TH",
    value: "20",
  },
  {
    label: "Phichit",
    countryCode: "TH",
    value: "66",
  },
  {
    label: "Phetchabun",
    countryCode: "TH",
    value: "67",
  },
  {
    label: "Kamphaeng Phet",
    countryCode: "TH",
    value: "62",
  },
  {
    label: "Maha Sarakham",
    countryCode: "TH",
    value: "44",
  },
  {
    label: "Rayong",
    countryCode: "TH",
    value: "21",
  },
  {
    label: "Ang Thong",
    countryCode: "TH",
    value: "15",
  },
  {
    label: "Nakhon Si Thammarat",
    countryCode: "TH",
    value: "80",
  },
  {
    label: "Yasothon",
    countryCode: "TH",
    value: "35",
  },
  {
    label: "Chai Nat",
    countryCode: "TH",
    value: "18",
  },
  {
    label: "Amnat Charoen",
    countryCode: "TH",
    value: "37",
  },
  {
    label: "Suphan Buri",
    countryCode: "TH",
    value: "72",
  },
  {
    label: "Tak",
    countryCode: "TH",
    value: "63",
  },
  {
    label: "Chumphon",
    countryCode: "TH",
    value: "86",
  },
  {
    label: "Udon Thani",
    countryCode: "TH",
    value: "41",
  },
  {
    label: "Phrae",
    countryCode: "TH",
    value: "54",
  },
  {
    label: "Sa Kaeo",
    countryCode: "TH",
    value: "27",
  },
  {
    label: "Nan",
    countryCode: "TH",
    value: "55",
  },
  {
    label: "Surin",
    countryCode: "TH",
    value: "32",
  },
  {
    label: "Phetchaburi",
    countryCode: "TH",
    value: "76",
  },
  {
    label: "Bueng Kan",
    countryCode: "TH",
    value: "38",
  },
  {
    label: "Buri Ram",
    countryCode: "TH",
    value: "31",
  },
  {
    label: "Nakhon Nayok",
    countryCode: "TH",
    value: "26",
  },
  {
    label: "Phuket",
    countryCode: "TH",
    value: "83",
  },
  {
    label: "Satun",
    countryCode: "TH",
    value: "91",
  },
  {
    label: "Phayao",
    countryCode: "TH",
    value: "56",
  },
  {
    label: "Songkhla",
    countryCode: "TH",
    value: "90",
  },
  {
    label: "Pattani",
    countryCode: "TH",
    value: "94",
  },
  {
    label: "Trang",
    countryCode: "TH",
    value: "92",
  },
  {
    label: "Prachin Buri",
    countryCode: "TH",
    value: "25",
  },
  {
    label: "Lop Buri",
    countryCode: "TH",
    value: "16",
  },
  {
    label: "Lampang",
    countryCode: "TH",
    value: "52",
  },
  {
    label: "Sukhothai",
    countryCode: "TH",
    value: "64",
  },
  {
    label: "Mukdahan",
    countryCode: "TH",
    value: "49",
  },
  {
    label: "Si Sa Ket",
    countryCode: "TH",
    value: "33",
  },
  {
    label: "Nakhon Phanom",
    countryCode: "TH",
    value: "48",
  },
  {
    label: "Phangnga",
    countryCode: "TH",
    value: "82",
  },
  {
    label: "Kalasin",
    countryCode: "TH",
    value: "46",
  },
  {
    label: "Uthai Thani",
    countryCode: "TH",
    value: "61",
  },
  {
    label: "Chachoengsao",
    countryCode: "TH",
    value: "24",
  },
  {
    label: "Narathiwat",
    countryCode: "TH",
    value: "96",
  },
  {
    label: "Bangkok",
    countryCode: "TH",
    value: "10",
  },
  {
    label: "Hiiu County",
    countryCode: "EE",
    value: "39",
  },
  {
    label: "Viljandi County",
    countryCode: "EE",
    value: "84",
  },
  {
    label: "Tartu County",
    countryCode: "EE",
    value: "78",
  },
  {
    label: "Valga County",
    countryCode: "EE",
    value: "82",
  },
  {
    label: "Rapla County",
    countryCode: "EE",
    value: "70",
  },
  {
    label: "Võru County",
    countryCode: "EE",
    value: "86",
  },
  {
    label: "Saare County",
    countryCode: "EE",
    value: "74",
  },
  {
    label: "Pärnu County",
    countryCode: "EE",
    value: "67",
  },
  {
    label: "Põlva County",
    countryCode: "EE",
    value: "65",
  },
  {
    label: "Lääne-Viru County",
    countryCode: "EE",
    value: "59",
  },
  {
    label: "Jõgeva County",
    countryCode: "EE",
    value: "49",
  },
  {
    label: "Järva County",
    countryCode: "EE",
    value: "51",
  },
  {
    label: "Harju County",
    countryCode: "EE",
    value: "37",
  },
  {
    label: "Lääne County",
    countryCode: "EE",
    value: "57",
  },
  {
    label: "Ida-Viru County",
    countryCode: "EE",
    value: "44",
  },
  {
    label: "Moyen-Chari Region",
    countryCode: "TD",
    value: "MC",
  },
  {
    label: "Mayo-Kebbi Ouest Region",
    countryCode: "TD",
    value: "MO",
  },
  {
    label: "Sila Region",
    countryCode: "TD",
    value: "SI",
  },
  {
    label: "Hadjer-Lamis",
    countryCode: "TD",
    value: "HL",
  },
  {
    label: "Borkou",
    countryCode: "TD",
    value: "BO",
  },
  {
    label: "Ennedi-Est",
    countryCode: "TD",
    value: "EE",
  },
  {
    label: "Guéra Region",
    countryCode: "TD",
    value: "GR",
  },
  {
    label: "Lac Region",
    countryCode: "TD",
    value: "LC",
  },
  {
    label: "Ennedi Region",
    countryCode: "TD",
    value: "EN",
  },
  {
    label: "Tandjilé Region",
    countryCode: "TD",
    value: "TA",
  },
  {
    label: "Mayo-Kebbi Est Region",
    countryCode: "TD",
    value: "ME",
  },
  {
    label: "Wadi Fira Region",
    countryCode: "TD",
    value: "WF",
  },
  {
    label: "Ouaddaï Region",
    countryCode: "TD",
    value: "OD",
  },
  {
    label: "Bahr el Gazel",
    countryCode: "TD",
    value: "BG",
  },
  {
    label: "Ennedi-Ouest",
    countryCode: "TD",
    value: "EO",
  },
  {
    label: "Logone Occidental Region",
    countryCode: "TD",
    value: "LO",
  },
  {
    label: "N'Djamena",
    countryCode: "TD",
    value: "ND",
  },
  {
    label: "Tibesti Region",
    countryCode: "TD",
    value: "TI",
  },
  {
    label: "Kanem Region",
    countryCode: "TD",
    value: "KA",
  },
  {
    label: "Mandoul Region",
    countryCode: "TD",
    value: "MA",
  },
  {
    label: "Batha Region",
    countryCode: "TD",
    value: "BA",
  },
  {
    label: "Logone Oriental Region",
    countryCode: "TD",
    value: "LR",
  },
  {
    label: "Salamat Region",
    countryCode: "TD",
    value: "SA",
  },
  {
    label: "Berry Islands",
    countryCode: "BS",
    value: "BY",
  },
  {
    label: "Nichollstown and Berry Islands",
    countryCode: "BS",
    value: "NB",
  },
  {
    label: "Green Turtle Cay",
    countryCode: "BS",
    value: "GT",
  },
  {
    label: "Central Eleuthera",
    countryCode: "BS",
    value: "CE",
  },
  {
    label: "Governor's Harbour",
    countryCode: "BS",
    value: "GH",
  },
  {
    label: "High Rock",
    countryCode: "BS",
    value: "HR",
  },
  {
    label: "West Grand Bahama",
    countryCode: "BS",
    value: "WG",
  },
  {
    label: "Rum Cay District",
    countryCode: "BS",
    value: "RC",
  },
  {
    label: "Acklins",
    countryCode: "BS",
    value: "AK",
  },
  {
    label: "North Eleuthera",
    countryCode: "BS",
    value: "NE",
  },
  {
    label: "Central Abaco",
    countryCode: "BS",
    value: "CO",
  },
  {
    label: "Marsh Harbour",
    countryCode: "BS",
    value: "MH",
  },
  {
    label: "Black Point",
    countryCode: "BS",
    value: "BP",
  },
  {
    label: "Sandy Point",
    countryCode: "BS",
    value: "SP",
  },
  {
    label: "South Eleuthera",
    countryCode: "BS",
    value: "SE",
  },
  {
    label: "South Abaco",
    countryCode: "BS",
    value: "SO",
  },
  {
    label: "Inagua",
    countryCode: "BS",
    value: "IN",
  },
  {
    label: "Long Island",
    countryCode: "BS",
    value: "LI",
  },
  {
    label: "Cat Island",
    countryCode: "BS",
    value: "CI",
  },
  {
    label: "Exuma",
    countryCode: "BS",
    value: "EX",
  },
  {
    label: "Harbour Island",
    countryCode: "BS",
    value: "HI",
  },
  {
    label: "East Grand Bahama",
    countryCode: "BS",
    value: "EG",
  },
  {
    label: "Ragged Island",
    countryCode: "BS",
    value: "RI",
  },
  {
    label: "North Abaco",
    countryCode: "BS",
    value: "NO",
  },
  {
    label: "North Andros",
    countryCode: "BS",
    value: "NS",
  },
  {
    label: "Kemps Bay",
    countryCode: "BS",
    value: "KB",
  },
  {
    label: "Fresh Creek",
    countryCode: "BS",
    value: "FC",
  },
  {
    label: "San Salvador and Rum Cay",
    countryCode: "BS",
    value: "SR",
  },
  {
    label: "Crooked Island",
    countryCode: "BS",
    value: "CK",
  },
  {
    label: "South Andros",
    countryCode: "BS",
    value: "SA",
  },
  {
    label: "Rock Sound",
    countryCode: "BS",
    value: "RS",
  },
  {
    label: "Hope Town",
    countryCode: "BS",
    value: "HT",
  },
  {
    label: "Mangrove Cay",
    countryCode: "BS",
    value: "MC",
  },
  {
    label: "Freeport",
    countryCode: "BS",
    value: "FP",
  },
  {
    label: "San Salvador Island",
    countryCode: "BS",
    value: "SS",
  },
  {
    label: "Acklins and Crooked Islands",
    countryCode: "BS",
    value: "AC",
  },
  {
    label: "Bimini",
    countryCode: "BS",
    value: "BI",
  },
  {
    label: "Spanish Wells",
    countryCode: "BS",
    value: "SW",
  },
  {
    label: "Central Andros",
    countryCode: "BS",
    value: "CS",
  },
  {
    label: "Grand Cay",
    countryCode: "BS",
    value: "GC",
  },
  {
    label: "Mayaguana District",
    countryCode: "BS",
    value: "MG",
  },
  {
    label: "San Juan Province",
    countryCode: "AR",
    value: "J",
  },
  {
    label: "Santiago del Estero Province",
    countryCode: "AR",
    value: "G",
  },
  {
    label: "San Luis Province",
    countryCode: "AR",
    value: "D",
  },
  {
    label: "Tucumán Province",
    countryCode: "AR",
    value: "T",
  },
  {
    label: "Corrientes",
    countryCode: "AR",
    value: "W",
  },
  {
    label: "Río Negro Province",
    countryCode: "AR",
    value: "R",
  },
  {
    label: "Chaco Province",
    countryCode: "AR",
    value: "H",
  },
  {
    label: "Santa Fe Province",
    countryCode: "AR",
    value: "S",
  },
  {
    label: "Córdoba Province",
    countryCode: "AR",
    value: "X",
  },
  {
    label: "Salta Province",
    countryCode: "AR",
    value: "A",
  },
  {
    label: "Misiones Province",
    countryCode: "AR",
    value: "N",
  },
  {
    label: "Jujuy Province",
    countryCode: "AR",
    value: "Y",
  },
  {
    label: "Mendoza",
    countryCode: "AR",
    value: "M",
  },
  {
    label: "Catamarca Province",
    countryCode: "AR",
    value: "K",
  },
  {
    label: "Neuquén Province",
    countryCode: "AR",
    value: "Q",
  },
  {
    label: "Santa Cruz Province",
    countryCode: "AR",
    value: "Z",
  },
  {
    label: "Tierra del Fuego Province",
    countryCode: "AR",
    value: "V",
  },
  {
    label: "Chubut Province",
    countryCode: "AR",
    value: "U",
  },
  {
    label: "Formosa Province",
    countryCode: "AR",
    value: "P",
  },
  {
    label: "La Rioja Province",
    countryCode: "AR",
    value: "F",
  },
  {
    label: "Entre Ríos Province",
    countryCode: "AR",
    value: "E",
  },
  {
    label: "La Pampa",
    countryCode: "AR",
    value: "L",
  },
  {
    label: "Buenos Aires Province",
    countryCode: "AR",
    value: "B",
  },
  {
    label: "Quiché Department",
    countryCode: "GT",
    value: "QC",
  },
  {
    label: "Jalapa Department",
    countryCode: "GT",
    value: "JA",
  },
  {
    label: "Izabal Department",
    countryCode: "GT",
    value: "IZ",
  },
  {
    label: "Suchitepéquez Department",
    countryCode: "GT",
    value: "SU",
  },
  {
    label: "Sololá Department",
    countryCode: "GT",
    value: "SO",
  },
  {
    label: "El Progreso Department",
    countryCode: "GT",
    value: "PR",
  },
  {
    label: "Totonicapán Department",
    countryCode: "GT",
    value: "TO",
  },
  {
    label: "Retalhuleu Department",
    countryCode: "GT",
    value: "RE",
  },
  {
    label: "Santa Rosa Department",
    countryCode: "GT",
    value: "SR",
  },
  {
    label: "Chiquimula Department",
    countryCode: "GT",
    value: "CQ",
  },
  {
    label: "San Marcos Department",
    countryCode: "GT",
    value: "SM",
  },
  {
    label: "Quetzaltenango Department",
    countryCode: "GT",
    value: "QZ",
  },
  {
    label: "Petén Department",
    countryCode: "GT",
    value: "PE",
  },
  {
    label: "Huehuetenango Department",
    countryCode: "GT",
    value: "HU",
  },
  {
    label: "Alta Verapaz Department",
    countryCode: "GT",
    value: "AV",
  },
  {
    label: "Guatemala Department",
    countryCode: "GT",
    value: "GU",
  },
  {
    label: "Jutiapa Department",
    countryCode: "GT",
    value: "JU",
  },
  {
    label: "Baja Verapaz Department",
    countryCode: "GT",
    value: "BV",
  },
  {
    label: "Chimaltenango Department",
    countryCode: "GT",
    value: "CM",
  },
  {
    label: "Sacatepéquez Department",
    countryCode: "GT",
    value: "SA",
  },
  {
    label: "Escuintla Department",
    countryCode: "GT",
    value: "ES",
  },
  {
    label: "Madre de Dios",
    countryCode: "PE",
    value: "MDD",
  },
  {
    label: "Huancavelica",
    countryCode: "PE",
    value: "HUV",
  },
  {
    label: "Áncash",
    countryCode: "PE",
    value: "ANC",
  },
  {
    label: "Arequipa",
    countryCode: "PE",
    value: "ARE",
  },
  {
    label: "Puno",
    countryCode: "PE",
    value: "PUN",
  },
  {
    label: "La Libertad",
    countryCode: "PE",
    value: "LAL",
  },
  {
    label: "Ucayali",
    countryCode: "PE",
    value: "UCA",
  },
  {
    label: "Amazonas",
    countryCode: "PE",
    value: "AMA",
  },
  {
    label: "Pasco",
    countryCode: "PE",
    value: "PAS",
  },
  {
    label: "Huanuco",
    countryCode: "PE",
    value: "HUC",
  },
  {
    label: "Cajamarca",
    countryCode: "PE",
    value: "CAJ",
  },
  {
    label: "Tumbes",
    countryCode: "PE",
    value: "TUM",
  },
  {
    label: "Cusco",
    countryCode: "PE",
    value: "CUS",
  },
  {
    label: "Ayacucho",
    countryCode: "PE",
    value: "AYA",
  },
  {
    label: "Junín",
    countryCode: "PE",
    value: "JUN",
  },
  {
    label: "San Martín",
    countryCode: "PE",
    value: "SAM",
  },
  {
    label: "Lima",
    countryCode: "PE",
    value: "LIM",
  },
  {
    label: "Tacna",
    countryCode: "PE",
    value: "TAC",
  },
  {
    label: "Piura",
    countryCode: "PE",
    value: "PIU",
  },
  {
    label: "Moquegua",
    countryCode: "PE",
    value: "MOQ",
  },
  {
    label: "Apurímac",
    countryCode: "PE",
    value: "APU",
  },
  {
    label: "Ica",
    countryCode: "PE",
    value: "ICA",
  },
  {
    label: "Callao",
    countryCode: "PE",
    value: "CAL",
  },
  {
    label: "Lambayeque",
    countryCode: "PE",
    value: "LAM",
  },
  {
    label: "Redonda",
    countryCode: "AG",
    value: "11",
  },
  {
    label: "Saint Peter Parish",
    countryCode: "AG",
    value: "07",
  },
  {
    label: "Saint Paul Parish",
    countryCode: "AG",
    value: "06",
  },
  {
    label: "Saint John Parish",
    countryCode: "AG",
    value: "04",
  },
  {
    label: "Saint Mary Parish",
    countryCode: "AG",
    value: "05",
  },
  {
    label: "Barbuda",
    countryCode: "AG",
    value: "10",
  },
  {
    label: "Saint George Parish",
    countryCode: "AG",
    value: "03",
  },
  {
    label: "Saint Philip Parish",
    countryCode: "AG",
    value: "08",
  },
  {
    label: "South Bačka District",
    countryCode: "RS",
    value: "06",
  },
  {
    label: "Pirot District",
    countryCode: "RS",
    value: "22",
  },
  {
    label: "South Banat District",
    countryCode: "RS",
    value: "04",
  },
  {
    label: "North Bačka District",
    countryCode: "RS",
    value: "01",
  },
  {
    label: "Jablanica District",
    countryCode: "RS",
    value: "23",
  },
  {
    label: "Central Banat District",
    countryCode: "RS",
    value: "02",
  },
  {
    label: "Bor District",
    countryCode: "RS",
    value: "14",
  },
  {
    label: "Toplica District",
    countryCode: "RS",
    value: "21",
  },
  {
    label: "Mačva District",
    countryCode: "RS",
    value: "08",
  },
  {
    label: "Rasina District",
    countryCode: "RS",
    value: "19",
  },
  {
    label: "Pčinja District",
    countryCode: "RS",
    value: "24",
  },
  {
    label: "Nišava District",
    countryCode: "RS",
    value: "20",
  },
  {
    label: "Prizren District",
    countryCode: "XK",
    value: "XPR",
  },
  {
    label: "Kolubara District",
    countryCode: "RS",
    value: "09",
  },
  {
    label: "Raška District",
    countryCode: "RS",
    value: "18",
  },
  {
    label: "West Bačka District",
    countryCode: "RS",
    value: "05",
  },
  {
    label: "Moravica District",
    countryCode: "RS",
    value: "17",
  },
  {
    label: "Belgrade",
    countryCode: "RS",
    value: "00",
  },
  {
    label: "Zlatibor District",
    countryCode: "RS",
    value: "16",
  },
  {
    label: "Zaječar District",
    countryCode: "RS",
    value: "15",
  },
  {
    label: "Braničevo District",
    countryCode: "RS",
    value: "11",
  },
  {
    label: "Vojvodina",
    countryCode: "RS",
    value: "VO",
  },
  {
    label: "Šumadija District",
    countryCode: "RS",
    value: "12",
  },
  {
    label: "North Banat District",
    countryCode: "RS",
    value: "03",
  },
  {
    label: "Pomoravlje District",
    countryCode: "RS",
    value: "13",
  },
  {
    label: "Peć District",
    countryCode: "XK",
    value: "XPE",
  },
  {
    label: "Srem District",
    countryCode: "RS",
    value: "07",
  },
  {
    label: "Podunavlje District",
    countryCode: "RS",
    value: "10",
  },
  {
    label: "Westmoreland Parish",
    countryCode: "JM",
    value: "10",
  },
  {
    label: "Saint Elizabeth Parish",
    countryCode: "JM",
    value: "11",
  },
  {
    label: "Saint Ann Parish",
    countryCode: "JM",
    value: "06",
  },
  {
    label: "Saint James Parish",
    countryCode: "JM",
    value: "08",
  },
  {
    label: "Saint Catherine Parish",
    countryCode: "JM",
    value: "14",
  },
  {
    label: "Saint Mary Parish",
    countryCode: "JM",
    value: "05",
  },
  {
    label: "Kingston Parish",
    countryCode: "JM",
    value: "01",
  },
  {
    label: "Hanover Parish",
    countryCode: "JM",
    value: "09",
  },
  {
    label: "Saint Thomas Parish",
    countryCode: "JM",
    value: "03",
  },
  {
    label: "Saint Andrew",
    countryCode: "JM",
    value: "02",
  },
  {
    label: "Portland Parish",
    countryCode: "JM",
    value: "04",
  },
  {
    label: "Clarendon Parish",
    countryCode: "JM",
    value: "13",
  },
  {
    label: "Manchester Parish",
    countryCode: "JM",
    value: "12",
  },
  {
    label: "Trelawny Parish",
    countryCode: "JM",
    value: "07",
  },
  {
    label: "Dennery Quarter",
    countryCode: "LC",
    value: "05",
  },
  {
    label: "Anse la Raye Quarter",
    countryCode: "LC",
    value: "01",
  },
  {
    label: "Castries Quarter",
    countryCode: "LC",
    value: "02",
  },
  {
    label: "Laborie Quarter",
    countryCode: "LC",
    value: "07",
  },
  {
    label: "Choiseul Quarter",
    countryCode: "LC",
    value: "03",
  },
  {
    label: "Canaries",
    countryCode: "LC",
    value: "12",
  },
  {
    label: "Micoud Quarter",
    countryCode: "LC",
    value: "08",
  },
  {
    label: "Vieux Fort Quarter",
    countryCode: "LC",
    value: "11",
  },
  {
    label: "Soufrière Quarter",
    countryCode: "LC",
    value: "10",
  },
  {
    label: "Praslin Quarter",
    countryCode: "LC",
    value: "09",
  },
  {
    label: "Gros Islet Quarter",
    countryCode: "LC",
    value: "06",
  },
  {
    label: "Dauphin Quarter",
    countryCode: "LC",
    value: "04",
  },
  {
    label: "Hưng Yên",
    countryCode: "VN",
    value: "66",
  },
  {
    label: "Đồng Tháp",
    countryCode: "VN",
    value: "45",
  },
  {
    label: "Bà Rịa-Vũng Tàu",
    countryCode: "VN",
    value: "43",
  },
  {
    label: "Thanh Hóa",
    countryCode: "VN",
    value: "21",
  },
  {
    label: "Kon Tum",
    countryCode: "VN",
    value: "28",
  },
  {
    label: "Điện Biên",
    countryCode: "VN",
    value: "71",
  },
  {
    label: "Vĩnh Phúc",
    countryCode: "VN",
    value: "70",
  },
  {
    label: "Thái Bình",
    countryCode: "VN",
    value: "20",
  },
  {
    label: "Quảng Nam",
    countryCode: "VN",
    value: "27",
  },
  {
    label: "Hậu Giang",
    countryCode: "VN",
    value: "73",
  },
  {
    label: "Cà Mau",
    countryCode: "VN",
    value: "59",
  },
  {
    label: "Hà Giang",
    countryCode: "VN",
    value: "03",
  },
  {
    label: "Nghệ An",
    countryCode: "VN",
    value: "22",
  },
  {
    label: "Tiền Giang",
    countryCode: "VN",
    value: "46",
  },
  {
    label: "Cao Bằng",
    countryCode: "VN",
    value: "04",
  },
  {
    label: "Hải Phòng",
    countryCode: "VN",
    value: "HP",
  },
  {
    label: "Yên Bái",
    countryCode: "VN",
    value: "06",
  },
  {
    label: "Bình Dương",
    countryCode: "VN",
    value: "57",
  },
  {
    label: "Ninh Bình",
    countryCode: "VN",
    value: "18",
  },
  {
    label: "Bình Thuận",
    countryCode: "VN",
    value: "40",
  },
  {
    label: "Ninh Thuận",
    countryCode: "VN",
    value: "36",
  },
  {
    label: "Nam Định",
    countryCode: "VN",
    value: "67",
  },
  {
    label: "Vĩnh Long",
    countryCode: "VN",
    value: "49",
  },
  {
    label: "Bắc Ninh",
    countryCode: "VN",
    value: "56",
  },
  {
    label: "Lạng Sơn",
    countryCode: "VN",
    value: "09",
  },
  {
    label: "Khánh Hòa",
    countryCode: "VN",
    value: "34",
  },
  {
    label: "An Giang",
    countryCode: "VN",
    value: "44",
  },
  {
    label: "Tuyên Quang",
    countryCode: "VN",
    value: "07",
  },
  {
    label: "Bến Tre",
    countryCode: "VN",
    value: "50",
  },
  {
    label: "Bình Phước",
    countryCode: "VN",
    value: "58",
  },
  {
    label: "Thừa Thiên-Huế",
    countryCode: "VN",
    value: "26",
  },
  {
    label: "Hòa Bình",
    countryCode: "VN",
    value: "14",
  },
  {
    label: "Kiên Giang",
    countryCode: "VN",
    value: "47",
  },
  {
    label: "Phú Thọ",
    countryCode: "VN",
    value: "68",
  },
  {
    label: "Hà Nam",
    countryCode: "VN",
    value: "63",
  },
  {
    label: "Quảng Trị",
    countryCode: "VN",
    value: "25",
  },
  {
    label: "Bạc Liêu",
    countryCode: "VN",
    value: "55",
  },
  {
    label: "Trà Vinh",
    countryCode: "VN",
    value: "51",
  },
  {
    label: "Đà Nẵng",
    countryCode: "VN",
    value: "DN",
  },
  {
    label: "Thái Nguyên",
    countryCode: "VN",
    value: "69",
  },
  {
    label: "Long An",
    countryCode: "VN",
    value: "41",
  },
  {
    label: "Quảng Bình",
    countryCode: "VN",
    value: "24",
  },
  {
    label: "Hà Nội",
    countryCode: "VN",
    value: "HN",
  },
  {
    label: "Hồ Chí Minh",
    countryCode: "VN",
    value: "SG",
  },
  {
    label: "Sơn La",
    countryCode: "VN",
    value: "05",
  },
  {
    label: "Gia Lai",
    countryCode: "VN",
    value: "30",
  },
  {
    label: "Quảng Ninh",
    countryCode: "VN",
    value: "13",
  },
  {
    label: "Bắc Giang",
    countryCode: "VN",
    value: "54",
  },
  {
    label: "Hà Tĩnh",
    countryCode: "VN",
    value: "23",
  },
  {
    label: "Lào Cai",
    countryCode: "VN",
    value: "02",
  },
  {
    label: "Lâm Đồng",
    countryCode: "VN",
    value: "35",
  },
  {
    label: "Sóc Trăng",
    countryCode: "VN",
    value: "52",
  },
  {
    label: "Đồng Nai",
    countryCode: "VN",
    value: "39",
  },
  {
    label: "Bắc Kạn",
    countryCode: "VN",
    value: "53",
  },
  {
    label: "Đắk Nông",
    countryCode: "VN",
    value: "72",
  },
  {
    label: "Phú Yên",
    countryCode: "VN",
    value: "32",
  },
  {
    label: "Lai Châu",
    countryCode: "VN",
    value: "01",
  },
  {
    label: "Tây Ninh",
    countryCode: "VN",
    value: "37",
  },
  {
    label: "Hải Dương",
    countryCode: "VN",
    value: "61",
  },
  {
    label: "Quảng Ngãi",
    countryCode: "VN",
    value: "29",
  },
  {
    label: "Đắk Lắk",
    countryCode: "VN",
    value: "33",
  },
  {
    label: "Bình Định",
    countryCode: "VN",
    value: "31",
  },
  {
    label: "Saint Peter Basseterre Parish",
    countryCode: "KN",
    value: "11",
  },
  {
    label: "Nevis",
    countryCode: "KN",
    value: "N",
  },
  {
    label: "Christ Church Nichola Town Parish",
    countryCode: "KN",
    value: "01",
  },
  {
    label: "Saint Paul Capisterre Parish",
    countryCode: "KN",
    value: "09",
  },
  {
    label: "Saint James Windward Parish",
    countryCode: "KN",
    value: "05",
  },
  {
    label: "Saint Anne Sandy Point Parish",
    countryCode: "KN",
    value: "02",
  },
  {
    label: "Saint George Gingerland Parish",
    countryCode: "KN",
    value: "04",
  },
  {
    label: "Saint Paul Charlestown Parish",
    countryCode: "KN",
    value: "10",
  },
  {
    label: "Saint Thomas Lowland Parish",
    countryCode: "KN",
    value: "12",
  },
  {
    label: "Saint John Figtree Parish",
    countryCode: "KN",
    value: "07",
  },
  {
    label: "Saint Kitts",
    countryCode: "KN",
    value: "K",
  },
  {
    label: "Saint Thomas Middle Island Parish",
    countryCode: "KN",
    value: "13",
  },
  {
    label: "Trinity Palmetto Point Parish",
    countryCode: "KN",
    value: "15",
  },
  {
    label: "Saint Mary Cayon Parish",
    countryCode: "KN",
    value: "08",
  },
  {
    label: "Saint John Capisterre Parish",
    countryCode: "KN",
    value: "06",
  },
  {
    label: "Daegu",
    countryCode: "KR",
    value: "27",
  },
  {
    label: "Gyeonggi Province",
    countryCode: "KR",
    value: "41",
  },
  {
    label: "Incheon",
    countryCode: "KR",
    value: "28",
  },
  {
    label: "Seoul",
    countryCode: "KR",
    value: "11",
  },
  {
    label: "Daejeon",
    countryCode: "KR",
    value: "30",
  },
  {
    label: "North Jeolla Province",
    countryCode: "KR",
    value: "45",
  },
  {
    label: "Ulsan",
    countryCode: "KR",
    value: "31",
  },
  {
    label: "Jeju",
    countryCode: "KR",
    value: "49",
  },
  {
    label: "North Chungcheong Province",
    countryCode: "KR",
    value: "43",
  },
  {
    label: "North Gyeongsang Province",
    countryCode: "KR",
    value: "47",
  },
  {
    label: "South Jeolla Province",
    countryCode: "KR",
    value: "46",
  },
  {
    label: "South Gyeongsang Province",
    countryCode: "KR",
    value: "48",
  },
  {
    label: "Gwangju",
    countryCode: "KR",
    value: "29",
  },
  {
    label: "South Chungcheong Province",
    countryCode: "KR",
    value: "44",
  },
  {
    label: "Busan",
    countryCode: "KR",
    value: "26",
  },
  {
    label: "Sejong City",
    countryCode: "KR",
    value: "50",
  },
  {
    label: "Gangwon Province",
    countryCode: "KR",
    value: "42",
  },
  {
    label: "Saint Patrick Parish",
    countryCode: "GD",
    value: "06",
  },
  {
    label: "Saint George Parish",
    countryCode: "GD",
    value: "03",
  },
  {
    label: "Saint Andrew Parish",
    countryCode: "GD",
    value: "01",
  },
  {
    label: "Saint Mark Parish",
    countryCode: "GD",
    value: "05",
  },
  {
    label: "Carriacou and Petite Martinique",
    countryCode: "GD",
    value: "10",
  },
  {
    label: "Saint John Parish",
    countryCode: "GD",
    value: "04",
  },
  {
    label: "Saint David Parish",
    countryCode: "GD",
    value: "02",
  },
  {
    label: "Ghazni",
    countryCode: "AF",
    value: "GHA",
  },
  {
    label: "Badghis",
    countryCode: "AF",
    value: "BDG",
  },
  {
    label: "Bamyan",
    countryCode: "AF",
    value: "BAM",
  },
  {
    label: "Helmand",
    countryCode: "AF",
    value: "HEL",
  },
  {
    label: "Zabul",
    countryCode: "AF",
    value: "ZAB",
  },
  {
    label: "Baghlan",
    countryCode: "AF",
    value: "BGL",
  },
  {
    label: "Kunar",
    countryCode: "AF",
    value: "KNR",
  },
  {
    label: "Paktika",
    countryCode: "AF",
    value: "PKA",
  },
  {
    label: "Khost",
    countryCode: "AF",
    value: "KHO",
  },
  {
    label: "Kapisa",
    countryCode: "AF",
    value: "KAP",
  },
  {
    label: "Nuristan",
    countryCode: "AF",
    value: "NUR",
  },
  {
    label: "Panjshir",
    countryCode: "AF",
    value: "PAN",
  },
  {
    label: "Nangarhar",
    countryCode: "AF",
    value: "NAN",
  },
  {
    label: "Samangan",
    countryCode: "AF",
    value: "SAM",
  },
  {
    label: "Balkh",
    countryCode: "AF",
    value: "BAL",
  },
  {
    label: "Sar-e Pol",
    countryCode: "AF",
    value: "SAR",
  },
  {
    label: "Jowzjan",
    countryCode: "AF",
    value: "JOW",
  },
  {
    label: "Herat",
    countryCode: "AF",
    value: "HER",
  },
  {
    label: "Ghōr",
    countryCode: "AF",
    value: "GHO",
  },
  {
    label: "Faryab",
    countryCode: "AF",
    value: "FYB",
  },
  {
    label: "Kandahar",
    countryCode: "AF",
    value: "KAN",
  },
  {
    label: "Laghman",
    countryCode: "AF",
    value: "LAG",
  },
  {
    label: "Daykundi",
    countryCode: "AF",
    value: "DAY",
  },
  {
    label: "Takhar",
    countryCode: "AF",
    value: "TAK",
  },
  {
    label: "Paktia",
    countryCode: "AF",
    value: "PIA",
  },
  {
    label: "Parwan",
    countryCode: "AF",
    value: "PAR",
  },
  {
    label: "Nimruz",
    countryCode: "AF",
    value: "NIM",
  },
  {
    label: "Logar",
    countryCode: "AF",
    value: "LOG",
  },
  {
    label: "Urozgan",
    countryCode: "AF",
    value: "URU",
  },
  {
    label: "Farah",
    countryCode: "AF",
    value: "FRA",
  },
  {
    label: "Kunduz Province",
    countryCode: "AF",
    value: "KDZ",
  },
  {
    label: "Badakhshan",
    countryCode: "AF",
    value: "BDS",
  },
  {
    label: "Kabul",
    countryCode: "AF",
    value: "KAB",
  },
  {
    label: "Victoria",
    countryCode: "AU",
    value: "VIC",
  },
  {
    label: "South Australia",
    countryCode: "AU",
    value: "SA",
  },
  {
    label: "Queensland",
    countryCode: "AU",
    value: "QLD",
  },
  {
    label: "Western Australia",
    countryCode: "AU",
    value: "WA",
  },
  {
    label: "Australian Capital Territory",
    countryCode: "AU",
    value: "ACT",
  },
  {
    label: "Tasmania",
    countryCode: "AU",
    value: "TAS",
  },
  {
    label: "New South Wales",
    countryCode: "AU",
    value: "NSW",
  },
  {
    label: "Northern Territory",
    countryCode: "AU",
    value: "NT",
  },
  {
    label: "Vavaʻu",
    countryCode: "TO",
    value: "05",
  },
  {
    label: "Tongatapu",
    countryCode: "TO",
    value: "04",
  },
  {
    label: "Haʻapai",
    countryCode: "TO",
    value: "02",
  },
  {
    label: "Niuas",
    countryCode: "TO",
    value: "03",
  },
  {
    label: "ʻEua",
    countryCode: "TO",
    value: "01",
  },
  {
    label: "Markazi Province",
    countryCode: "IR",
    value: "00",
  },
  {
    label: "Khuzestan Province",
    countryCode: "IR",
    value: "06",
  },
  {
    label: "Ilam Province",
    countryCode: "IR",
    value: "16",
  },
  {
    label: "Kermanshah Province",
    countryCode: "IR",
    value: "05",
  },
  {
    label: "Gilan Province",
    countryCode: "IR",
    value: "01",
  },
  {
    label: "Chaharmahal and Bakhtiari Province",
    countryCode: "IR",
    value: "14",
  },
  {
    label: "Qom Province",
    countryCode: "IR",
    value: "25",
  },
  {
    label: "Isfahan Province",
    countryCode: "IR",
    value: "10",
  },
  {
    label: "West Azarbaijan Province",
    countryCode: "IR",
    value: "04",
  },
  {
    label: "Zanjan Province",
    countryCode: "IR",
    value: "19",
  },
  {
    label: "Kohgiluyeh and Boyer-Ahmad Province",
    countryCode: "IR",
    value: "17",
  },
  {
    label: "Razavi Khorasan Province",
    countryCode: "IR",
    value: "09",
  },
  {
    label: "Lorestan Province",
    countryCode: "IR",
    value: "15",
  },
  {
    label: "Alborz Province",
    countryCode: "IR",
    value: "30",
  },
  {
    label: "South Khorasan Province",
    countryCode: "IR",
    value: "29",
  },
  {
    label: "Sistan and Baluchestan",
    countryCode: "IR",
    value: "11",
  },
  {
    label: "Bushehr Province",
    countryCode: "IR",
    value: "18",
  },
  {
    label: "Golestan Province",
    countryCode: "IR",
    value: "27",
  },
  {
    label: "Ardabil Province",
    countryCode: "IR",
    value: "24",
  },
  {
    label: "Kurdistan Province",
    countryCode: "IR",
    value: "12",
  },
  {
    label: "Yazd Province",
    countryCode: "IR",
    value: "21",
  },
  {
    label: "Hormozgan Province",
    countryCode: "IR",
    value: "22",
  },
  {
    label: "Mazandaran Province",
    countryCode: "IR",
    value: "02",
  },
  {
    label: "Fars Province",
    countryCode: "IR",
    value: "07",
  },
  {
    label: "Semnan Province",
    countryCode: "IR",
    value: "20",
  },
  {
    label: "Qazvin Province",
    countryCode: "IR",
    value: "26",
  },
  {
    label: "North Khorasan Province",
    countryCode: "IR",
    value: "28",
  },
  {
    label: "Kerman Province",
    countryCode: "IR",
    value: "08",
  },
  {
    label: "East Azerbaijan Province",
    countryCode: "IR",
    value: "03",
  },
  {
    label: "Tehran Province",
    countryCode: "IR",
    value: "23",
  },
  {
    label: "Niutao Island Council",
    countryCode: "TV",
    value: "NIT",
  },
  {
    label: "Nanumanga",
    countryCode: "TV",
    value: "NMG",
  },
  {
    label: "Nui",
    countryCode: "TV",
    value: "NUI",
  },
  {
    label: "Nanumea",
    countryCode: "TV",
    value: "NMA",
  },
  {
    label: "Vaitupu",
    countryCode: "TV",
    value: "VAI",
  },
  {
    label: "Funafuti",
    countryCode: "TV",
    value: "FUN",
  },
  {
    label: "Nukufetau",
    countryCode: "TV",
    value: "NKF",
  },
  {
    label: "Nukulaelae",
    countryCode: "TV",
    value: "NKL",
  },
  {
    label: "Dhi Qar Governorate",
    countryCode: "IQ",
    value: "DQ",
  },
  {
    label: "Babylon Governorate",
    countryCode: "IQ",
    value: "BB",
  },
  {
    label: "Al-Qādisiyyah Governorate",
    countryCode: "IQ",
    value: "QA",
  },
  {
    label: "Karbala Governorate",
    countryCode: "IQ",
    value: "KA",
  },
  {
    label: "Al Muthanna Governorate",
    countryCode: "IQ",
    value: "MU",
  },
  {
    label: "Baghdad Governorate",
    countryCode: "IQ",
    value: "BG",
  },
  {
    label: "Basra Governorate",
    countryCode: "IQ",
    value: "BA",
  },
  {
    label: "Saladin Governorate",
    countryCode: "IQ",
    value: "SD",
  },
  {
    label: "Najaf Governorate",
    countryCode: "IQ",
    value: "NA",
  },
  {
    label: "Nineveh Governorate",
    countryCode: "IQ",
    value: "NI",
  },
  {
    label: "Al Anbar Governorate",
    countryCode: "IQ",
    value: "AN",
  },
  {
    label: "Diyala Governorate",
    countryCode: "IQ",
    value: "DI",
  },
  {
    label: "Maysan Governorate",
    countryCode: "IQ",
    value: "MA",
  },
  {
    label: "Dohuk Governorate",
    countryCode: "IQ",
    value: "DA",
  },
  {
    label: "Erbil Governorate",
    countryCode: "IQ",
    value: "AR",
  },
  {
    label: "Sulaymaniyah Governorate",
    countryCode: "IQ",
    value: "SU",
  },
  {
    label: "Wasit Governorate",
    countryCode: "IQ",
    value: "WA",
  },
  {
    label: "Kirkuk Governorate",
    countryCode: "IQ",
    value: "KI",
  },
  {
    label: "Svay Rieng Province",
    countryCode: "KH",
    value: "20",
  },
  {
    label: "Preah Vihear Province",
    countryCode: "KH",
    value: "13",
  },
  {
    label: "Prey Veng Province",
    countryCode: "KH",
    value: "14",
  },
  {
    label: "Takéo Province",
    countryCode: "KH",
    value: "21",
  },
  {
    label: "Battambang Province",
    countryCode: "KH",
    value: "2",
  },
  {
    label: "Pursat Province",
    countryCode: "KH",
    value: "15",
  },
  {
    label: "Kep Province",
    countryCode: "KH",
    value: "23",
  },
  {
    label: "Kampong Chhnang Province",
    countryCode: "KH",
    value: "4",
  },
  {
    label: "Pailin Province",
    countryCode: "KH",
    value: "24",
  },
  {
    label: "Kampot Province",
    countryCode: "KH",
    value: "7",
  },
  {
    label: "Koh Kong Province",
    countryCode: "KH",
    value: "9",
  },
  {
    label: "Kandal Province",
    countryCode: "KH",
    value: "8",
  },
  {
    label: "Banteay Meanchey Province",
    countryCode: "KH",
    value: "1",
  },
  {
    label: "Mondulkiri Province",
    countryCode: "KH",
    value: "11",
  },
  {
    label: "Kratié Province",
    countryCode: "KH",
    value: "10",
  },
  {
    label: "Oddar Meanchey Province",
    countryCode: "KH",
    value: "22",
  },
  {
    label: "Kampong Speu Province",
    countryCode: "KH",
    value: "5",
  },
  {
    label: "Sihanoukville Province",
    countryCode: "KH",
    value: "18",
  },
  {
    label: "Ratanakiri Province",
    countryCode: "KH",
    value: "16",
  },
  {
    label: "Kampong Cham Province",
    countryCode: "KH",
    value: "3",
  },
  {
    label: "Siem Reap Province",
    countryCode: "KH",
    value: "17",
  },
  {
    label: "Stung Treng Province",
    countryCode: "KH",
    value: "19",
  },
  {
    label: "Phnom Penh",
    countryCode: "KH",
    value: "12",
  },
  {
    label: "North Hamgyong Province",
    countryCode: "KP",
    value: "09",
  },
  {
    label: "Ryanggang Province",
    countryCode: "KP",
    value: "10",
  },
  {
    label: "South Pyongan Province",
    countryCode: "KP",
    value: "02",
  },
  {
    label: "Chagang Province",
    countryCode: "KP",
    value: "04",
  },
  {
    label: "Kangwon Province",
    countryCode: "KP",
    value: "07",
  },
  {
    label: "South Hamgyong Province",
    countryCode: "KP",
    value: "08",
  },
  {
    label: "Rason",
    countryCode: "KP",
    value: "13",
  },
  {
    label: "North Pyongan Province",
    countryCode: "KP",
    value: "03",
  },
  {
    label: "South Hwanghae Province",
    countryCode: "KP",
    value: "05",
  },
  {
    label: "North Hwanghae Province",
    countryCode: "KP",
    value: "06",
  },
  {
    label: "Pyongyang",
    countryCode: "KP",
    value: "01",
  },
  {
    label: "Meghalaya",
    countryCode: "IN",
    value: "ML",
  },
  {
    label: "Haryana",
    countryCode: "IN",
    value: "HR",
  },
  {
    label: "Maharashtra",
    countryCode: "IN",
    value: "MH",
  },
  {
    label: "Goa",
    countryCode: "IN",
    value: "GA",
  },
  {
    label: "Manipur",
    countryCode: "IN",
    value: "MN",
  },
  {
    label: "Puducherry",
    countryCode: "IN",
    value: "PY",
  },
  {
    label: "Telangana",
    countryCode: "IN",
    value: "TG",
  },
  {
    label: "Odisha",
    countryCode: "IN",
    value: "OR",
  },
  {
    label: "Rajasthan",
    countryCode: "IN",
    value: "RJ",
  },
  {
    label: "Punjab",
    countryCode: "IN",
    value: "PB",
  },
  {
    label: "Uttarakhand",
    countryCode: "IN",
    value: "UT",
  },
  {
    label: "Andhra Pradesh",
    countryCode: "IN",
    value: "AP",
  },
  {
    label: "Nagaland",
    countryCode: "IN",
    value: "NL",
  },
  {
    label: "Lakshadweep",
    countryCode: "IN",
    value: "LD",
  },
  {
    label: "Himachal Pradesh",
    countryCode: "IN",
    value: "HP",
  },
  {
    label: "Delhi",
    countryCode: "IN",
    value: "DL",
  },
  {
    label: "Uttar Pradesh",
    countryCode: "IN",
    value: "UP",
  },
  {
    label: "Andaman and Nicobar Islands",
    countryCode: "IN",
    value: "AN",
  },
  {
    label: "Arunachal Pradesh",
    countryCode: "IN",
    value: "AR",
  },
  {
    label: "Jharkhand",
    countryCode: "IN",
    value: "JH",
  },
  {
    label: "Karnataka",
    countryCode: "IN",
    value: "KA",
  },
  {
    label: "Assam",
    countryCode: "IN",
    value: "AS",
  },
  {
    label: "Kerala",
    countryCode: "IN",
    value: "KL",
  },
  {
    label: "Jammu and Kashmir",
    countryCode: "IN",
    value: "JK",
  },
  {
    label: "Gujarat",
    countryCode: "IN",
    value: "GJ",
  },
  {
    label: "Chandigarh",
    countryCode: "IN",
    value: "CH",
  },
  {
    label: "Dadra and Nagar Haveli and Daman and Diu",
    countryCode: "IN",
    value: "DH",
  },
  {
    label: "Sikkim",
    countryCode: "IN",
    value: "SK",
  },
  {
    label: "Tamil Nadu",
    countryCode: "IN",
    value: "TN",
  },
  {
    label: "Mizoram",
    countryCode: "IN",
    value: "MZ",
  },
  {
    label: "Bihar",
    countryCode: "IN",
    value: "BR",
  },
  {
    label: "Tripura",
    countryCode: "IN",
    value: "TR",
  },
  {
    label: "Madhya Pradesh",
    countryCode: "IN",
    value: "MP",
  },
  {
    label: "Chhattisgarh",
    countryCode: "IN",
    value: "CT",
  },
  {
    label: "Choluteca Department",
    countryCode: "HN",
    value: "CH",
  },
  {
    label: "Comayagua Department",
    countryCode: "HN",
    value: "CM",
  },
  {
    label: "El Paraíso Department",
    countryCode: "HN",
    value: "EP",
  },
  {
    label: "Intibucá Department",
    countryCode: "HN",
    value: "IN",
  },
  {
    label: "Bay Islands Department",
    countryCode: "HN",
    value: "IB",
  },
  {
    label: "Cortés Department",
    countryCode: "HN",
    value: "CR",
  },
  {
    label: "Atlántida Department",
    countryCode: "HN",
    value: "AT",
  },
  {
    label: "Gracias a Dios Department",
    countryCode: "HN",
    value: "GD",
  },
  {
    label: "Copán Department",
    countryCode: "HN",
    value: "CP",
  },
  {
    label: "Olancho Department",
    countryCode: "HN",
    value: "OL",
  },
  {
    label: "Colón Department",
    countryCode: "HN",
    value: "CL",
  },
  {
    label: "Francisco Morazán Department",
    countryCode: "HN",
    value: "FM",
  },
  {
    label: "Santa Bárbara Department",
    countryCode: "HN",
    value: "SB",
  },
  {
    label: "Lempira Department",
    countryCode: "HN",
    value: "LE",
  },
  {
    label: "Valle Department",
    countryCode: "HN",
    value: "VA",
  },
  {
    label: "Ocotepeque Department",
    countryCode: "HN",
    value: "OC",
  },
  {
    label: "Yoro Department",
    countryCode: "HN",
    value: "YO",
  },
  {
    label: "La Paz Department",
    countryCode: "HN",
    value: "LP",
  },
  {
    label: "Northland Region",
    countryCode: "NZ",
    value: "NTL",
  },
  {
    label: "Manawatu-Wanganui Region",
    countryCode: "NZ",
    value: "MWT",
  },
  {
    label: "Waikato Region",
    countryCode: "NZ",
    value: "WKO",
  },
  {
    label: "Otago Region",
    countryCode: "NZ",
    value: "OTA",
  },
  {
    label: "Marlborough Region",
    countryCode: "NZ",
    value: "MBH",
  },
  {
    label: "West Coast Region",
    countryCode: "NZ",
    value: "WTC",
  },
  {
    label: "Wellington Region",
    countryCode: "NZ",
    value: "WGN",
  },
  {
    label: "Canterbury Region",
    countryCode: "NZ",
    value: "CAN",
  },
  {
    label: "Chatham Islands",
    countryCode: "NZ",
    value: "CIT",
  },
  {
    label: "Gisborne District",
    countryCode: "NZ",
    value: "GIS",
  },
  {
    label: "Taranaki Region",
    countryCode: "NZ",
    value: "TKI",
  },
  {
    label: "Nelson Region",
    countryCode: "NZ",
    value: "NSN",
  },
  {
    label: "Southland Region",
    countryCode: "NZ",
    value: "STL",
  },
  {
    label: "Auckland Region",
    countryCode: "NZ",
    value: "AUK",
  },
  {
    label: "Tasman District",
    countryCode: "NZ",
    value: "TAS",
  },
  {
    label: "Bay of Plenty Region",
    countryCode: "NZ",
    value: "BOP",
  },
  {
    label: "Hawke's Bay Region",
    countryCode: "NZ",
    value: "HKB",
  },
  {
    label: "Saint John Parish",
    countryCode: "DM",
    value: "05",
  },
  {
    label: "Saint Mark Parish",
    countryCode: "DM",
    value: "08",
  },
  {
    label: "Saint David Parish",
    countryCode: "DM",
    value: "03",
  },
  {
    label: "Saint George Parish",
    countryCode: "DM",
    value: "04",
  },
  {
    label: "Saint Patrick Parish",
    countryCode: "DM",
    value: "09",
  },
  {
    label: "Saint Peter Parish",
    countryCode: "DM",
    value: "11",
  },
  {
    label: "Saint Andrew Parish",
    countryCode: "DM",
    value: "02",
  },
  {
    label: "Saint Luke Parish",
    countryCode: "DM",
    value: "07",
  },
  {
    label: "Saint Paul Parish",
    countryCode: "DM",
    value: "10",
  },
  {
    label: "Saint Joseph Parish",
    countryCode: "DM",
    value: "06",
  },
  {
    label: "El Seibo Province",
    countryCode: "DO",
    value: "08",
  },
  {
    label: "La Romana Province",
    countryCode: "DO",
    value: "12",
  },
  {
    label: "Sánchez Ramírez Province",
    countryCode: "DO",
    value: "24",
  },
  {
    label: "Hermanas Mirabal Province",
    countryCode: "DO",
    value: "19",
  },
  {
    label: "Barahona Province",
    countryCode: "DO",
    value: "04",
  },
  {
    label: "San Cristóbal Province",
    countryCode: "DO",
    value: "21",
  },
  {
    label: "Puerto Plata Province",
    countryCode: "DO",
    value: "18",
  },
  {
    label: "Santo Domingo Province",
    countryCode: "DO",
    value: "32",
  },
  {
    label: "María Trinidad Sánchez Province",
    countryCode: "DO",
    value: "14",
  },
  {
    label: "Distrito Nacional",
    countryCode: "DO",
    value: "01",
  },
  {
    label: "Peravia Province",
    countryCode: "DO",
    value: "17",
  },
  {
    label: "Independencia",
    countryCode: "DO",
    value: "10",
  },
  {
    label: "San Juan Province",
    countryCode: "DO",
    value: "22",
  },
  {
    label: "Monseñor Nouel Province",
    countryCode: "DO",
    value: "28",
  },
  {
    label: "Santiago Rodríguez Province",
    countryCode: "DO",
    value: "26",
  },
  {
    label: "Pedernales Province",
    countryCode: "DO",
    value: "16",
  },
  {
    label: "Espaillat Province",
    countryCode: "DO",
    value: "09",
  },
  {
    label: "Samaná Province",
    countryCode: "DO",
    value: "20",
  },
  {
    label: "Valverde Province",
    countryCode: "DO",
    value: "27",
  },
  {
    label: "Baoruco Province",
    countryCode: "DO",
    value: "03",
  },
  {
    label: "Hato Mayor Province",
    countryCode: "DO",
    value: "30",
  },
  {
    label: "Dajabón Province",
    countryCode: "DO",
    value: "05",
  },
  {
    label: "Santiago Province",
    countryCode: "DO",
    value: "25",
  },
  {
    label: "La Altagracia Province",
    countryCode: "DO",
    value: "11",
  },
  {
    label: "San Pedro de Macorís",
    countryCode: "DO",
    value: "23",
  },
  {
    label: "Monte Plata Province",
    countryCode: "DO",
    value: "29",
  },
  {
    label: "San José de Ocoa Province",
    countryCode: "DO",
    value: "31",
  },
  {
    label: "Duarte Province",
    countryCode: "DO",
    value: "06",
  },
  {
    label: "Azua Province",
    countryCode: "DO",
    value: "02",
  },
  {
    label: "Monte Cristi Province",
    countryCode: "DO",
    value: "15",
  },
  {
    label: "La Vega Province",
    countryCode: "DO",
    value: "13",
  },
  {
    label: "Nord",
    countryCode: "HT",
    value: "ND",
  },
  {
    label: "Nippes",
    countryCode: "HT",
    value: "NI",
  },
  {
    label: "Grand'Anse",
    countryCode: "HT",
    value: "GA",
  },
  {
    label: "Ouest",
    countryCode: "HT",
    value: "OU",
  },
  {
    label: "Nord-Est",
    countryCode: "HT",
    value: "NE",
  },
  {
    label: "Sud",
    countryCode: "HT",
    value: "SD",
  },
  {
    label: "Artibonite",
    countryCode: "HT",
    value: "AR",
  },
  {
    label: "Sud-Est",
    countryCode: "HT",
    value: "SE",
  },
  {
    label: "Centre",
    countryCode: "HT",
    value: "CE",
  },
  {
    label: "Nord-Ouest",
    countryCode: "HT",
    value: "NO",
  },
  {
    label: "San Vicente Department",
    countryCode: "SV",
    value: "SV",
  },
  {
    label: "Santa Ana Department",
    countryCode: "SV",
    value: "SA",
  },
  {
    label: "Usulután Department",
    countryCode: "SV",
    value: "US",
  },
  {
    label: "Morazán Department",
    countryCode: "SV",
    value: "MO",
  },
  {
    label: "Chalatenango Department",
    countryCode: "SV",
    value: "CH",
  },
  {
    label: "Cabañas Department",
    countryCode: "SV",
    value: "CA",
  },
  {
    label: "San Salvador Department",
    countryCode: "SV",
    value: "SS",
  },
  {
    label: "La Libertad Department",
    countryCode: "SV",
    value: "LI",
  },
  {
    label: "San Miguel Department",
    countryCode: "SV",
    value: "SM",
  },
  {
    label: "La Paz Department",
    countryCode: "SV",
    value: "PA",
  },
  {
    label: "Cuscatlán Department",
    countryCode: "SV",
    value: "CU",
  },
  {
    label: "La Unión Department",
    countryCode: "SV",
    value: "UN",
  },
  {
    label: "Ahuachapán Department",
    countryCode: "SV",
    value: "AH",
  },
  {
    label: "Sonsonate Department",
    countryCode: "SV",
    value: "SO",
  },
  {
    label: "Braslovče Municipality",
    countryCode: "SI",
    value: "151",
  },
  {
    label: "Lenart Municipality",
    countryCode: "SI",
    value: "058",
  },
  {
    label: "Oplotnica",
    countryCode: "SI",
    value: "171",
  },
  {
    label: "Velike Lašče Municipality",
    countryCode: "SI",
    value: "134",
  },
  {
    label: "Hajdina Municipality",
    countryCode: "SI",
    value: "159",
  },
  {
    label: "Podčetrtek Municipality",
    countryCode: "SI",
    value: "092",
  },
  {
    label: "Cankova Municipality",
    countryCode: "SI",
    value: "152",
  },
  {
    label: "Vitanje Municipality",
    countryCode: "SI",
    value: "137",
  },
  {
    label: "Sežana Municipality",
    countryCode: "SI",
    value: "111",
  },
  {
    label: "Kidričevo Municipality",
    countryCode: "SI",
    value: "045",
  },
  {
    label: "Črenšovci Municipality",
    countryCode: "SI",
    value: "015",
  },
  {
    label: "Idrija Municipality",
    countryCode: "SI",
    value: "036",
  },
  {
    label: "Trnovska Vas Municipality",
    countryCode: "SI",
    value: "185",
  },
  {
    label: "Vodice Municipality",
    countryCode: "SI",
    value: "138",
  },
  {
    label: "Ravne na Koroškem Municipality",
    countryCode: "SI",
    value: "103",
  },
  {
    label: "Lovrenc na Pohorju Municipality",
    countryCode: "SI",
    value: "167",
  },
  {
    label: "Majšperk Municipality",
    countryCode: "SI",
    value: "069",
  },
  {
    label: "Loški Potok Municipality",
    countryCode: "SI",
    value: "066",
  },
  {
    label: "Domžale Municipality",
    countryCode: "SI",
    value: "023",
  },
  {
    label: "Rečica ob Savinji Municipality",
    countryCode: "SI",
    value: "209",
  },
  {
    label: "Podlehnik Municipality",
    countryCode: "SI",
    value: "172",
  },
  {
    label: "Cerknica Municipality",
    countryCode: "SI",
    value: "013",
  },
  {
    label: "Vransko Municipality",
    countryCode: "SI",
    value: "189",
  },
  {
    label: "Sveta Ana Municipality",
    countryCode: "SI",
    value: "181",
  },
  {
    label: "Brezovica Municipality",
    countryCode: "SI",
    value: "008",
  },
  {
    label: "Benedikt Municipality",
    countryCode: "SI",
    value: "148",
  },
  {
    label: "Divača Municipality",
    countryCode: "SI",
    value: "019",
  },
  {
    label: "Moravče Municipality",
    countryCode: "SI",
    value: "077",
  },
  {
    label: "Slovenj Gradec City Municipality",
    countryCode: "SI",
    value: "112",
  },
  {
    label: "Škocjan Municipality",
    countryCode: "SI",
    value: "121",
  },
  {
    label: "Šentjur Municipality",
    countryCode: "SI",
    value: "120",
  },
  {
    label: "Pesnica Municipality",
    countryCode: "SI",
    value: "089",
  },
  {
    label: "Dol pri Ljubljani Municipality",
    countryCode: "SI",
    value: "022",
  },
  {
    label: "Loška Dolina Municipality",
    countryCode: "SI",
    value: "065",
  },
  {
    label: "Hoče–Slivnica Municipality",
    countryCode: "SI",
    value: "160",
  },
  {
    label: "Cerkvenjak Municipality",
    countryCode: "SI",
    value: "153",
  },
  {
    label: "Naklo Municipality",
    countryCode: "SI",
    value: "082",
  },
  {
    label: "Cerkno Municipality",
    countryCode: "SI",
    value: "014",
  },
  {
    label: "Bistrica ob Sotli Municipality",
    countryCode: "SI",
    value: "149",
  },
  {
    label: "Kamnik Municipality",
    countryCode: "SI",
    value: "043",
  },
  {
    label: "Bovec Municipality",
    countryCode: "SI",
    value: "006",
  },
  {
    label: "Zavrč Municipality",
    countryCode: "SI",
    value: "143",
  },
  {
    label: "Ajdovščina Municipality",
    countryCode: "SI",
    value: "001",
  },
  {
    label: "Pivka Municipality",
    countryCode: "SI",
    value: "091",
  },
  {
    label: "Štore Municipality",
    countryCode: "SI",
    value: "127",
  },
  {
    label: "Kozje Municipality",
    countryCode: "SI",
    value: "051",
  },
  {
    label: "Municipality of Škofljica",
    countryCode: "SI",
    value: "123",
  },
  {
    label: "Prebold Municipality",
    countryCode: "SI",
    value: "174",
  },
  {
    label: "Dobrovnik Municipality",
    countryCode: "SI",
    value: "156",
  },
  {
    label: "Mozirje Municipality",
    countryCode: "SI",
    value: "079",
  },
  {
    label: "City Municipality of Celje",
    countryCode: "SI",
    value: "011",
  },
  {
    label: "Žiri Municipality",
    countryCode: "SI",
    value: "147",
  },
  {
    label: "Horjul Municipality",
    countryCode: "SI",
    value: "162",
  },
  {
    label: "Tabor Municipality",
    countryCode: "SI",
    value: "184",
  },
  {
    label: "Radeče Municipality",
    countryCode: "SI",
    value: "099",
  },
  {
    label: "Vipava Municipality",
    countryCode: "SI",
    value: "136",
  },
  {
    label: "Kungota",
    countryCode: "SI",
    value: "055",
  },
  {
    label: "Slovenske Konjice Municipality",
    countryCode: "SI",
    value: "114",
  },
  {
    label: "Osilnica Municipality",
    countryCode: "SI",
    value: "088",
  },
  {
    label: "Borovnica Municipality",
    countryCode: "SI",
    value: "005",
  },
  {
    label: "Piran Municipality",
    countryCode: "SI",
    value: "090",
  },
  {
    label: "Bled Municipality",
    countryCode: "SI",
    value: "003",
  },
  {
    label: "Jezersko Municipality",
    countryCode: "SI",
    value: "163",
  },
  {
    label: "Rače–Fram Municipality",
    countryCode: "SI",
    value: "098",
  },
  {
    label: "Nova Gorica City Municipality",
    countryCode: "SI",
    value: "084",
  },
  {
    label: "Razkrižje Municipality",
    countryCode: "SI",
    value: "176",
  },
  {
    label: "Ribnica na Pohorju Municipality",
    countryCode: "SI",
    value: "177",
  },
  {
    label: "Muta Municipality",
    countryCode: "SI",
    value: "081",
  },
  {
    label: "Rogatec Municipality",
    countryCode: "SI",
    value: "107",
  },
  {
    label: "Gorišnica Municipality",
    countryCode: "SI",
    value: "028",
  },
  {
    label: "Kuzma Municipality",
    countryCode: "SI",
    value: "056",
  },
  {
    label: "Mislinja Municipality",
    countryCode: "SI",
    value: "076",
  },
  {
    label: "Duplek Municipality",
    countryCode: "SI",
    value: "026",
  },
  {
    label: "Trebnje Municipality",
    countryCode: "SI",
    value: "130",
  },
  {
    label: "Brežice Municipality",
    countryCode: "SI",
    value: "009",
  },
  {
    label: "Dobrepolje Municipality",
    countryCode: "SI",
    value: "020",
  },
  {
    label: "Grad Municipality",
    countryCode: "SI",
    value: "158",
  },
  {
    label: "Moravske Toplice Municipality",
    countryCode: "SI",
    value: "078",
  },
  {
    label: "Luče Municipality",
    countryCode: "SI",
    value: "067",
  },
  {
    label: "Miren–Kostanjevica Municipality",
    countryCode: "SI",
    value: "075",
  },
  {
    label: "Ormož Municipality",
    countryCode: "SI",
    value: "087",
  },
  {
    label: "Šalovci Municipality",
    countryCode: "SI",
    value: "033",
  },
  {
    label: "Miklavž na Dravskem Polju Municipality",
    countryCode: "SI",
    value: "169",
  },
  {
    label: "Makole Municipality",
    countryCode: "SI",
    value: "198",
  },
  {
    label: "Lendava Municipality",
    countryCode: "SI",
    value: "059",
  },
  {
    label: "Vuzenica Municipality",
    countryCode: "SI",
    value: "141",
  },
  {
    label: "Kanal ob Soči Municipality",
    countryCode: "SI",
    value: "044",
  },
  {
    label: "Ptuj City Municipality",
    countryCode: "SI",
    value: "096",
  },
  {
    label: "Sveti Andraž v Slovenskih Goricah Municipality",
    countryCode: "SI",
    value: "182",
  },
  {
    label: "Selnica ob Dravi Municipality",
    countryCode: "SI",
    value: "178",
  },
  {
    label: "Radovljica Municipality",
    countryCode: "SI",
    value: "102",
  },
  {
    label: "Črna na Koroškem Municipality",
    countryCode: "SI",
    value: "016",
  },
  {
    label: "Rogaška Slatina Municipality",
    countryCode: "SI",
    value: "106",
  },
  {
    label: "Podvelka Municipality",
    countryCode: "SI",
    value: "093",
  },
  {
    label: "Ribnica Municipality",
    countryCode: "SI",
    value: "104",
  },
  {
    label: "City Municipality of Novo Mesto",
    countryCode: "SI",
    value: "085",
  },
  {
    label: "Mirna Peč Municipality",
    countryCode: "SI",
    value: "170",
  },
  {
    label: "Križevci Municipality",
    countryCode: "SI",
    value: "166",
  },
  {
    label: "Poljčane Municipality",
    countryCode: "SI",
    value: "200",
  },
  {
    label: "Brda Municipality",
    countryCode: "SI",
    value: "007",
  },
  {
    label: "Šentjernej Municipality",
    countryCode: "SI",
    value: "119",
  },
  {
    label: "Maribor City Municipality",
    countryCode: "SI",
    value: "070",
  },
  {
    label: "Kobarid Municipality",
    countryCode: "SI",
    value: "046",
  },
  {
    label: "Markovci Municipality",
    countryCode: "SI",
    value: "168",
  },
  {
    label: "Vojnik Municipality",
    countryCode: "SI",
    value: "139",
  },
  {
    label: "Trbovlje Municipality",
    countryCode: "SI",
    value: "129",
  },
  {
    label: "Tolmin Municipality",
    countryCode: "SI",
    value: "128",
  },
  {
    label: "Šoštanj Municipality",
    countryCode: "SI",
    value: "126",
  },
  {
    label: "Žetale Municipality",
    countryCode: "SI",
    value: "191",
  },
  {
    label: "Tržič Municipality",
    countryCode: "SI",
    value: "131",
  },
  {
    label: "Turnišče Municipality",
    countryCode: "SI",
    value: "132",
  },
  {
    label: "Dobrna Municipality",
    countryCode: "SI",
    value: "155",
  },
  {
    label: "Renče–Vogrsko Municipality",
    countryCode: "SI",
    value: "201",
  },
  {
    label: "Kostanjevica na Krki Municipality",
    countryCode: "SI",
    value: "197",
  },
  {
    label: "Sveti Jurij ob Ščavnici Municipality",
    countryCode: "SI",
    value: "116",
  },
  {
    label: "Železniki Municipality",
    countryCode: "SI",
    value: "146",
  },
  {
    label: "Veržej Municipality",
    countryCode: "SI",
    value: "188",
  },
  {
    label: "Žalec Municipality",
    countryCode: "SI",
    value: "190",
  },
  {
    label: "Starše Municipality",
    countryCode: "SI",
    value: "115",
  },
  {
    label: "Sveta Trojica v Slovenskih Goricah Municipality",
    countryCode: "SI",
    value: "204",
  },
  {
    label: "Solčava Municipality",
    countryCode: "SI",
    value: "180",
  },
  {
    label: "Vrhnika Municipality",
    countryCode: "SI",
    value: "140",
  },
  {
    label: "Središče ob Dravi",
    countryCode: "SI",
    value: "202",
  },
  {
    label: "Rogašovci Municipality",
    countryCode: "SI",
    value: "105",
  },
  {
    label: "Mežica Municipality",
    countryCode: "SI",
    value: "074",
  },
  {
    label: "Juršinci Municipality",
    countryCode: "SI",
    value: "042",
  },
  {
    label: "Velika Polana Municipality",
    countryCode: "SI",
    value: "187",
  },
  {
    label: "Sevnica Municipality",
    countryCode: "SI",
    value: "110",
  },
  {
    label: "Zagorje ob Savi Municipality",
    countryCode: "SI",
    value: "142",
  },
  {
    label: "Ljubljana City Municipality",
    countryCode: "SI",
    value: "061",
  },
  {
    label: "Gornji Petrovci Municipality",
    countryCode: "SI",
    value: "031",
  },
  {
    label: "Polzela Municipality",
    countryCode: "SI",
    value: "173",
  },
  {
    label: "Sveti Tomaž Municipality",
    countryCode: "SI",
    value: "205",
  },
  {
    label: "Prevalje Municipality",
    countryCode: "SI",
    value: "175",
  },
  {
    label: "Radlje ob Dravi Municipality",
    countryCode: "SI",
    value: "101",
  },
  {
    label: "Žirovnica Municipality",
    countryCode: "SI",
    value: "192",
  },
  {
    label: "Sodražica Municipality",
    countryCode: "SI",
    value: "179",
  },
  {
    label: "Bloke Municipality",
    countryCode: "SI",
    value: "150",
  },
  {
    label: "Šmartno pri Litiji Municipality",
    countryCode: "SI",
    value: "194",
  },
  {
    label: "Ruše Municipality",
    countryCode: "SI",
    value: "108",
  },
  {
    label: "Dolenjske Toplice Municipality",
    countryCode: "SI",
    value: "157",
  },
  {
    label: "Bohinj Municipality",
    countryCode: "SI",
    value: "004",
  },
  {
    label: "Komenda Municipality",
    countryCode: "SI",
    value: "164",
  },
  {
    label: "Gorje Municipality",
    countryCode: "SI",
    value: "207",
  },
  {
    label: "Šmarje pri Jelšah Municipality",
    countryCode: "SI",
    value: "124",
  },
  {
    label: "Ig Municipality",
    countryCode: "SI",
    value: "037",
  },
  {
    label: "Kranj City Municipality",
    countryCode: "SI",
    value: "052",
  },
  {
    label: "Puconci Municipality",
    countryCode: "SI",
    value: "097",
  },
  {
    label: "Šmarješke Toplice Municipality",
    countryCode: "SI",
    value: "206",
  },
  {
    label: "Dornava Municipality",
    countryCode: "SI",
    value: "024",
  },
  {
    label: "Črnomelj Municipality",
    countryCode: "SI",
    value: "017",
  },
  {
    label: "Radenci Municipality",
    countryCode: "SI",
    value: "100",
  },
  {
    label: "Gorenja Vas–Poljane Municipality",
    countryCode: "SI",
    value: "027",
  },
  {
    label: "Ljubno Municipality",
    countryCode: "SI",
    value: "062",
  },
  {
    label: "Dobje Municipality",
    countryCode: "SI",
    value: "154",
  },
  {
    label: "Šmartno ob Paki Municipality",
    countryCode: "SI",
    value: "125",
  },
  {
    label: "Mokronog–Trebelno Municipality",
    countryCode: "SI",
    value: "199",
  },
  {
    label: "Mirna Municipality",
    countryCode: "SI",
    value: "212",
  },
  {
    label: "Šenčur Municipality",
    countryCode: "SI",
    value: "117",
  },
  {
    label: "Videm Municipality",
    countryCode: "SI",
    value: "135",
  },
  {
    label: "Beltinci Municipality",
    countryCode: "SI",
    value: "002",
  },
  {
    label: "Lukovica Municipality",
    countryCode: "SI",
    value: "068",
  },
  {
    label: "Preddvor Municipality",
    countryCode: "SI",
    value: "095",
  },
  {
    label: "Destrnik Municipality",
    countryCode: "SI",
    value: "018",
  },
  {
    label: "Ivančna Gorica Municipality",
    countryCode: "SI",
    value: "039",
  },
  {
    label: "Log–Dragomer Municipality",
    countryCode: "SI",
    value: "208",
  },
  {
    label: "Žužemberk Municipality",
    countryCode: "SI",
    value: "193",
  },
  {
    label: "Dobrova–Polhov Gradec Municipality",
    countryCode: "SI",
    value: "021",
  },
  {
    label: "Municipality of Cirkulane",
    countryCode: "SI",
    value: "196",
  },
  {
    label: "Cerklje na Gorenjskem Municipality",
    countryCode: "SI",
    value: "012",
  },
  {
    label: "Šentrupert Municipality",
    countryCode: "SI",
    value: "211",
  },
  {
    label: "Tišina Municipality",
    countryCode: "SI",
    value: "010",
  },
  {
    label: "Murska Sobota City Municipality",
    countryCode: "SI",
    value: "080",
  },
  {
    label: "Municipality of Krško",
    countryCode: "SI",
    value: "054",
  },
  {
    label: "Komen Municipality",
    countryCode: "SI",
    value: "049",
  },
  {
    label: "Škofja Loka Municipality",
    countryCode: "SI",
    value: "122",
  },
  {
    label: "Šempeter–Vrtojba Municipality",
    countryCode: "SI",
    value: "183",
  },
  {
    label: "Municipality of Apače",
    countryCode: "SI",
    value: "195",
  },
  {
    label: "Koper City Municipality",
    countryCode: "SI",
    value: "050",
  },
  {
    label: "Odranci Municipality",
    countryCode: "SI",
    value: "086",
  },
  {
    label: "Hrpelje–Kozina Municipality",
    countryCode: "SI",
    value: "035",
  },
  {
    label: "Izola Municipality",
    countryCode: "SI",
    value: "040",
  },
  {
    label: "Metlika Municipality",
    countryCode: "SI",
    value: "073",
  },
  {
    label: "Šentilj Municipality",
    countryCode: "SI",
    value: "118",
  },
  {
    label: "Kobilje Municipality",
    countryCode: "SI",
    value: "047",
  },
  {
    label: "Ankaran Municipality",
    countryCode: "SI",
    value: "213",
  },
  {
    label: "Hodoš Municipality",
    countryCode: "SI",
    value: "161",
  },
  {
    label: "Sveti Jurij v Slovenskih Goricah Municipality",
    countryCode: "SI",
    value: "210",
  },
  {
    label: "Nazarje Municipality",
    countryCode: "SI",
    value: "083",
  },
  {
    label: "Postojna Municipality",
    countryCode: "SI",
    value: "094",
  },
  {
    label: "Kostel Municipality",
    countryCode: "SI",
    value: "165",
  },
  {
    label: "Slovenska Bistrica Municipality",
    countryCode: "SI",
    value: "113",
  },
  {
    label: "Straža Municipality",
    countryCode: "SI",
    value: "203",
  },
  {
    label: "Trzin Municipality",
    countryCode: "SI",
    value: "186",
  },
  {
    label: "Kočevje Municipality",
    countryCode: "SI",
    value: "048",
  },
  {
    label: "Grosuplje Municipality",
    countryCode: "SI",
    value: "032",
  },
  {
    label: "Jesenice Municipality",
    countryCode: "SI",
    value: "041",
  },
  {
    label: "Laško Municipality",
    countryCode: "SI",
    value: "057",
  },
  {
    label: "Gornji Grad Municipality",
    countryCode: "SI",
    value: "030",
  },
  {
    label: "Kranjska Gora Municipality",
    countryCode: "SI",
    value: "053",
  },
  {
    label: "Hrastnik Municipality",
    countryCode: "SI",
    value: "034",
  },
  {
    label: "Zreče Municipality",
    countryCode: "SI",
    value: "144",
  },
  {
    label: "Gornja Radgona Municipality",
    countryCode: "SI",
    value: "029",
  },
  {
    label: "Municipality of Ilirska Bistrica",
    countryCode: "SI",
    value: "038",
  },
  {
    label: "Dravograd Municipality",
    countryCode: "SI",
    value: "025",
  },
  {
    label: "Semič Municipality",
    countryCode: "SI",
    value: "109",
  },
  {
    label: "Litija Municipality",
    countryCode: "SI",
    value: "060",
  },
  {
    label: "Mengeš Municipality",
    countryCode: "SI",
    value: "072",
  },
  {
    label: "Medvode Municipality",
    countryCode: "SI",
    value: "071",
  },
  {
    label: "Logatec Municipality",
    countryCode: "SI",
    value: "064",
  },
  {
    label: "Ljutomer Municipality",
    countryCode: "SI",
    value: "063",
  },
  {
    label: "Banská Bystrica Region",
    countryCode: "SK",
    value: "BC",
  },
  {
    label: "Košice Region",
    countryCode: "SK",
    value: "KI",
  },
  {
    label: "Prešov Region",
    countryCode: "SK",
    value: "PV",
  },
  {
    label: "Trnava Region",
    countryCode: "SK",
    value: "TA",
  },
  {
    label: "Bratislava Region",
    countryCode: "SK",
    value: "BL",
  },
  {
    label: "Nitra Region",
    countryCode: "SK",
    value: "NI",
  },
  {
    label: "Trenčín Region",
    countryCode: "SK",
    value: "TC",
  },
  {
    label: "Žilina Region",
    countryCode: "SK",
    value: "ZI",
  },
  {
    label: "Cimișlia District",
    countryCode: "MD",
    value: "CM",
  },
  {
    label: "Orhei District",
    countryCode: "MD",
    value: "OR",
  },
  {
    label: "Bender Municipality",
    countryCode: "MD",
    value: "BD",
  },
  {
    label: "Nisporeni District",
    countryCode: "MD",
    value: "NI",
  },
  {
    label: "Sîngerei District",
    countryCode: "MD",
    value: "SI",
  },
  {
    label: "Căușeni District",
    countryCode: "MD",
    value: "CS",
  },
  {
    label: "Călărași District",
    countryCode: "MD",
    value: "CL",
  },
  {
    label: "Glodeni District",
    countryCode: "MD",
    value: "GL",
  },
  {
    label: "Anenii Noi District",
    countryCode: "MD",
    value: "AN",
  },
  {
    label: "Ialoveni District",
    countryCode: "MD",
    value: "IA",
  },
  {
    label: "Florești District",
    countryCode: "MD",
    value: "FL",
  },
  {
    label: "Telenești District",
    countryCode: "MD",
    value: "TE",
  },
  {
    label: "Taraclia District",
    countryCode: "MD",
    value: "TA",
  },
  {
    label: "Chișinău Municipality",
    countryCode: "MD",
    value: "CU",
  },
  {
    label: "Soroca District",
    countryCode: "MD",
    value: "SO",
  },
  {
    label: "Briceni District",
    countryCode: "MD",
    value: "BR",
  },
  {
    label: "Rîșcani District",
    countryCode: "MD",
    value: "RI",
  },
  {
    label: "Strășeni District",
    countryCode: "MD",
    value: "ST",
  },
  {
    label: "Ștefan Vodă District",
    countryCode: "MD",
    value: "SV",
  },
  {
    label: "Basarabeasca District",
    countryCode: "MD",
    value: "BS",
  },
  {
    label: "Cantemir District",
    countryCode: "MD",
    value: "CT",
  },
  {
    label: "Fălești District",
    countryCode: "MD",
    value: "FA",
  },
  {
    label: "Hîncești District",
    countryCode: "MD",
    value: "HI",
  },
  {
    label: "Dubăsari District",
    countryCode: "MD",
    value: "DU",
  },
  {
    label: "Dondușeni District",
    countryCode: "MD",
    value: "DO",
  },
  {
    label: "Gagauzia",
    countryCode: "MD",
    value: "GA",
  },
  {
    label: "Ungheni District",
    countryCode: "MD",
    value: "UN",
  },
  {
    label: "Edineț District",
    countryCode: "MD",
    value: "ED",
  },
  {
    label: "Șoldănești District",
    countryCode: "MD",
    value: "SD",
  },
  {
    label: "Ocnița District",
    countryCode: "MD",
    value: "OC",
  },
  {
    label: "Criuleni District",
    countryCode: "MD",
    value: "CR",
  },
  {
    label: "Cahul District",
    countryCode: "MD",
    value: "CA",
  },
  {
    label: "Drochia District",
    countryCode: "MD",
    value: "DR",
  },
  {
    label: "Bălți Municipality",
    countryCode: "MD",
    value: "BA",
  },
  {
    label: "Rezina District",
    countryCode: "MD",
    value: "RE",
  },
  {
    label: "Transnistria autonomous territorial unit",
    countryCode: "MD",
    value: "SN",
  },
  {
    label: "Salacgrīva Municipality",
    countryCode: "LV",
    value: "086",
  },
  {
    label: "Vecumnieki Municipality",
    countryCode: "LV",
    value: "105",
  },
  {
    label: "Naukšēni Municipality",
    countryCode: "LV",
    value: "064",
  },
  {
    label: "Ilūkste Municipality",
    countryCode: "LV",
    value: "036",
  },
  {
    label: "Gulbene Municipality",
    countryCode: "LV",
    value: "033",
  },
  {
    label: "Līvāni Municipality",
    countryCode: "LV",
    value: "056",
  },
  {
    label: "Salaspils Municipality",
    countryCode: "LV",
    value: "087",
  },
  {
    label: "Ventspils Municipality",
    countryCode: "LV",
    value: "106",
  },
  {
    label: "Rundāle Municipality",
    countryCode: "LV",
    value: "083",
  },
  {
    label: "Pļaviņas Municipality",
    countryCode: "LV",
    value: "072",
  },
  {
    label: "Vārkava Municipality",
    countryCode: "LV",
    value: "103",
  },
  {
    label: "Jaunpiebalga Municipality",
    countryCode: "LV",
    value: "039",
  },
  {
    label: "Sēja Municipality",
    countryCode: "LV",
    value: "090",
  },
  {
    label: "Tukums Municipality",
    countryCode: "LV",
    value: "099",
  },
  {
    label: "Cibla Municipality",
    countryCode: "LV",
    value: "023",
  },
  {
    label: "Burtnieki Municipality",
    countryCode: "LV",
    value: "019",
  },
  {
    label: "Ķegums Municipality",
    countryCode: "LV",
    value: "051",
  },
  {
    label: "Krustpils Municipality",
    countryCode: "LV",
    value: "049",
  },
  {
    label: "Cesvaine Municipality",
    countryCode: "LV",
    value: "021",
  },
  {
    label: "Skrīveri Municipality",
    countryCode: "LV",
    value: "092",
  },
  {
    label: "Ogre Municipality",
    countryCode: "LV",
    value: "067",
  },
  {
    label: "Olaine Municipality",
    countryCode: "LV",
    value: "068",
  },
  {
    label: "Limbaži Municipality",
    countryCode: "LV",
    value: "054",
  },
  {
    label: "Lubāna Municipality",
    countryCode: "LV",
    value: "057",
  },
  {
    label: "Kandava Municipality",
    countryCode: "LV",
    value: "043",
  },
  {
    label: "Ventspils",
    countryCode: "LV",
    value: "VEN",
  },
  {
    label: "Krimulda Municipality",
    countryCode: "LV",
    value: "048",
  },
  {
    label: "Rugāji Municipality",
    countryCode: "LV",
    value: "082",
  },
  {
    label: "Jelgava Municipality",
    countryCode: "LV",
    value: "041",
  },
  {
    label: "Valka Municipality",
    countryCode: "LV",
    value: "101",
  },
  {
    label: "Rūjiena Municipality",
    countryCode: "LV",
    value: "084",
  },
  {
    label: "Babīte Municipality",
    countryCode: "LV",
    value: "012",
  },
  {
    label: "Dundaga Municipality",
    countryCode: "LV",
    value: "027",
  },
  {
    label: "Priekule Municipality",
    countryCode: "LV",
    value: "074",
  },
  {
    label: "Zilupe Municipality",
    countryCode: "LV",
    value: "110",
  },
  {
    label: "Varakļāni Municipality",
    countryCode: "LV",
    value: "102",
  },
  {
    label: "Nereta Municipality",
    countryCode: "LV",
    value: "065",
  },
  {
    label: "Madona Municipality",
    countryCode: "LV",
    value: "059",
  },
  {
    label: "Sala Municipality",
    countryCode: "LV",
    value: "085",
  },
  {
    label: "Ķekava Municipality",
    countryCode: "LV",
    value: "052",
  },
  {
    label: "Nīca Municipality",
    countryCode: "LV",
    value: "066",
  },
  {
    label: "Dobele Municipality",
    countryCode: "LV",
    value: "026",
  },
  {
    label: "Jēkabpils Municipality",
    countryCode: "LV",
    value: "042",
  },
  {
    label: "Saldus Municipality",
    countryCode: "LV",
    value: "088",
  },
  {
    label: "Roja Municipality",
    countryCode: "LV",
    value: "079",
  },
  {
    label: "Iecava Municipality",
    countryCode: "LV",
    value: "034",
  },
  {
    label: "Ozolnieki Municipality",
    countryCode: "LV",
    value: "069",
  },
  {
    label: "Saulkrasti Municipality",
    countryCode: "LV",
    value: "089",
  },
  {
    label: "Ērgļi Municipality",
    countryCode: "LV",
    value: "030",
  },
  {
    label: "Aglona Municipality",
    countryCode: "LV",
    value: "001",
  },
  {
    label: "Jūrmala",
    countryCode: "LV",
    value: "JUR",
  },
  {
    label: "Skrunda Municipality",
    countryCode: "LV",
    value: "093",
  },
  {
    label: "Engure Municipality",
    countryCode: "LV",
    value: "029",
  },
  {
    label: "Inčukalns Municipality",
    countryCode: "LV",
    value: "037",
  },
  {
    label: "Mārupe Municipality",
    countryCode: "LV",
    value: "062",
  },
  {
    label: "Mērsrags Municipality",
    countryCode: "LV",
    value: "063",
  },
  {
    label: "Koknese Municipality",
    countryCode: "LV",
    value: "046",
  },
  {
    label: "Kārsava Municipality",
    countryCode: "LV",
    value: "044",
  },
  {
    label: "Carnikava Municipality",
    countryCode: "LV",
    value: "020",
  },
  {
    label: "Rēzekne Municipality",
    countryCode: "LV",
    value: "077",
  },
  {
    label: "Viesīte Municipality",
    countryCode: "LV",
    value: "107",
  },
  {
    label: "Ape Municipality",
    countryCode: "LV",
    value: "009",
  },
  {
    label: "Durbe Municipality",
    countryCode: "LV",
    value: "028",
  },
  {
    label: "Talsi Municipality",
    countryCode: "LV",
    value: "097",
  },
  {
    label: "Liepāja",
    countryCode: "LV",
    value: "LPX",
  },
  {
    label: "Mālpils Municipality",
    countryCode: "LV",
    value: "061",
  },
  {
    label: "Smiltene Municipality",
    countryCode: "LV",
    value: "094",
  },
  {
    label: "Daugavpils",
    countryCode: "LV",
    value: "DGV",
  },
  {
    label: "Jēkabpils",
    countryCode: "LV",
    value: "JKB",
  },
  {
    label: "Bauska Municipality",
    countryCode: "LV",
    value: "016",
  },
  {
    label: "Vecpiebalga Municipality",
    countryCode: "LV",
    value: "104",
  },
  {
    label: "Pāvilosta Municipality",
    countryCode: "LV",
    value: "071",
  },
  {
    label: "Brocēni Municipality",
    countryCode: "LV",
    value: "018",
  },
  {
    label: "Cēsis Municipality",
    countryCode: "LV",
    value: "022",
  },
  {
    label: "Grobiņa Municipality",
    countryCode: "LV",
    value: "032",
  },
  {
    label: "Beverīna Municipality",
    countryCode: "LV",
    value: "017",
  },
  {
    label: "Aizkraukle Municipality",
    countryCode: "LV",
    value: "002",
  },
  {
    label: "Valmiera",
    countryCode: "LV",
    value: "VMR",
  },
  {
    label: "Krāslava Municipality",
    countryCode: "LV",
    value: "047",
  },
  {
    label: "Jaunjelgava Municipality",
    countryCode: "LV",
    value: "038",
  },
  {
    label: "Sigulda Municipality",
    countryCode: "LV",
    value: "091",
  },
  {
    label: "Viļaka Municipality",
    countryCode: "LV",
    value: "108",
  },
  {
    label: "Stopiņi Municipality",
    countryCode: "LV",
    value: "095",
  },
  {
    label: "Rauna Municipality",
    countryCode: "LV",
    value: "076",
  },
  {
    label: "Tērvete Municipality",
    countryCode: "LV",
    value: "098",
  },
  {
    label: "Auce Municipality",
    countryCode: "LV",
    value: "010",
  },
  {
    label: "Baldone Municipality",
    countryCode: "LV",
    value: "013",
  },
  {
    label: "Preiļi Municipality",
    countryCode: "LV",
    value: "073",
  },
  {
    label: "Aloja Municipality",
    countryCode: "LV",
    value: "005",
  },
  {
    label: "Alsunga Municipality",
    countryCode: "LV",
    value: "006",
  },
  {
    label: "Viļāni Municipality",
    countryCode: "LV",
    value: "109",
  },
  {
    label: "Alūksne Municipality",
    countryCode: "LV",
    value: "007",
  },
  {
    label: "Līgatne Municipality",
    countryCode: "LV",
    value: "055",
  },
  {
    label: "Jaunpils Municipality",
    countryCode: "LV",
    value: "040",
  },
  {
    label: "Kuldīga Municipality",
    countryCode: "LV",
    value: "050",
  },
  {
    label: "Riga",
    countryCode: "LV",
    value: "RIX",
  },
  {
    label: "Daugavpils Municipality",
    countryCode: "LV",
    value: "025",
  },
  {
    label: "Ropaži Municipality",
    countryCode: "LV",
    value: "080",
  },
  {
    label: "Strenči Municipality",
    countryCode: "LV",
    value: "096",
  },
  {
    label: "Kocēni Municipality",
    countryCode: "LV",
    value: "045",
  },
  {
    label: "Aizpute Municipality",
    countryCode: "LV",
    value: "003",
  },
  {
    label: "Amata Municipality",
    countryCode: "LV",
    value: "008",
  },
  {
    label: "Baltinava Municipality",
    countryCode: "LV",
    value: "014",
  },
  {
    label: "Aknīste Municipality",
    countryCode: "LV",
    value: "004",
  },
  {
    label: "Jelgava",
    countryCode: "LV",
    value: "JEL",
  },
  {
    label: "Ludza Municipality",
    countryCode: "LV",
    value: "058",
  },
  {
    label: "Riebiņi Municipality",
    countryCode: "LV",
    value: "078",
  },
  {
    label: "Rucava Municipality",
    countryCode: "LV",
    value: "081",
  },
  {
    label: "Dagda Municipality",
    countryCode: "LV",
    value: "024",
  },
  {
    label: "Balvi Municipality",
    countryCode: "LV",
    value: "015",
  },
  {
    label: "Priekuļi Municipality",
    countryCode: "LV",
    value: "075",
  },
  {
    label: "Pārgauja Municipality",
    countryCode: "LV",
    value: "070",
  },
  {
    label: "Vaiņode Municipality",
    countryCode: "LV",
    value: "100",
  },
  {
    label: "Rēzekne",
    countryCode: "LV",
    value: "REZ",
  },
  {
    label: "Garkalne Municipality",
    countryCode: "LV",
    value: "031",
  },
  {
    label: "Ikšķile Municipality",
    countryCode: "LV",
    value: "035",
  },
  {
    label: "Lielvārde Municipality",
    countryCode: "LV",
    value: "053",
  },
  {
    label: "Mazsalaca Municipality",
    countryCode: "LV",
    value: "060",
  },
  {
    label: "Viqueque Municipality",
    countryCode: "TL",
    value: "VI",
  },
  {
    label: "Liquiçá Municipality",
    countryCode: "TL",
    value: "LI",
  },
  {
    label: "Ermera District",
    countryCode: "TL",
    value: "ER",
  },
  {
    label: "Manatuto District",
    countryCode: "TL",
    value: "MT",
  },
  {
    label: "Ainaro Municipality",
    countryCode: "TL",
    value: "AN",
  },
  {
    label: "Manufahi Municipality",
    countryCode: "TL",
    value: "MF",
  },
  {
    label: "Aileu municipality",
    countryCode: "TL",
    value: "AL",
  },
  {
    label: "Baucau Municipality",
    countryCode: "TL",
    value: "BA",
  },
  {
    label: "Cova Lima Municipality",
    countryCode: "TL",
    value: "CO",
  },
  {
    label: "Lautém Municipality",
    countryCode: "TL",
    value: "LA",
  },
  {
    label: "Dili municipality",
    countryCode: "TL",
    value: "DI",
  },
  {
    label: "Bobonaro Municipality",
    countryCode: "TL",
    value: "BO",
  },
  {
    label: "Peleliu",
    countryCode: "PW",
    value: "350",
  },
  {
    label: "Ngardmau",
    countryCode: "PW",
    value: "222",
  },
  {
    label: "Airai",
    countryCode: "PW",
    value: "004",
  },
  {
    label: "Hatohobei",
    countryCode: "PW",
    value: "050",
  },
  {
    label: "Melekeok",
    countryCode: "PW",
    value: "212",
  },
  {
    label: "Ngatpang",
    countryCode: "PW",
    value: "224",
  },
  {
    label: "Koror",
    countryCode: "PW",
    value: "150",
  },
  {
    label: "Ngarchelong",
    countryCode: "PW",
    value: "218",
  },
  {
    label: "Ngiwal",
    countryCode: "PW",
    value: "228",
  },
  {
    label: "Sonsorol",
    countryCode: "PW",
    value: "370",
  },
  {
    label: "Ngchesar",
    countryCode: "PW",
    value: "226",
  },
  {
    label: "Ngaraard",
    countryCode: "PW",
    value: "214",
  },
  {
    label: "Angaur",
    countryCode: "PW",
    value: "010",
  },
  {
    label: "Kayangel",
    countryCode: "PW",
    value: "100",
  },
  {
    label: "Aimeliik",
    countryCode: "PW",
    value: "002",
  },
  {
    label: "Ngeremlengui",
    countryCode: "PW",
    value: "227",
  },
  {
    label: "Břeclav",
    countryCode: "CZ",
    value: "644",
  },
  {
    label: "Český Krumlov",
    countryCode: "CZ",
    value: "312",
  },
  {
    label: "Plzeň-město",
    countryCode: "CZ",
    value: "323",
  },
  {
    label: "Brno-venkov",
    countryCode: "CZ",
    value: "643",
  },
  {
    label: "Příbram",
    countryCode: "CZ",
    value: "20B",
  },
  {
    label: "Pardubice",
    countryCode: "CZ",
    value: "532",
  },
  {
    label: "Nový Jičín",
    countryCode: "CZ",
    value: "804",
  },
  {
    label: "Náchod",
    countryCode: "CZ",
    value: "523",
  },
  {
    label: "Prostějov",
    countryCode: "CZ",
    value: "713",
  },
  {
    label: "Zlínský kraj",
    countryCode: "CZ",
    value: "72",
  },
  {
    label: "Chomutov",
    countryCode: "CZ",
    value: "422",
  },
  {
    label: "Středočeský kraj",
    countryCode: "CZ",
    value: "20",
  },
  {
    label: "České Budějovice",
    countryCode: "CZ",
    value: "311",
  },
  {
    label: "Rakovník",
    countryCode: "CZ",
    value: "20C",
  },
  {
    label: "Frýdek-Místek",
    countryCode: "CZ",
    value: "802",
  },
  {
    label: "Písek",
    countryCode: "CZ",
    value: "314",
  },
  {
    label: "Hodonín",
    countryCode: "CZ",
    value: "645",
  },
  {
    label: "Zlín",
    countryCode: "CZ",
    value: "724",
  },
  {
    label: "Plzeň-sever",
    countryCode: "CZ",
    value: "325",
  },
  {
    label: "Tábor",
    countryCode: "CZ",
    value: "317",
  },
  {
    label: "Brno-město",
    countryCode: "CZ",
    value: "642",
  },
  {
    label: "Svitavy",
    countryCode: "CZ",
    value: "533",
  },
  {
    label: "Vsetín",
    countryCode: "CZ",
    value: "723",
  },
  {
    label: "Cheb",
    countryCode: "CZ",
    value: "411",
  },
  {
    label: "Olomouc",
    countryCode: "CZ",
    value: "712",
  },
  {
    label: "Kraj Vysočina",
    countryCode: "CZ",
    value: "63",
  },
  {
    label: "Ústecký kraj",
    countryCode: "CZ",
    value: "42",
  },
  {
    label: "Prachatice",
    countryCode: "CZ",
    value: "315",
  },
  {
    label: "Trutnov",
    countryCode: "CZ",
    value: "525",
  },
  {
    label: "Hradec Králové",
    countryCode: "CZ",
    value: "521",
  },
  {
    label: "Karlovarský kraj",
    countryCode: "CZ",
    value: "41",
  },
  {
    label: "Nymburk",
    countryCode: "CZ",
    value: "208",
  },
  {
    label: "Rokycany",
    countryCode: "CZ",
    value: "326",
  },
  {
    label: "Ostrava-město",
    countryCode: "CZ",
    value: "806",
  },
  {
    label: "Karviná",
    countryCode: "CZ",
    value: "803",
  },
  {
    label: "Pardubický kraj",
    countryCode: "CZ",
    value: "53",
  },
  {
    label: "Olomoucký kraj",
    countryCode: "CZ",
    value: "71",
  },
  {
    label: "Liberec",
    countryCode: "CZ",
    value: "513",
  },
  {
    label: "Klatovy",
    countryCode: "CZ",
    value: "322",
  },
  {
    label: "Uherské Hradiště",
    countryCode: "CZ",
    value: "722",
  },
  {
    label: "Kroměříž",
    countryCode: "CZ",
    value: "721",
  },
  {
    label: "Sokolov",
    countryCode: "CZ",
    value: "413",
  },
  {
    label: "Semily",
    countryCode: "CZ",
    value: "514",
  },
  {
    label: "Třebíč",
    countryCode: "CZ",
    value: "634",
  },
  {
    label: "Praha, Hlavní město",
    countryCode: "CZ",
    value: "10",
  },
  {
    label: "Ústí nad Labem",
    countryCode: "CZ",
    value: "427",
  },
  {
    label: "Moravskoslezský kraj",
    countryCode: "CZ",
    value: "80",
  },
  {
    label: "Liberecký kraj",
    countryCode: "CZ",
    value: "51",
  },
  {
    label: "Jihomoravský kraj",
    countryCode: "CZ",
    value: "64",
  },
  {
    label: "Karlovy Vary",
    countryCode: "CZ",
    value: "412",
  },
  {
    label: "Litoměřice",
    countryCode: "CZ",
    value: "423",
  },
  {
    label: "Praha-východ",
    countryCode: "CZ",
    value: "209",
  },
  {
    label: "Plzeňský kraj",
    countryCode: "CZ",
    value: "32",
  },
  {
    label: "Plzeň-jih",
    countryCode: "CZ",
    value: "324",
  },
  {
    label: "Děčín",
    countryCode: "CZ",
    value: "421",
  },
  {
    label: "Havlíčkův Brod",
    countryCode: "CZ",
    value: "631",
  },
  {
    label: "Jablonec nad Nisou",
    countryCode: "CZ",
    value: "512",
  },
  {
    label: "Jihlava",
    countryCode: "CZ",
    value: "632",
  },
  {
    label: "Královéhradecký kraj",
    countryCode: "CZ",
    value: "52",
  },
  {
    label: "Blansko",
    countryCode: "CZ",
    value: "641",
  },
  {
    label: "Louny",
    countryCode: "CZ",
    value: "424",
  },
  {
    label: "Kolín",
    countryCode: "CZ",
    value: "204",
  },
  {
    label: "Praha-západ",
    countryCode: "CZ",
    value: "20A",
  },
  {
    label: "Beroun",
    countryCode: "CZ",
    value: "202",
  },
  {
    label: "Teplice",
    countryCode: "CZ",
    value: "426",
  },
  {
    label: "Vyškov",
    countryCode: "CZ",
    value: "646",
  },
  {
    label: "Opava",
    countryCode: "CZ",
    value: "805",
  },
  {
    label: "Jindřichův Hradec",
    countryCode: "CZ",
    value: "313",
  },
  {
    label: "Jeseník",
    countryCode: "CZ",
    value: "711",
  },
  {
    label: "Přerov",
    countryCode: "CZ",
    value: "714",
  },
  {
    label: "Benešov",
    countryCode: "CZ",
    value: "201",
  },
  {
    label: "Strakonice",
    countryCode: "CZ",
    value: "316",
  },
  {
    label: "Most",
    countryCode: "CZ",
    value: "425",
  },
  {
    label: "Znojmo",
    countryCode: "CZ",
    value: "647",
  },
  {
    label: "Kladno",
    countryCode: "CZ",
    value: "203",
  },
  {
    label: "Česká Lípa",
    countryCode: "CZ",
    value: "511",
  },
  {
    label: "Chrudim",
    countryCode: "CZ",
    value: "531",
  },
  {
    label: "Rychnov nad Kněžnou",
    countryCode: "CZ",
    value: "524",
  },
  {
    label: "Mělník",
    countryCode: "CZ",
    value: "206",
  },
  {
    label: "Jihočeský kraj",
    countryCode: "CZ",
    value: "31",
  },
  {
    label: "Jičín",
    countryCode: "CZ",
    value: "522",
  },
  {
    label: "Domažlice",
    countryCode: "CZ",
    value: "321",
  },
  {
    label: "Šumperk",
    countryCode: "CZ",
    value: "715",
  },
  {
    label: "Mladá Boleslav",
    countryCode: "CZ",
    value: "207",
  },
  {
    label: "Bruntál",
    countryCode: "CZ",
    value: "801",
  },
  {
    label: "Pelhřimov",
    countryCode: "CZ",
    value: "633",
  },
  {
    label: "Tachov",
    countryCode: "CZ",
    value: "327",
  },
  {
    label: "Ústí nad Orlicí",
    countryCode: "CZ",
    value: "534",
  },
  {
    label: "Žďár nad Sázavou",
    countryCode: "CZ",
    value: "635",
  },
  {
    label: "North East Community Development Council",
    countryCode: "SG",
    value: "02",
  },
  {
    label: "South East Community Development Council",
    countryCode: "SG",
    value: "04",
  },
  {
    label: "Central Singapore Community Development Council",
    countryCode: "SG",
    value: "01",
  },
  {
    label: "South West Community Development Council",
    countryCode: "SG",
    value: "05",
  },
  {
    label: "North West Community Development Council",
    countryCode: "SG",
    value: "03",
  },
  {
    label: "Ewa District",
    countryCode: "NR",
    value: "09",
  },
  {
    label: "Uaboe District",
    countryCode: "NR",
    value: "13",
  },
  {
    label: "Aiwo District",
    countryCode: "NR",
    value: "01",
  },
  {
    label: "Meneng District",
    countryCode: "NR",
    value: "11",
  },
  {
    label: "Anabar District",
    countryCode: "NR",
    value: "02",
  },
  {
    label: "Nibok District",
    countryCode: "NR",
    value: "12",
  },
  {
    label: "Baiti District",
    countryCode: "NR",
    value: "05",
  },
  {
    label: "Ijuw District",
    countryCode: "NR",
    value: "10",
  },
  {
    label: "Buada District",
    countryCode: "NR",
    value: "07",
  },
  {
    label: "Anibare District",
    countryCode: "NR",
    value: "04",
  },
  {
    label: "Yaren District",
    countryCode: "NR",
    value: "14",
  },
  {
    label: "Boe District",
    countryCode: "NR",
    value: "06",
  },
  {
    label: "Denigomodu District",
    countryCode: "NR",
    value: "08",
  },
  {
    label: "Anetan District",
    countryCode: "NR",
    value: "03",
  },
  {
    label: "Zhytomyr Oblast",
    countryCode: "UA",
    value: "18",
  },
  {
    label: "Vinnytsia Oblast",
    countryCode: "UA",
    value: "05",
  },
  {
    label: "Zakarpattia Oblast",
    countryCode: "UA",
    value: "21",
  },
  {
    label: "Kyiv Oblast",
    countryCode: "UA",
    value: "32",
  },
  {
    label: "Lviv Oblast",
    countryCode: "UA",
    value: "46",
  },
  {
    label: "Luhansk Oblast",
    countryCode: "UA",
    value: "09",
  },
  {
    label: "Ternopil Oblast",
    countryCode: "UA",
    value: "61",
  },
  {
    label: "Dnipropetrovsk Oblast",
    countryCode: "UA",
    value: "12",
  },
  {
    label: "Kiev",
    countryCode: "UA",
    value: "30",
  },
  {
    label: "Kirovohrad Oblast",
    countryCode: "UA",
    value: "35",
  },
  {
    label: "Chernivtsi Oblast",
    countryCode: "UA",
    value: "77",
  },
  {
    label: "Mykolaiv Oblast",
    countryCode: "UA",
    value: "48",
  },
  {
    label: "Cherkasy Oblast",
    countryCode: "UA",
    value: "71",
  },
  {
    label: "Khmelnytsky Oblast",
    countryCode: "UA",
    value: "68",
  },
  {
    label: "Ivano-Frankivsk Oblast",
    countryCode: "UA",
    value: "26",
  },
  {
    label: "Rivne Oblast",
    countryCode: "UA",
    value: "56",
  },
  {
    label: "Kherson Oblast",
    countryCode: "UA",
    value: "65",
  },
  {
    label: "Sumy Oblast",
    countryCode: "UA",
    value: "59",
  },
  {
    label: "Kharkiv Oblast",
    countryCode: "UA",
    value: "63",
  },
  {
    label: "Zaporizhzhya Oblast",
    countryCode: "UA",
    value: "23",
  },
  {
    label: "Odessa Oblast",
    countryCode: "UA",
    value: "51",
  },
  {
    label: "Autonomous Republic of Crimea",
    countryCode: "UA",
    value: "43",
  },
  {
    label: "Volyn Oblast",
    countryCode: "UA",
    value: "07",
  },
  {
    label: "Donetsk Oblast",
    countryCode: "UA",
    value: "14",
  },
  {
    label: "Chernihiv Oblast",
    countryCode: "UA",
    value: "74",
  },
  {
    label: "Gabrovo Province",
    countryCode: "BG",
    value: "07",
  },
  {
    label: "Smolyan Province",
    countryCode: "BG",
    value: "21",
  },
  {
    label: "Pernik Province",
    countryCode: "BG",
    value: "14",
  },
  {
    label: "Montana Province",
    countryCode: "BG",
    value: "12",
  },
  {
    label: "Vidin Province",
    countryCode: "BG",
    value: "05",
  },
  {
    label: "Razgrad Province",
    countryCode: "BG",
    value: "17",
  },
  {
    label: "Blagoevgrad Province",
    countryCode: "BG",
    value: "01",
  },
  {
    label: "Sliven Province",
    countryCode: "BG",
    value: "20",
  },
  {
    label: "Plovdiv Province",
    countryCode: "BG",
    value: "16",
  },
  {
    label: "Kardzhali Province",
    countryCode: "BG",
    value: "09",
  },
  {
    label: "Kyustendil Province",
    countryCode: "BG",
    value: "10",
  },
  {
    label: "Haskovo Province",
    countryCode: "BG",
    value: "26",
  },
  {
    label: "Sofia City Province",
    countryCode: "BG",
    value: "22",
  },
  {
    label: "Pleven Province",
    countryCode: "BG",
    value: "15",
  },
  {
    label: "Stara Zagora Province",
    countryCode: "BG",
    value: "24",
  },
  {
    label: "Silistra Province",
    countryCode: "BG",
    value: "19",
  },
  {
    label: "Veliko Tarnovo Province",
    countryCode: "BG",
    value: "04",
  },
  {
    label: "Lovech Province",
    countryCode: "BG",
    value: "11",
  },
  {
    label: "Vratsa Province",
    countryCode: "BG",
    value: "06",
  },
  {
    label: "Pazardzhik Province",
    countryCode: "BG",
    value: "13",
  },
  {
    label: "Ruse Province",
    countryCode: "BG",
    value: "18",
  },
  {
    label: "Targovishte Province",
    countryCode: "BG",
    value: "25",
  },
  {
    label: "Burgas Province",
    countryCode: "BG",
    value: "02",
  },
  {
    label: "Yambol Province",
    countryCode: "BG",
    value: "28",
  },
  {
    label: "Varna Province",
    countryCode: "BG",
    value: "03",
  },
  {
    label: "Dobrich Province",
    countryCode: "BG",
    value: "08",
  },
  {
    label: "Sofia Province",
    countryCode: "BG",
    value: "23",
  },
  {
    label: "Suceava County",
    countryCode: "RO",
    value: "SV",
  },
  {
    label: "Hunedoara County",
    countryCode: "RO",
    value: "HD",
  },
  {
    label: "Arges",
    countryCode: "RO",
    value: "AG",
  },
  {
    label: "Bihor County",
    countryCode: "RO",
    value: "BH",
  },
  {
    label: "Alba",
    countryCode: "RO",
    value: "AB",
  },
  {
    label: "Ilfov County",
    countryCode: "RO",
    value: "IF",
  },
  {
    label: "Giurgiu County",
    countryCode: "RO",
    value: "GR",
  },
  {
    label: "Tulcea County",
    countryCode: "RO",
    value: "TL",
  },
  {
    label: "Teleorman County",
    countryCode: "RO",
    value: "TR",
  },
  {
    label: "Prahova County",
    countryCode: "RO",
    value: "PH",
  },
  {
    label: "Bucharest",
    countryCode: "RO",
    value: "B",
  },
  {
    label: "Neamț County",
    countryCode: "RO",
    value: "NT",
  },
  {
    label: "Călărași County",
    countryCode: "RO",
    value: "CL",
  },
  {
    label: "Bistrița-Năsăud County",
    countryCode: "RO",
    value: "BN",
  },
  {
    label: "Cluj County",
    countryCode: "RO",
    value: "CJ",
  },
  {
    label: "Iași County",
    countryCode: "RO",
    value: "IS",
  },
  {
    label: "Braila",
    countryCode: "RO",
    value: "BR",
  },
  {
    label: "Constanța County",
    countryCode: "RO",
    value: "CT",
  },
  {
    label: "Olt County",
    countryCode: "RO",
    value: "OT",
  },
  {
    label: "Arad County",
    countryCode: "RO",
    value: "AR",
  },
  {
    label: "Botoșani County",
    countryCode: "RO",
    value: "BT",
  },
  {
    label: "Sălaj County",
    countryCode: "RO",
    value: "SJ",
  },
  {
    label: "Dolj County",
    countryCode: "RO",
    value: "DJ",
  },
  {
    label: "Ialomița County",
    countryCode: "RO",
    value: "IL",
  },
  {
    label: "Bacău County",
    countryCode: "RO",
    value: "BC",
  },
  {
    label: "Dâmbovița County",
    countryCode: "RO",
    value: "DB",
  },
  {
    label: "Satu Mare County",
    countryCode: "RO",
    value: "SM",
  },
  {
    label: "Galați County",
    countryCode: "RO",
    value: "GL",
  },
  {
    label: "Timiș County",
    countryCode: "RO",
    value: "TM",
  },
  {
    label: "Harghita County",
    countryCode: "RO",
    value: "HR",
  },
  {
    label: "Gorj County",
    countryCode: "RO",
    value: "GJ",
  },
  {
    label: "Mehedinți County",
    countryCode: "RO",
    value: "MH",
  },
  {
    label: "Vaslui County",
    countryCode: "RO",
    value: "VS",
  },
  {
    label: "Caraș-Severin County",
    countryCode: "RO",
    value: "CS",
  },
  {
    label: "Covasna County",
    countryCode: "RO",
    value: "CV",
  },
  {
    label: "Sibiu County",
    countryCode: "RO",
    value: "SB",
  },
  {
    label: "Buzău County",
    countryCode: "RO",
    value: "BZ",
  },
  {
    label: "Vâlcea County",
    countryCode: "RO",
    value: "VL",
  },
  {
    label: "Vrancea County",
    countryCode: "RO",
    value: "VN",
  },
  {
    label: "Brașov County",
    countryCode: "RO",
    value: "BV",
  },
  {
    label: "Maramureș County",
    countryCode: "RO",
    value: "MM",
  },
  {
    label: "Aiga-i-le-Tai",
    countryCode: "WS",
    value: "AL",
  },
  {
    label: "Satupa'itea",
    countryCode: "WS",
    value: "SA",
  },
  {
    label: "A'ana",
    countryCode: "WS",
    value: "AA",
  },
  {
    label: "Fa'asaleleaga",
    countryCode: "WS",
    value: "FA",
  },
  {
    label: "Atua",
    countryCode: "WS",
    value: "AT",
  },
  {
    label: "Vaisigano",
    countryCode: "WS",
    value: "VS",
  },
  {
    label: "Palauli",
    countryCode: "WS",
    value: "PA",
  },
  {
    label: "Va'a-o-Fonoti",
    countryCode: "WS",
    value: "VF",
  },
  {
    label: "Gaga'emauga",
    countryCode: "WS",
    value: "GE",
  },
  {
    label: "Tuamasaga",
    countryCode: "WS",
    value: "TU",
  },
  {
    label: "Gaga'ifomauga",
    countryCode: "WS",
    value: "GI",
  },
  {
    label: "Torba",
    countryCode: "VU",
    value: "TOB",
  },
  {
    label: "Penama",
    countryCode: "VU",
    value: "PAM",
  },
  {
    label: "Shefa",
    countryCode: "VU",
    value: "SEE",
  },
  {
    label: "Malampa",
    countryCode: "VU",
    value: "MAP",
  },
  {
    label: "Sanma",
    countryCode: "VU",
    value: "SAM",
  },
  {
    label: "Tafea",
    countryCode: "VU",
    value: "TAE",
  },
  {
    label: "Honiara",
    countryCode: "SB",
    value: "CT",
  },
  {
    label: "Temotu Province",
    countryCode: "SB",
    value: "TE",
  },
  {
    label: "Isabel Province",
    countryCode: "SB",
    value: "IS",
  },
  {
    label: "Choiseul Province",
    countryCode: "SB",
    value: "CH",
  },
  {
    label: "Makira-Ulawa Province",
    countryCode: "SB",
    value: "MK",
  },
  {
    label: "Malaita Province",
    countryCode: "SB",
    value: "ML",
  },
  {
    label: "Central Province",
    countryCode: "SB",
    value: "CE",
  },
  {
    label: "Guadalcanal Province",
    countryCode: "SB",
    value: "GU",
  },
  {
    label: "Western Province",
    countryCode: "SB",
    value: "WE",
  },
  {
    label: "Rennell and Bellona Province",
    countryCode: "SB",
    value: "RB",
  },
  {
    label: "Burgundy",
    countryCode: "FR",
    value: "D",
  },
  {
    label: "Auvergne",
    countryCode: "FR",
    value: "C",
  },
  {
    label: "Picardy",
    countryCode: "FR",
    value: "S",
  },
  {
    label: "Champagne-Ardenne",
    countryCode: "FR",
    value: "G",
  },
  {
    label: "Limousin",
    countryCode: "FR",
    value: "L",
  },
  {
    label: "Nord-Pas-de-Calais",
    countryCode: "FR",
    value: "O",
  },
  {
    label: "Saint Barthélemy",
    countryCode: "FR",
    value: "BL",
  },
  {
    label: "Nouvelle-Aquitaine",
    countryCode: "FR",
    value: "NAQ",
  },
  {
    label: "Île-de-France",
    countryCode: "FR",
    value: "IDF",
  },
  {
    label: "Mayotte",
    countryCode: "FR",
    value: "YT",
  },
  {
    label: "Auvergne-Rhône-Alpes",
    countryCode: "FR",
    value: "ARA",
  },
  {
    label: "Occitania",
    countryCode: "FR",
    value: "OCC",
  },
  {
    label: "Alo",
    countryCode: "FR",
    value: "WF-AL",
  },
  {
    label: "Lorraine",
    countryCode: "FR",
    value: "M",
  },
  {
    label: "Pays de la Loire",
    countryCode: "FR",
    value: "PDL",
  },
  {
    label: "Languedoc-Roussillon",
    countryCode: "FR",
    value: "K",
  },
  {
    label: "Normandy",
    countryCode: "FR",
    value: "NOR",
  },
  {
    label: "Franche-Comté",
    countryCode: "FR",
    value: "I",
  },
  {
    label: "Corsica",
    countryCode: "FR",
    value: "COR",
  },
  {
    label: "Brittany",
    countryCode: "FR",
    value: "BRE",
  },
  {
    label: "Aquitaine",
    countryCode: "FR",
    value: "B",
  },
  {
    label: "Saint Martin",
    countryCode: "FR",
    value: "MF",
  },
  {
    label: "Wallis and Futuna",
    countryCode: "FR",
    value: "WF",
  },
  {
    label: "Alsace",
    countryCode: "FR",
    value: "A",
  },
  {
    label: "Provence-Alpes-Côte d'Azur",
    countryCode: "FR",
    value: "PAC",
  },
  {
    label: "Rhône-Alpes",
    countryCode: "FR",
    value: "V",
  },
  {
    label: "Lower Normandy",
    countryCode: "FR",
    value: "P",
  },
  {
    label: "Poitou-Charentes",
    countryCode: "FR",
    value: "T",
  },
  {
    label: "Paris",
    countryCode: "FR",
    value: "75",
  },
  {
    label: "Uvea",
    countryCode: "FR",
    value: "WF-UV",
  },
  {
    label: "Centre-Val de Loire",
    countryCode: "FR",
    value: "CVL",
  },
  {
    label: "Sigave",
    countryCode: "FR",
    value: "WF-SG",
  },
  {
    label: "Grand Est",
    countryCode: "FR",
    value: "GES",
  },
  {
    label: "Saint Pierre and Miquelon",
    countryCode: "FR",
    value: "PM",
  },
  {
    label: "French Guiana",
    countryCode: "FR",
    value: "GF",
  },
  {
    label: "Réunion",
    countryCode: "FR",
    value: "RE",
  },
  {
    label: "French Polynesia",
    countryCode: "FR",
    value: "PF",
  },
  {
    label: "Bourgogne-Franche-Comté",
    countryCode: "FR",
    value: "BFC",
  },
  {
    label: "Upper Normandy",
    countryCode: "FR",
    value: "Q",
  },
  {
    label: "Martinique",
    countryCode: "FR",
    value: "MQ",
  },
  {
    label: "Hauts-de-France",
    countryCode: "FR",
    value: "HDF",
  },
  {
    label: "Guadeloupe",
    countryCode: "FR",
    value: "GP",
  },
  {
    label: "West New Britain Province",
    countryCode: "PG",
    value: "WBK",
  },
  {
    label: "Bougainville",
    countryCode: "PG",
    value: "NSB",
  },
  {
    label: "Jiwaka Province",
    countryCode: "PG",
    value: "JWK",
  },
  {
    label: "Hela",
    countryCode: "PG",
    value: "HLA",
  },
  {
    label: "East New Britain",
    countryCode: "PG",
    value: "EBR",
  },
  {
    label: "Morobe Province",
    countryCode: "PG",
    value: "MPL",
  },
  {
    label: "Sandaun Province",
    countryCode: "PG",
    value: "SAN",
  },
  {
    label: "Port Moresby",
    countryCode: "PG",
    value: "NCD",
  },
  {
    label: "Oro Province",
    countryCode: "PG",
    value: "NPP",
  },
  {
    label: "Gulf",
    countryCode: "PG",
    value: "GPK",
  },
  {
    label: "Western Highlands Province",
    countryCode: "PG",
    value: "WHM",
  },
  {
    label: "New Ireland Province",
    countryCode: "PG",
    value: "NIK",
  },
  {
    label: "Manus Province",
    countryCode: "PG",
    value: "MRL",
  },
  {
    label: "Madang Province",
    countryCode: "PG",
    value: "MPM",
  },
  {
    label: "Southern Highlands Province",
    countryCode: "PG",
    value: "SHM",
  },
  {
    label: "Eastern Highlands Province",
    countryCode: "PG",
    value: "EHG",
  },
  {
    label: "Chimbu Province",
    countryCode: "PG",
    value: "CPK",
  },
  {
    label: "Central Province",
    countryCode: "PG",
    value: "CPM",
  },
  {
    label: "Enga Province",
    countryCode: "PG",
    value: "EPW",
  },
  {
    label: "Milne Bay Province",
    countryCode: "PG",
    value: "MBA",
  },
  {
    label: "Western Province",
    countryCode: "PG",
    value: "WPD",
  },
  {
    label: "Ohio",
    countryCode: "US",
    value: "OH",
  },
  {
    label: "Ladakh",
    countryCode: "IN",
    value: "LA",
  },
  {
    label: "West Bengal",
    countryCode: "IN",
    value: "WB",
  },
  {
    label: "Sinop Province",
    countryCode: "TR",
    value: "57",
  },
  {
    label: "Capital District",
    countryCode: "VE",
    value: "A",
  },
  {
    label: "Apure",
    countryCode: "VE",
    value: "C",
  },
  {
    label: "Jalisco",
    countryCode: "MX",
    value: "JAL",
  },
  {
    label: "Roraima",
    countryCode: "BR",
    value: "RR",
  },
  {
    label: "Guarda",
    countryCode: "PT",
    value: "09",
  },
  {
    label: "Devonshire Parish",
    countryCode: "BM",
    value: "DEV",
  },
  {
    label: "Hamilton Parish",
    countryCode: "BM",
    value: "HA",
  },
  {
    label: "Hamilton Municipality",
    countryCode: "BM",
    value: "HAM",
  },
  {
    label: "Paget Parish",
    countryCode: "BM",
    value: "PAG",
  },
  {
    label: "Pembroke Parish",
    countryCode: "BM",
    value: "PEM",
  },
  {
    label: "Saint George's Municipality",
    countryCode: "BM",
    value: "SG",
  },
  {
    label: "Saint George's Parish",
    countryCode: "BM",
    value: "SGE",
  },
  {
    label: "Sandys Parish",
    countryCode: "BM",
    value: "SAN",
  },
  {
    label: "Smith's Parish,",
    countryCode: "BM",
    value: "SMI",
  },
  {
    label: "Southampton Parish",
    countryCode: "BM",
    value: "SOU",
  },
  {
    label: "Warwick Parish",
    countryCode: "BM",
    value: "WAR",
  },
  {
    label: "Huila",
    countryCode: "CO",
    value: "HUI",
  },
  {
    label: "Uroševac District (Ferizaj)",
    countryCode: "XK",
    value: "XUF",
  },
  {
    label: "Đakovica District (Gjakove)",
    countryCode: "XK",
    value: "XDG",
  },
  {
    label: "Gjilan District",
    countryCode: "XK",
    value: "XGJ",
  },
  {
    label: "Kosovska Mitrovica District",
    countryCode: "XK",
    value: "XKM",
  },
  {
    label: "Pristina (Priştine)",
    countryCode: "XK",
    value: "XPI",
  },
  {
    label: "Autonomous City Of Buenos Aires",
    countryCode: "AR",
    value: "C",
  },
  {
    label: "New Providence",
    countryCode: "BS",
    value: "NP",
  },
  {
    label: "Shumen",
    countryCode: "BG",
    value: "27",
  },
  {
    label: "Yuen Long District",
    countryCode: "HK",
    value: "NYL",
  },
  {
    label: "Tsuen Wan District",
    countryCode: "HK",
    value: "NTW",
  },
  {
    label: "Tai Po District",
    countryCode: "KH",
    value: "NTP",
  },
  {
    label: "Sai Kung District",
    countryCode: "HK",
    value: "NSK",
  },
  {
    label: "Islands District",
    countryCode: "HK",
    value: "NIS",
  },
  {
    label: "Central and Western District",
    countryCode: "HK",
    value: "HCW",
  },
  {
    label: "Wan Chai",
    countryCode: "HK",
    value: "HWC",
  },
  {
    label: "Eastern",
    countryCode: "HK",
    value: "HEA",
  },
  {
    label: "Southern",
    countryCode: "HK",
    value: "HSO",
  },
  {
    label: "Yau Tsim Mong",
    countryCode: "HK",
    value: "KYT",
  },
  {
    label: "Sham Shui Po",
    countryCode: "HK",
    value: "KSS",
  },
  {
    label: "Kowloon City",
    countryCode: "HK",
    value: "KKC",
  },
  {
    label: "Wong Tai Sin",
    countryCode: "HK",
    value: "KWT",
  },
  {
    label: "Kwun Tong",
    countryCode: "HK",
    value: "KKT",
  },
  {
    label: "Kwai Tsing",
    countryCode: "HK",
    value: "NKT",
  },
  {
    label: "Tuen Mun",
    countryCode: "HK",
    value: "NTM",
  },
  {
    label: "North",
    countryCode: "HK",
    value: "NNO",
  },
  {
    label: "Sha Tin",
    countryCode: "HK",
    value: "NST",
  },
  {
    label: "Sidi Bel Abbès",
    countryCode: "DZ",
    value: "22",
  },
  {
    label: "El M'ghair",
    countryCode: "DZ",
    value: "49",
  },
  {
    label: "El Menia",
    countryCode: "DZ",
    value: "50",
  },
  {
    label: "Ouled Djellal",
    countryCode: "DZ",
    value: "51",
  },
  {
    label: "Bordj Baji Mokhtar",
    countryCode: "DZ",
    value: "52",
  },
  {
    label: "Béni Abbès",
    countryCode: "DZ",
    value: "53",
  },
  {
    label: "Timimoun",
    countryCode: "DZ",
    value: "54",
  },
  {
    label: "Touggourt",
    countryCode: "DZ",
    value: "55",
  },
  {
    label: "Djanet",
    countryCode: "DZ",
    value: "56",
  },
  {
    label: "In Salah",
    countryCode: "DZ",
    value: "57",
  },
  {
    label: "In Guezzam",
    countryCode: "DZ",
    value: "58",
  },
  {
    label: "Mureș County",
    countryCode: "RO",
    value: "MS",
  },
  {
    label: "Volgograd Oblast",
    countryCode: "RU",
    value: "VGG",
  },
  {
    label: "La Colle",
    countryCode: "MC",
    value: "CL",
  },
  {
    label: "La Condamine",
    countryCode: "MC",
    value: "CO",
  },
  {
    label: "Moneghetti",
    countryCode: "MC",
    value: "MG",
  },
  {
    label: "Hamadan Province",
    countryCode: "IR",
    value: "13",
  },
  {
    label: "Bogotá",
    countryCode: "CO",
    value: "DC",
  },
  {
    label: "Loreto",
    countryCode: "PE",
    value: "LOR",
  },
  {
    label: "Kutná Hora",
    countryCode: "CZ",
    value: "205",
  },
  {
    label: "Kōchi Prefecture",
    countryCode: "JP",
    value: "39",
  },
  {
    label: "Cần Thơ",
    countryCode: "VN",
    value: "CT",
  },
  {
    label: "Rivers",
    countryCode: "NG",
    value: "RI",
  },
  {
    label: "Rabat-Salé-Kénitra",
    countryCode: "MA",
    value: "04",
  },
  {
    label: "Agadir-Ida-Ou-Tanane",
    countryCode: "MA",
    value: "AGD",
  },
  {
    label: "Berrechid",
    countryCode: "MA",
    value: "BRR",
  },
  {
    label: "Casablanca",
    countryCode: "MA",
    value: "CAS",
  },
  {
    label: "Driouch",
    countryCode: "MA",
    value: "DRI",
  },
  {
    label: "Fès",
    countryCode: "MA",
    value: "FES",
  },
  {
    label: "Fquih Ben Salah",
    countryCode: "MA",
    value: "FQH",
  },
  {
    label: "Guercif",
    countryCode: "MA",
    value: "GUF",
  },
  {
    label: "Marrakech",
    countryCode: "MA",
    value: "MAR",
  },
  {
    label: "M’diq-Fnideq",
    countryCode: "MA",
    value: "MDF",
  },
  {
    label: "Meknès",
    countryCode: "MA",
    value: "MEK",
  },
  {
    label: "Midelt",
    countryCode: "MA",
    value: "MID",
  },
  {
    label: "Mohammadia",
    countryCode: "MA",
    value: "MOH",
  },
  {
    label: "Oujda-Angad",
    countryCode: "MA",
    value: "OUJ",
  },
  {
    label: "Ouezzane",
    countryCode: "MA",
    value: "OUZ",
  },
  {
    label: "Rabat",
    countryCode: "MA",
    value: "RAB",
  },
  {
    label: "Rehamna",
    countryCode: "MA",
    value: "REH",
  },
  {
    label: "Salé",
    countryCode: "MA",
    value: "SAL",
  },
  {
    label: "Sidi Bennour",
    countryCode: "MA",
    value: "SIB",
  },
  {
    label: "Sidi Ifni",
    countryCode: "MA",
    value: "SIF",
  },
  {
    label: "Skhirate-Témara",
    countryCode: "MA",
    value: "SKH",
  },
  {
    label: "Tarfaya (EH-partial)",
    countryCode: "MA",
    value: "TAF",
  },
  {
    label: "Tinghir",
    countryCode: "MA",
    value: "TIN",
  },
  {
    label: "Tanger-Assilah",
    countryCode: "MA",
    value: "TNG",
  },
  {
    label: "Youssoufia",
    countryCode: "MA",
    value: "YUS",
  },
  {
    label: "Sidi Slimane",
    countryCode: "MA",
    value: "SIL",
  },
  {
    label: "Lualaba",
    countryCode: "CD",
    value: "LU",
  },
  {
    label: "Chaiyaphum",
    countryCode: "TH",
    value: "36",
  },
  {
    label: "Mbeya",
    countryCode: "TZ",
    value: "14",
  },
  {
    label: "Songwe",
    countryCode: "TZ",
    value: "31",
  },
];
