import {useMatch, useNavigate} from "@tanstack/react-location";
import {Box, Heading, useColorMode, Table, Tag, Tbody, Tr, Td} from "@chakra-ui/react";
import humanizeString from "string-humanize";
import {getCreditNotesStatusColorScheme} from "./utils/getCreditNoteStatusColorScheme";

const CreditNoteCard = (props) => {
  const {data = {}} = props;
  const {amount_available: amountAvailable, amount_refunded: amountRefunded, currency_code: currencyCode, id, status, type} = data;
  const {colorMode} = useColorMode();
  const navigate = useNavigate();

  const {
    params: {subscriptionId},
  } = useMatch();

  const colorScheme = getCreditNotesStatusColorScheme(status);
  const readableStatus = humanizeString(status);
  const readableType = humanizeString(type);

  return (
    <Box
      borderRadius="3px"
      borderWidth="1px"
      borderColor={colorMode === "light" ? "gray.300" : "gray.600"}
      boxShadow={"0px 3px 6px #00000029"}
      padding="20px"
      overflowX={"auto"}
      onClick={() => navigate({to: `/app/subscriptions/${subscriptionId}/credit-notes/${id}`})}
    >
      <Table>
        <Tbody>
          <Tr>
            <Td>
              <Heading fontSize={"20px"} fontWeight={700}>
                Status
              </Heading>
            </Td>
            <Td>
              <Tag colorScheme={colorScheme}>{readableStatus}</Tag>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Heading fontSize={"20px"} fontWeight={700}>
                Type
              </Heading>
            </Td>
            <Td>{readableType}</Td>
          </Tr>
          <Tr>
            <Td>
              <Heading fontSize={"20px"} fontWeight={700}>
                Amount Available
              </Heading>
            </Td>
            <Td>{`${amountAvailable} ${currencyCode}`}</Td>
          </Tr>
          <Tr>
            <Td>
              <Heading fontSize={"20px"} fontWeight={700}>
                Amount Refunded
              </Heading>
            </Td>
            <Td>{`${amountRefunded} ${currencyCode}`}</Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );
};

export default CreditNoteCard;
