import {IconButton} from "@chakra-ui/react";
import {IoMdArrowRoundBack} from "react-icons/io";
import {navigateBack} from "../../utils/navigateBack";
import {BackButtonProps} from "./backButton.types";

export const BackButton = (props: BackButtonProps) => {
  const {icon = <IoMdArrowRoundBack />, size = "lg", ...rest} = props;

  return <IconButton id="back-button" aria-label="back-button-aria" onClick={navigateBack} icon={icon} variant="back-btn" size={size} {...rest} />;
};
