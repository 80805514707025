import React, {useEffect, useMemo} from "react";
import {Box, Heading, SimpleGrid, useMediaQuery} from "@chakra-ui/react";
import {HeaderContainer} from "@onemind-services-llc/ui-components";
import ProductGroupCard from "./productGroupCard";
import ProductGroupLoadingCard from "./ProductGroupLoadingCard";
import {useListBookingProductGroupsBookingsProductsGroupsGet} from "../../../../orval-cml/default/default.ts";
const ProductGroups = ({selectedProductGroup, setSelectedProductGroup}) => {
  const [isMobile] = useMediaQuery("(max-width: 660px)");
  const [isIpad] = useMediaQuery("(max-width: 820px)");
  const arrayWithEmptyValues = useMemo(() => {
    if (isMobile) {
      return Array(1).fill("");
    } else if (isIpad) {
      return Array(2).fill("");
    } else {
      return Array(4).fill("");
    }
  }, [isMobile, isIpad]);

  const {data: {data: {results: productGroupsList = []} = {}} = {}, isLoading, isFetching} = useListBookingProductGroupsBookingsProductsGroupsGet();

  useEffect(() => {
    if (productGroupsList.length > 0 && !selectedProductGroup.id) {
      setSelectedProductGroup(productGroupsList[0]);
    }
  }, [productGroupsList, selectedProductGroup, setSelectedProductGroup]);

  return (
    <Box>
      <HeaderContainer>
        <Box display="flex" alignItems="center">
          <Heading fontWeight={700} fontSize="24px">
            1. Select Product Group
          </Heading>
        </Box>
      </HeaderContainer>
      {isLoading || isFetching ? (
        <SimpleGrid columns={{base: 1, sm: 2, md: 4}} spacing="20px">
          {arrayWithEmptyValues.map((item, index) => {
            return <ProductGroupLoadingCard key={index} />;
          })}
        </SimpleGrid>
      ) : (
        <SimpleGrid columns={{base: 1, sm: 2, md: 4}} spacing="20px">
          {productGroupsList?.length > 0 &&
            productGroupsList.map((productGroup) => {
              return (
                <ProductGroupCard
                  key={productGroup.id}
                  productGroup={productGroup}
                  selectedProductGroup={selectedProductGroup}
                  setSelectedProductGroup={setSelectedProductGroup}
                />
              );
            })}
        </SimpleGrid>
      )}
    </Box>
  );
};

export default ProductGroups;
