import {useQueryClient, FetchQueryOptions} from "@tanstack/react-query";
import {useGetCookies} from "../hooks/useGetCookies";

export const usePrefetchQuery = () => {
  const queryClient = useQueryClient();
  const {cookies} = useGetCookies();
  const {accessToken} = cookies;
  const prefetchQuery = async (options: FetchQueryOptions) => {
    if (accessToken) {
      return await queryClient.prefetchQuery(options);
    } else {
      return;
    }
  };
  return {prefetchQuery};
};
