import React, {useContext, useEffect} from "react";
import {useNavigate, useSearch} from "@tanstack/react-location";
import {Box, Button, Center, Flex, Image, Stack} from "@chakra-ui/react";
import {AppContext} from "../../contexts/appContext";
import {useGetCookies} from "../../hooks/useGetCookies";

const AuthPage = () => {
  const appSettings = useContext(AppContext);
  const {cookies} = useGetCookies();
  const {accessToken} = cookies;
  const navigate = useNavigate();
  const search = useSearch();

  const login = () => {
    navigate({to: "/auth/login", search});
  };

  const loginURL = `/auth/login${search.next ? `?next=${encodeURIComponent(search.next)}` : ""}`;
  const register = () => {
    navigate({to: "/auth/register", search: {login_url: loginURL}});
  };

  useEffect(() => {
    if (accessToken) {
      login();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  return (
    <Box backgroundImage={appSettings.brandingBackground} backgroundPosition="right center" backgroundSize={"cover"} minH="100dvh">
      <Center minH="100dvh" width={{base: "full", md: "50%"}}>
        <Flex
          flexDirection={"column"}
          alignItems="center"
          bg="white"
          padding="80px 20px"
          margin="15px"
          borderRadius={"5px"}
          boxShadow="0px 3px 6px #00000029"
        >
          <Image bg="white" alt={"Brand Logo"} objectFit={"cover"} width={"150px"} src={appSettings.logo} />
          <Stack spacing={4} w={{base: "250px", sm: "300px"}} pt="40px">
            <Button variant={"brand"} type={"submit"} id={"loginButton"} onClick={login}>
              Login
            </Button>
            {appSettings.features.registration && (
              <Button variant={"brand"} type={"submit"} id={"registerButton"} onClick={register}>
                Register
              </Button>
            )}
          </Stack>
        </Flex>
      </Center>
    </Box>
  );
};

export default AuthPage;
