import React, {useState} from "react";
import {useMatch} from "@tanstack/react-location";
import {Table} from "@onemind-services-llc/ui-components";
import useSearchAndSort from "../../../../hooks/useSearchAndSort";
import {useListDeviceModuleBaysApiDcimModuleBaysGet} from "../../../../orval-cml/default/default.ts";
import ModuleBaysCard from "./moduleBaysCard";
import {columnsList, defaultColumns} from "./moduleBaysTableColumns";

const sortOptions = [
  {label: "Name", value: "name"},
  {label: "Description", value: "description"},
];

const ModuleBaysTable = () => {
  const {
    params: {deviceId},
  } = useMatch();
  const {searchKey, setSearchKey, sortString, sortConfig, setSortConfig} = useSearchAndSort(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);

  const {
    data: {data: {results: moduleBaysList = [], count: totalCount = 0} = {}} = {},
    isLoading,
    isFetching,
  } = useListDeviceModuleBaysApiDcimModuleBaysGet({
    device_id: deviceId,
    q: searchKey,
    ordering: sortString,
    limit: pageSize,
    offset: (currentPage - 1) * pageSize,
  });

  return (
    <Table
      card={ModuleBaysCard}
      columns={defaultColumns}
      data={moduleBaysList}
      isLoading={isLoading || isFetching}
      paginationProps={{currentPage, setCurrentPage, pageSize, setPageSize, totalCount}}
      searchProps={{searchKey, setSearchKey}}
      sortProps={{sortConfig, setSortConfig, sortOptions}}
      tableConfigProps={{columnsList}}
    />
  );
};

export default ModuleBaysTable;
