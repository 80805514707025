export const getCreditNotesStatusColorScheme = (status: string) => {
  switch (status) {
    case "voided":
    case "adjusted":
      return "orange";
    case "refund_due":
      return "yellow";
    default:
      return "green";
  }
};
