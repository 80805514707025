import React from "react";
import {Box, useColorModeValue, Skeleton} from "@chakra-ui/react";

const ProductLoadingCard = () => {
  const borderColor = useColorModeValue("gray.300", "gray.600");

  return (
    <Box borderRadius="3px" padding="15px" borderWidth="1px" borderColor={borderColor}>
      <Box borderColor={borderColor} borderBottomWidth="1px" paddingBottom="10px">
        <Skeleton minHeight="120px" />
      </Box>
      <Box paddingTop="10px">
        <Skeleton minHeight="40px" />
      </Box>
    </Box>
  );
};

export default ProductLoadingCard;
