import {createContext} from "react";

type FeatureFlags = {
  baremetal: boolean;
  billing: boolean;
  labs: boolean;
  legacy: boolean;
  registration: boolean;
  cloud_instance: boolean;
};

type AppContextData = {
  auth0ClientId: string;
  auth0Domain: string;
  billingSite: string;
  brandingBackground: string | null;
  features: FeatureFlags;
  logo: string;
};

export const AppContext = createContext<AppContextData>({} as AppContextData);
