import {createDataColumn} from "@onemind-services-llc/ui-components";
import {Link as RLLink, useMatch} from "@tanstack/react-location";
import {Link, Tag, Text} from "@chakra-ui/react";
import humanizeString from "string-humanize";
import {getCreditNotesStatusColorScheme} from "./utils/getCreditNoteStatusColorScheme";

const RenderLink = ({data}) => {
  const {
    params: {subscriptionId},
  } = useMatch();

  return (
    <Link to={`/app/subscriptions/${subscriptionId}/credit-notes/${data.getValue()}`} as={RLLink} color="link.100" preload={1}>
      {data.getValue()}
    </Link>
  );
};

const RenderInvoiceLink = ({data}) => {
  const {
    params: {subscriptionId},
  } = useMatch();

  return (
    <Link to={`/app/subscriptions/${subscriptionId}/invoices/${data.getValue()}`} as={RLLink} color="link.100" preload={1}>
      {data.getValue()}
    </Link>
  );
};

const RenderStatus = ({data}) => {
  const status = data.getValue();
  const colorScheme = getCreditNotesStatusColorScheme(status);
  return <Tag colorScheme={colorScheme}>{humanizeString(status)}</Tag>;
};

export const defaultColumns = [
  createDataColumn("id", {
    cell: (data) => <RenderLink data={data} />,
  }),
  createDataColumn("type", {cell: (data) => <Text>{humanizeString(data.getValue())}</Text>}),
  createDataColumn("status", {cell: (data) => <RenderStatus data={data} />}),
  createDataColumn("reference_invoice_id", {
    header: () => "Reference Invoice",
    cell: (data) => <RenderInvoiceLink data={data} />,
  }),
  createDataColumn("amount_allocated", {
    accessorFn: (row) => `${row.amount_allocated} ${row.currency_code}`,
  }),
  createDataColumn("amount_available", {
    accessorFn: (row) => `${row.amount_available} ${row.currency_code}`,
  }),
  createDataColumn("amount_refunded", {
    accessorFn: (row) => `${row.amount_refunded} ${row.currency_code}`,
  }),
  createDataColumn("price_type", {cell: (data) => <Text>{humanizeString(data.getValue())}</Text>}),
];

export const columnsList = [
  {display: "ID", value: "id", visible: true},
  {display: "Type", value: "type", visible: true},
  {display: "Status", value: "status", visible: true},
  {display: "Amount Allocated", value: "amount_allocated", visible: true},
  {display: "Amount Available", value: "amount_available", visible: true},
  {display: "Amount Refunded", value: "amount_refunded", visible: true},
  {display: "Reference Invoice", value: "reference_invoice_id", visible: false},
  {display: "Price Type", value: "price_type", visible: false},
];
