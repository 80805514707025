import React from "react";
import {Box} from "@chakra-ui/react";
import Overview from "./LegacySubscriptionOverview";

const LegacySubscriptionTabList = [
  {
    label: () => {
      return <Box fontSize={"20px"}>Overview</Box>;
    },
    component: Overview,
  },
];

export default LegacySubscriptionTabList;
