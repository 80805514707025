import React, {Fragment, useMemo, useState} from "react";
import {
  Box,
  Button,
  Heading,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";
import {Loader, SafeView} from "@onemind-services-llc/ui-components";
import {AiOutlineMinus} from "@onemind-services-llc/react-icons-ng/ai";
import {IoAdd} from "@onemind-services-llc/react-icons-ng/io5";
import {sidebarWidth} from "../../../../components/sidebar/sidebar.constants";
import {useAddSubscriptionBillingCheckoutSubscriptionPost} from "../../../../orval-cml";
import {useHandleError} from "../../../../services/handleError";
import {useHandleQuantity} from "./useHandleQuantity";

const PurchaseServer = (props) => {
  const {selectedFrequency, selectedPlan, selectedOS, selectedJumpbox} = props;
  const [checkoutModalOpen, setCheckoutModalOpen] = useState(false);
  const [checkoutUrl, setCheckoutUrl] = useState("");
  const borderColor = useColorModeValue("gray.300", "gray.600");
  const backgroundColor = useColorModeValue("white", "gray.800");
  const {handleError} = useHandleError();
  const {decreaseCount, increaseCount, maxQuantity, minQuantity, serverCount} = useHandleQuantity();

  const {mutate: checkoutSubscription, isLoading} = useAddSubscriptionBillingCheckoutSubscriptionPost({
    mutation: {
      onSuccess: (data) => {
        const checkoutUrl = data?.data?.url;
        if (checkoutUrl) {
          setCheckoutModalOpen(true);
          setCheckoutUrl(checkoutUrl);
        }
      },
      onError: (e) => handleError(e),
    },
  });

  const openCheckout = () => {
    // Add selected plan to payload as a dictionary with the key being the plan id
    const items = [
      {
        item_price_id: selectedPlan?.item_prices[0]?.id,
        quantity: serverCount,
      },
      {
        item_price_id: selectedOS?.item_prices[0]?.id,
        quantity: serverCount,
      },
    ];
    selectedJumpbox.forEach((item) => {
      items.push({
        item_price_id: item?.item_prices[0]?.id,
        quantity: 1,
      });
    });
    const payload = {
      items,
      redirect_url: `${window.location.origin}/app/subscriptions/{{subscription.id}}`,
    };

    checkoutSubscription({data: payload});
  };

  const totalCost = useMemo(() => {
    const baseCost = selectedPlan?.item_prices ? selectedPlan?.item_prices[0]?.price : 0;
    const osCost = selectedOS?.item_prices ? selectedOS?.item_prices[0]?.price : 0;
    let jumpboxCost = 0;
    selectedJumpbox.forEach((item) => {
      jumpboxCost = jumpboxCost + item?.item_prices[0]?.price || 0;
    });
    return (baseCost + osCost) * serverCount + jumpboxCost;
  }, [selectedPlan?.item_prices, selectedOS?.item_prices, selectedJumpbox, serverCount]);

  if (isLoading) {
    return <Loader />;
  }

  const checkoutModalClose = () => {
    setCheckoutModalOpen(false);
    setCheckoutUrl("");
  };

  return (
    <Fragment>
      <Modal isOpen={checkoutModalOpen} onClose={checkoutModalClose} autoFocus={true} closeOnEsc={false} closeOnOverlayClick={false}>
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
        <ModalContent height={"80%"}>
          <ModalBody>
            <iframe src={checkoutUrl} width="100%" height="100%" title="Checkout" allowFullScreen={true} />
          </ModalBody>
          <ModalFooter>
            <Button variant="brand" onClick={checkoutModalClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box
        position="fixed"
        bottom="0px"
        right="0px"
        left={sidebarWidth}
        minH="80px"
        zIndex="banner"
        borderTopWidth="1px"
        borderColor={borderColor}
        bg={backgroundColor}
      >
        <SafeView>
          <Stack
            direction={{base: "column", sm: "row"}}
            alignItems="center"
            justifyContent={{base: "start", md: "space-between"}}
            minHeight="80px"
            py={2}
            spacing={{base: 2, sm: 4}}
          >
            <HStack alignItems="center" spacing={{base: 3, sm: 6}}>
              <HStack alignItems="center" borderWidth="1px" borderColor={borderColor} borderRadius="3px" padding={2} spacing={3}>
                <IconButton icon={<AiOutlineMinus />} onClick={decreaseCount} isDisabled={serverCount === minQuantity} />
                <Heading fontWeight={700} fontSize="20px" color="brand.100">
                  {serverCount}
                </Heading>
                <IconButton icon={<IoAdd />} onClick={increaseCount} isDisabled={serverCount === maxQuantity} />
              </HStack>
              <Heading display="flex" alignItems="baseline" color="brand.100" fontWeight={700} data-cy="total-cost">
                <Box fontSize="30px">{`$${totalCost}/ `}</Box>
                <Box fontSize="18px">{selectedFrequency.display}</Box>
              </Heading>
            </HStack>
            <Box px={{base: 0, md: 16}}>
              <Button variant={"brand"} width={{base: "full", sm: "200px"}} onClick={openCheckout}>
                Proceed to Checkout
              </Button>
            </Box>
          </Stack>
        </SafeView>
      </Box>
    </Fragment>
  );
};

export default PurchaseServer;
