import React, {Fragment} from "react";
import {createRoot} from "react-dom/client";
import TagManager from "react-gtm-module";
import {init as initApm} from "@elastic/apm-rum";
import {ColorModeScript} from "@chakra-ui/react";
import packageJson from "../package.json";
import {theme} from "./theme";
import App from "./App";

const isCI = import.meta.env.VITE_CI === "true" || false;
const isProduction = import.meta.env.MODE === "production";

if (!isCI && isProduction) {
  initApm({
    serviceName: "cml-portal",
    serverUrl: "https://fleet.onemindservices.com:8443",
    serviceVersion: packageJson.version,
    environment: import.meta.env.MODE,
    breakdownMetrics: true,
    centralConfig: true,
  });

  const tagManagerArgs = {
    gtmId: "GTM-PWNN646",
  };

  TagManager.initialize(tagManagerArgs);
}

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <Fragment>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Fragment>
);
