import {Fragment, useContext} from "react";
import {isEmpty} from "lodash";
import {Link} from "@tanstack/react-location";
import {Button, Menu, MenuButton, MenuItem, MenuList} from "@chakra-ui/react";
import {MdKeyboardArrowDown} from "@onemind-services-llc/react-icons-ng/md";
import {TiDeviceDesktop} from "@onemind-services-llc/react-icons-ng/ti";
import {FaNetworkWired} from "@onemind-services-llc/react-icons-ng/fa";
import {AppContext} from "../../contexts/appContext";
import {useGetCookies} from "../../hooks/useGetCookies";
import {useListProductFamiliesBillingProductsProductFamilyGet} from "../../orval-cml/default/default";

const NavigationMenu = () => {
  const {cookies} = useGetCookies();
  const {accessToken} = cookies;
  const appSettings = useContext(AppContext);
  const {features} = appSettings;
  const {labs} = features;

  const {data: {data: {results: productFamilies = []} = {}} = {}} = useListProductFamiliesBillingProductsProductFamilyGet({
    query: {enabled: accessToken ? true : false},
  });

  return (
    <Fragment>
      {(!isEmpty(productFamilies) || labs) && (
        <Menu>
          <MenuButton as={Button} variant={"brand"} rightIcon={<MdKeyboardArrowDown />} data-cy={"buy-dropdown"}>
            Buy
          </MenuButton>
          <MenuList data-cy="buy-dropdown-list">
            {productFamilies.map((item) => (
              <MenuItem key={item.id} icon={<TiDeviceDesktop />} as={Link} to={`/app/add-product/${item.id}`} preload={1}>
                {item.name}
              </MenuItem>
            ))}
            {labs && (
              <MenuItem icon={<FaNetworkWired />} as={Link} to={"/app/learning-labs/buy-product"} preload={1}>
                Learning Labs
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      )}
    </Fragment>
  );
};

export default NavigationMenu;
