export const colors = {
  brand: {
    100: "#1e87f0",
  },
  link: {
    100: "#0d6efd",
  },
  select: {
    100: "#4299e1",
  },
  "table-header-bg": "#1e87f0",
  "table-header-bg-dark": "#2D3748",
  "table-header-color": "#fff",
  "table-header-color-dark": "#EDF2F7",
};
