import React from "react";
import {Box, Heading, useColorModeValue} from "@chakra-ui/react";
import {useProductsListLoader} from "../../../../react-query/queryLoaders/learningLabsLoader";

const ProductGroupCard = ({productGroup, selectedProductGroup, setSelectedProductGroup}) => {
  const {productsListLoader} = useProductsListLoader();
  const borderColor = useColorModeValue("gray.300", "gray.600");
  const textBackgroundColor = useColorModeValue("gray.100", "gray.600");

  const handleSelection = () => {
    setSelectedProductGroup(productGroup);
  };

  return (
    <Box
      borderRadius="3px"
      borderWidth={productGroup.id === selectedProductGroup.id ? "0px" : "1px"}
      borderColor={borderColor}
      boxShadow={productGroup.id === selectedProductGroup.id ? "0 0 0 2px var(--chakra-colors-select-100)" : ""}
      transition="all 0.2s ease"
      _hover={{
        border: "none",
        boxShadow: "0 0 0 2px var(--chakra-colors-select-100)",
        cursor: "pointer",
      }}
      onClick={handleSelection}
      onMouseOver={() => productsListLoader(productGroup.id)}
    >
      <Box minH={"120px"} display="flex" justifyContent={"center"} alignItems="center">
        <img src={productGroup.image_url} alt={productGroup.display} style={{objectFit: "contain", maxHeight: "80px"}} />
      </Box>
      <Box backgroundColor={textBackgroundColor} padding="15px" display={"flex"} justifyContent="center" alignItems={"center"}>
        <Heading fontSize="16px" lineHeight={"1"} fontWeight={700}>
          {productGroup.display}
        </Heading>
      </Box>
    </Box>
  );
};

export default ProductGroupCard;
