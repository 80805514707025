import React from "react";
import {useMatch, useSearch} from "@tanstack/react-location";
import {Button, HStack} from "@chakra-ui/react";
import {Loader} from "@onemind-services-llc/ui-components";
import {useListFrequenciesBillingProductsFrequenciesGet} from "../../../../orval-cml/default/default";

const BillingFrequency = (props) => {
  const {selectedFrequency, setSelectedFrequency} = props;

  const {
    params: {productFamily},
  } = useMatch();

  const search = useSearch();
  const {frequency = ""} = search;

  const successCallback = (response = {}) => {
    const {data = {}} = response;
    const {results = []} = data;
    const defaultFrequency = results.find((item) => item.id === "1_month");
    const filteredFrequency = results.find((item) => {
      return item.id.toLowerCase() === frequency.toLowerCase();
    });
    setSelectedFrequency(filteredFrequency || defaultFrequency || results[0] || {});
  };

  const {data: {data: {results: frequencyList = []} = {}} = {}, isLoading} = useListFrequenciesBillingProductsFrequenciesGet(
    {
      product_family_id: productFamily,
    },
    {
      query: {
        queryKey: ["frequencyList", productFamily],
        onSuccess: successCallback,
      },
    }
  );

  return (
    <HStack alignItems={{base: "start", sm: "center"}}>
      {isLoading ? <Loader /> : null}
      {frequencyList.map((frequency) => {
        const isSelected = selectedFrequency?.id === frequency.id;

        return (
          <Button
            borderWidth={"1px"}
            borderColor={"brand.100"}
            backgroundColor={isSelected ? "brand.100" : "transparent"}
            color={isSelected ? "white" : "brand.100"}
            key={frequency.id}
            onClick={() => setSelectedFrequency(frequency)}
            w={{base: "auto", sm: "180px"}}
            data-cy="billing-frequency"
            _hover={{backgroundColor: "brand.100", color: "white"}}
          >
            {frequency.display}
          </Button>
        );
      })}
    </HStack>
  );
};

export default BillingFrequency;
