import React from "react";
import {Box, Heading, useColorMode, Table, Tbody, Thead, Tr, Th, Td, Tag} from "@chakra-ui/react";
import {Loader} from "@onemind-services-llc/ui-components";
import {useListSecretsApiSecretsSecretsGet} from "../../orval-cml/default/default.ts";

const CredentialsCard = (props) => {
  const {params = {}} = props;
  const {colorMode} = useColorMode();

  const {data: {data: {results: secretsList = []} = {}} = {}, isLoading, isFetching} = useListSecretsApiSecretsSecretsGet(params);

  return (
    <Box
      borderRadius="3px"
      borderWidth="1px"
      borderColor={colorMode === "light" ? "gray.300" : "gray.600"}
      boxShadow={"0px 3px 6px #00000029"}
      padding="20px"
      overflowX={"auto"}
      position="relative"
    >
      {isLoading || isFetching ? <Loader position="absolute" /> : null}
      <Heading fontSize={"24px"} fontWeight={700} marginBottom="10px">
        Credentials
      </Heading>
      <Table>
        <Thead>
          <Tr>
            <Th>Username</Th>
            <Th>Password</Th>
            <Th>Tags</Th>
          </Tr>
        </Thead>
        <Tbody>
          {secretsList?.length > 0 &&
            secretsList.map((secret) => (
              <Tr key={secret.id}>
                <Td>{secret?.display}</Td>
                <Td>{secret?.plaintext}</Td>
                <Td>
                  {secret?.tags.map((o, index) => (
                    <Tag key={index} backgroundColor={`#${o.color}`} marginRight={"5px"}>
                      {o.display}
                    </Tag>
                  ))}
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
      {secretsList?.length === 0 && (
        <Box padding="20px" textAlign="center">
          {"No Credentials Present"}
        </Box>
      )}
    </Box>
  );
};

export default CredentialsCard;
