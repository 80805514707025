import {SafeView, TableContainer} from "@onemind-services-llc/ui-components";
import LegacySubscriptionsTable from "./legacySubscriptionsTable";

const LegacySubscriptionsPage = () => {
  return (
    <SafeView>
      <TableContainer>
        <LegacySubscriptionsTable />
      </TableContainer>
    </SafeView>
  );
};

export default LegacySubscriptionsPage;
