import React, {useState} from "react";
import {Heading, Stack} from "@chakra-ui/react";
import {SafeView} from "@onemind-services-llc/ui-components";
import ProductGroups from "./ProductGroups";
import Products from "./Products";

const BookingPage = () => {
  const [selectedProductGroup, setSelectedProductGroup] = useState({});
  return (
    <SafeView>
      <Stack my={5} spacing={8}>
        <Heading fontWeight="semibold" data-cy="title">
          Buy Learning Lab
        </Heading>
        <ProductGroups selectedProductGroup={selectedProductGroup} setSelectedProductGroup={setSelectedProductGroup} />
        {selectedProductGroup.id && <Products selectedProductGroup={selectedProductGroup} />}
      </Stack>
    </SafeView>
  );
};

export default BookingPage;
