import {useIsMutating} from "@tanstack/react-query";
import {SimpleGrid, Box, useColorModeValue, Table, Tbody, Heading, Tr, Td} from "@chakra-ui/react";
import {Loader} from "@onemind-services-llc/ui-components";
import {useContext} from "react";
import {UserContext} from "../../../contexts/userContext";

const UserProfile = () => {
  const userProfile = useContext(UserContext);
  const borderColor = useColorModeValue("gray.300", "gray.600");
  const isMutating = useIsMutating();

  return (
    <SimpleGrid gap={"20px"} mt="20px" columns={{base: 1, lg: 2}}>
      {isMutating ? <Loader /> : null}
      <Box
        borderRadius="3px"
        borderWidth="1px"
        borderColor={borderColor}
        boxShadow={"0px 3px 6px #00000029"}
        padding="20px"
        overflowX={"auto"}
        position="relative"
      >
        <Heading fontSize={"24px"} fontWeight={700} marginBottom="10px">
          Overview
        </Heading>
        <Table>
          <Tbody>
            <Tr>
              <Td>
                <Heading fontSize={"20px"}>Full Name</Heading>
              </Td>
              <Td>{`${userProfile?.name} ${userProfile?.family_name}`}</Td>
            </Tr>
            <Tr>
              <Td>
                <Heading fontSize={"20px"}>Email</Heading>
              </Td>
              <Td>{userProfile?.email}</Td>
            </Tr>
            <Tr>
              <Td>
                <Heading fontSize={"20px"}>Username</Heading>
              </Td>
              <Td>{userProfile?.username}</Td>
            </Tr>
            <Tr>
              <Td>
                <Heading fontSize={"20px"}>Phone</Heading>
              </Td>
              <Td>{userProfile?.phone}</Td>
            </Tr>
            <Tr>
              <Td>
                <Heading fontSize={"20px"}>Updated At</Heading>
              </Td>
              <Td>
                {new Date(userProfile?.updated_at).toLocaleString(undefined, {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric",
                  timeZoneName: "short",
                })}
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
    </SimpleGrid>
  );
};

export default UserProfile;
