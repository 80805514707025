import {useContext} from "react";
import {Link} from "@tanstack/react-location";
import {Box, Link as ChakraLink, Text} from "@chakra-ui/react";
import {SafeView, TableContainer} from "@onemind-services-llc/ui-components";
import {sidebarWidth} from "../../components/sidebar/sidebar.constants";
import {AppContext} from "../../contexts/appContext";
import SubscriptionsTable from "./subscriptionsTable";

const SubscriptionsPage = () => {
  const appSettings = useContext(AppContext);

  return (
    <SafeView>
      <TableContainer>
        <SubscriptionsTable />
      </TableContainer>

      {appSettings.features.legacy && (
        <Box bg="white" position="fixed" bottom={0} left={sidebarWidth} right={0} p={2} zIndex={1}>
          <Text>
            Subscriptions purchased before 22 May 2023 can be found on the{" "}
            <ChakraLink as={Link} color="blue.500" to={"/app/legacy/subscriptions"}>
              legacy
            </ChakraLink>{" "}
            page.
          </Text>
        </Box>
      )}
    </SafeView>
  );
};

export default SubscriptionsPage;
