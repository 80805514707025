import {useMatch} from "@tanstack/react-location";
import {Box, SimpleGrid} from "@chakra-ui/react";
import {useGetSubscriptionLegacySubscriptionsIdGet} from "../../../../orval-cml";
import {Loader} from "@onemind-services-llc/ui-components";
import LegacySubscriptionCard from "../legacySubscriptionCard";
import {isEmpty} from "lodash";
import LegacySubscriptionItems from "./legacySubscriptionItems";
import DeviceDetailsCardItem from "../../../Baremetal/inc/DeviceDetailsCardItem";
import CloudInstanceDetailsCardItem from "../../../cloudInstance/inc/cloudInstanceDetailsCardItem";

const LegacySubscriptionDetails = () => {
  const {
    params: {subscriptionId},
  } = useMatch();

  const {data: {data: subscriptionDetails = {}} = {}, isLoading} = useGetSubscriptionLegacySubscriptionsIdGet(subscriptionId, {
    query: {
      queryKey: ["legacySubscriptionDetails", subscriptionId],
    },
  });

  return (
    <Box margin="20px 0px">
      {isLoading ? <Loader /> : null}
      <SimpleGrid gap={"20px"} columns={{base: 1, lg: 2}}>
        <LegacySubscriptionCard data={subscriptionDetails} fromTable={false} />
        <Box>
          {!isEmpty(subscriptionDetails.line_items) && (
            <Box mb="15px">
              <LegacySubscriptionItems items={subscriptionDetails.line_items} currencyCode={subscriptionDetails.currency} />
            </Box>
          )}
          {!isEmpty(subscriptionDetails.devices) && (
            <Box mb="15px">
              <DeviceDetailsCardItem objects={subscriptionDetails.devices} />
            </Box>
          )}
          {!isEmpty(subscriptionDetails.cloud_instances) && (
            <Box mb="15px">
              <CloudInstanceDetailsCardItem objects={subscriptionDetails.cloud_instances} />
            </Box>
          )}
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default LegacySubscriptionDetails;
