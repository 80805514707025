import React, {useEffect} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {useNavigate} from "@tanstack/react-location";
import {Loader} from "@onemind-services-llc/ui-components";
import {useSetCookies} from "../../hooks/useSetCookies";

const Callback = () => {
  const auth0 = useAuth0();
  const {getAccessTokenSilently, getIdTokenClaims, isAuthenticated, user} = auth0;
  const {setCookies} = useSetCookies();
  const navigate = useNavigate();

  useEffect(() => {
    const getToken = async () => {
      await getAccessTokenSilently();
      const id_token = await getIdTokenClaims();
      setCookies({accessToken: id_token.__raw, user: user});
      navigate({to: "/app"});
    };
    if (isAuthenticated) {
      getToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <div>
      <Loader />
    </div>
  );
};

export default Callback;
