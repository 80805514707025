import React, {useEffect} from "react";
import {Loader} from "@onemind-services-llc/ui-components";
import {useAuth0Login} from "../../hooks/useAuth0Login";

const Login = () => {
  const {auth0Login} = useAuth0Login();

  useEffect(() => {
    auth0Login();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Loader />;
};

export default Login;
