import {isEmpty} from "lodash";
import {Table} from "@onemind-services-llc/ui-components";
import {useListSubscriptionsLegacySubscriptionsGet} from "../../orval-cml/default/default";
import {defaultColumns, columnsList} from "./legacySubscriptionsTableColumns";
import {useContext} from "react";
import {useUpdateUserMetadataAuthUserMetadataPatch} from "../../orval-cml";
import {UserContext} from "../../contexts/userContext";

const LegacySubscriptionsTable = () => {
  const userProfile = useContext(UserContext);
  const {user_metadata = {}} = userProfile || {};
  const {table = {}} = user_metadata;
  const {legacySubscriptionColumns = []} = table;

  const {data: {data: {results: subscriptionsList = []} = {}} = {}, isLoading} = useListSubscriptionsLegacySubscriptionsGet(
    {},
    {
      query: {
        queryKey: ["legacySubscriptions"],
      },
    }
  );

  const {mutate: updateUserData} = useUpdateUserMetadataAuthUserMetadataPatch();

  const saveTableConfig = (columnList) => {
    updateUserData({
      data: {
        user_metadata: {
          ...user_metadata,
          table: {...table, legacySubscriptionColumns: columnList},
        },
      },
    });
  };

  return (
    <Table
      columns={defaultColumns}
      data={subscriptionsList}
      isLoading={isLoading}
      tableConfigProps={{
        columnsList: isEmpty(legacySubscriptionColumns) ? columnsList : legacySubscriptionColumns,
        saveTableConfig,
      }}
    />
  );
};

export default LegacySubscriptionsTable;
