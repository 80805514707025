import {useEffect, useState} from "react";
import {useMatch} from "@tanstack/react-location";

export const useHandleQuantity = () => {
  const {
    params: {productFamily},
  } = useMatch();

  const isBaremetalCluster = productFamily === "Baremetal-Cluster";
  const minQuantity = isBaremetalCluster ? 2 : 1;
  const maxQuantity = isBaremetalCluster ? 6 : 10;
  const [serverCount, setServerCount] = useState(minQuantity);

  useEffect(() => {
    setServerCount(minQuantity);
  }, [minQuantity]);

  const increaseCount = () => {
    if (serverCount < maxQuantity) {
      setServerCount((prevState) => prevState + 1);
    }
  };

  const decreaseCount = () => {
    if (serverCount > minQuantity) {
      setServerCount((prevState) => prevState - 1);
    }
  };

  return {
    decreaseCount,
    increaseCount,
    maxQuantity,
    minQuantity,
    serverCount,
  };
};
