import React, {Fragment, useContext, useState} from "react";
import {isEmpty} from "lodash";
import {Table} from "@onemind-services-llc/ui-components";
import BookingCard from "../../components/DeviceDetails/bookingDetails";
import {NoDataComponent} from "../../components/noDataComponent";
import {TableHeader} from "../../components/tableHeader";
import {UserContext} from "../../contexts/userContext";
import useSearchAndSort from "../../hooks/useSearchAndSort";
import {useListBookingsBookingsBookingsGet, useUpdateUserMetadataAuthUserMetadataPatch} from "../../orval-cml/default/default";
import {columnsList, defaultColumns} from "./learningLabsTableColumns";

const sortOptions = [
  {label: "ID", value: "id"},
  {label: "Booking", value: "display"},
  {label: "URL", value: "url"},
  {label: "Status", value: "status"},
  {label: "Start Time", value: "start_datetime"},
  {label: "End Time", value: "end_datetime"},
];

const linkOptions = {
  linkText: "Add Learning Lab",
  linkTo: "/app/learning-labs/buy-product",
};

const LearningLabsTable = () => {
  const userProfile = useContext(UserContext);
  const {user_metadata = {}} = userProfile || {};
  const {table = {}} = user_metadata;
  const {learningLabColumns = []} = table;

  const {searchKey, setSearchKey, sortString, sortConfig, setSortConfig} = useSearchAndSort();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);

  const {data: {data: {results: bookingsList = [], count: totalCount = 0} = {}} = {}, isLoading} = useListBookingsBookingsBookingsGet(
    {
      q: searchKey,
      ordering: sortString,
      limit: pageSize,
      offset: (currentPage - 1) * pageSize,
    },
    {
      query: {
        queryKey: ["learningLabs", searchKey, sortString, currentPage, pageSize],
      },
    }
  );

  const {mutate: updateUserData} = useUpdateUserMetadataAuthUserMetadataPatch();

  const saveTableConfig = (columnList) => {
    updateUserData({
      data: {
        user_metadata: {
          ...user_metadata,
          table: {...table, learningLabColumns: columnList},
        },
      },
    });
  };

  return (
    <Fragment>
      <Table
        actionButtons={[bookingsList.length > 0 ? <TableHeader linkText={linkOptions.linkText} linkTo={linkOptions.linkTo} /> : null]}
        card={BookingCard}
        columns={defaultColumns}
        data={bookingsList}
        isLoading={isLoading}
        noDataComponent={
          <NoDataComponent
            description="It looks like you haven't added any learning labs yet. Click the button below to add your first learning lab."
            linkText={linkOptions.linkText}
            linkTo={linkOptions.linkTo}
            title="Get started with your first Learning Lab"
          />
        }
        paginationProps={{currentPage, setCurrentPage, pageSize, setPageSize, totalCount}}
        searchProps={{searchKey, setSearchKey}}
        sortProps={{sortConfig, setSortConfig, sortOptions}}
        tableConfigProps={{
          columnsList: isEmpty(learningLabColumns) ? columnsList : learningLabColumns,
          saveTableConfig,
        }}
      />
    </Fragment>
  );
};

export default LearningLabsTable;
