import React from "react";
import {Box} from "@chakra-ui/react";
import Monitor from "./cloudInstanceMonitor";
import Overview from "./cloudInstanceOverview";
// import Settings from "./cloudInstanceSettings";

const cloudInstanceTabList = [
  {
    label: () => {
      return <Box fontSize={"20px"}>Overview</Box>;
    },
    component: Overview,
  },
  {
    label: () => {
      return <Box fontSize={"20px"}>Monitor</Box>;
    },
    component: Monitor,
  },
  // {
  //   label: () => {
  //     return <Box fontSize={"20px"}>Settings</Box>;
  //   },
  //   component: Settings,
  // },
];

export default cloudInstanceTabList;
