import React from "react";
import {Box} from "@chakra-ui/react";
import CreditNotes from "./CreditNotes";
import Invoices from "./Invoices";
import Overview from "./SubscriptionOverview";

const SubscriptionTabList = [
  {
    label: () => {
      return <Box fontSize={"20px"}>Overview</Box>;
    },
    component: Overview,
  },
  {
    label: () => {
      return <Box fontSize={"20px"}>Invoices</Box>;
    },
    component: Invoices,
  },
  {
    label: () => {
      return <Box fontSize={"20px"}>Credit Notes</Box>;
    },
    component: CreditNotes,
  },
];

export default SubscriptionTabList;
