import {isEmpty} from "lodash";
import {Table} from "@onemind-services-llc/ui-components";
import SubscriptionCard from "./SubscriptionDetails/subscriptionCard";
import {useListSubscriptionsBillingSubscriptionsGet} from "../../orval-cml/default/default";
import {defaultColumns, columnsList} from "./subscriptionsTableColumns";
import {useContext} from "react";
import {useUpdateUserMetadataAuthUserMetadataPatch} from "../../orval-cml";
import {UserContext} from "../../contexts/userContext";

const SubscriptionsTable = () => {
  const userProfile = useContext(UserContext);
  const {user_metadata = {}} = userProfile || {};
  const {table = {}} = user_metadata;
  const {subscriptionColumns = []} = table;

  const {data: {data: {results: subscriptionsList = []} = {}} = {}, isLoading} = useListSubscriptionsBillingSubscriptionsGet({
    query: {
      queryKey: ["subscriptions"],
    },
  });

  const {mutate: updateUserData} = useUpdateUserMetadataAuthUserMetadataPatch();

  const saveTableConfig = (columnList) => {
    updateUserData({
      data: {
        user_metadata: {
          ...user_metadata,
          table: {...table, subscriptionColumns: columnList},
        },
      },
    });
  };

  return (
    <Table
      card={SubscriptionCard}
      columns={defaultColumns}
      data={subscriptionsList}
      isLoading={isLoading}
      tableConfigProps={{
        columnsList: isEmpty(subscriptionColumns) ? columnsList : subscriptionColumns,
        saveTableConfig,
      }}
    />
  );
};

export default SubscriptionsTable;
