import {useCookies} from "react-cookie";
import {COOKIES_CONSTANTS} from "../constants/cookies";

export const useSetCookies = () => {
  const [, setCookie] = useCookies([COOKIES_CONSTANTS.ACCESS_TOKEN, COOKIES_CONSTANTS.AUTH0_USER]);
  const setCookies = (data: {accessToken?: string; user?: object}) => {
    const {accessToken, user} = data;
    const options = {
      path: "/",
      sameSite: true,
    };
    if (accessToken) {
      setCookie(COOKIES_CONSTANTS.ACCESS_TOKEN_KEY, accessToken, options);
    }
    if (user) {
      setCookie(COOKIES_CONSTANTS.AUTH0_USER_KEY, user, options);
    }
  };
  return {setCookies};
};
