import {useIsMutating} from "@tanstack/react-query";
import {isEmpty} from "lodash";
import {Table} from "@onemind-services-llc/ui-components";
import PaymentMethodCard from "./paymentMethodCard";
import {useListCardsBillingCardsGet, useUpdateUserMetadataAuthUserMetadataPatch} from "../../../orval-cml/default/default";
import {defaultColumns, columnsList} from "./paymentMethodTableColumns";
import {useContext} from "react";
import {UserContext} from "../../../contexts/userContext";

const PaymentMethodsTable = () => {
  const userProfile = useContext(UserContext);
  const {user_metadata = {}} = userProfile || {};
  const {table = {}} = user_metadata;
  const {paymentMethodColumns = []} = table;

  const isMutating = useIsMutating();

  const {data: {data: {results: cardsList = []} = {}} = {}, isLoading} = useListCardsBillingCardsGet({
    query: {queryKey: ["paymentMethodsList"]},
  });

  const {mutate: updateUserData} = useUpdateUserMetadataAuthUserMetadataPatch();

  const saveTableConfig = (columnList) => {
    updateUserData({
      data: {
        user_metadata: {
          ...user_metadata,
          table: {...table, paymentMethodColumns: columnList},
        },
      },
    });
  };

  return (
    <Table
      card={PaymentMethodCard}
      columns={defaultColumns}
      data={cardsList}
      isLoading={isMutating || isLoading}
      tableConfigProps={{
        columnsList: isEmpty(paymentMethodColumns) ? columnsList : paymentMethodColumns,
        saveTableConfig,
      }}
    />
  );
};

export default PaymentMethodsTable;
