import React from "react";
import {Box, useColorMode, Heading, Table, Tbody, Tr, Td} from "@chakra-ui/react";

const ModuleBaysCard = (props) => {
  const {data = {}} = props;
  const {colorMode} = useColorMode();

  return (
    <Box
      borderRadius="3px"
      borderWidth="1px"
      borderColor={colorMode === "light" ? "gray.300" : "gray.600"}
      boxShadow={"0px 3px 6px #00000029"}
      padding="20px"
    >
      <Table>
        <Tbody>
          <Tr>
            <Td>
              <Heading fontSize={"20px"} fontWeight={700}>
                Name
              </Heading>
            </Td>
            <Td>{data.name}</Td>
          </Tr>
          <Tr>
            <Td>
              <Heading fontSize={"20px"} fontWeight={700}>
                Description
              </Heading>
            </Td>
            <Td>{data.description}</Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );
};

export default ModuleBaysCard;
