import {Fragment, useContext, useEffect, useState} from "react";
import ReCaptcha from "react-google-recaptcha";
import {Formik, Form} from "formik";
import {useIsMutating} from "@tanstack/react-query";
import {useSearch} from "@tanstack/react-location";
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  Icon,
  Image,
  PinInput,
  PinInputField,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import {Input, Loader} from "@onemind-services-llc/ui-components";
import {AiOutlineMail} from "@onemind-services-llc/react-icons-ng/ai";
import {RiLockPasswordLine} from "@onemind-services-llc/react-icons-ng/ri";
import {AppContext} from "../../contexts/appContext";
import {useRegisterUserAuthRegisterPost, useVerifyOtpAuthVerifyEmailPost} from "../../orval-cml/default/default";
import {useHandleError} from "../../services/handleError";
import {navigateBack} from "../../utils/navigateBack";

const BUTTON_TEXT = {1: "Register", 2: "Validate OTP"};

const Register = () => {
  const [action, setAction] = useState(1);
  const [ipAddress, setIpAddress] = useState();
  const [errors, setErrors] = useState({});
  const isMutating = useIsMutating();
  const appSettings = useContext(AppContext);
  const {handleError} = useHandleError();
  const search = useSearch();
  const {login_url} = search;
  const toast = useToast({status: "success"});

  const initialValues = {
    captcha: "",
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    password: "",
    password_confirm: "",
    otp: "",
  };

  const fetchIP = async () => {
    const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();
    setIpAddress(data.ip);
  };

  useEffect(() => {
    fetchIP();
    return () => {};
  }, []);

  const errorCallback = (error) => {
    handleError(error, setErrors);
  };

  const registerSuccessCallback = () => {
    toast({title: "OTP Sent", description: "Please check your email for OTP"});
    setAction(2);
  };

  const verifySuccessCallback = () => {
    window.location.href = login_url;
  };

  const {mutate: mutateRegister} = useRegisterUserAuthRegisterPost({
    mutation: {
      onSuccess: registerSuccessCallback,
      onError: errorCallback,
    },
  });

  const {mutate: mutateVerify} = useVerifyOtpAuthVerifyEmailPost({
    mutation: {
      onSuccess: verifySuccessCallback,
      onError: errorCallback,
    },
  });

  const onSubmit = (values) => {
    if (action === 1) {
      if (values.captcha) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const {otp, ...rest} = values;
        mutateRegister({data: rest});
      } else {
        setErrors((errors) => ({...errors, captcha: "Please check reCaptcha verification"}));
      }
    } else {
      const hubspot = {
        hutk: document.cookie.match(/hubspotutk=(\w*);/i)?.[1],
        ip_address: ipAddress,
        page_uri: window.location.host + window.location.pathname,
        page_name: document.title,
      };
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const {captcha, ...rest} = values;
      mutateVerify({data: {...rest, hubspot}});
    }
  };

  return (
    <Box backgroundImage={appSettings.brandingBackground} backgroundPosition="right center" backgroundSize={"cover"} minH="100dvh">
      {isMutating ? <Loader /> : null}
      <Center minH="100vh" width={{base: "full", md: "50%"}}>
        <Flex
          flexDirection={"column"}
          alignItems="center"
          bg="white"
          padding="40px 20px"
          margin="15px"
          borderRadius={"5px"}
          boxShadow="0px 3px 6px #00000029"
        >
          <Image alt={"Brand Logo"} objectFit={"cover"} width={"150px"} src={appSettings.logo} />
          <Stack spacing={4} w={{base: "250px", sm: "300px"}} pt="20px">
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
              {(formik) => {
                const {
                  handleChange,
                  handleSubmit,
                  values: {email, password, password_confirm, first_name, last_name, username, otp},
                } = formik;
                return (
                  <Form>
                    <Stack spacing={2} w={"full"}>
                      {action === 1 && (
                        <Fragment>
                          <Input
                            label="First Name"
                            placeholder="Enter First name"
                            name="first_name"
                            value={first_name}
                            onChange={handleChange}
                            isInvalid={errors.first_name}
                            errorMessage={errors.first_name}
                          />
                          <Input
                            label="Last Name"
                            placeholder="Enter Last name"
                            name="last_name"
                            value={last_name}
                            onChange={handleChange}
                            isInvalid={errors.last_name}
                            errorMessage={errors.last_name}
                          />
                          <Input
                            label="Username"
                            placeholder="Enter Username"
                            name="username"
                            value={username}
                            onChange={handleChange}
                            isInvalid={errors.username}
                            errorMessage={errors.username}
                          />
                          <Input
                            label="Email address"
                            placeholder="Enter Email"
                            name="email"
                            value={email}
                            onChange={handleChange}
                            isInvalid={errors.email}
                            errorMessage={errors.email}
                            leftElement={() => <Icon as={AiOutlineMail} color="gray.600" />}
                          />
                          <Input
                            label="Password"
                            placeholder="Enter Password"
                            type="password"
                            name="password"
                            value={password}
                            onChange={handleChange}
                            isInvalid={errors.password}
                            errorMessage={errors.password}
                            leftElement={() => <Icon as={RiLockPasswordLine} color="gray.600" />}
                          />
                          <Input
                            label="Confirm Password"
                            placeholder="Enter Password"
                            type="password"
                            name="password_confirm"
                            value={password_confirm}
                            onChange={handleChange}
                            isInvalid={errors.password_confirm}
                            errorMessage={errors.password_confirm}
                            leftElement={() => <Icon as={RiLockPasswordLine} color="gray.600" />}
                          />
                          <FormControl id="recaptcha" isInvalid={errors.captcha}>
                            <ReCaptcha
                              sitekey={import.meta.env.VITE_GOOGLE_SITE_KEY}
                              onChange={(value) => {
                                handleChange({target: {name: "captcha", value}});
                                if (value) {
                                  setErrors((errors) => ({...errors, captcha: ""}));
                                }
                              }}
                            />
                            <FormErrorMessage>{errors.captcha}</FormErrorMessage>
                          </FormControl>
                        </Fragment>
                      )}
                      {action === 2 && (
                        <FormControl id="otp" isInvalid={errors.otp} mb={4}>
                          <HStack justifyContent="center">
                            <PinInput
                              otp
                              autoFocus={true}
                              type={"number"}
                              value={otp}
                              onChange={(value) => handleChange({target: {name: "otp", value}})}
                              onComplete={handleSubmit}
                            >
                              <PinInputField />
                              <PinInputField />
                              <PinInputField />
                              <PinInputField />
                              <PinInputField />
                              <PinInputField />
                            </PinInput>
                          </HStack>
                          <FormErrorMessage>{errors.otp}</FormErrorMessage>
                        </FormControl>
                      )}
                      <Button variant={"brand"} type={"submit"} id={"registerButton"}>
                        {BUTTON_TEXT[action]}
                      </Button>
                      <Text color="link.100" cursor={"pointer"} textDecoration="underline" onClick={navigateBack} data-cy={"go-back"}>
                        Go Back
                      </Text>
                    </Stack>
                  </Form>
                );
              }}
            </Formik>
          </Stack>
        </Flex>
      </Center>
    </Box>
  );
};

export default Register;
