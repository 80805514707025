import {useErrorToast} from "@onemind-services-llc/ui-components";
import {useLogout} from "../hooks/useLogout";

const validationErrorKeys = ["field_errors", "non_field_errors"];

type HandleErrorType = (error: any, setError?: (error: object) => void) => void;

export const useHandleError = () => {
  const toast = useErrorToast();
  const {logout} = useLogout();

  const getErrorMessage = (errorData) => {
    return errorData?.message || errorData?.error || errorData?.detail;
  };

  const handle401Error = () => {
    logout();
  };

  const handle404Error = (errorData) => {
    toast({description: getErrorMessage(errorData) || "Page not found"});
  };

  const handle500Error = (errorData) => {
    toast({description: getErrorMessage(errorData) || "Internal server error occurred, contact support@cloudmylab.com"});
  };

  const handleValidationError = (errorData, setError) => {
    if (errorData && errorData[validationErrorKeys[0]]) {
      setError(errorData[validationErrorKeys[0]]);
    } else if (errorData && errorData[validationErrorKeys[1]]) {
      for (const message of errorData[validationErrorKeys[1]]) {
        if (message) {
          toast({description: message});
        }
      }
    } else if (typeof errorData === "string") {
      if (errorData) {
        toast({description: errorData});
      }
    } else {
      const errorDescription = errorData?.message || errorData?.error || errorData?.detail;
      if (errorDescription) {
        toast({description: errorDescription});
      }
    }
  };

  const handleError: HandleErrorType = (error, setError = () => {}) => {
    const errorData = error?.response?.data;
    const errorStatus = error?.response?.status;
    setError({});

    switch (errorStatus) {
      case 401:
        handle401Error();
        break;
      case 404:
        handle404Error(errorData);
        break;
      case 500:
      case 0:
        handle500Error(errorData);
        break;
      default:
        handleValidationError(errorData, setError);
        break;
    }
  };

  return {handleError};
};
