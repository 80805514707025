import React from "react";
import {Box} from "@chakra-ui/react";
import Overview from "./baremetalOverview";
import ModuleBays from "./moduleBays";

const BaremetalDetailsTabList = [
  {
    label: () => {
      return <Box fontSize={"20px"}>Overview</Box>;
    },
    component: Overview,
  },
  {
    label: () => {
      return <Box fontSize={"20px"}>Module Bays</Box>;
    },
    component: ModuleBays,
  },
];

export default BaremetalDetailsTabList;
