import React from "react";
import {Box, useColorMode, Skeleton, SkeletonText} from "@chakra-ui/react";

const PlanLoadingCard = () => {
  const {colorMode} = useColorMode();

  return (
    <Box borderRadius="3px" padding="15px" borderWidth="1px" borderColor={colorMode === "light" ? "gray.300" : "gray.600"}>
      <Box borderColor={colorMode === "light" ? "gray.300" : "gray.600"} borderBottomWidth="1px" paddingBottom="10px">
        <Skeleton minHeight="40px" />
      </Box>
      <Box borderColor={colorMode === "light" ? "gray.300" : "gray.600"} borderBottomWidth="1px" padding="10px 0px">
        <SkeletonText mb="4" noOfLines={3} spacing="4" />
      </Box>
      <Box paddingTop="10px">
        <Skeleton minHeight="40px" />
      </Box>
    </Box>
  );
};

export default PlanLoadingCard;
