import {Flex, Button, Box, Heading, Stack} from "@chakra-ui/react";
import {HeaderContainer} from "@onemind-services-llc/ui-components";

const CreditNoteHeader = (props) => {
  const {creditNoteDetails = {}} = props;
  const {pdf_link: pdfLink, total, currency_code: currencyCode} = creditNoteDetails;

  const openPDF = () => {
    window.open(pdfLink, "_blank");
  };
  return (
    <HeaderContainer>
      <Stack direction={{base: "column", sm: "row"}} justifyContent={{base: "start", sm: "space-between"}}>
        <Box>
          <Flex flexDirection={"row"} justifyContent={"start"}>
            <Heading fontWeight={400} fontSize={{base: "20px", sm: "30px"}}>
              {"Credit Note for "}
            </Heading>
            <Heading fontWeight={700} fontSize={{base: "20px", sm: "30px"}}>
              <pre>{`${" "}$${total}${" "}`}</pre>
            </Heading>
            <Heading fontWeight={400} fontSize={{base: "20px", sm: "30px"}}>
              {currencyCode}
            </Heading>
          </Flex>
        </Box>
        <Button variant={"outline"} onClick={openPDF}>
          PDF
        </Button>
      </Stack>
    </HeaderContainer>
  );
};

export default CreditNoteHeader;
