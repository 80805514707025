import {useSearch} from "@tanstack/react-location";
import {useAuth0} from "@auth0/auth0-react";

export const useAuth0Login = () => {
  const {loginWithRedirect} = useAuth0();
  const search = useSearch();

  const auth0Login = () => {
    loginWithRedirect({appState: {next: search.next}});
  };
  return {auth0Login};
};
