import React from "react";
import {useLocation} from "@tanstack/react-location";
import {Box, Heading, HStack, useColorModeValue} from "@chakra-ui/react";
import NavigationMenu from "../navigationMenu";
import ProfileMenu from "../profileMenu";
import {sidebarWidth, topBarHeight} from "../sidebar/sidebar.constants";

const TopBar = () => {
  const location = useLocation();
  const params = location.current.pathname.split("/");
  const headerText = params[2] || "";
  const borderColor = useColorModeValue("gray.300", "gray.600");
  const backgroundColor = useColorModeValue("white", "gray.800");
  const headingColor = useColorModeValue("gray.700", "gray.300");

  return (
    <Box
      position="fixed"
      top="0px"
      right="0px"
      left={sidebarWidth}
      minH={topBarHeight}
      zIndex="banner"
      borderBottomWidth="1px"
      borderColor={borderColor}
      bg={backgroundColor}
    >
      <Box
        position="absolute"
        top="0px"
        bottom="0px"
        left={{
          base: "15px",
          md: "30px",
        }}
        display="flex"
        alignItems="center"
      >
        <Heading fontWeight={700} color={headingColor} data-cy="brand-text" fontSize={"26px"}>
          {headerText.replaceAll("-", " ").replace(/\b\w/g, (char) => char.toUpperCase())}
        </Heading>
      </Box>
      <Box
        position="absolute"
        top="00px"
        bottom="00px"
        right={{
          base: "15px",
          md: "30px",
        }}
        display="flex"
        alignItems="center"
      >
        <HStack alignItems={"center"} spacing={3}>
          <NavigationMenu />
          <ProfileMenu />
        </HStack>
      </Box>
    </Box>
  );
};

export default TopBar;
