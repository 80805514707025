import {stateList} from "../constants/stateList";
export const sortStates = (a, b) => {
  if (a.label < b.label) return -1;
  if (a.label > b.label) return 1;
  return 0;
};

export function getStatesOfCountry(countryCode) {
  if (!countryCode) return [];
  const states = stateList.filter((value) => {
    return value.countryCode === countryCode;
  });
  return states.sort(sortStates);
}
