import {SafeView, TabsContainer, Tabs} from "@onemind-services-llc/ui-components";
import BookingDetailsHeader from "./bookingDetailsHeader";
import tabList from "./bookingDetailsTabList";

const BookingDetails = () => {
  return (
    <SafeView>
      <BookingDetailsHeader />
      <TabsContainer>
        <Tabs tabList={tabList} />
      </TabsContainer>
    </SafeView>
  );
};

export default BookingDetails;
