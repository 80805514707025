import React from "react";
import {createDataColumn} from "@onemind-services-llc/ui-components";
import {Link as RLLink} from "@tanstack/react-location";
import {Link} from "@chakra-ui/react";

export const defaultColumns = [
  createDataColumn("id", {
    cell: (data) => (
      <Link to={`/app/cloud-instances/${data.getValue()}`} preload={1} as={RLLink} color="link.100">
        {data.getValue()}
      </Link>
    ),
  }),
  createDataColumn("name"),
  createDataColumn("primary_ip", {
    header: () => "IP",
    accessorFn: (row) => row.primary_ip?.display,
  }),
  createDataColumn("platform", {
    header: () => "OS",
    accessorFn: (row) => row.platform?.display,
  }),
  createDataColumn("role", {
    accessorFn: (row) => row.role?.display,
  }),
  createDataColumn("status", {
    accessorFn: (row) => row.status?.label,
  }),
  createDataColumn("vcpus", {
    header: () => "vCPU's",
  }),
  createDataColumn("disk", {
    header: () => "SSD",
    accessorFn: (row) => `${row.disk || 0} GB`,
  }),
  createDataColumn("memory", {
    header: () => "RAM",
    accessorFn: (row) => `${Math.round((row.memory || 0) / 1024)} GB`,
  }),
];

export const columnsList = [
  {display: "ID", value: "id", visible: true},
  {display: "Name", value: "name", visible: true},
  {display: "IP", value: "primary_ip", visible: true},
  {display: "OS", value: "platform", visible: true},
  {display: "Role", value: "role", visible: true},
  {display: "Status", value: "status", visible: true},
  {display: "vCPU's", value: "vcpus", visible: false},
  {display: "SSD", value: "disk", visible: false},
  {display: "RAM", value: "memory", visible: false},
];
