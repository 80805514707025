import React, {useCallback, useContext} from "react";
import {Avatar, Box, Heading, Icon, Menu, MenuButton, MenuItem, MenuList, Text, useColorModeValue, useMediaQuery} from "@chakra-ui/react";
import {useNavigate} from "@tanstack/react-location";
import {BiLogOutCircle, BiCreditCardFront} from "@onemind-services-llc/react-icons-ng/bi";
import {UserContext} from "../../contexts/userContext";
import {useLogout} from "../../hooks/useLogout";
import {FaUserAlt} from "@onemind-services-llc/react-icons-ng/fa";
import {MdOutlinePayments} from "@onemind-services-llc/react-icons-ng/md";

const ProfileMenu = () => {
  const navigate = useNavigate();
  const userProfile = useContext(UserContext) || {};
  const {logout} = useLogout();

  const handleSupportClick = useCallback(() => {
    window.open("https://support.cloudmylab.com", "_blank");
  }, []);

  const profileMenuItems = [
    {icon: FaUserAlt, label: "Profile", tab: 0},
    {icon: MdOutlinePayments, label: "Payment Methods", tab: 1},
    {icon: BiCreditCardFront, label: "Credits", tab: 2},
  ];
  const [isMobile] = useMediaQuery("(max-width: 660px)");
  const textColor = useColorModeValue("gray.800", "gray.300");

  return (
    <Menu>
      <MenuButton data-cy="navbar-menu">
        <Box display="flex" alignItems="center">
          <Avatar className="avatar" name={userProfile.name} />
          {!isMobile && (
            <Heading color={textColor} fontSize="20px" fontWeight={700} pl="5px">
              {userProfile.name || "Profile"}
            </Heading>
          )}
        </Box>
      </MenuButton>
      <MenuList>
        {profileMenuItems.map((menu, index) => {
          const {icon, label, tab} = menu;
          return (
            <MenuItem
              key={index}
              onClick={() =>
                navigate({
                  to: "/app/profile",
                  search: {tab},
                })
              }
            >
              <Icon as={icon} /> <Text pl="10px">{label}</Text>
            </MenuItem>
          );
        })}
        <MenuItem onClick={handleSupportClick} data-cy="naybar-support">
          <Icon /> <Text pl="10px">Support</Text>
        </MenuItem>
        <MenuItem onClick={logout} data-cy="navbar-logout">
          <Icon as={BiLogOutCircle} /> <Text pl="5px">{"Logout"}</Text>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default ProfileMenu;
