import {Fragment, useState} from "react";
import isEmpty from "lodash/isEmpty";
import {useMatch} from "@tanstack/react-location";
import {Box, Divider, Heading, Stack} from "@chakra-ui/react";
import {SafeView} from "@onemind-services-llc/ui-components";
import {sidebarWidth, topBarHeight} from "../../../components/sidebar/sidebar.constants";
import FrequencySelection from "./BillingFrequency";
import JumpBox from "./Jumpbox";
import OperatingSystem from "./OperatingSystem";
import PurchaseServer from "./PurchaseServer";
import ServerSize from "./ServerSize/index";
import {useListProductFamiliesBillingProductsProductFamilyGet} from "../../../orval-cml";

const AddProduct = () => {
  const {
    params: {productFamily},
  } = useMatch();
  const [selectedFrequency, setSelectedFrequency] = useState({});
  const [selectedOS, setSelectedOS] = useState({});
  const [selectedPlan, setSelectedPlan] = useState({});
  const [selectedJumpbox, setSelectedJumpbox] = useState([]);

  const {data: {data: {results: productFamilies = []} = {}} = {}} = useListProductFamiliesBillingProductsProductFamilyGet();

  return (
    <SafeView>
      <Box position="fixed" top={topBarHeight} right="0px" left={sidebarWidth} zIndex="sticky" bg={"gray.100"} boxShadow="md" px={6} py={3}>
        <Stack alignItems="center" direction={{base: "column", md: "row"}} justify="space-between">
          <Heading fontWeight="semibold" data-cy="title">
            {productFamilies.find((item) => item.id === productFamily)?.display}
          </Heading>
          <FrequencySelection selectedFrequency={selectedFrequency} setSelectedFrequency={setSelectedFrequency} />
        </Stack>
      </Box>

      <Stack spacing={8} mt={{base: "125px", md: "90px"}}>
        {/**margin top is height of top bar + height of frequency selection*/}
        {isEmpty(selectedFrequency) ? null : (
          <Fragment>
            <OperatingSystem selectedFrequency={selectedFrequency} selectedOS={selectedOS} setSelectedOS={setSelectedOS} />
            <Divider />
          </Fragment>
        )}
        {isEmpty(selectedOS) ? null : (
          <ServerSize selectedFrequency={selectedFrequency} selectedOS={selectedOS} selectedPlan={selectedPlan} setSelectedPlan={setSelectedPlan} />
        )}
        {isEmpty(selectedFrequency) ? null : (
          <JumpBox selectedFrequency={selectedFrequency} selectedJumpbox={selectedJumpbox} setSelectedJumpbox={setSelectedJumpbox} />
        )}
      </Stack>
      {!isEmpty(selectedOS) && !isEmpty(selectedPlan) && (
        <Fragment>
          <Box paddingBottom={{base: "125px", sm: "100px"}}></Box>
          <PurchaseServer
            selectedFrequency={selectedFrequency}
            selectedOS={selectedOS}
            selectedPlan={selectedPlan}
            selectedJumpbox={selectedJumpbox}
          />
        </Fragment>
      )}
    </SafeView>
  );
};

export default AddProduct;
