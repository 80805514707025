import React from "react";
import {SafeView, TabsContainer, Tabs} from "@onemind-services-llc/ui-components";
import BareMetalDetailsHeader from "./bareMetalDetailsHeader";
import tabList from "./baremetalDetailsTabList";
import BreadCrumb from "../../../components/breadCrumb";

const BaremetalDetails = () => {
  return (
    <SafeView>
      <BreadCrumb />
      <BareMetalDetailsHeader />
      <TabsContainer>
        <Tabs tabList={tabList} />
      </TabsContainer>
    </SafeView>
  );
};

export default BaremetalDetails;
