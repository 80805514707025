import {Box, Flex, Heading, Icon, useColorModeValue, Tooltip} from "@chakra-ui/react";
import {iconName} from "./iconmap";

const PlanCard = (props) => {
  const {isSelected, onSelection, plan = {}, selectedOS} = props;
  const {config, description, item_prices = [], name} = plan;
  const {memory, num_ssd, ssd, vcpu} = config;
  const itemPrice = item_prices[0] || {};
  const {item_prices: osItemPrices = []} = selectedOS;
  const osItemPrice = osItemPrices[0] || {};
  const cardBorderColor = useColorModeValue("gary.300", "gray.600");
  const basePrice = itemPrice.price || 0;
  const osPrice = osItemPrice.price || 0;
  const totalPrice = basePrice + osPrice;

  return (
    <Tooltip label={description} aria-label={description}>
      <Box
        borderRadius="3px"
        padding="15px"
        borderWidth={isSelected ? "0px" : "1px"}
        borderColor={cardBorderColor}
        boxShadow={isSelected ? "0 0 0 2px var(--chakra-colors-select-100)" : ""}
        transition="all 0.2s ease"
        _hover={{
          border: "none",
          boxShadow: "0 0 0 2px var(--chakra-colors-select-100)",
          cursor: "pointer",
        }}
        onClick={onSelection}
        data-cy="choose-size-card"
      >
        <Box
          borderColor={cardBorderColor}
          borderBottomWidth="1px"
          paddingBottom="5px"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          data-cy="choose-size-card-name"
        >
          <Heading fontWeight={700} fontSize="18px">
            {name}
          </Heading>
          <Box>
            <Icon as={iconName(name)} color={"brand.100"} boxSize={6} />
          </Box>
        </Box>
        <Box borderColor={cardBorderColor} borderBottomWidth="1px">
          <Box display="flex" alignItems="baseline" paddingTop="5px" data-cy="choose-size-card-vcpu">
            <Heading fontWeight={700} fontSize="18px">
              {vcpu}
            </Heading>
            <Box fontSize="16px" ml="5px">
              vCPU
            </Box>
          </Box>
          <Box display="flex" alignItems="baseline" paddingTop="5px" data-cy="choose-size-card-memory">
            <Heading fontWeight={700} fontSize="18px">
              {`${memory} GB`}
            </Heading>
            <Box fontSize="16px" ml="5px">
              {"RAM"}
            </Box>
          </Box>
          <Box display="flex" alignItems="baseline" paddingTop="5px" data-cy="choose-size-card-ssd">
            <Heading fontWeight={700} fontSize="18px">
              {`${ssd} GB `}
            </Heading>
            <Box fontSize="16px" ml="5px">
              {"SSD"} {num_ssd ? `x ${num_ssd}` : ""}
            </Box>
          </Box>
        </Box>
        <Flex paddingTop="10px" alignItems={"baseline"} data-cy="choose-size-card-price">
          <Heading fontSize={"20px"} fontWeight={700}>{`$${totalPrice}`}</Heading>
          <Box>{`/ ${itemPrice.period} ${itemPrice.period_unit}`}</Box>
        </Flex>
      </Box>
    </Tooltip>
  );
};

export default PlanCard;
