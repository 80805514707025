import React, {Fragment} from "react";
import {Heading, SimpleGrid, Stack, useBreakpointValue} from "@chakra-ui/react";
import {useMatch, useSearch} from "@tanstack/react-location";
import NoData from "../../../../components/noData";
import {useListPlansBillingProductsPlansGet} from "../../../../orval-cml/default/default";
import PlanCard from "./PlanCard";
import PlanLoadingCard from "./PlanLoadingCard";

const ServerSize = (props) => {
  const {selectedFrequency, selectedOS, selectedPlan, setSelectedPlan} = props;
  const {
    params: {productFamily},
  } = useMatch();

  const search = useSearch();
  const {plan = ""} = search;

  const arraySize = useBreakpointValue({base: 1, sm: 2, md: 4, lg: 6});
  const arrayWithEmptyValues = Array(arraySize).fill("");

  const successCallback = (response = {}) => {
    const {data = {}} = response;
    const {results = []} = data;
    const filteredPlan = results.find((item) => {
      return item.id.toLowerCase() === plan.toLowerCase();
    });
    setSelectedPlan(filteredPlan || results[0] || {});
  };

  const {data: {data: {results: plansList = []} = {}} = {}, isLoading} = useListPlansBillingProductsPlansGet(
    {
      product_family_id: productFamily,
      period_unit: selectedFrequency.unit,
      period: selectedFrequency.period,
    },
    {
      query: {
        queryKey: ["plans", productFamily, selectedFrequency],
        onSuccess: successCallback,
      },
    }
  );

  return (
    <Stack spacing={4}>
      <Heading as="h2" fontSize={"20px"} fontWeight={700} data-cy="choose-size-heading">
        Choose size
      </Heading>
      {isLoading ? (
        <SimpleGrid columns={{base: 1, sm: 2, md: 4, lg: 6}} spacing={5} data-cy="choose-size-loading">
          {arrayWithEmptyValues.map((plan) => {
            return <PlanLoadingCard key={plan.id} />;
          })}
        </SimpleGrid>
      ) : (
        <Fragment>
          {plansList.length > 0 ? (
            <SimpleGrid columns={{base: 1, sm: 2, md: 4, lg: 6}} spacing={5}>
              {plansList.map((plan) => {
                return (
                  <PlanCard
                    key={plan.id}
                    isSelected={plan.id === selectedPlan?.id}
                    onSelection={() => setSelectedPlan(plan)}
                    plan={plan}
                    selectedOS={selectedOS}
                  />
                );
              })}
            </SimpleGrid>
          ) : (
            <Fragment>
              {selectedOS.name && (
                <NoData content={`No plans currently available for ${selectedOS.name} ${selectedFrequency.display} subscription.`} />
              )}
            </Fragment>
          )}
        </Fragment>
      )}
    </Stack>
  );
};

export default ServerSize;
