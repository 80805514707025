import React from "react";
import {TableContainer, SafeView} from "@onemind-services-llc/ui-components";
import LearningLabsTable from "./learningLabsTable";

const LearningLabsPage = () => {
  return (
    <SafeView>
      <TableContainer>
        <LearningLabsTable />
      </TableContainer>
    </SafeView>
  );
};

export default LearningLabsPage;
