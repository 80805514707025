export const Button = {
  variants: {
    brand: (props) => ({
      bg: props.colorMode === "light" ? "brand.100" : "gray.600",
      color: "white",
    }),
    "pagination-btn": (props) => ({
      bg: props.colorMode === "light" ? "brand.100" : "gray.600",
      color: "white",
    }),
    "back-btn": (props) => ({
      bg: props.colorMode === "light" ? "brand.100" : "gray.600",
      color: "white",
    }),
    "table-config-btn": (props) => ({
      bg: props.colorMode === "light" ? "brand.100" : "gray.600",
      color: "white",
    }),
  },
};
