import {useState, useEffect, useMemo} from "react";
import {useNavigate, useSearch} from "@tanstack/react-location";

const useSearchAndSort = (withRouteParams = true) => {
  const navigate = useNavigate();
  const search = useSearch();

  const getDefaultSearch = () => {
    let searchKey = "";
    if (withRouteParams && search.q) {
      searchKey = search.q as string;
    }
    return searchKey;
  };

  type sortConfigType = {direction?: string; key?: string};

  const getDefaultSortConfig = () => {
    const obj: sortConfigType = {};
    if (withRouteParams && search.sort) {
      obj.direction = "asc";
      obj.key = search?.sort as string;
      if (obj.key?.startsWith("-")) {
        obj.direction = "desc";
        obj.key = obj.key?.slice(1);
      }
    }
    return obj;
  };

  const [searchKey, setSearchKey] = useState(getDefaultSearch());
  const [sortConfig, setSortConfig] = useState(getDefaultSortConfig());

  const sortString = useMemo(() => {
    return sortConfig?.key ? `${sortConfig?.direction === "desc" ? "-" : ""}${sortConfig?.key}` : undefined;
  }, [sortConfig]);

  useEffect(() => {
    if (withRouteParams) {
      navigate({
        search: (old) => ({
          ...old,
          q: searchKey || undefined,
          sort: sortString,
        }),
        replace: true,
      });
    }
  }, [navigate, searchKey, sortString, withRouteParams]);

  return {searchKey, setSearchKey, sortString, sortConfig, setSortConfig};
};

export default useSearchAndSort;
