import React from "react";
import {Box, Heading, useColorModeValue} from "@chakra-ui/react";

const OSCard = (props) => {
  const {os = {}, isSelected, onSelection} = props;

  const cardBorderColor = useColorModeValue("gary.300", "gray.600");
  const nameBackgroundColor = useColorModeValue("gray.100", "gray.600");

  return (
    <Box
      borderRadius="3px"
      borderWidth={isSelected ? "0px" : "1px"}
      borderColor={cardBorderColor}
      boxShadow={isSelected ? "0 0 0 2px var(--chakra-colors-select-100)" : ""}
      _hover={{
        border: "none",
        boxShadow: "0 0 0 2px var(--chakra-colors-select-100)",
        cursor: "pointer",
      }}
      onClick={onSelection}
      data-cy={"os-card"}
    >
      <Box minH={"120px"} display="flex" justifyContent={"center"} alignItems="center">
        <img src={os.image_url} alt={os.name} style={{objectFit: "contain", maxHeight: "80px"}} />
      </Box>
      <Box backgroundColor={nameBackgroundColor} padding="15px" display={"flex"} justifyContent="center" alignItems={"center"}>
        <Heading fontSize="16px" lineHeight={"1"} fontWeight={700}>
          {os.name}
        </Heading>
      </Box>
    </Box>
  );
};

export default OSCard;
