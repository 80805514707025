import {Box, Flex, Heading} from "@chakra-ui/react";

const InvoiceOverview = (props) => {
  const {invoiceDetails = {}} = props;
  const {amount_due: amountDue, generated_at: generatedDate} = invoiceDetails;

  return (
    <Box>
      <Flex alignItems={"baseline"}>
        <Heading mr="10px" fontSize={"18px"} fontWeight={400} minWidth="150px">
          Invoiced On
        </Heading>
        <Heading fontWeight={700} fontSize={"20px"}>
          {new Date(generatedDate).toLocaleString(undefined, {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </Heading>
      </Flex>
      <Flex alignItems={"baseline"}>
        <Heading mr="10px" fontSize={"18px"} fontWeight={400} minWidth="150px">
          Amount Due
        </Heading>
        <Heading fontWeight={700} fontSize={"20px"}>
          ${amountDue}
        </Heading>
      </Flex>
      <Flex alignItems={"baseline"}>
        <Heading mr="10px" fontSize={"18px"} fontWeight={400} minWidth="150px">
          Billing Period
        </Heading>
        <Heading fontWeight={700} fontSize={"20px"}>
          -
        </Heading>
      </Flex>
      <Flex alignItems={"baseline"}>
        <Heading mr="10px" fontSize={"18px"} fontWeight={400} minWidth="150px">
          Next Billing Date
        </Heading>
        <Heading fontWeight={700} fontSize={"20px"}>
          -
        </Heading>
      </Flex>
    </Box>
  );
};

export default InvoiceOverview;
