import {useCookies} from "react-cookie";
import {COOKIES_CONSTANTS} from "../constants/cookies";

export const useGetCookies = () => {
  type CookiesType = {
    accessToken: string;
    auth0User: object;
  };
  const [cookies] = useCookies([COOKIES_CONSTANTS.ACCESS_TOKEN_KEY, COOKIES_CONSTANTS.AUTH0_USER_KEY]);
  return {cookies: cookies as CookiesType};
};
