import React from "react";
import {SafeView, TableContainer} from "@onemind-services-llc/ui-components";
import BareMetalTable from "./bareMetalTable";

const BareMetalPage = () => {
  return (
    <SafeView>
      <TableContainer>
        <BareMetalTable />
      </TableContainer>
    </SafeView>
  );
};

export default BareMetalPage;
