import {useContext} from "react";
import {useMatch} from "@tanstack/react-location";
import {isEmpty} from "lodash";
import {TableContainer, Table} from "@onemind-services-llc/ui-components";
import {UserContext} from "../../../../contexts/userContext";
import {
  useListSubscriptionCreditNotesBillingSubscriptionsIdCreditNotesGet,
  useUpdateUserMetadataAuthUserMetadataPatch,
} from "../../../../orval-cml/default/default";
import CreditNoteCard from "./creditNoteCard";
import {defaultColumns, columnsList} from "./creditNotesTableColumns";

const Utils = () => {
  const userProfile = useContext(UserContext);
  const {user_metadata = {}} = userProfile || {};
  const {table = {}} = user_metadata;
  const {creditNotesColumns = []} = table;

  const {
    params: {subscriptionId},
  } = useMatch();
  const {data: {data: {results: creditNotes = []} = {}} = {}, isInitialLoading} = useListSubscriptionCreditNotesBillingSubscriptionsIdCreditNotesGet(
    subscriptionId,
    {
      query: {
        queryKey: ["subscriptionCreditNotes", subscriptionId],
        enabled: Boolean(subscriptionId),
      },
    }
  );

  const {mutate: updateUserData} = useUpdateUserMetadataAuthUserMetadataPatch();

  const saveTableConfig = (columnList) => {
    updateUserData({
      data: {
        user_metadata: {
          ...user_metadata,
          table: {...table, invoiceColumns: columnList},
        },
      },
    });
  };
  return (
    <TableContainer>
      <Table
        card={CreditNoteCard}
        columns={defaultColumns}
        data={creditNotes}
        isLoading={isInitialLoading}
        tableConfigProps={{
          columnsList: isEmpty(creditNotesColumns) ? columnsList : creditNotesColumns,
          saveTableConfig,
        }}
      />
    </TableContainer>
  );
};

export default Utils;
