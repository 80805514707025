import React, {useEffect, useMemo, useState} from "react";
import {Auth0Provider} from "@auth0/auth0-react";
import {ChakraProvider} from "@chakra-ui/react";
import {Loader} from "@onemind-services-llc/ui-components";
import {parseSearchWith, ReactLocation, Router, stringifySearchWith} from "@tanstack/react-location";
import {QueryCache, QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";
import axios from "axios";
import {CookiesProvider} from "react-cookie";
import {Scrollbars} from "react-custom-scrollbars-2";
import {AppContext} from "./contexts/appContext";
import {useMainRoutes} from "./routes/mainRoutes";
import {useHandleError} from "./services/handleError";
import {theme} from "./theme";
import "@onemind-services-llc/ui-components/lib/styles.css";
import "@onemind-services-llc/ui-charts/lib/styles.css";
import "./app.css";

const location = new ReactLocation({
  parseSearch: (search) => {
    return parseSearchWith(JSON.parse)(search);
  },
  stringifySearch: (search) => {
    return stringifySearchWith(JSON.stringify)(search);
  },
});

const App = () => {
  const [auth0Domain, setAuth0Domain] = useState("");
  const [auth0ClientId, setAuth0ClientId] = useState("");
  const [billingSite, setBillingSite] = useState("");
  const [features, setFeatures] = useState({
    baremetal: false,
    billing: false,
    labs: false,
    legacy: false,
    registration: false,
    cloud_instance: false,
  });

  const [logo, setLogo] = useState("");
  const [brandingBackground, setBrandingBackground] = useState<string | null>(null);

  useEffect(() => {
    axios.get(`${import.meta.env.VITE_API_BASE_URL}/settings/`).then((response) => {
      setAuth0Domain(response.data.auth0_domain);
      setAuth0ClientId(response.data.auth0_client_id);
      setBillingSite(response.data.billing_site);
      setFeatures(response.data.features);
      setLogo(response.data.logo);
      setBrandingBackground(response.data?.background);
    });
  }, []);

  const onRedirectCallback = (appState = {next: ""}) => {
    const {next} = appState;
    if (next) {
      window.location.replace(next);
    }
  };

  return (
    <AppContext.Provider value={{auth0ClientId, auth0Domain, billingSite, features, logo, brandingBackground}}>
      <Scrollbars style={{width: "100vw", minHeight: "100vh"}} autoHide autoHideTimeout={5000} autoHideDuration={200}>
        {auth0Domain && auth0ClientId ? (
          <Auth0Provider
            domain={auth0Domain}
            clientId={auth0ClientId}
            useCookiesForTransactions={true}
            authorizationParams={{
              redirect_uri: `${window.location.origin}/auth/callback`,
            }}
            onRedirectCallback={onRedirectCallback}
          >
            <CookiesProvider>
              <ChakraSetup>
                <ReactQuerySetup>
                  <RouterSetup />
                  <ReactQueryDevtools initialIsOpen={false} />
                </ReactQuerySetup>
              </ChakraSetup>
            </CookiesProvider>
          </Auth0Provider>
        ) : (
          <Loader />
        )}
      </Scrollbars>
    </AppContext.Provider>
  );
};

export default App;

const RouterSetup = () => {
  const {mainRoutes} = useMainRoutes();

  return <Router routes={mainRoutes} location={location} />;
};

const ChakraSetup = (props: {children: React.ReactNode}) => {
  const {children} = props;

  return (
    <ChakraProvider theme={theme} toastOptions={{defaultOptions: {duration: 9000, isClosable: true, position: "top"}}}>
      {children}
    </ChakraProvider>
  );
};

const ReactQuerySetup = (props: {children: React.ReactNode}) => {
  const {children} = props;
  const {handleError} = useHandleError();
  const queryClient = useMemo(() => {
    return new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: import.meta.env.PROD,
        },
      },
      queryCache: new QueryCache({
        onError: (error) => {
          handleError(error);
        },
      }),
    });
  }, [handleError]);

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};
