import {Box, Button, useColorModeValue, useDisclosure} from "@chakra-ui/react";
import {TableContainer} from "@onemind-services-llc/ui-components";
import {MdAdd} from "@onemind-services-llc/react-icons-ng/md";
import AddPaymentMethod from "./AddPaymentMethod";
import PaymentMethodsTable from "./paymentMethodTable";

const PaymentMethods = () => {
  const {isOpen, onOpen, onClose} = useDisclosure();

  const buttonVariant = useColorModeValue("brand", "solid");

  return (
    <Box position={"relative"}>
      <AddPaymentMethod isOpen={isOpen} onClose={onClose} />
      <Box position={{base: "static", lg: "absolute"}} mt={{base: "15px", lg: "0px"}}>
        <Button variant={buttonVariant} onClick={onOpen} leftIcon={<MdAdd />}>
          Add Card
        </Button>
      </Box>
      <TableContainer>
        <PaymentMethodsTable />
      </TableContainer>
    </Box>
  );
};

export default PaymentMethods;
