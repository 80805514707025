import React from "react";
import {Box, Heading, useColorMode, Table, Tbody, Thead, Tr, Th, Td} from "@chakra-ui/react";

const LegacySubscriptionItems = (props) => {
  const {items = [], currencyCode = ""} = props;
  const {colorMode} = useColorMode();

  return (
    <Box
      borderRadius="3px"
      borderWidth="1px"
      borderColor={colorMode === "light" ? "gray.300" : "gray.600"}
      boxShadow={"0px 3px 6px #00000029"}
      padding="20px"
      overflowX={"auto"}
      position="relative"
    >
      <Heading fontSize={"24px"} fontWeight={700} marginBottom="10px">
        Subscription Items
      </Heading>
      <Table>
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Amount</Th>
            <Th>Quantity</Th>
          </Tr>
        </Thead>
        <Tbody>
          {items.length > 0 &&
            items.map((item, index) => (
              <Tr key={index}>
                <Td>{item.display}</Td>
                <Td>{`${item.total} ${currencyCode} `}</Td>
                <Td>{item.quantity}</Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
      {items.length === 0 && (
        <Box padding="20px" textAlign="center">
          {"No Items Present"}
        </Box>
      )}
    </Box>
  );
};

export default LegacySubscriptionItems;
