import {Fragment} from "react";
import {createDataColumn} from "@onemind-services-llc/ui-components";
import {Link as RLLink} from "@tanstack/react-location";
import {Box, Link} from "@chakra-ui/react";

export const defaultColumns = [
  createDataColumn("id", {
    cell: (data) => (
      <Link to={`/app/learning-labs/${data.getValue()}`} as={RLLink} color="link.100" preload={1}>
        {data.getValue()}
      </Link>
    ),
  }),
  createDataColumn("display"),
  createDataColumn("status", {
    accessorFn: (row) => row.status?.label,
  }),
  createDataColumn("start_datetime", {
    header: () => "START DATE",
    accessorFn: (row) => row.start_datetime?.split("T")[0],
  }),
  createDataColumn("end_datetime", {
    header: () => "END DATE",
    accessorFn: (row) => row.end_datetime?.split("T")[0],
  }),
  createDataColumn("booked_products", {
    header: () => "Products",
    cell: (data) => {
      return (
        <Box>
          {data.getValue().map((product, index) => (
            <div key={index}>{product.display} </div>
          ))}
        </Box>
      );
    },
  }),
  createDataColumn("devices", {
    cell: (data) => {
      return (
        <Fragment>
          {data.getValue().map((device = {}, index) => (
            <Fragment key={index}>
              <Link to={`/app/baremetal/${device.id}`} as={RLLink} color="link.100">
                {device.display}
              </Link>
              <div>{index < data.getValue().length - 1 ? ", " : ""}</div>
            </Fragment>
          ))}
        </Fragment>
      );
    },
  }),
  createDataColumn("virtual_machines", {
    header: () => "Cloud Instances",
    cell: (data) => {
      return (
        <Fragment>
          {data.getValue().map((device = {}, index) => (
            <Fragment key={index}>
              <Link to={`/app/cloud-instances/${device.id}`} as={RLLink} color="link.100">
                {device.display}
              </Link>
              <div>{index < data.getValue().length - 1 ? ", " : ""}</div>
            </Fragment>
          ))}
        </Fragment>
      );
    },
  }),
];

export const columnsList = [
  {display: "ID", value: "id", visible: true},
  {display: "Booking", value: "display", visible: true},
  {display: "Status", value: "status", visible: true},
  {display: "Start Time", value: "start_datetime", visible: true},
  {display: "End Time", value: "end_datetime", visible: true},
  {display: "Products", value: "booked_products", visible: true},
  {display: "Devices", value: "devices", visible: true},
  {display: "Cloud Instances", value: "virtual_machines", visible: false},
];
