import React from "react";
import {TableContainer, SafeView} from "@onemind-services-llc/ui-components";
import CloudInstanceTable from "./cloudInstanceTable";

const CloudInstances = () => {
  return (
    <SafeView>
      <TableContainer>
        <CloudInstanceTable />
      </TableContainer>
    </SafeView>
  );
};

export default CloudInstances;
