import React from "react";
import {Navigate} from "@tanstack/react-location";
import {useRoutes} from "./routes";

export const useAppLayoutRoutes = () => {
  const {routes} = useRoutes();
  let appLayoutRoutes = [];

  routes.forEach((route) => {
    const {path, element, loader = () => {}, children = []} = route;
    appLayoutRoutes.push({
      path: path,
      children: [
        {
          path: "/",
          element,
          loader,
        },
        ...children,
      ],
    });
  });

  appLayoutRoutes = [
    ...appLayoutRoutes,
    {
      path: "/",
      element: <Navigate to="/app/subscriptions" />,
    },
    {
      path: "*",
      element: <Navigate to="/app/subscriptions" />,
    },
  ];

  return {appLayoutRoutes};
};
