import {createDataColumn} from "@onemind-services-llc/ui-components";
import {Link as RLLink} from "@tanstack/react-location";
import {Link} from "@chakra-ui/react";

export const defaultColumns = [
  createDataColumn("id", {
    cell: (data) => (
      <Link to={`/app/subscriptions/${data.getValue()}`} as={RLLink} color="link.100" preload={1}>
        {data.getValue()}
      </Link>
    ),
  }),
  createDataColumn("status"),
  createDataColumn("billing_period", {
    accessorFn: (row) => `Every ${row.billing_period} ${row.billing_period_unit}`,
    isPlaceholder: true,
  }),
  createDataColumn("start_date", {header: () => "Started On"}),
  createDataColumn("next_billing_at", {header: () => "Next Billing On"}),
  createDataColumn("created_at", {header: () => "Created On"}),
  createDataColumn("activated_at", {header: () => "Activated On"}),
  createDataColumn("updated_at", {header: () => "Updated On"}),
  createDataColumn("due_invoices_count"),
  createDataColumn("due_since"),
  createDataColumn("remaining_billing_cycles"),
  createDataColumn("total_dues"),
];

export const columnsList = [
  {display: "ID", value: "id", visible: true},
  {display: "Status", value: "status", visible: true},
  {display: "Billing period", value: "billing_period", visible: true},
  {display: "Started on", value: "start_date", visible: true},
  {display: "Next billing on", value: "next_billing_at", visible: true},
  {display: "Created on", value: "created_at", visible: true},
  {display: "Activated on", value: "activated_at", visible: false},
  {display: "Updated on", value: "updated_at", visible: true},
  {display: "Due invoices count", value: "due_invoices_count", visible: false},
  {display: "Due since", value: "due_since", visible: false},
  {
    display: "Remaining billing cycles",
    value: "remaining_billing_cycles",
    visible: false,
  },
  {display: "Total dues", value: "total_dues", visible: false},
];
