import React from "react";
import {Navigate} from "@tanstack/react-location";
import AuthPage from "../views/Auth";
import Callback from "../views/Auth/callback";
import Login from "../views/Auth/login";
import Logout from "../views/Auth/logout";
import Register from "../views/Auth/register";

export const authLayoutRoutes = [
  {
    path: "/",
    element: <AuthPage />,
  },
  {path: "/callback", element: <Callback />},
  {path: "/login", element: <Login />},
  {path: "/logout", element: <Logout />},
  {path: "/register", element: <Register />},
  {
    path: "*",
    element: <Navigate to="/auth" />,
  },
];
