import React, {useMemo} from "react";
import {useMatch} from "@tanstack/react-location";

import {Box, Flex, HStack, Button, Heading, useMediaQuery} from "@chakra-ui/react";
import {HeaderContainer, Loader, ActionMenu} from "@onemind-services-llc/ui-components";
import {FiMonitor} from "@onemind-services-llc/react-icons-ng/fi";
import {useGetCloudInstanceApiCloudInstanceIdGet} from "../../../orval-cml/default/default";

const CloudInstanceHeader = () => {
  const [isMobileOrIpad] = useMediaQuery("(max-width: 820px)");

  const {
    params: {cloudInstanceId},
  } = useMatch();
  const {data: {data: cloudInstanceDetails = {}} = {}, isLoading} = useGetCloudInstanceApiCloudInstanceIdGet(cloudInstanceId, {
    query: {
      queryKey: ["cloudInstanceDetails", cloudInstanceId],
    },
  });

  const actionMenuList = useMemo(() => {
    let list = [
      {
        label: "VPN Site",
        icon: <FiMonitor />,
        onClick: () => {
          window.open(cloudInstanceDetails.vpn_server, "_blank");
        },
      },
    ];
    return list;
  }, [cloudInstanceDetails]);

  return (
    <Box>
      <HeaderContainer>
        {isLoading ? <Loader /> : null}
        <Flex justifyContent={"space-between"} alignItems="start">
          <Box>
            <Heading fontSize={"20px"} fontWeight={700}>
              {cloudInstanceDetails?.display}
            </Heading>
          </Box>
          {isMobileOrIpad ? (
            <ActionMenu menuList={actionMenuList} />
          ) : (
            <HStack spacing={"10px"}>
              <Button as="a" href={cloudInstanceDetails.vpn_server} leftIcon={<FiMonitor />} size={"md"} target={"_blank"} variant={"brand"}>
                VPN Site
              </Button>
            </HStack>
          )}
        </Flex>
      </HeaderContainer>
    </Box>
  );
};

export default CloudInstanceHeader;
