import React from "react";
import {Box, Heading, useColorModeValue} from "@chakra-ui/react";
import {useNavigate} from "@tanstack/react-location";

const ProductCard = ({product = {}}) => {
  const navigate = useNavigate();
  const borderColor = useColorModeValue("gray.300", "gray.600");
  const textBackgroundColor = useColorModeValue("gray.100", "gray.600");

  const handleSelection = () => {
    navigate({
      to: `/app/learning-labs/buy-product/${product.id}`,
      search: {
        product: {
          display: product.display,
          product_id: product.product_id,
          category_id: product.group?.category_id,
          account_host: product.account_host,
        },
      },
    });
  };

  return (
    <Box
      borderRadius="3px"
      borderWidth="1px"
      borderColor={borderColor}
      _hover={{
        border: "none",
        boxShadow: "0 0 0 2px var(--chakra-colors-select-100)",
        cursor: "pointer",
      }}
      onClick={handleSelection}
    >
      <Box minH={"120px"} display="flex" justifyContent={"center"} alignItems="center">
        <img src={product.image_url} alt={product.display} style={{objectFit: "contain", maxHeight: "80px"}} />
      </Box>
      <Box backgroundColor={textBackgroundColor} padding="15px" display={"flex"} justifyContent="center" alignItems={"center"}>
        <Heading fontSize="16px" lineHeight={"1"} fontWeight={700}>
          {product.display}
        </Heading>
      </Box>
    </Box>
  );
};

export default ProductCard;
