import {Link} from "@tanstack/react-location";
import {Button, Heading, Text, VStack} from "@chakra-ui/react";
import {BsDatabaseAdd} from "@onemind-services-llc/react-icons-ng/bs";

type Props = {
  description: string;
  linkIcon?: React.ReactElement;
  linkText: string;
  linkTo: string;
  title: string;
};

export const NoDataComponent = (props: Props) => {
  const {description, linkIcon = <BsDatabaseAdd />, linkText, linkTo, title} = props;
  return (
    <VStack px={8} py={12} spacing={4} borderWidth="1px" data-cy="get-started">
      <Heading fontSize={"1.5rem"}>{title}</Heading>
      <Text>{description}</Text>
      <Button as={Link} variant={"brand"} leftIcon={linkIcon} to={linkTo}>
        {linkText}
      </Button>
    </VStack>
  );
};
