import {useNavigate} from "@tanstack/react-location";
import {Box, Heading, useColorMode, Table, Tbody, Tr, Td} from "@chakra-ui/react";

const BaremetalOverview = (props) => {
  const {data: deviceDetails = {}, fromTable = true} = props;
  const {colorMode} = useColorMode();
  const navigate = useNavigate();

  const handleCardClick = () => {
    if (fromTable) {
      navigate({to: `/app/baremetal/${deviceDetails.id}`});
    }
  };

  return (
    <Box
      borderRadius="3px"
      borderWidth="1px"
      borderColor={colorMode === "light" ? "gray.300" : "gray.600"}
      boxShadow={"0px 3px 6px #00000029"}
      padding="20px"
      onClick={handleCardClick}
    >
      <Heading fontSize={"24px"} fontWeight={700} marginBottom="10px">
        {fromTable ? deviceDetails.display : "Overview"}
      </Heading>
      <Table>
        <Tbody>
          <Tr>
            <Td>
              <Heading fontSize={"20px"} fontWeight={700}>
                Status
              </Heading>
            </Td>
            <Td>{deviceDetails.status?.label}</Td>
          </Tr>
          <Tr>
            <Td>
              <Heading fontSize={"20px"} fontWeight={700}>
                OS
              </Heading>
            </Td>
            <Td>{deviceDetails?.platform?.display}</Td>
          </Tr>
          <Tr>
            <Td>
              <Heading fontSize={"20px"} fontWeight={700}>
                IP Address
              </Heading>
            </Td>
            <Td>{deviceDetails?.primary_ip?.display}</Td>
          </Tr>
          <Tr>
            <Td>
              <Heading fontSize={"20px"} fontWeight={700}>
                Role
              </Heading>
            </Td>
            <Td>{deviceDetails?.device_role?.display}</Td>
          </Tr>
          <Tr>
            <Td>
              <Heading fontSize={"20px"} fontWeight={700}>
                Type
              </Heading>
            </Td>
            <Td>{`${deviceDetails?.device_type?.manufacturer?.display || ""} ${deviceDetails?.device_type?.display || ""}`}</Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );
};

export default BaremetalOverview;
