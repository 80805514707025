import {useSearch} from "@tanstack/react-location";
import {SafeView, TabsContainer, Tabs} from "@onemind-services-llc/ui-components";
import tabList from "./profileTabs";
const Profile = () => {
  const search = useSearch();
  const {tab = 0} = search;

  return (
    <SafeView>
      <TabsContainer>
        <Tabs defaultActiveTab={tab} tabList={tabList} />
      </TabsContainer>
    </SafeView>
  );
};

export default Profile;
