import {SafeView, TabsContainer, Tabs} from "@onemind-services-llc/ui-components";
import LegacySubscriptionHeader from "./legacySubscriptionHeader";
import tabList from "./legacySubscriptionTabList";

const LegacySubscriptionDetails = () => {
  return (
    <SafeView>
      <LegacySubscriptionHeader />
      <TabsContainer>
        <Tabs tabList={tabList} />
      </TabsContainer>
    </SafeView>
  );
};

export default LegacySubscriptionDetails;
