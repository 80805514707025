import {useMatch} from "@tanstack/react-location";
import {useIsMutating, useQueryClient} from "@tanstack/react-query";
import {
  Button,
  Flex,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
} from "@chakra-ui/react";
import {HeaderContainer, Loader} from "@onemind-services-llc/ui-components";
import {useCancelSubscriptionLegacySubscriptionsIdCancelPatch, useGetSubscriptionLegacySubscriptionsIdGet} from "../../../orval-cml";
import React, {useState} from "react";
import {useHandleError} from "../../../services/handleError";

const LegacySubscriptionHeader = () => {
  const isMutating = useIsMutating();
  const {handleError} = useHandleError();
  const queryClient = useQueryClient();
  const [isCancel, setIsCancel] = useState(false);

  const {
    params: {subscriptionId},
  } = useMatch();

  const {data: {data: subscriptionDetails = {}} = {}} = useGetSubscriptionLegacySubscriptionsIdGet(subscriptionId, {
    query: {
      queryKey: ["legacySubscriptionDetails", subscriptionId],
    },
  });

  const {display, status} = subscriptionDetails;

  const handleSuccess = () => {
    setIsCancel(false);
    queryClient.invalidateQueries({queryKey: ["legacySubscriptionDetails"]});
  };

  const {mutate: cancelSubscription} = useCancelSubscriptionLegacySubscriptionsIdCancelPatch({
    mutation: {onError: (e) => handleError(e), onSuccess: handleSuccess},
  });

  const buttonVariant = useColorModeValue("brand", "solid");

  const showCancelModal = () => {
    setIsCancel(true);
  };

  const hideCancelModal = () => {
    setIsCancel(false);
  };

  const handleCancelConfirm = () => {
    cancelSubscription({id: subscriptionId});
  };

  return (
    <>
      <HeaderContainer>
        {isMutating ? <Loader /> : null}
        <Flex flexDirection={"row"} justifyContent="space-between">
          <Heading fontSize={"25px"}>#{display}</Heading>
          <HStack>
            {status === "Active" && (
              <Button variant={buttonVariant} width="100px" onClick={showCancelModal}>
                Cancel
              </Button>
            )}
          </HStack>
        </Flex>
      </HeaderContainer>
      <Modal isOpen={isCancel} onClose={hideCancelModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmation</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to cancel this subscription?</ModalBody>
          <ModalFooter>
            <Button colorScheme="yellow" mr={3} onClick={hideCancelModal}>
              Cancel
            </Button>
            <Button variant="brand" onClick={handleCancelConfirm}>
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default LegacySubscriptionHeader;
