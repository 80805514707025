import {Box, Heading, useColorMode, Table, Tbody, Tr, Td} from "@chakra-ui/react";
import {BooleanDot} from "@onemind-services-llc/ui-components";
import {ActionCell} from "./paymentMethodTableColumns";

const PaymentMethodCard = (props) => {
  const {data = {}} = props;
  const {colorMode} = useColorMode();

  return (
    <Box
      borderRadius="3px"
      borderWidth="1px"
      borderColor={colorMode === "light" ? "gray.300" : "gray.600"}
      boxShadow={"0px 3px 6px #00000029"}
      padding="20px"
      overflowX={"auto"}
      position="relative"
    >
      <Box position={"absolute"} top="10px" right="10px">
        <ActionCell paymentMethod={data} />
      </Box>
      <Table>
        <Tbody>
          <Tr>
            <Td>
              <Heading fontSize={"20px"} fontWeight={700}>
                Name
              </Heading>
            </Td>
            <Td>{`${data.first_name || ""} ${data.last_name || ""}`}</Td>
          </Tr>
          <Tr>
            <Td>
              <Heading fontSize={"20px"} fontWeight={700}>
                Brand
              </Heading>
            </Td>
            <Td>{data.brand}</Td>
          </Tr>
          <Tr>
            <Td>
              <Heading fontSize={"20px"} fontWeight={700}>
                Number
              </Heading>
            </Td>
            <Td>{data.masked_number}</Td>
          </Tr>
          <Tr>
            <Td>
              <Heading fontSize={"20px"} fontWeight={700}>
                Expiry
              </Heading>
            </Td>
            <Td>{`${data.expiry_month} / ${data.expiry_year}`}</Td>
          </Tr>
          <Tr>
            <Td>
              <Heading fontSize={"20px"} fontWeight={700}>
                Status
              </Heading>
            </Td>
            <Td>{data.status}</Td>
          </Tr>
          <Tr>
            <Td>
              <Heading fontSize={"20px"} fontWeight={700}>
                Primary
              </Heading>
            </Td>
            <Td>
              <BooleanDot value={data.is_primary} />
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Heading fontSize={"20px"} fontWeight={700}>
                Backup
              </Heading>
            </Td>
            <Td>
              <BooleanDot value={data.is_backup} />
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );
};

export default PaymentMethodCard;
