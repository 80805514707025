import {extendTheme} from "@chakra-ui/react";
import {breakpoints} from "./breakpoints";
import {colors} from "./colors";
import {components} from "./components";
import {config} from "./config";
import {fonts} from "./fonts";

export const theme = extendTheme({
  breakpoints,
  colors,
  components,
  config,
  fonts,
});
