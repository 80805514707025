import React from "react";
import {Box, Heading, Link, Table, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react";
import {Link as RLLink} from "@tanstack/react-location";

const CloudInstanceDetailsCardItem = (props) => {
  const {objects} = props;

  return (
    <Box borderRadius="3px" borderWidth="1px" boxShadow={"0px 3px 6px #00000029"} padding="20px" position={"relative"} overflowX="auto">
      <Heading fontSize={"24px"} fontWeight={700} marginBottom="10px">
        Cloud Instances
      </Heading>
      <Table size="sm">
        <Thead>
          <Tr>
            <Th textTransform={"none"}>ID</Th>
            <Th textTransform={"none"}>Name</Th>
            <Th textTransform={"none"}>Status</Th>
            <Th textTransform={"none"}>IP Address</Th>
            <Th textTransform={"none"}>Role</Th>
          </Tr>
        </Thead>
        {objects?.length > 0 && (
          <Tbody>
            {objects?.map((object, index) => (
              <Tr key={index}>
                <Td>
                  <Link key={index} to={`/app/cloud-instances/${object?.id}`} as={RLLink} color="link.100">
                    {object?.id}
                  </Link>
                </Td>
                <Td>
                  <Link key={index} to={`/app/cloud-instances/${object?.id}`} as={RLLink} color="link.100">
                    {object?.display}
                  </Link>
                </Td>
                <Td>{object?.status?.label}</Td>
                <Td>{object?.primary_ip?.display}</Td>
                <Td>{object?.role?.display}</Td>
              </Tr>
            ))}
          </Tbody>
        )}
      </Table>
      {objects?.length === 0 && (
        <Box padding="20px" textAlign="center">
          {"No Devices Found"}
        </Box>
      )}
    </Box>
  );
};

export default CloudInstanceDetailsCardItem;
