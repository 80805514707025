import {useMatch} from "@tanstack/react-location";
import {Box, Flex, Divider, Tag, useColorModeValue} from "@chakra-ui/react";
import {SafeView, Loader} from "@onemind-services-llc/ui-components";
import humanizeString from "string-humanize";
import {useGetSubscriptionInvoiceBillingSubscriptionsSubscriptionIdInvoicesIdGet} from "../../../../../orval-cml/default/default";
import {getInvoiceStatusColorScheme} from "../utils/getInvoiceStatusColorScheme";
import InvoiceHeader from "./invoiceHeader";
import InvoiceOverview from "./invoiceOverview";
import InvoiceItems from "./invoiceItems";
import BreadCrumb from "../../../../../components/breadCrumb";

const InvoiceDetails = () => {
  const {
    params: {subscriptionId, invoiceId},
  } = useMatch();

  const {data: {data: invoiceDetails = {}} = {}, isLoading} = useGetSubscriptionInvoiceBillingSubscriptionsSubscriptionIdInvoicesIdGet(
    subscriptionId,
    invoiceId,
    {
      query: {queryKey: ["invoiceDetails", subscriptionId, invoiceId]},
    }
  );

  const {status, date} = invoiceDetails;

  const borderColor = useColorModeValue("gray.300", "gray.600");
  return (
    <SafeView>
      {isLoading ? <Loader /> : null}
      <BreadCrumb />
      <Flex my="20px" justifyContent={"center"}>
        <Box
          width={{base: "100%", lg: "800px", xl: "1000px"}}
          borderRadius="3px"
          borderWidth="1px"
          borderColor={borderColor}
          boxShadow={"0px 3px 6px #00000029"}
          padding="20px"
          overflowX={"auto"}
          position="relative"
        >
          {status && (
            <Tag colorScheme={getInvoiceStatusColorScheme(status)} borderTopRadius="none" position={"absolute"} top="0px" size="md">
              {`${humanizeString(status)} on`}{" "}
              {new Date(date).toLocaleString(undefined, {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                timeZoneName: "short",
              })}
            </Tag>
          )}
          <InvoiceHeader invoiceDetails={invoiceDetails} />
          <InvoiceOverview invoiceDetails={invoiceDetails} />
          <Divider my="25px" borderColor={borderColor} />
          <InvoiceItems invoiceDetails={invoiceDetails} />
        </Box>
      </Flex>
    </SafeView>
  );
};

export default InvoiceDetails;
