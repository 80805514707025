import {useContext} from "react";
import {FaNetworkWired, FaFileInvoiceDollar} from "@onemind-services-llc/react-icons-ng/fa";
import {FiDatabase} from "@onemind-services-llc/react-icons-ng/fi";
import {TiDeviceDesktop} from "@onemind-services-llc/react-icons-ng/ti";
import {AppContext} from "../contexts/appContext";
import {useLearningLabsDetailsLoader} from "../react-query/queryLoaders/learningLabsLoader";
import {useBaremetalDetailsLoader} from "../react-query/queryLoaders/baremetalLoader";
import {useSubscriptionDetailsLoader, useInvoiceDetailsLoader, useCreditNoteDetailsLoader} from "../react-query/queryLoaders/subscriptionsLoader";
import {useCloudInstanceDetailsLoader} from "../react-query/queryLoaders/cloudInstanceLoaders";
import AddProduct from "../views/Products/AddProduct";
import LearningLabsPage from "../views/learningLabs";
import LearningLabsBookingPage from "../views/learningLabs/BookingPage";
import BookProduct from "../views/learningLabs/BookingPage/bookProduct";
import BookingDetails from "../views/learningLabs/BookingDetails";
import BareMetalPage from "../views/Baremetal";
import BareMetalDetails from "../views/Baremetal/BaremetalDetails";
import CloudInstances from "../views/cloudInstance";
import CloudInstanceDetails from "../views/cloudInstance/cloudInstanceDetails";
import SubscriptionsPage from "../views/Subscriptions";
import SubscriptionDetails from "../views/Subscriptions/SubscriptionDetails";
import InvoiceDetails from "../views/Subscriptions/SubscriptionDetails/Invoices/invoiceDetails";
import CreditNotesDetails from "../views/Subscriptions/SubscriptionDetails/CreditNotes/creditNoteDetails";
import Profile from "../views/Profile";
import LegacySubscriptionsPage from "../views/LegacySubscriptions";
import LegacySubscriptionDetails from "../views/LegacySubscriptions/LegacySubscriptionDetails";
import ProtectedRoute from "./ProtectedRoute";

export const useRoutes = () => {
  const appSettings = useContext(AppContext);
  const {features} = appSettings;
  const {baremetal, labs, cloud_instance} = features;
  const {learningLabsDetailsLoader} = useLearningLabsDetailsLoader();
  const {baremetalDetailsLoader} = useBaremetalDetailsLoader();
  const {creditNoteDetailsLoader} = useCreditNoteDetailsLoader();
  const {invoiceDetailsLoader} = useInvoiceDetailsLoader();
  const {subscriptionDetailsLoader} = useSubscriptionDetailsLoader();
  const {cloudInstanceDetailsLoader} = useCloudInstanceDetailsLoader();
  const routes = [
    {
      name: "Profile",
      path: "profile",
      element: <ProtectedRoute component={Profile} />,
      showInSidebar: false,
    },
    {
      name: "Add Product",
      path: "add-product/:productFamily",
      element: <ProtectedRoute component={AddProduct} />,
      showInSidebar: false,
    },
    {
      name: "Subscriptions",
      path: "subscriptions",
      element: <ProtectedRoute component={SubscriptionsPage} />,
      showInSidebar: true,
      icon: FaFileInvoiceDollar,
      children: [
        {
          path: ":subscriptionId",
          children: [
            {
              path: "/",
              element: <ProtectedRoute component={SubscriptionDetails} />,
              loader: async ({params: {subscriptionId}}) => {
                await subscriptionDetailsLoader(subscriptionId);
              },
            },
            {
              path: "/invoices",
              children: [
                {
                  path: "/:invoiceId",
                  element: <ProtectedRoute component={InvoiceDetails} />,
                  loader: async ({params: {subscriptionId, invoiceId}}) => {
                    await invoiceDetailsLoader(subscriptionId, invoiceId);
                  },
                },
              ],
            },
            {
              path: "/credit-notes",
              children: [
                {
                  path: "/:creditNoteId",
                  element: <ProtectedRoute component={CreditNotesDetails} />,
                  loader: async ({params: {subscriptionId, creditNoteId}}) => {
                    await creditNoteDetailsLoader(subscriptionId, creditNoteId);
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: "Legacy Subscriptions",
      path: "legacy/subscriptions",
      element: <ProtectedRoute component={LegacySubscriptionsPage} />,
      showInSidebar: false,
      children: [
        {
          path: ":subscriptionId",
          children: [
            {
              path: "/",
              element: <ProtectedRoute component={LegacySubscriptionDetails} />,
            },
          ],
        },
      ],
    },
    {
      name: "Learning Labs",
      path: "learning-labs",
      element: <ProtectedRoute component={LearningLabsPage} />,
      showInSidebar: labs,
      icon: FaNetworkWired,
      children: [
        {
          path: "buy-product",
          children: [
            {
              path: "/",
              element: <ProtectedRoute component={LearningLabsBookingPage} />,
            },
            {
              path: ":productId",
              element: <ProtectedRoute component={BookProduct} />,
            },
          ],
        },
        {
          path: ":bookingId",
          element: <ProtectedRoute component={BookingDetails} />,
          loader: async ({params: {bookingId}}) => {
            await learningLabsDetailsLoader(bookingId);
          },
        },
      ],
    },
    {
      name: "Baremetal",
      path: "baremetal",
      element: <ProtectedRoute component={BareMetalPage} />,
      showInSidebar: baremetal,
      icon: FiDatabase,
      children: [
        {
          path: ":deviceId",
          element: <ProtectedRoute component={BareMetalDetails} />,
          loader: async ({params: {deviceId}}) => {
            await baremetalDetailsLoader(deviceId);
          },
        },
      ],
    },
    {
      name: "Cloud Instances",
      path: "cloud-instances",
      element: <ProtectedRoute component={CloudInstances} />,
      showInSidebar: cloud_instance,
      icon: TiDeviceDesktop,
      children: [
        {
          path: ":cloudInstanceId",
          element: <ProtectedRoute component={CloudInstanceDetails} />,
          loader: async ({params: {cloudInstanceId}}) => {
            await cloudInstanceDetailsLoader(cloudInstanceId);
          },
        },
      ],
    },
  ];

  const sidebarRoutes = routes.filter((route) => route.showInSidebar);

  return {routes, sidebarRoutes};
};
