import {Cookies} from "react-cookie";
import {COOKIES_CONSTANTS} from "../constants/cookies";

export default () => {
  return {
    options() {
      const options = {
        path: "/",
        sameSite: true,
      };
      return options;
    },

    setAccessToken(accessToken) {
      const cookies = new Cookies();
      cookies.set(COOKIES_CONSTANTS.ACCESS_TOKEN_KEY, accessToken, this.options());
    },

    setAuth0User(user) {
      const cookies = new Cookies();
      cookies.set(COOKIES_CONSTANTS.AUTH0_USER_KEY, user, this.options());
    },

    getAccessToken() {
      const cookies = new Cookies();
      return cookies.get(COOKIES_CONSTANTS.ACCESS_TOKEN_KEY, this.options());
    },

    getAuth0User() {
      const cookies = new Cookies();
      return cookies.get(COOKIES_CONSTANTS.AUTH0_USER_KEY, this.options());
    },

    clear() {
      const cookies = new Cookies();
      cookies.remove(COOKIES_CONSTANTS.ACCESS_TOKEN_KEY, this.options());
      cookies.remove(COOKIES_CONSTANTS.AUTH0_USER_KEY, this.options());
    },

    isTokenAvailable() {
      return this.getAccessToken() !== undefined;
    },
  };
};
