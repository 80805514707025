import React, {Fragment} from "react";
import {Box, Heading, SimpleGrid, Stack, useBreakpointValue} from "@chakra-ui/react";
import {useMatch, useSearch} from "@tanstack/react-location";
import NoData from "../../../../components/noData";
import {useListOperatingSystemsBillingProductsPlansOsGet} from "../../../../orval-cml/default/default";
import OSCard from "./osCard";
import OsLoadingCard from "./osLoadingCard";

const OperatingSystem = (props) => {
  const {selectedFrequency, selectedOS, setSelectedOS} = props;
  const arraySize = useBreakpointValue({base: 1, sm: 2, md: 4});
  const arrayWithEmptyValues = Array(arraySize).fill("");

  const {
    params: {productFamily},
  } = useMatch();

  const search = useSearch();
  const {os = ""} = search;

  const successCallback = (response = {}) => {
    const {data = {}} = response;
    const {results = []} = data;
    const filteredOs = results.find((item) => {
      return item.id.toLowerCase() === os.toLowerCase();
    });
    setSelectedOS(filteredOs || results[0] || {});
  };

  const {data: {data: {results: osList = []} = {}} = {}, isLoading} = useListOperatingSystemsBillingProductsPlansOsGet(
    {
      product_family_id: productFamily,
      period_unit: selectedFrequency.unit,
      period: selectedFrequency.period,
    },
    {
      query: {
        queryKey: ["os", productFamily, selectedFrequency],
        onSuccess: successCallback,
      },
    }
  );

  return (
    <Stack spacing={4}>
      <Heading as="h2" fontSize={"20px"} fontWeight={700} data-cy="choose-os-heading">
        Choose your Operating System
      </Heading>
      {isLoading ? (
        <SimpleGrid columns={{base: 1, sm: 2, md: 4}} spacing={5} data-cy="os-card-loader">
          {arrayWithEmptyValues.map((item, index) => {
            return <OsLoadingCard key={index} />;
          })}
        </SimpleGrid>
      ) : (
        <Box>
          {osList.length > 0 ? (
            <SimpleGrid columns={{base: 1, sm: 2, md: 4}} spacing={5}>
              {osList.map((os) => {
                return <OSCard key={os.id} isSelected={os.id === selectedOS?.id} os={os} onSelection={() => setSelectedOS(os)} />;
              })}
            </SimpleGrid>
          ) : (
            <Fragment>
              {selectedFrequency.display && <NoData content={`No OS currently available for ${selectedFrequency.display} subscription.`} />}
            </Fragment>
          )}
        </Box>
      )}
    </Stack>
  );
};

export default OperatingSystem;
